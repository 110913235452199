import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Fullscreen } from '@abyss/web/ui/Fullscreen';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { Fragment, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../common/Constants';
import { RecentActivityDrawer } from '../../common/RecentActivity/RecentActivityDrawer';
import { CountySearchContext } from '../../context/CountySearchContext';
import { SearchLocation } from '../../frontends/ProviderSearch/context/SearchLocation';
import {
  ButtonStyles,
  IconStyles,
} from '../../frontends/ProviderSearch/routes/NullSpecialtyPage/NullSpecialtyPage.styles';
import { useDeviceLocation } from '../../hooks/useDeviceLocation';
import { useLagoon } from '../../hooks/useLagoon';
import { useGetRecentSearchesResults } from '../../hooks/useRecentSearches';
import { CardHeader } from '../CardHeader/CardHeader';
import { cardHeaderNullPageStyle, phoneOnly } from '../ConstantsStyles';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { LocationSearchMobile } from '../PSXHeader/LocationSearchMobile/LocationSearchMobile';
import {
  getGeoLocationFromStorage,
  setGeoLocationToStorage,
} from '../PSXHeader/SearchBar/utils';
import { isCounty } from '../SnackCardContainer/utils';
import { getLagoonValueByKey } from '../Utils/getLagoonValueByKey';
import {
  NullResultSectionStyled,
  StyledMarkdown,
  SubHeader,
  Wrapper,
} from './NullResultsPage.styles';

export type NullPageProps = {
  searchTitle: string;
  lagoonData: any;
  specialtySearch?: boolean;
  setSpecialtySearch?: (specialtySearch: boolean) => void;
};

export const NoResult = ({
  searchTitle,
  lagoonData,
  specialtySearch,
  setSpecialtySearch,
}: NullPageProps) => {
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const { t } = useTranslation();
  const [changeLocation, setChangeLocation] = useState();
  const [viewHistory, setViewHistory] = useState();
  const [changeLocationText, setChangeLocationText] = useState('');
  const [viewHistoryText, setViewHistoryText] = useState('');
  const [isOpenLocationChange, setIsOpenLocationChange] =
    useState<Boolean>(false);
  const [, setDeviceLocation] = useState<string>(
    getGeoLocationFromStorage() ?? ''
  );
  const { setIsCountySearch } = useContext(CountySearchContext);

  const mobileScreen = useMediaQuery(phoneOnly);
  const headingKey = Constants.RECENT_ACTIVITY_HEADING_KEY['All'];
  const headingsLagoon = useLagoon('recent-searches')();
  const allCareDrawerHeading = getLagoonValueByKey(
    headingsLagoon,
    headingKey,
    'drawer'
  );
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useGetRecentSearchesResults();

  useEffect(() => {
    const changeLocationElement = document.querySelector('#nullpage-location');
    const viewHistoryElement = document.querySelector('#nullpage-history');
    if (changeLocationElement) {
      const content = changeLocationElement.innerHTML;
      setChangeLocationText(content);
      changeLocationElement.innerHTML = '';
      setChangeLocation(changeLocationElement);
    }
    if (viewHistoryElement) {
      const content = viewHistoryElement.innerHTML;
      setViewHistoryText(content);
      viewHistoryElement.innerHTML = '';
      setViewHistory(viewHistoryElement);
    }
  }, [lagoonData]);
  const handleChangeLocation = () => {
    setIsOpenLocationChange(true);
  };
  const handleOnClickHistory = () => {
    setIsOpen(true);
  };

  const handleBack = () => {
    const updatedToken = tokenizer.update(token, {
      searchType: Constants.SEARCH_TYPES.NAME,
      previous: true,
    });
    navigate(`/results/${updatedToken}`);
  };

  const { getDeviceLocation } = useDeviceLocation(
    setDeviceLocation,
    setIsOpenLocationChange
  );
  const geoLocation = getGeoLocationFromStorage();

  const { name: locationName } = geoLocation;
  if (!lagoonData) return null;
  return (
    <Fragment>
      <NullResultSectionStyled>
        <Wrapper data-auto-testid="null-page" data-testid="null-page">
          <CardHeader
            css={cardHeaderNullPageStyle}
            data-auto-testid="browse-common-specialities-heading"
            data-testid="browse-common-specialities-heading"
            offset={3}
          >
            {lagoonData?.heading} {`'${searchTitle}'`}
          </CardHeader>
          <SubHeader
            color="$gray7"
            css={{
              '&.abyss-heading-root': {
                fontWeight: '$semibold',
                fontSize: '14.22px',
                lineHeight: '16px',
              },
            }}
            data-auto-testid="browse-common-specialities-sub-heading"
            data-testid="browse-common-specialities-sub-heading"
            offset={5}
          >
            {lagoonData.subHeading}
          </SubHeader>
          <StyledMarkdown
            data-auto-testid="null-search-suggestions"
            data-testid="null-search-suggestions"
            options={{
              overrides: {
                ul: {
                  props: {
                    style: {
                      paddingLeft: '1.25rem',
                    },
                  },
                },
              },
            }}
          >
            {lagoonData.content ?? ''}
          </StyledMarkdown>
          {specialtySearch && setSpecialtySearch ? (
            <Button
              css={ButtonStyles}
              data-auto-testid="back-to-previous-page"
              data-testid="back-to-previous-page"
              onClick={handleBack}
              size="$md"
            >
              {t('NULL_PAGE.NULL_RESULTS_BACK_TO_PREVIOUS_PAGE_BUTTON')}
              <IconMaterial
                color="$white"
                css={IconStyles}
                icon="chevron_right"
                isScreenReadable
                size="24px"
              />
            </Button>
          ) : null}
          {viewHistory
            ? createPortal(
                <Text size="$sm">
                  <LinkWithTracking
                    css={{
                      lineHeight: '15px',
                    }}
                    data-auto-testid="null-page-view-history"
                    data-testid="null-page-view-history"
                    isStandardAnchor
                    onClick={handleOnClickHistory}
                    size="14.22px"
                  >
                    {viewHistoryText}
                  </LinkWithTracking>
                </Text>,
                viewHistory
              )
            : ''}
          {changeLocation
            ? createPortal(
                <Text size="$sm">
                  <LinkWithTracking
                    css={{
                      lineHeight: '15px',
                    }}
                    data-auto-testid="null-page-location"
                    data-testid="null-page-location"
                    isStandardAnchor
                    onClick={() => handleChangeLocation()}
                    size="14.22px"
                  >
                    {changeLocationText}
                  </LinkWithTracking>
                </Text>,
                changeLocation
              )
            : ''}
        </Wrapper>
      </NullResultSectionStyled>
      {mobileScreen && isOpenLocationChange ? (
        <Drawer
          css={{
            'abyss-modal-close-button': {
              marginTop: '$md',
              marginRight: '8px',
            },
            'abyss-modal-header-container': {
              marginLeft: '$sm',
            },
            'abyss-modal-content-container': {
              borderRadius: '20px 20px 0px 0px',
            },
          }}
          isOpen={isOpenLocationChange}
          onClose={() => setIsOpenLocationChange(false)}
          position="bottom"
          size="$lg"
          title={
            <Heading color="$gray8" offset={4}>
              {!locationName ? t('My search location') : t('Location Change')}
            </Heading>
          }
        >
          <LocationSearchMobile
            findUserLocation={getDeviceLocation}
            location={locationName}
            setIsOpen={setIsOpenLocationChange}
            setLocation={(targetLocation) => {
              const {
                id,
                center = [],
                place_name: placeName,
                stateCode,
                zipCode,
              } = targetLocation;
              const [selectedLong, selectedLat] = center;
              if (isCounty(id)) setIsCountySearch(true);
              else setIsCountySearch(false);
              setGeoLocationToStorage({
                id,
                name: placeName,
                longitude: selectedLong,
                latitude: selectedLat,
                stateCode,
                zipCode,
              });
            }}
          />
        </Drawer>
      ) : null}
      {!mobileScreen && isOpenLocationChange ? (
        <Fullscreen
          isOpen={isOpenLocationChange}
          onClose={() => setIsOpenLocationChange(false)}
          title=""
        >
          <Fullscreen.Section>
            <SearchLocation isOpenLocationChange={isOpenLocationChange} />
          </Fullscreen.Section>
        </Fullscreen>
      ) : null}
      {data?.recentSearches ? (
        <RecentActivityDrawer
          drawerHeading={allCareDrawerHeading}
          isOpen={isOpen}
          recentSearchesResponse={data?.recentSearches}
          setIsOpen={setIsOpen}
        />
      ) : null}
    </Fragment>
  );
};
