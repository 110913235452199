import { styled } from '@abyss/web/tools/styled';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const TitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'left',
  paddingBottom: '32px',
  paddingTop: '8px',
  flexWrap: 'wrap',
  flexDirection: 'column',
  '@screen < $md': {
    padding: '0 $md 12px',
  },
});
type SearchResultsTitlePropType = {
  choosePCP: Boolean;
  offset: Number;
  resultType: String;
  searchTitle: String;
  onSpecialtyClick: (value: boolean, title: string) => void;
  isMixedSuggestions?: boolean;
  specialtySearch: boolean;
};
export const SearchResultsTitle = ({
  choosePCP,
  offset,
  resultType,
  searchTitle,
  onSpecialtyClick,
  isMixedSuggestions,
  specialtySearch,
}: SearchResultsTitlePropType) => {
  const { t } = useTranslation();
  let title = `${t('Showing_results_for')} ${searchTitle} ${t(
    'in'
  )} ${resultType}`;
  let subTitle;
  if (isMixedSuggestions && specialtySearch) {
    title = `${t('SEARCH_SPECIALITY_RESULTS.title').replace(
      '{searchTitle}',
      searchTitle
    )} ${t(resultType)}`;
    subTitle = t('SEARCH_SPECIALITY_RESULTS.subTitle').replace(
      '{searchTitle}',
      searchTitle
    );
  }

  if (isMixedSuggestions && !specialtySearch) {
    title = `${t('SEARCH_NAME_RESULTS.title').replace(
      '{searchTitle}',
      searchTitle
    )} ${t(resultType)}`;
    subTitle = t('SEARCH_NAME_RESULTS.subTitle').replace(
      '{searchTitle}',
      searchTitle
    );
  }

  return (
    <Fragment>
      {!choosePCP && searchTitle && (
        <Fragment>
          <TitleContainer>
            <Heading
              css={{
                'abyss-heading-root': {
                  '@screen < $md': {
                    fontSize: '$mediumText !important',
                    lineHeight: '$lg !important',
                  },
                },
              }}
              data-auto-testid="results-heading"
              data-testid="results-heading"
              display="h4"
              offset={1}
              textAlign="left"
            >
              {title}
            </Heading>
            {isMixedSuggestions && specialtySearch ? (
              <Text
                css={{
                  'abyss-heading-root': {
                    '@screen < $md': {
                      fontSize: '$mediumText !important',
                      lineHeight: '$lg !important',
                    },
                  },
                }}
                data-auto-testid="results-heading-for-provider-name"
                data-testid="results-heading-for-provider-name"
                display="h4"
                offset={1}
                onClick={() => onSpecialtyClick(false, 'name search')}
                textAlign="left"
              >
                <span
                  style={{
                    color: '#196ECF',
                    cursor: 'pointer',
                  }}
                >
                  {subTitle}
                  <IconMaterial icon="chevron_right" size="$xs" />
                </span>
              </Text>
            ) : null}
            {isMixedSuggestions && !specialtySearch ? (
              <Text
                css={{
                  'abyss-heading-root': {
                    '@screen < $md': {
                      fontSize: '$mediumText !important',
                      lineHeight: '$lg !important',
                    },
                  },
                }}
                data-auto-testid="results-heading-for-specialty"
                data-testid="results-heading-for-specialty"
                display="h4"
                offset={1}
                onClick={() => onSpecialtyClick(true, 'specialty search')}
                textAlign="left"
              >
                <span
                  style={{
                    color: '#196ECF',
                    cursor: 'pointer',
                  }}
                >
                  {subTitle} <IconMaterial icon="chevron_right" size="$xs" />
                </span>
              </Text>
            ) : null}
          </TitleContainer>
        </Fragment>
      )}
      {choosePCP && (
        <TitleContainer>
          <Heading
            css={{
              'abyss-heading-root': {
                '@screen < $sm': {
                  fontSize: '$mediumText !important',
                  lineHeight: '$lg !important',
                },
              },
            }}
            data-testid="provider-results-for-choose-pcp"
            display="h4"
            offset={offset}
            textAlign="left"
          >
            {t('Results for')} {t('primary care doctor')}
          </Heading>
        </TitleContainer>
      )}
    </Fragment>
  );
};
