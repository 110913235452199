import { storage } from '@abyss/web/tools/storage';

import {
  Constants,
  RelationshipTypeCodes,
  ReverseCoverageTypesCodes,
} from '../common/Constants';
import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { getFeatureFlag } from '../common/Utils';
import { StoreKeys } from '../hooks/useStore/state';
import { useStore } from '../hooks/useStore/useStore';

export const getCurrentMember = () => {
  const currentIndex =
    storage.session.get(Constants.STORAGE_KEYS.SESSION.ACTIVE_MEMBER_INDEX) ||
    0;

  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  return memberInfo?.[currentIndex];
};

export const getIsCurrentMemberSubscriber = () => {
  const currentMember = getCurrentMember();
  const memberRelationshipTypecode =
    currentMember?.demographics?.relationshipTypeCode?.code;
  return memberRelationshipTypecode === RelationshipTypeCodes.SUBSCRIBER;
};

export const getDependentInfo = (dependentSeqNbr) => {
  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  return memberInfo?.find(
    (member) => member?.demographics.dependentSeqNbr === dependentSeqNbr
  );
};

export const fetchMemberStateFromMapBox = (longitude, latitude, mapKey) =>
  fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=address&&access_token=${mapKey}`,
    { method: 'GET' }
  ).then((resp) => resp.json());

export const getLoggedInMember = () => {
  const loggedInMemberIndex =
    storage.session.get(
      Constants.STORAGE_KEYS.SESSION.LOGGED_IN_MEMBER_INDEX
    ) || 0;
  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  return memberInfo?.[loggedInMemberIndex];
};

export const getCoverageTypes = (currentMember) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.map(
    (coverage) => coverage.typeCode.coverageTypeCode
  );

export const getActiveCoverages = (currentMember) => {
  const todayDate = new Date().toISOString().slice(0, 10);

  return currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType
    ?.map((coverageType) =>
      coverageType?.coverageEffDateRange?.endDate >= todayDate &&
      coverageType?.coverageEffDateRange?.startDate <= todayDate
        ? coverageType.typeCode.coverageTypeCode
        : null
    )
    .filter((coverageTypeCode) => coverageTypeCode !== null);
};

export const getMarketTypes = (currentMember) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.map(
    (coverage) => coverage.marketType
  );
export const getPolicyNumber = (currentMember, coverageTypeCode) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
    (coverage) => coverage.typeCode?.coverageTypeCode === coverageTypeCode
  )?.policyNumber;

export const getPlanVariationCode = (currentMember, coverageTypeCode) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
    (coverage) =>
      coverage.typeCode?.coverageTypeCode === (coverageTypeCode ?? 'M')
  )?.planVariationCode;

export const getReportingCode = (currentMember, coverageTypeCode) =>
  currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.find(
    (coverage) => coverage.typeCode?.coverageTypeCode === coverageTypeCode
  )?.reportingCode;

export const getCurrentMemberPCPInfo = (dependentSeqNbr, primaryCare) =>
  primaryCare?.find(
    ({ depSeqNbr, pcpInfo }) =>
      pcpInfo.hasPCP &&
      pcpInfo.isCoverageActive &&
      depSeqNbr === dependentSeqNbr
  )?.pcpInfo;

export const getCurrentPlanYear = () => String(new Date().getFullYear());

export const getMemberByDependentSeqNbr = (dependentSeqNbr) => {
  const memberInfo = useStore(StoreKeys.OBAPI_MEMBERS);
  const reqMember = memberInfo.filter(
    (member) => member.demographics.dependentSeqNbr === dependentSeqNbr
  );
  return reqMember[0];
};

export const getMemberCoverage = (
  currentMember: any,
  featureFlags: { key: string; active: boolean }[]
) => {
  const medicalCov = `md_${
    currentMember?.reciprocityId +
    '%3B'.concat(Constants.NETWORK_COVERAGE_CODE.MEDICAL)
  }`;

  const todayDate = new Date().toISOString().slice(0, 10);

  const visionChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VISION_CHIP_ENABLED
  );
  const dentalChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_CHIP_ENABLED
  );
  const visionNetworkId = currentMember?.networks?.filter(
    (coverage) =>
      ReverseCoverageTypesCodes[coverage?.type] ===
      ReverseCoverageTypesCodes.VISION
  )[0]?.value[0];

  const dentalNetworkId = currentMember?.networks?.filter(
    (coverage) =>
      ReverseCoverageTypesCodes[coverage?.type] ===
      ReverseCoverageTypesCodes.DENTAL
  )[0]?.value[0];

  const coverageTypeCodeMap = {
    [ReverseCoverageTypesCodes.MEDICAL]: medicalCov,
    [ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']]:
      Constants.NETWORK_COVERAGE_CODE.BEHAVIORAL_B,
    [ReverseCoverageTypesCodes[
      'BEHAVIORAL CARVE-OUT'
    ]]: `bh_${currentMember.bhReciprocityId}`,
    [ReverseCoverageTypesCodes.VISION]:
      visionChipEnabled && visionNetworkId ? `vn_${visionNetworkId}` : '',
    [ReverseCoverageTypesCodes.DENTAL]:
      dentalChipEnabled && dentalNetworkId ? `dt_${dentalNetworkId}` : '',
  };

  const coverageTypeCodes = currentMember?.eligibility
    .flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
    .filter(
      (coverageType) =>
        coverageType?.coverageEffDateRange?.endDate >= todayDate &&
        coverageType?.coverageEffDateRange?.startDate < todayDate
    )
    .map(
      (coverageType) =>
        coverageTypeCodeMap?.[coverageType?.typeCode?.coverageTypeCode] || ''
    );

  return coverageTypeCodes
    .filter((networkType: string) => networkType)
    ?.join('%3B');
};

export const getNetworkIdsForPES = (
  currentMember: any,
  coverageType: string,
  featureFlags: { key: string; active: boolean }[]
) => {
  if (coverageType === 'B') {
    const todayDate = new Date().toISOString().slice(0, 10);
    const coverageTypeCodeMap = {
      [ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']]:
        Constants.NETWORK_COVERAGE_CODE_PES.BEHAVIORAL_B,
      [ReverseCoverageTypesCodes['BEHAVIORAL CARVE-OUT']]:
        currentMember.bhReciprocityId,
    };
    const coverageTypeCodes = currentMember?.eligibility
      .flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
      .filter(
        (covType) =>
          covType?.coverageEffDateRange?.endDate >= todayDate &&
          covType?.coverageEffDateRange?.startDate < todayDate
      )
      .map(
        (covType) =>
          coverageTypeCodeMap?.[covType?.typeCode?.coverageTypeCode] || ''
      );

    return coverageTypeCodes
      .filter((networkType: string) => networkType)
      ?.join(',');
  }
  if (coverageType === 'D') {
    const networks =
      currentMember?.networks?.filter(
        (coverage) => ReverseCoverageTypesCodes[coverage?.type] === coverageType
      ) || [];
    return networks[0]?.value[0] || currentMember?.reciprocityId;
  }

  if (coverageType === 'V') {
    const visionChipEnabled: boolean = getFeatureFlag(
      featureFlags,
      ConstantsLagoon.FEATURE_FLAGS.VISION_CHIP_ENABLED
    );
    if (visionChipEnabled) {
      const networks =
        currentMember?.networks?.filter(
          (coverage) =>
            ReverseCoverageTypesCodes[coverage?.type] === coverageType
        ) || [];
      return networks[0]?.value[0] || currentMember?.reciprocityId;
    }
    return '0';
  }

  return currentMember?.reciprocityId;
};

export const getOnlineRetailers = (isOnlineProviders: any) => {
  if (typeof isOnlineProviders === 'boolean') return isOnlineProviders;
  if (isOnlineProviders === null) return null;
  if (isOnlineProviders === 'Y') return true;
  if (isOnlineProviders === 'N') return false;
  if (isOnlineProviders?.toLowerCase() === 'blank') return null;
  return undefined;
};
