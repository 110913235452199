import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../../../../../../common/ConstantsLagoon';
import { useFeatureFlag } from '../../../../../../../../../hooks/useFeatureFlag';
import {
  ReviewsText,
  StyledNullAttributeDiv,
  popoverStyle,
} from '../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../utility/compareDrawerConstants';

export interface Props {
  rating: string | number;
  mobile: boolean;
  surveyCount?: number;
}
export const HealthGradeRating = ({ rating, mobile, surveyCount }: Props) => {
  const truncatedRatingForIcon = Math.trunc(Number(rating));
  const isRatingAvailable = !Number.isNaN(parseFloat(rating as string));
  const { t } = useTranslation();
  const [shouldGetHGData] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_HEALTH_GRADES_INFO_FOR_PRACTIONER,
  ]);
  const surveyRating = shouldGetHGData ? ` (${surveyCount})` : '';

  if (isRatingAvailable) {
    return (
      <Layout.Group
        css={{ minHeight: '24px' }}
        data-auto-testid="compare-card-rating"
        data-testid="compare-card-rating"
        space=""
      >
        {isRatingAvailable && (
          <React.Fragment>
            <IconMaterial
              className="star-icon"
              css={{
                'abyss-icon': {
                  fill: truncatedRatingForIcon ? '$secondary2' : '$black',
                  stroke: truncatedRatingForIcon ? '$starBorder' : '',
                },
              }}
              icon={truncatedRatingForIcon ? 'star_rate' : 'star_border'}
              size="16px"
            />
          </React.Fragment>
        )}
        <ReviewsText className="text" color="$primary1" size="12.64px">
          {`${Number(rating).toFixed(1)}${surveyRating}`}
        </ReviewsText>
      </Layout.Group>
    );
  }

  return mobile ? (
    <Popover
      content={t(NULL_ATTRIBUTE_STATE.HEALTH_GRADE_RATINGS)}
      css={popoverStyle}
      showClose={false}
      tabIndex="-1"
      width={150}
    >
      <StyledNullAttributeDiv>
        {t(NULL_ATTRIBUTE_STATE.HEALTH_GRADE_RATINGS)}
      </StyledNullAttributeDiv>
    </Popover>
  ) : (
    <StyledNullAttributeDiv>
      {t(NULL_ATTRIBUTE_STATE.HEALTH_GRADE_RATINGS)}
    </StyledNullAttributeDiv>
  );
};
