export const ConstantsLagoon = {
  CONTENT_FILTERS: {
    INFO_MODAL_CONTENT: 'RESULT_SORT_INFO_MODAL_CONTENT',
    PREMIUM_CARE_PHYSICIAN_CONTENT: 'PREMIUM_CARE_PHYSICIAN_CONTENT',
    PREMIUM_CARE_POPOVER_URL: 'PREMIUM_CARE_POPOVER_URL',
    PREFERRED_FACILITY_CONTENT: 'PREFERRED_FACILITY_CONTENT',
    BEST_MATCH_POPOVER_CONTENT_VISION_PROVIDER:
      'BEST_MATCH_POPOVER_CONTENT_VISION_PROVIDER',
    BEST_MATCH_POPOVER_CONTENT_VISION_FACILITY:
      'BEST_MATCH_POPOVER_CONTENT_VISION_FACILITY',
    BEST_MATCH_POPOVER_CONTENT: 'BEST_MATCH_POPOVER_CONTENT',
    COST_TAB_IN_NETWORK_TEXT: 'COST_TAB_IN_NETWORK_TEXT',
    COST_TAB_COST_ESTIMATES_TEXT: 'COST_TAB_COST_ESTIMATES_TEXT',
    COST_TAB_COST_ESTIMATES_HEADING: 'COST_TAB_COST_ESTIMATES_HEADING',
    COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_HEADING:
      'COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_HEADING',
    COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_TEXT:
      'COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_TEXT',
    COST_TAB_IN_NETWORK_HEADING: 'COST_TAB_IN_NETWORK_HEADING',
    CARE_TYPE_TITLE: 'CARE_TYPE_TITLE',
    SELECT_CARE_TYPE_TITLE: 'SELECT_CARE_TYPE_TITLE',
    PHYSICIAN_DIRECTORY_DIRECTORY_CONTENT:
      'PHYSICIAN_DIRECTORY_DIRECTORY_CONTENT',
    PHYSICIAN_DIRECTORY_LOCATION_CONTENT:
      'PHYSICIAN_DIRECTORY_LOCATION_CONTENT',
  },
  FEATURE_FLAGS: {
    DEMO_FLAG: 'DEMO',
    REQUEST_RECIPROCITY_ID: 'REQUEST_RECIPROCITY_ID',
    EDUCATIONAL_CONTENT_ENABLED: 'EDUCATIONAL_CONTENT_ENABLED',
    COST_ENABLED: 'COST_ENABLED',
    COST_TAB_ENABLED: 'COST_TAB',
    ENABLE_CEX_GET_FULL_COST_ESTIMATE: 'ENABLE_CEX_GET_FULL_COST_ESTIMATE',
    ENABLE_PRE_PROD: 'ENABLE_PRE_PROD_ENV',
    PRINT_SHARE_DIRECTORY: 'PRINT_SHARE_DIRECTORY',
    NOTIFICATIONS: 'NOTIFICATIONS',
    SHARE_RECIPIENT_DISABLE: 'SHARE_RECIPIENT_DISABLE',
    THINGS_TO_KNOW_DECEMBER_RLS: 'THINGS_TO_KNOW_DECEMBER_RLS',
    ENABLE_SEARCH_LOCATION: 'ENABLE_SEARCH_LOCATION',
    AUTOCOMPLETE_CAPTURE_RESULTS: 'AUTOCOMPLETE_CAPTURE_RESULTS',
    UPCOMING_AVAILABILITY: 'UPCOMING_AVAILABILITY',
    ENABLE_COUNTY_SEARCH_ALERT: 'ENABLE_COUNTY_SEARCH_ALERT',
    SHOW_MAP_COLLAPSE: 'SHOW_MAP_COLLAPSE',
    REFERRAL_REQUIRED_BANNER: 'REFERRAL_REQUIRED_BANNER',
    VIRTUAL_CARE_INDICATOR_ENABLED: 'VIRTUAL_CARE_INDICATOR_ENABLED',
    VISION_CHIP_ENABLED: 'VISION_CHIP_ENABLED',
    DENTAL_CHIP_ENABLED: 'DENTAL_CHIP_ENABLED',
    USE_MEDICAL_NEW_ROLLUP_CODES: 'USE_MEDICAL_NEW_ROLLUP_CODES',
    USE_BH_NEW_ROLLUP_CODES: 'USE_BH_NEW_ROLLUP_CODES',
    USE_DENTAL_NEW_ROLLUP_CODES: 'USE_DENTAL_NEW_ROLLUP_CODES',
    USE_VISION_NEW_ROLLUP_CODES: 'USE_VISION_NEW_ROLLUP_CODES',
    DISABLE_ACO_FILTER: 'DISABLE_ACO_FILTER',
    ENABLE_VIRTUAL_CARE_BH_FILTER: 'ENABLE_VIRTUAL_CARE_BH_FILTER',
    VISION_WEEKEND_EVENINGS_FILTER_ENABLED:
      'VISION_WEEKEND_EVENINGS_FILTER_ENABLED',
    VISION_RECENT_VISIT: 'VISION_RECENT_VISIT',
    EAP_CODE: 'EAP_CODE',
    TIERED_BENEFITS_ENABLED: 'TIERED_BENEFITS_ENABLED',
    FEATURE_SUPPRESSION: 'FEATURE_SUPPRESSION',
    DENTAL_INTEGRATED_VIEW: 'DENTAL_INTEGRATED_VIEW',
    PREMIUM_CARE: 'PREMIUM_CARE',
    CUSTOM_MESSAGE_NOTIFICATION: 'CUSTOM_MESSAGE_NOTIFICATION',
    ENABLE_LLM: 'ENABLE_LLM',
    TIER_ONE_FILTER_COMPARE: 'TIER_ONE_FILTER_COMPARE',
    PATIENT_REVIEWS_DYNAMIC_HEADER: 'PATIENT_REVIEWS_DYNAMIC_HEADER',
    PREFERRED_FACILITY_IDENTIFICATION: 'PREFERRED_FACILITY_IDENTIFICATION',
    GET_HEALTH_GRADES_INFO_FOR_PRACTIONER: 'GET_HG_DATA',
  },
  MEDICAL_SPECIALISTS: {
    HOW_TO_GET_REFERRAL_CARD: 'HOW_TO_GET_REFERRAL',
    TIER_ONE_BENEFITS_ONE: 'TIER_ONE_BENEFITS_ONE',
  },
  BEHAVIORAL_HEALTH: {
    TITLE_KEY: 'TITLE',
    CONTENT_KEY: 'CONTENT',
    TALK_TO_NURSE_CARD_INFO: 'TALK_TO_NURSE_CARD_INFO',
  },
  PRIMARY_CARE: {
    PRIMARY_CARE_PROVIDERS: 'PRIMARY_CARE_PROVIDERS',
    PRIMARY_CARE_LOCATIONS: 'PRIMARY_CARE_LOCATIONS',
    PRIMARY_CARE_CLINICS: 'PRIMARY_CARE_CLINICS',
    PRIMARY_CARE_MEDICAL_GROUPS: 'PRIMARY_CARE_MEDICAL_GROUPS',
    TIER_ONE_PROVIDER: 'TIER_ONE_PROVIDER',
    TIERED_BENEFITS: 'TIERED_BENEFITS',
  },
  SHARE_THIS_PROVIDER: {
    SHARE_THIS_PROVIDER_MESSAGE: 'SHARE_THIS_PROVIDER_MESSAGE',
  },
  VALID_EMAIL_ADDRESS: {
    VALID_EMAIL_ADDRESS_MESSAGE: 'VALID_EMAIL_ADDRESS_MESSAGE',
  },
  SEARCH_LABEL: 'SEARCH_LABEL',
  LOCATION_LABEL: 'LOCATION_LABEL',
  SUGGESTED_PROVIDERS: {
    SUGGESTED_PROVIDERS_MESSAGE: 'SUGGESTED_PROVIDERS_MESSAGE',
  },
  HEALTHGRADES: {
    MODAL_DISCLAIMER_SHORT: 'HEALTH_GRADES_DISCLAIMER_CONTENT',
    MODAL_DISCLAIMER_FULL: 'HEALTH_GRADES_DISCLAIMER_POPUP',
    DISCLAIMER_IN_PAGE: 'HEALTH_GRADES_IN_PAGE',
    PRINT_VIEW_CONTENT: 'HEALTH_GRADES_PRINT_VIEW_CONTENT',
  },
  DENTAL: {
    TITLE_KEY: 'TITLE',
  },
  VISION: {
    TITLE_KEY: 'TITLE',
    VISION_PAGE_TALK_TO_NURSE_TOGGLE: 'VISION_PAGE_TALK_TO_NURSE_TOGGLE',
  },
  EDUCATIONAL_CONTENT_MODAL: {
    EDUCATIONAL_CONTENT_MODAL_TITLE: 'EDUCATIONAL_CONTENT_MODAL_TITLE',
    EDUCATIONAL_CONTENT_MODAL_DESCRIPTION:
      'EDUCATIONAL_CONTENT_MODAL_DESCRIPTION',
  },
  COST_INDICATOR_CONTENT_MODAL: {
    COST_INDICATOR_POPOVER_CONTENT: 'COST_INDICATOR_POPOVER_CONTENT',
  },
  NATIONAL_ANCILLARY_NO_LOCATION_MODAL: 'NATIONAL_ANCILLARY_NO_LOCATION_MODAL',
  ONLINE_PROVIDERS_VISION_NO_LOCATION_MODAL:
    'ONLINE_PROVIDERS_VISION_NO_LOCATION_MODAL',
  MEMBER_SELECTION_CONFIRMATION_MODAL: {
    MEMBER_SELECTION_MODAL_BODY_CONTENT: 'MEMBER_SELECTION_MODAL_BODY_CONTENT',
    MEMBER_SELECTION_MODAL_BODY_HEADER: 'MEMBER_SELECTION_MODAL_BODY_HEADER',
    MEMBER_SELECTION_MODAL_PCP_CONTENT: 'MEMBER_SELECTION_MODAL_PCP_CONTENT',
    MEMBER_SELECTION_MODAL_CARE_SECTION_CONTENT:
      'MEMBER_SELECTION_MODAL_CARE_SECTION_CONTENT',
    MEMBER_SELECTION_MODAL_SCHEDULING_SECTION_CONTENT:
      'MEMBER_SELECTION_MODAL_SCHEDULING_SECTION_CONTENT',
  },
  NO_PROVIDERS_FOUND: 'NO_PROVIDERS_FOUND',
  FEATURE_SUPPRESSION_FLAGS: {
    PREMIUM_CARE: 'Premium Care',
    PATIENT_REVIEWS: 'Patient Reviews',
    PREFERRED_FACILITY: 'Preferred Facility',
    DDP: 'DDP',
    PLN: 'PLN',
    FSF: 'FSF',
  },
  EAP_AUTHORIZATION_CODE: 'EAP_AUTHORIZATION_CODE',
  PREFERRED_FACILITY: {
    PREFERRED_FACILITY_IDENTIFICATION: 'PREFERRED_FACILITY_IDENTIFICATION',
    PREFERRED_FACILITY_CONTENT: 'PREFERRED_FACILITY_CONTENT',
    DESIGNATED_DIAGNOSTIC_PROVIDER_CONTENT:
      'DESIGNATED_DIAGNOSTIC_PROVIDER_CONTENT',
    PREFERRED_LAB_NETWORK_CONTENT: 'PREFERRED_LAB_NETWORK_CONTENT',
    FREESTANDING_FACILITY_CONTENT: 'FREESTANDING_FACILITY_CONTENT',
  },
};
