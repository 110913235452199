import { Tooltip } from '@abyss/web/ui/Tooltip';

import { Address as ProviderAddress } from '../../models/ProviderDetails';
import {
  AddressText,
  AddressWrapper,
  Dot,
  addressTextToolTipStyles,
} from './DataCard.style';

type Props = {
  address?: ProviderAddress;
  id?: string;
  sectionType?: string;
};

export const Address = ({ address, id, sectionType }: Props) => {
  const { line } = address || {};
  const addressLine = line?.join(', ');
  if (!line) return null;
  return (
    <AddressWrapper>
      <Dot />
      <Tooltip
        content={addressLine}
        css={addressTextToolTipStyles}
        position="top"
        positionOffset={8}
      >
        <AddressText
          as="div"
          color="$gray7"
          data-auto-testid={`data-card-address-${sectionType}-${id}`}
          data-testid={`data-card-address-${sectionType}-${id}`}
          fontWeight="$medium"
        >
          {addressLine}
        </AddressText>
      </Tooltip>
    </AddressWrapper>
  );
};
