import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';

export const AddressContainer = styled('span', {
  fontWeight: '$semibold',
  fontSize: '$lg',
  lineHeight: '24px',
  color: '#4B4D4F',
  whiteSpace: 'pre-line',
  '@screen < $sm': {
    width: '162px',
    fontSize: '$md',
    lineHeight: '$md',
  },
});

export const ChipContainer = styled('div', {
  color: '$neutralGray',
  fontSize: '$extraSmallText',
  fontWeight: '$medium',
  lineHeight: '$md',
  background: 'rgba(0, 12, 39, 0.05)',
  padding: '4px 12px',
  borderRadius: '20px',
  fontStyle: 'medium',
  fontFamily: '$primary',
});
export const CollapsePhonesContainer = styled('button', {
  display: 'flex',
  gap: '2px',
  marginLeft: '30px',
  marginTop: '12px',
});
export const CollapsePhonesHeader = styled('div', {
  color: '#196ECF',
  fontSize: '16px',
  fontWeight: '$bold',
  lineHeight: '20px',
});
export const ArrowContainer = styled('div', {
  margin: '0',
  height: '20px',
});

export const PhonesContainer = styled('div', {
  dynamic: ({ cssProps: { showMorePhoneNumbers } }) => ({
    display: showMorePhoneNumbers ? 'block' : 'none',
  }),
});

export const Container = styled('div', {
  padding: '0px 0px',
  gap: '$lg',
  background: '$primary2',
  marginTop: '8px',
});

export const DividerContainer = styled('div', {
  marginRight: '8px',
  marginLeft: '8px',
});

export const IconAlignmentContainer = styled('div', {
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
});
export const InnerContainer = styled('div', {
  marginTop: '$sm',
  border: '1px solid $cardBorder',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$lg',
  background: '$primary2',
  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
  '@screen < $sm': {
    gap: '20px',
  },
});

export const LocationDistance = styled('p', {
  display: 'flex',
  flexWrap: 'nowrap',
  marginBottom: '0',
});

export const LocationsHeader = styled(Flex, {
  '&.abyss-flex-root': {
    gap: '24px',
    padding: '0px',
    width: '407px',
    justifyContent: 'flex-start',
    marginBottom: '12px',
    '@screen < $sm': {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
});

export const MapViewBoxContainer = styled('div', {
  borderTop: '1px solid $cardBorder',
  boxSizing: 'border-box',
  height: 'unset',
  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
  '@screen < $sm': {
    background: 'white',
    padding: '0px',
  },
});

export const SectionContainer = styled('div', {
  borderWidth: '1px 0',
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  borderColor: '$cardBorder',
  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
});

export const ViewOnMapLabel = styled(Text, {
  fontStyle: 'normal',
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '$md',
  marginRight: '7px',
  '&.abyss-text-root': {
    '@screen < $sm': {
      fontSize: '$md',
      lineHeight: '$md',
    },
  },
});
