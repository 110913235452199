import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Layout } from '@abyss/web/ui/Layout';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { CardWrapperDetailsPage } from '../../../../common/CardWrapper';
import { CareCategories, Constants } from '../../../../common/Constants';
import { FeatureFlags } from '../../../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { CustomMessageNotification } from '../../../../common/CustomMessageConfig/CustomMessageNotification';
import { DisclaimerContent } from '../../../../common/DisclaimerContent';
import { getLagoonFlag } from '../../../../common/LagoonContent/LagoonContent';
import { SectionSkeleton } from '../../../../common/LazyLoaderSkeleton';
import { RecentSearchSkeleton } from '../../../../common/LazyLoaderSkeleton/RecentSearchSkeleton';
import { LoadingSpinnerComp } from '../../../../common/Loader';
import { PSXHeader } from '../../../../common/PSXHeader';
import {
  CoverageType,
  MemberInfo,
} from '../../../../common/PSXHeader/ObapiDemo/memberProfile';
import { SuicideAndCrisisCard } from '../../../../common/SuicideAndCrisisCard';
import { getFeatureFlag } from '../../../../common/Utils';
import { WhereToGetHelp } from '../../../../common/WhereToGetHelp/WhereToGetHelp';
import { ChipCategoryContext } from '../../../../context/ChipCategoryContext';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { ErrorBoundary } from '../../../../errors/ErrorBoundary';
import { useAdobeProvidersImpressionBlock } from '../../../../hooks/adobeHook/useAdobeProvidersImpressionBlock';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { getLagoonSuicideAndCrisisCardTabs } from '../../../../utils/suicideAndCrisis.utils';
import { VisionCareProviders } from '../VisionCareProviders';
import * as LazyComponents from './lazyComponents';

const Loader = () => <SectionSkeleton />;

const primaryCareApiCalls = {
  numberOfApiCalls: 2,
  skipCostIndicator: true,
};

export const PSXHome = () => {
  const { LOGGED_IN_MEMBER_INDEX, ACTIVE_MEMBER_INDEX } =
    Constants.STORAGE_KEYS.SESSION;
  const activeMemberIndex =
    Number(storage.session.get(ACTIVE_MEMBER_INDEX)) ?? 0;
  const storedMembers = useStore(StoreKeys.OBAPI_MEMBERS);
  const [memberProfile, setMemberProfile] =
    useState<MemberInfo[]>(storedMembers);
  const loggedInMemberIndex = storage.session.get(LOGGED_IN_MEMBER_INDEX);
  const loggedInMemberInfo = memberProfile[loggedInMemberIndex];
  const { value, setChipValue } = useContext(ChipCategoryContext);
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const [hasMedicalCoverage, setHasMedicalCoverage] = useState(true);
  const chipCatagories: CareCategories[] = Object.values(CareCategories);
  const [isCountySearch, setIsCountySearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customMessage, setCustomMessage] = useState('');
  const [onProvidersLoad] =
    useAdobeProvidersImpressionBlock(primaryCareApiCalls);
  useEffect(() => {
    const updatedToken = tokenizer.parse(token);
    if (updatedToken?.chipValue) {
      setChipValue(updatedToken.chipValue);
    } else {
      setChipValue(Constants.CHIPS_CATEGORIES.ALL);
    }
  }, [token]);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, [activeMemberIndex]);

  useEffect(() => {
    document.title = `${value} | ${Constants.SITE_NAME}`;
  }, [value]);

  const [virtualCareCardShow, setVirtualCareCardShow] =
    useState<boolean>(false);

  const [memberId, setMemberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  const [, setBreadcrumbUrls] = useSessionStorage<BreadcrumbSessionStorage>(
    Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
    {}
  );

  const { clearSearchFilters } = useContext(SearchFilterContext);

  const featureFlags = useLagoon('feature-flags')();
  const galileoFlag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY.GALILEO
  );
  const notificationsFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.NOTIFICATIONS
  );
  const thingsToKnowFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.THINGS_TO_KNOW_DECEMBER_RLS
  );

  const showCustomMessageNotification = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.CUSTOM_MESSAGE_NOTIFICATION
  );

  const dentalIntergratedViewEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_INTEGRATED_VIEW
  );

  const lagoonData: [{ key: string; isActive: boolean }] =
    useLagoon('vision')();

  const talkToNurseVisionPageFLag = getLagoonFlag(
    lagoonData,
    ConstantsLagoon.VISION.VISION_PAGE_TALK_TO_NURSE_TOGGLE
  );

  const suicideAndCrisisInfos = useLagoon('suicide-and-crisis-lifeline')();
  const lagoonSuicideAndCrisisCardTabs = getLagoonSuicideAndCrisisCardTabs(
    suicideAndCrisisInfos
  );

  useEffect(() => {
    setBreadcrumbUrls({});
    setMemberId(memberId);
    clearSearchFilters();

    storage.session.set(Constants.STORAGE_KEYS.SESSION.IS_COUNTY_SEARCH, false);
  }, []);

  useEffect(() => {
    let showVirtualCareCard: boolean = false;
    if (memberProfile[0]?.eligibility[0]?.memberHealthCoverage?.coverageType) {
      const coverageType =
        memberProfile[0]?.eligibility[0]?.memberHealthCoverage.coverageType.find(
          (x: CoverageType) =>
            x.typeCode &&
            x.typeCode.desc === Constants.COVERAGE_TYPECODE_TO_CHIP.M
        );
      if (coverageType && coverageType?.designatedNetwork) {
        showVirtualCareCard = coverageType?.designatedNetwork.includes('DVCN');
      }
    }
    setVirtualCareCardShow(showVirtualCareCard);

    let showMedical = false;

    if (memberProfile[activeMemberIndex]?.eligibility) {
      showMedical = memberProfile[activeMemberIndex].eligibility.some(
        (record) =>
          record?.memberHealthCoverage?.coverageType?.some(
            (coverageType) =>
              coverageType.typeCode &&
              coverageType.typeCode.desc ===
                Constants.COVERAGE_TYPECODE_TO_CHIP.M
          )
      );
    }

    setHasMedicalCoverage(showMedical);
  }, [memberProfile, activeMemberIndex]);

  const handleMemberIdChange = (id) => {
    setMemberId(id);
  };

  const handleMemberDataChange = (data: MemberInfo[]) => {
    setMemberProfile(data);
  };

  const setCustomMessageFn = (customMessageValue) => {
    setCustomMessage(customMessageValue);
  };

  // checks if tabs are active in lagoon
  const isActiveTab = (
    currentChip: string,
    chipCategory: string,
    lagoonActiveChips: string[]
  ): boolean =>
    currentChip === chipCategory &&
    lagoonActiveChips?.includes(chipCategory.replace(/_/g, ' '));

  return (
    <React.Fragment>
      <ErrorBoundary>
        <CountySearchContext.Provider
          value={{ isCountySearch, setIsCountySearch }}
        >
          <PSXHeader
            customMessage={customMessage}
            dataTestId="search-home-on-submit"
            onMemberDataChange={handleMemberDataChange}
            onMemberIdChange={handleMemberIdChange}
          />
          {showCustomMessageNotification &&
            value === Constants.CHIPS_CATEGORIES.ALL && (
              <Suspense fallback={<Loader />}>
                <CustomMessageNotification
                  setCustomMessageFn={setCustomMessageFn}
                />
              </Suspense>
            )}
          <CardWrapperDetailsPage>
            {notificationsFlag ? (
              <LazyComponents.Notifications value={value} />
            ) : null}
            <Layout.Stack grow space={0}>
              {value !== Constants.CHIPS_CATEGORIES.PRIMARY_CARE && (
                <Suspense fallback={<RecentSearchSkeleton />}>
                  <LazyComponents.RecentActivityAndVisit activeChip={value} />
                </Suspense>
              )}

              {value === Constants.CHIPS_CATEGORIES.ALL &&
                hasMedicalCoverage &&
                (isLoading ? (
                  <LoadingSpinnerComp isLoading={isLoading} />
                ) : (
                  <ErrorBoundary>
                    <LazyComponents.CareOptions
                      virtualCareCardShowFlag={virtualCareCardShow}
                    />
                    <LazyComponents.CommonlySearchedCard
                      selectedChipValue={value}
                    />
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={false}
                    />
                  </ErrorBoundary>
                ))}
              {value === Constants.CHIPS_CATEGORIES.PRIMARY_CARE && (
                <ErrorBoundary>
                  <Suspense fallback={<Loader />}>
                    <LazyComponents.PrimaryCareProvider
                      data-auto-testid="search-home-primary-card"
                      data-testid="search-home-primary-card"
                      loggedInMemberInfo={loggedInMemberInfo}
                      memberInfo={memberProfile}
                    />
                    <LazyComponents.RecentActivityAndVisit activeChip={value} />
                    <LazyComponents.TopPrimaryCareProviderCards
                      data-auto-testid="home-top-primary-card"
                      data-testid="home-top-primary-card"
                      onCompleted={onProvidersLoad}
                    />
                    <LazyComponents.TopPrimaryCareMedicalGroupsCards
                      data-auto-testid="home-top-primary-care-medical-groups"
                      data-testid="home-top-primary-care-medical-groups"
                      onCompleted={onProvidersLoad}
                    />
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={virtualCareCardShow}
                    />
                  </Suspense>
                </ErrorBoundary>
              )}

              {value === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS && (
                <ErrorBoundary>
                  {/* Active Referrals Cards are not part of V1 release */}

                  {/* <ActiveReferralsCard
                  data-auto-testid="active-referals-card"
                  data-testid="active-referals-card"
                  referralsCount="2"
                  to="#"
                /> */}
                  <Suspense fallback={<Loader />}>
                    <LazyComponents.BrowseMedicalSpecialists
                      data-auto-testid="search-home-browse-common-specialties-card"
                      data-testid="search-home-browse-common-specialties-card"
                    />
                    {!!loggedInMemberInfo?.isGatedUser && (
                      <LazyComponents.ReferralCard
                        data-auto-testid="search-home-referral-card"
                        data-testid="search-home-referral-card"
                      />
                    )}
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={virtualCareCardShow}
                    />
                  </Suspense>
                </ErrorBoundary>
              )}
              {value === Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH && (
                <ErrorBoundary>
                  <Suspense fallback={<Loader />}>
                    <LazyComponents.BrowseBehavioralHealthProviders />
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={virtualCareCardShow}
                    />
                  </Suspense>
                </ErrorBoundary>
              )}
              {value === Constants.CHIPS_CATEGORIES.HEALTH_SUPPLIES && (
                <ErrorBoundary>
                  <Suspense fallback={<Loader />}>
                    <LazyComponents.BrowseHealthSupplies
                      data-auto-testid="browse-health-supplies-facility-section"
                      data-testid="browse-health-supplies-facility-section"
                    />
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={virtualCareCardShow}
                    />
                  </Suspense>
                </ErrorBoundary>
              )}
              {value === Constants.CHIPS_CATEGORIES.FACILITIES && (
                <ErrorBoundary>
                  <Suspense fallback={<Loader />}>
                    <LazyComponents.BrowseLabAndServices />
                    {thingsToKnowFlag && <LazyComponents.ThingsToKnowCard />}
                    <WhereToGetHelp selectedChipValue={value} />
                    <LazyComponents.ChipsGeneralContent
                      data-auto-testid="chips-general-content"
                      data-testid="chips-general-content"
                      selectedChipValue={value}
                      virtualPCPAssignedFlag={virtualCareCardShow}
                    />
                  </Suspense>
                </ErrorBoundary>
              )}
            </Layout.Stack>
            {value !== Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS &&
              value !== Constants.CHIPS_CATEGORIES.HEALTH_SUPPLIES &&
              value !== Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH &&
              value !== Constants.CHIPS_CATEGORIES.FACILITIES && (
                <ErrorBoundary>
                  <Suspense fallback={<Loader />}>
                    {galileoFlag && (
                      <LazyComponents.GalileoCard
                        data-auto-testid="search-home-galileo-card"
                        data-testid="search-home-galileo-card"
                      />
                    )}
                  </Suspense>
                </ErrorBoundary>
              )}
            {value === Constants.CHIPS_CATEGORIES.VISION && (
              <Suspense fallback={<Loader />}>
                <VisionCareProviders />
                <WhereToGetHelp selectedChipValue={value} />
                <LazyComponents.ChipsGeneralContent
                  data-auto-testid="chips-general-content"
                  data-testid="chips-general-content"
                  selectedChipValue={value}
                  virtualPCPAssignedFlag={virtualCareCardShow}
                />
              </Suspense>
            )}
            {value === Constants.CHIPS_CATEGORIES.DENTAL && (
              <Suspense fallback={<Loader />}>
                {dentalIntergratedViewEnabled ? (
                  <React.Fragment>
                    <LazyComponents.BrowseDentalProviders />
                    <WhereToGetHelp selectedChipValue={value} />
                  </React.Fragment>
                ) : (
                  <LazyComponents.CommonlySearchedCard
                    selectedChipValue={value}
                  />
                )}
                <LazyComponents.ChipsGeneralContent
                  data-auto-testid="chips-general-content"
                  data-testid="chips-general-content"
                  selectedChipValue={value}
                  virtualPCPAssignedFlag={virtualCareCardShow}
                />
              </Suspense>
            )}
            {chipCatagories.map(
              (chipCategory) =>
                isActiveTab(
                  value,
                  chipCategory,
                  lagoonSuicideAndCrisisCardTabs
                ) && (
                  <Suspense fallback={<Loader />}>
                    <SuicideAndCrisisCard
                      activeChip={value}
                      data-auto-testid="suicide-and-crisis-card"
                      data-testid="suicide-and-crisis-card"
                    />
                  </Suspense>
                )
            )}
            {value === Constants.CHIPS_CATEGORIES.VISION &&
              (talkToNurseVisionPageFLag ? (
                <LazyComponents.TalkToNurseCard
                  data-auto-testid="talk-to-nurse-card-vision"
                  data-testid="talk-to-nurse-card-vision"
                />
              ) : null)}
            <DisclaimerContent selectedChipValue={value} />
          </CardWrapperDetailsPage>
        </CountySearchContext.Provider>
      </ErrorBoundary>
    </React.Fragment>
  );
};
