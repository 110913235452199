import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { phoneOnly } from '../ConstantsStyles';
import { ProviderIndicator } from './DetailsPageHeader.styled';

export type Props = {
  virtualCareOffered: boolean;
};

export const VirtualCareOfferedLabel = ({ virtualCareOffered }: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(phoneOnly) || isWidget;

  if (virtualCareOffered) {
    return (
      <Layout.Group space={0}>
        <IconMaterial
          color="$primary1"
          icon="videocam"
          size={mobileScreen ? '20px' : '$md'}
          variant="filled"
        />
        <ProviderIndicator
          color={mobileScreen ? '$primary1' : '$gray6'}
          fontWeight={mobileScreen ? '500' : '$semibold'}
          size={mobileScreen ? '$md' : '$lg'}
        >
          {t('DETAILS_PAGE.OFFERS_VIRTUAL_CARE')}
        </ProviderIndicator>
      </Layout.Group>
    );
  }
  return null;
};
