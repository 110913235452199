import { storage } from '@abyss/web/tools/storage';
import { useCallback, useContext, useEffect, useState } from 'react';

import { Constants } from '../../common/Constants';
import { getIndicatorsForImpressions } from '../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { ChipCategoryContext } from '../../context/ChipCategoryContext';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useAdobePageTrackEvent } from './useAdobePageTrackEvent';

type Props = {
  numberOfApiCalls?: number;
  skipCostIndicator?: boolean;
};

export const useAdobeProvidersImpressionBlock = ({
  numberOfApiCalls,
  skipCostIndicator,
}: Props) => {
  const [allProviders, setProviders] = useState([] as any[]);
  const [numberOfCalls, setNumberOfCalls] = useState(0);
  const { longitude, latitude } = useGeoLocationStorage();
  const [isTracked, setIsTracked] = useState(false);
  const { value: contextValue } = useContext(ChipCategoryContext);
  const loggedInMemberIndex = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.LOGGED_IN_MEMBER_INDEX
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: `${contextValue} overview`,
    impressionBlock: {
      type: 'provider',
      indicator:
        allProviders?.length > 0
          ? getIndicatorsForImpressions(allProviders, skipCostIndicator)
          : '',
    },
  });

  const onProvidersLoad = useCallback(
    (providers: any) => {
      if (Array.isArray(providers?.snackCardList)) {
        setProviders((prev) => [...prev, ...providers.snackCardList]);
      }
      setNumberOfCalls((prev) => prev + 1);
    },
    [setProviders, setNumberOfCalls]
  );

  useEffect(() => {
    if (latitude && longitude) {
      setNumberOfCalls(0);
      setProviders([]);
      setIsTracked(false);
    }
  }, [latitude, longitude]);

  useEffect(() => {
    const apisCount = numberOfApiCalls || 0;
    if (apisCount == 0 || apisCount != numberOfCalls || isTracked) {
      return;
    }

    if (loggedInMemberIndex != null) {
      setIsTracked(true);
      adobePageTrackEvent();
    }
  }, [numberOfApiCalls, numberOfCalls, loggedInMemberIndex]);

  return [onProvidersLoad];
};
