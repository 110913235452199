import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../../../models/ProviderDetails';
import {
  eveningAndWeekendSubtext,
  getInNetworkStartDate,
  getPracticeLimitations,
  getServicesText,
  getYesNoText,
} from '../../../../utils/providerDetails.utils';
import { mobileOnly, phoneOnly } from '../../../ConstantsStyles';
import { CopyToClipBoard } from '../CopyToClipBoard';
import { InfoProviderContent } from '../InfoProviderContent';
import {
  infoContainer,
  infoProviderContentContainer,
} from '../ProviderLocationsDetails.style';
import { EmailAndWebsiteInfo } from './EmailAndWebsiteInfo';
import { ListItem } from './ListItem';

export type Props = {
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
  detailType: String;
  facilityDetails?: FacilityDetails;
  providerDetails?: ProviderDetails;
};

export const VisionCareAllOtherInfosContainer = ({
  selectedLocation,
  detailType,
  facilityDetails,
  providerDetails,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;

  const publicTransportationmap =
    selectedLocation?.publicTransportationMethods?.map((element) =>
      t(element)
    ) || [];

  const practiceLimitation = getPracticeLimitations(
    selectedLocation?.practiceLimitations?.age,
    selectedLocation?.practiceLimitations?.gender
  );
  const inNetworkStartDate = getInNetworkStartDate(
    selectedLocation?.network?.start
  );

  return (
    <React.Fragment>
      <Flex
        css={infoProviderContentContainer(mobileScreen)}
        data-testid="vision-care-all-other-infos"
      >
        <EmailAndWebsiteInfo selectedLocation={selectedLocation} />
        {detailType === ProviderType.ORGANIZATION ? (
          <InfoProviderContent
            icon="inventory"
            infoSubtext={selectedLocation.specialties?.join(', ')}
            infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.SPECIALITY')}
            variant="outlined"
          />
        ) : null}
        <InfoProviderContent
          icon="date_range"
          infoSubtext={eveningAndWeekendSubtext(t, selectedLocation)}
          infoTitle={t('OTHER_CARE_DETAILS.EVENING_AND_WEEKEND_OPTIONS')}
        />
        <InfoProviderContent
          icon="accessibility"
          infoSubtext={selectedLocation.accessibility?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.ACCESSIBILITY')}
        />
        <InfoProviderContent
          icon="translate"
          infoTitle={t('OTHER_CARE_DETAILS.LANGUAGE_SUPPORT')}
          variant="outlined"
        >
          <ListItem
            text={t('OTHER_CARE_DETAILS.LANGUAGES_SPOKEN_BY_STAFF')}
            value={
              selectedLocation?.languagesSpokenByOffice?.join(', ') ||
              t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
            }
          />
          <ListItem
            text={t('OTHER_CARE_DETAILS.LANGUAGE_LINE_AVAILABLE')}
            value={getYesNoText(t, selectedLocation?.languageLineAvailable)}
          />
          <ListItem
            text={t('OTHER_CARE_DETAILS.MEDICAL_INTERPRETER_IN_OFFICE')}
            value={getYesNoText(
              t,
              selectedLocation?.medicalInterpreterInOffice
            )}
          />
        </InfoProviderContent>
        <InfoProviderContent
          icon="task"
          infoSubtext={getServicesText(t, selectedLocation)}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.SERVICES_PERFORMED')}
          variant="outlined"
        />
        <InfoProviderContent
          icon="mobile_friendly"
          infoSubtext={selectedLocation.methodsOfService?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.METHODS_OF_SERVICE')}
          variant="outlined"
        />
        <InfoProviderContent
          icon="medical_services"
          infoSubtext={`${
            selectedLocation.isSedationOffered ? t('Yes') : t('No')
          }`}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.SEDATION_OFFERED')}
          variant="outlined"
        />

        <InfoProviderContent
          icon="directions_bus"
          infoSubtext={publicTransportationmap.join(', ')}
          infoTitle={t(
            'OTHER_CARE_DETAILS.PUBLIC_TRANSPORTATION_OPTIONS_AVAILABLE'
          )}
        />
        {providerDetails?.providerType === ProviderType.PRACTITIONER ? (
          <InfoProviderContent
            icon="pan_tool"
            infoSubtext={
              practiceLimitation && practiceLimitation?.trim().length > 0
                ? practiceLimitation
                : t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
            }
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PRACTICE_LIMITATIONS')}
          />
        ) : null}
        {detailType === ProviderType.ORGANIZATION ? (
          <InfoProviderContent
            icon="event"
            infoSubtext={inNetworkStartDate}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.IN_NETWORK')}
            variant="outlined"
          />
        ) : null}
        {detailType === ProviderType.ORGANIZATION ? (
          <React.Fragment>
            <InfoProviderContent
              icon="location_city"
              infoSubtext={facilityDetails?.providerId}
              infoTitle={t('PROVIDER_LOCATIONS_DETAILS.FACILITY_ID')}
              variant="outlined"
            >
              <Flex css={{ gap: '$xs' }}>
                <Text
                  color="$info1"
                  css={infoContainer(phoneScreen)}
                  fontWeight="$bold"
                >
                  {facilityDetails?.providerId ?? ''}
                </Text>

                <CopyToClipBoard
                  gap="0"
                  label={t('OTHER_CARE_DETAILS.COPY_FACILITY_ID_TO_CLIPBOARD')}
                  text={facilityDetails?.providerId ?? ''}
                  title="facility-id"
                />
              </Flex>
            </InfoProviderContent>
            <InfoProviderContent
              icon="person_pin"
              infoSubtext={facilityDetails?.npi}
              infoTitle={t('OTHER_CARE_DETAILS.NATIONAL_PROVIDER_IDENTIFIER')}
              variant="outlined"
            >
              <Flex css={{ gap: '$xs' }}>
                <Text
                  color="$info1"
                  css={infoContainer(phoneScreen)}
                  fontWeight="$bold"
                >
                  {facilityDetails?.npi ?? ''}
                </Text>

                <CopyToClipBoard
                  gap="0"
                  label={t(
                    'OTHER_CARE_DETAILS.COPY_NATIONAL_PROVIDER_IDENTIFIER_TO_CLIPBOARD'
                  )}
                  text={facilityDetails?.npi ?? ''}
                  title="facility-id"
                />
              </Flex>
            </InfoProviderContent>
          </React.Fragment>
        ) : null}
      </Flex>
    </React.Fragment>
  );
};
