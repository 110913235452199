import { title } from 'process';

import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { useTranslation } from 'react-i18next';

import { RadioGroup } from '../RadioGroup';
import { PatientReviewsSortOrder } from './AllPatientReviewsSortOrder';

type Props = {
  onApply: Function;
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  sortOrder: string;
  setSortOrder: Function;
};

export const AllPatientReviewsSortMobile = ({
  onApply,
  sortOrder,
  isOpen,
  setIsOpen,
  setSortOrder,
}: Props) => {
  const { t } = useTranslation();

  const properties = {
    drawerSize: 'fit-content',
    drawerPosition: 'bottom',
  };

  const handleOnApply = (value: string) => {
    if (value) {
      onApply(value);
    }
    setIsOpen(false);
    setSortOrder(value);
  };

  return (
    <Flex>
      {isOpen ? (
        <Drawer
          css={{
            'abyss-modal-content-container': {
              borderRadius: '20px 20px 0px 0px',
              padding: '$md',
              '.abyss-radio-root': {
                '&:hover': {
                  backgroundColor: '$tint2',
                },
              },
            },
            'abyss-modal-close-button': {
              top: '12px',
              right: '12px',
            },
            'abyss-modal-header-container': {
              padding: '0',
              marginBottom: '$md',
            },
            'abyss-modal-content-title': {
              fontFamily: '$primary !important',
              fontSize: '$mediumText !important',
              fontWeight: '$bold !important',
              lineHeight: '24px !important',
              color: '$gray-07 !important',
              padding: '0',
            },
          }}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          position={properties.drawerPosition}
          size={properties.drawerSize}
          title={t('SORT_BUTTONS.SORT_REVIEWS')}
        >
          <RadioGroup
            defaultValue={sortOrder}
            labelFontSize="18px"
            locationForAnalytics={`modal:${title}`}
            model="SortBy"
            onApply={handleOnApply}
            options={[
              {
                label: t(PatientReviewsSortOrder.MOST_RECENT),
                value: PatientReviewsSortOrder.MOST_RECENT,
              },
              {
                label: t(PatientReviewsSortOrder.HIGHEST_RATING),
                value: PatientReviewsSortOrder.HIGHEST_RATING,
              },
              {
                label: t(PatientReviewsSortOrder.LOWEST_RATING),
                value: PatientReviewsSortOrder.LOWEST_RATING,
              },
            ]}
            submitBtnName={t('Sort')}
          />
        </Drawer>
      ) : null}
    </Flex>
  );
};
