import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Badge } from '@abyss/web/ui/Badge';
import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import React, { useState } from 'react';

import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { phoneOnly } from '../ConstantsStyles';
import { PSXIcon } from '../PSXIcon';
import {
  PopoverContainer,
  TierOneSnackCardPopoverStyles,
} from '../SnackCard/SnackCard.styles';
import { TieredDrawerStyles } from '../TierBenefitsCard/TierBenefitsCard.style';
import { BadgeContainer } from './DetailsPageHeader.styled';

export type Props = {
  content: string;
  icon?: string;
  testid: string;
  title: string;
};

export const BenefitsBadge = ({ content, title, icon, testid = '' }: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const renderIcon = icon && <PSXIcon icon={icon} size={16} />;

  return (
    <BadgeContainer>
      {phoneScreen && !isWidget ? (
        <React.Fragment>
          <Drawer
            css={TieredDrawerStyles}
            isOpen={isDrawerOpen}
            onClose={() => setDrawerOpen(!isDrawerOpen)}
            position="bottom"
            size="200"
            title={title}
          >
            {content}
          </Drawer>

          <Layout.Group space="4">
            <Badge
              ariaText={title}
              data-auto-testid={`${testid}-drawer`}
              data-testid={`${testid}-drawer`}
              icon={renderIcon}
              onClick={() => setDrawerOpen(!isDrawerOpen)}
              variant="neutral"
            >
              {title}
            </Badge>
            <IconMaterial
              data-auto-testid={`${testid}-drawer-info`}
              data-testid={`${testid}-drawer-info`}
              icon="info"
              size={16}
              variant="outlined"
            />
          </Layout.Group>
        </React.Fragment>
      ) : (
        <PopoverContainer>
          <Popover
            content={content}
            css={TierOneSnackCardPopoverStyles}
            data-auto-testid={`${testid}-popover`}
            data-testid={`${testid}-popover`}
            title={title}
            width={214}
          >
            <Layout.Group space="4px">
              <Badge ariaText={title} icon={renderIcon} variant="neutral">
                {title}
              </Badge>
              <IconMaterial
                data-auto-testid={`${testid}-popover-info`}
                data-testid={`${testid}-popover-info`}
                icon="info"
                size={16}
                variant="outlined"
              />
            </Layout.Group>
          </Popover>
        </PopoverContainer>
      )}
    </BadgeContainer>
  );
};
