import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchFilterContext } from '../../../context/SearchFilterContext';
import { useParsedProviderFilters } from '../../../hooks/useProviderFilterCount';
import { useProviderFilters } from '../../../hooks/useProviderFilters';
import {
  LastModifiedFilter,
  ProviderSearchFilters,
} from '../../../models/ProviderSearch';
import { getTokenData } from '../../../utils/common';
import { transformFiltersData } from '../../../utils/providerSearch.utils';
import { adobeSearchFilterEvent } from '../../AdobeTagging/adobeSearchFilterEvent';
import {
  getFormattedFilters,
  getSearchTerm,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { AllFilterToggleSwitchGroup } from './AllFilterToggleSwitchGroup';
import {
  BottomSection,
  DrawerHeading,
  FilterWrapper,
  ScrollableSection,
  StyledDrawer,
} from './index.style';
import { ProviderFilters } from './ProviderFilters';
import { SelectedFilters } from './SelectedFilters';

type Props = {
  position: string;
  isOpen: boolean;
  size: number;
  onClose: () => void;
  allFiltersButtonRef: React.RefObject<HTMLButtonElement>;
};

export const AllFilterDrawer = ({
  position,
  isOpen,
  size,
  onClose,
  allFiltersButtonRef,
}: Props) => {
  const { t } = useTranslation();
  const { searchFilters, setSearchFilters, defaultSearchFilters } =
    useContext(SearchFilterContext);

  const filtersCollection = useProviderFilters();

  const [selectedFilters, setSelectedFilters] =
    useState<ProviderSearchFilters>(searchFilters);
  const [lastModifiedFilter, setLastModifiedFilter] =
    useState<LastModifiedFilter>({});

  const [selectedFiltersContainerHeight, setSelectedFiltersContainerHeight] =
    useState<number>(0);

  const tokenData = getTokenData();

  const {
    totalCount = 0,
    providerFilterList: filtersData,
    isLoading,
  } = useParsedProviderFilters({
    selectedFilters,
  });

  const filters = transformFiltersData(
    t,
    filtersCollection,
    filtersData,
    lastModifiedFilter
  );

  const onFilterSelectionChange = (
    data: ProviderSearchFilters,
    lastModifiedModel: string = ''
  ) => {
    setLastModifiedFilter(
      filters.find((filter) => filter.model === lastModifiedModel) || {}
    );
    setSelectedFilters(data);
  };

  const handleSubmit = () => {
    adobeSearchFilterEvent({
      filters: getFormattedFilters(selectedFilters),
      term: getSearchTerm(tokenData),
    });
    setLastModifiedFilter({});
    setSearchFilters(selectedFilters);
    onClose();
  };

  const handleCloseAllFilterDrawer = () => {
    onClose();
    if (allFiltersButtonRef.current) {
      allFiltersButtonRef.current.focus();
    }
  };

  // unsure why linter is complaining about no return for a useEffect function
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isOpen) {
      const previousActiveElement = document.activeElement as HTMLElement;
      const firstFocusableElement = document.querySelector(
        '[data-auto-testid="all-filter-heading"] [tabindex="0"]'
      ) as HTMLElement;
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
      return () => {
        if (allFiltersButtonRef?.current) {
          allFiltersButtonRef.current.focus();
        }
        previousActiveElement.focus();
      };
    }
  }, [isOpen, allFiltersButtonRef]);

  return (
    <StyledDrawer
      data-auto-testid="all-filter-drawer"
      data-testid="all-filter-drawer"
      footer={
        <BottomSection>
          <Button
            css={{
              fontWeight: '$semibold',
              '@screen < $sm': {
                marginBottom: '70px',
              },
            }}
            data-auto-testid="show-result"
            data-testid="show-result"
            isDisabled={isLoading || totalCount === 0}
            onClick={handleSubmit}
            ref={allFiltersButtonRef}
          >
            {!isLoading
              ? t(
                  'CONTENT_FILTERS.ALL_FILTERS_DRAWER.SHOW_RESULT_BUTTON'
                ).replace('$count', totalCount?.toString())
              : t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.LOADING')}
          </Button>
        </BottomSection>
      }
      isOpen={isOpen}
      onClose={handleCloseAllFilterDrawer}
      position={position}
      size={size}
      title={
        <Heading
          color="$gray8"
          css={DrawerHeading}
          data-testid="all-filters-heading"
          offset={5}
        >
          {t('CONTENT_FILTERS.ALL_FILTERS')}
        </Heading>
      }
    >
      <SelectedFilters
        defaultSearchFilters={defaultSearchFilters}
        lastModifiedFilter={lastModifiedFilter}
        onFilterSelectionChange={onFilterSelectionChange}
        selectedFilters={selectedFilters}
        setSelectedFiltersContainerHeight={setSelectedFiltersContainerHeight}
      />

      <FilterWrapper className="hideScrollBar">
        <AllFilterToggleSwitchGroup
          sectionType={tokenData.sectionType}
          selectedFilters={selectedFilters}
          selectedFiltersContainerHeight={selectedFiltersContainerHeight}
          setSelectedFilters={onFilterSelectionChange}
        />
        <ScrollableSection>
          <ProviderFilters
            isData={!isLoading}
            onFilterSelectionChange={onFilterSelectionChange}
            providerFilters={filters}
            sectionType={tokenData.sectionType}
            selectedFilters={selectedFilters}
          />
        </ScrollableSection>
      </FilterWrapper>
    </StyledDrawer>
  );
};
