import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import { useHealthgradesGetProviderReview } from '../../../../hooks/useHealthgrades';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  HealthgradesProviderReview,
  Survey,
} from '../../../../models/Healthgrades';
import {
  ProviderDetails,
  ProviderLocation,
} from '../../../../models/ProviderDetails';
import {
  getNetworkStatus,
  getPatientCardLength,
} from '../../../../utils/providerDetails.utils';
import { formatProviderNameWithFirstMiddleLastName } from '../../../../utils/providerNames.utils';
import { Constants } from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { ConstantsRoutes } from '../../../ConstantsRoutes';
import { h2SmallerFontForResponsive } from '../../../ConstantsStyles';
import { ContentWrapper } from '../../../ContentWrapper';
import { handleLinkAndModalTrack } from '../../../Utils/adobeTrackUtils/adobeTrackUtils';
import { getFeatureFlag } from '../../../Utils/getFeatureFlag';
import { PatientReviewMobileCarousel } from './PatientReviewMobileCarousel';
import { PatientReviewsModal } from './PatientReviewModal';
import {
  Container,
  ContentContainer,
  RatingSectionContainerStyles,
  StyledIcon,
  modalStyles,
  verbatimContainerStyles,
  verbatimHeader,
} from './PatientReviews.styled';
import { PatientReviewsOverviewSection } from './PatientReviewsOverviewSection';
import { PatientReviewsViewAll } from './PatientReviewsViewAll';
import { PatientVerbatimCard } from './PatientVerbatimCard';

type Props = {
  selectedLocation?: ProviderLocation;
  providerDetails: ProviderDetails;
};

export const PatientReviews = ({
  selectedLocation,
  providerDetails,
}: Props) => {
  const patientReviewsDataTestId: string = 'patient-reviews-section';
  const sectionHeading: string =
    Constants.PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION
      .WHAT_PATIENTS_ARE_SAYING;
  const featureFlags = useLagoon('feature-flags')();
  const displayPatientReviewsDynamicHeader: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PATIENT_REVIEWS_DYNAMIC_HEADER
  );
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const [healthgradesReview, setHealthgradesReview] =
    useState<HealthgradesProviderReview>({});
  const [isLoading, setIsLoading] = useState(false);
  const [reviewModal, setReviewModal] = useState<Survey | null>(null);
  const [, getHealthgradesProviderReview] = useHealthgradesGetProviderReview({
    onCompleted: (result) => {
      setIsLoading(false);
      if (result?.data?.healthgradesGetProviderReview) {
        setHealthgradesReview(result.data.healthgradesGetProviderReview);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      throw error;
    },
  });

  const networkStatus = getNetworkStatus(selectedLocation?.network?.status);

  const handleViewAllOnClick = () => {
    const updatedToken = tokenizer.update(token, {
      showDirection: false,
      avatar: providerDetails?.imageLocation || 'heart',
      inNetwork: networkStatus,
      providerName: providerDetails.providerName,
      primarySpeciality: providerDetails.specialties?.join('|'),
      professionalDesignation: providerDetails.primaryDegrees?.[0],
      rating: healthgradesReview.overallSurveyStarScore,
      npi: providerDetails.npi,
      providerDetails: JSON.stringify(providerDetails),
      imageLocation: providerDetails.imageLocation,
    });
    navigate(`${ConstantsRoutes.ALL_PATIENT_REVIEWS.path}/${updatedToken}`);
  };

  useEffect(() => {
    if (providerDetails?.npi) {
      setIsLoading(true);
      getHealthgradesProviderReview({
        variables: {
          npi: providerDetails.npi,
        },
      });
    }
  }, []);

  const reviewsLength = healthgradesReview.surveyResults?.surveys?.length || 0;
  const displayCarousel = getPatientCardLength(reviewsLength, isWidget);

  const displayViewAll = healthgradesReview?.surveyResults?.surveyCount
    ? healthgradesReview?.surveyResults?.surveyCount > 4
    : false;

  useEffect(() => {
    if (reviewModal) {
      const linkName: string = patientReviewsDataTestId;
      const linkLocation: string = `body:${sectionHeading}`;
      const modalName: string = 'patient review';
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [reviewModal]);

  const patientReviewsHeader = () => {
    if (displayPatientReviewsDynamicHeader) {
      return `${t(
        'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING_ABOUT'
      )} ${formatProviderNameWithFirstMiddleLastName(providerDetails)}${
        providerDetails.primaryDegrees
          ? `, ${providerDetails.primaryDegrees}`
          : ''
      }`;
    }
    return t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.PATIENT_REVIEWS');
  };

  return (
    <Container>
      <ContentWrapper>
        <ContentContainer data-testid="patient-review-section">
          {reviewModal ? (
            <Modal
              css={modalStyles}
              isOpen={!!reviewModal}
              onClose={() => setReviewModal(null)}
            >
              <Modal.Section
                data-auto-testId={patientReviewsDataTestId}
                data-testId={patientReviewsDataTestId}
              >
                <PatientReviewsModal review={reviewModal} />
              </Modal.Section>
            </Modal>
          ) : null}
          <StyledIcon
            icon="healthgrades"
            isScreenReadable
            size={132}
            title={t('HEALTH_GRADES_LOGO_TITLE')}
          />
          <Heading
            css={h2SmallerFontForResponsive}
            data-auto-testid="patient-review-heading"
            data-testid="patient-review-heading"
            display="h4"
            offset={1}
          >
            {patientReviewsHeader()}
          </Heading>
          {isLoading ? (
            <LoadingSpinner
              ariaLoadingLabel="loading results"
              isLoading={isLoading}
              size="$sm"
            />
          ) : (
            <div
              data-auto-testid="reviews-container"
              data-testid="reviews-container"
              style={RatingSectionContainerStyles}
            >
              {healthgradesReview.surveyResults ? (
                <div>
                  <PatientReviewsOverviewSection
                    healthgradesReview={healthgradesReview}
                  />
                  {healthgradesReview.surveyResults?.surveys?.length ? (
                    <div>
                      <Flex css={verbatimHeader}>
                        <Heading
                          css={{
                            '@screen < $md': {
                              '&.abyss-heading-root': {
                                height: '20px !important',
                                fontSize: '16px !important',
                                lineHeight: '20px !important',
                              },
                            },
                          }}
                          data-auto-testid="what-patients-are-saying-subtitle"
                          data-testid="what-patients-are-saying-subtitle"
                          display="h6"
                          offset={2}
                        >
                          {t(
                            'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING'
                          )}
                        </Heading>
                        <PatientReviewsViewAll
                          displayViewAll={displayViewAll}
                          handleViewAllOnClick={handleViewAllOnClick}
                          locationForAnalytics={sectionHeading}
                        />
                      </Flex>
                      {displayCarousel ? (
                        <PatientReviewMobileCarousel
                          healthgradesReview={healthgradesReview}
                          setReviewModal={setReviewModal}
                        />
                      ) : (
                        <Flex
                          alignItems="flex-end"
                          css={verbatimContainerStyles}
                        >
                          {healthgradesReview.surveyResults.surveys
                            .slice(0, 4)
                            .map((survey) => (
                              <PatientVerbatimCard
                                key={survey.id}
                                setReviewModal={setReviewModal}
                                survey={survey}
                              />
                            ))}
                        </Flex>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : (
                <Text>
                  {t(
                    'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.NO_REVIEWS_FOUND'
                  )}
                </Text>
              )}
            </div>
          )}
        </ContentContainer>
      </ContentWrapper>
    </Container>
  );
};
