import { IconMaterial } from '@abyss/web/ui/IconMaterial';
/* eslint-disable @optum-fpc-psx-mobile-apps/no-missing-testid */
/* eslint-disable react/prop-types */
import { Link } from '@abyss/web/ui/Link';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../Constants';
import { isLocationActiveSuggetion } from './LocationDropdown.styled';

// import { IChangeLocationItemProps } from './types';

export const LocationItem = ({
  location,
  onClick,
  index,
  activeSuggestion,
  suggestions,
  getDeviceLocation,
}) => {
  const { t } = useTranslation();

  return (
    <li
      aria-posinset={index}
      aria-selected={activeSuggestion === index}
      id={
        activeSuggestion === index ? 'location-search-result-suggestions' : ''
      }
      key={index}
      role="option"
      style={activeSuggestion === index ? isLocationActiveSuggetion : {}}
    >
      <Link
        before={
          index === suggestions.length - 1 && (
            <IconMaterial icon="my_location" />
          )
        }
        css={
          index === suggestions.length - 1
            ? {
                '&.abyss-link-root': {
                  height: '42px',
                  marginTop: '7px',
                  padding: '8px 0px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '4px',
                },
              }
            : {
                '&.abyss-link-root': {},
              }
        }
        data-auto-testid={
          index === suggestions.length - 1
            ? `changeLocation_currentLocationAction`
            : `suggested-location-item${
                activeSuggestion === index ? '-active-suggestion' : ''
              }`
        }
        data-testid={
          index === suggestions.length - 1
            ? `changeLocation_currentLocationAction`
            : `suggested-location-item${
                activeSuggestion === index ? '-active-suggestion' : ''
              }`
        }
        fontWeight={index === suggestions.length - 1 ? 600 : '$bold'}
        id={`option-${index}`}
        onClick={() => {
          if (index === suggestions.length - 1) {
            const locationForAnalytics: string = 'change search location';
            adobeLinkTrackEvent({
              name: Constants.LOCATION_SEARCH.FIND_MY_LOCATION,
              location: `body:${locationForAnalytics}`,
              type: 'internal',
            });
            getDeviceLocation();
          } else {
            onClick(location);
          }
        }}
        tabIndex={-1}
      >
        {index === suggestions.length - 1
          ? t('Find my location')
          : location?.place_name}
      </Link>
    </li>
  );
};
