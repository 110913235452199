import isEmpty from 'lodash/isEmpty';

import { ProviderType } from '../models/ProviderDetails';

export const formatProviderNameWithFirstMiddleLastName = (provider: any) =>
  [provider?.firstName, provider?.middleName, provider?.lastName]
    .filter((item) => !isEmpty(item) && item?.trim()?.length > 0)
    .map((item) => item.trim())
    .join(' ');

export const getNameAttrs = (provider) => ({
  firstName: provider.firstName,
  middleName: provider.middleName,
  lastName: provider.lastName,
});

export const parseProviderName = (
  providerName,
  providerType,
  primaryDegree?: string,
  nameAttrs?
) =>
  // eslint-disable-next-line no-nested-ternary
  providerType === ProviderType.PRACTITIONER
    ? `${formatProviderNameWithFirstMiddleLastName(nameAttrs)}${
        primaryDegree ? `, ${primaryDegree}` : ''
      }`
    : providerName || providerType === ProviderType.MEDICAL_GROUP
    ? providerName
    : '';

export function commaInBetweenLastAndFirstName(providerName) {
  const splitName = providerName.split(' ');
  const firstName = splitName[1];
  const lastName = splitName[0];
  return `${lastName}, ${firstName}`;
}

export function capitalizeFirstLetters(words) {
  return words?.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`
  );
}

export function capitalizeEachWord(words) {
  return words?.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  );
}
