import {
  Constants,
  NULL_RESULTS,
  ProviderTypes,
} from '../../../../common/Constants';
import {
  convertProviderTypeToAdobeType,
  getDisplayedProviderCardsCount,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import {
  getCoverageTypes,
  getNetworkIdsForPES,
} from '../../../../utils/user.utils';

export function getProviderType(providerType) {
  if (providerType === '') {
    return `${convertProviderTypeToAdobeType(
      ProviderTypes.PRACTITIONER
    )}|${convertProviderTypeToAdobeType(
      ProviderTypes.ORGANIZATION
    )}|${convertProviderTypeToAdobeType(ProviderTypes.MEDICAL_GROUP)}`;
  }
  return convertProviderTypeToAdobeType(providerType);
}
export const formatData = (practitionerData, orgdata, medGrpData) => {
  const data: any = {};
  data.totalCountPractitioners = practitionerData.totalCountPractitioners;
  data.totalCountOrganizations = orgdata.totalCountOrganizations;
  data.totalCountMedicalGroup = medGrpData.totalCountMedicalGroup;
  data.practitionerSearchRadius = practitionerData.practitionerSearchRadius;
  data.organizationSearchRadius = orgdata.organizationSearchRadius;
  data.medicalGroupSearchRadius = medGrpData.medicalGroupSearchRadius;
  data.medicalGroup = medGrpData.medicalGroup;
  data.facilities = orgdata.facilities;
  data.providers = practitionerData.providers;
  return data;
};

export const isEmptyResult = (
  isLoading,
  resultType,
  totalCountOrganizations,
  totalCountMedicalGroup,
  totalCountPractitioners
) =>
  !isLoading &&
  (resultType === NULL_RESULTS ||
    (totalCountOrganizations === 0 &&
      totalCountMedicalGroup === 0 &&
      totalCountPractitioners === 0));

export const getPayloadWithRecriprocityId = ({
  currentMember,
  onlineRetailers,
  featureFlags,
}) => {
  const memberCoverageTypes = getCoverageTypes(currentMember);
  return JSON.parse(JSON.stringify(Constants.CARE_CATEGORIES_NAME_PAYLOAD))
    .filter((category) =>
      memberCoverageTypes.includes(category?.coverages?.[0])
    )
    .map((category) => {
      if (
        category.chipCategory === 'health-supplies' ||
        category.chipCategory === 'labs-and-services' ||
        category.chipCategory === 'behavioral-health'
      ) {
        return category;
      }
      return {
        ...category,
        onlineRetailer: onlineRetailers,
        reciprocityId: getNetworkIdsForPES(
          currentMember,
          category?.coverages?.[0],
          featureFlags
        ),
      };
    });
};

export const getNoOfResultsForAnalytics = (data) => {
  let totalDisplayedProviderCardsCount = 0;
  totalDisplayedProviderCardsCount = getDisplayedProviderCardsCount(
    totalDisplayedProviderCardsCount,
    data?.totalCountPractitioners || 0
  );
  totalDisplayedProviderCardsCount = getDisplayedProviderCardsCount(
    totalDisplayedProviderCardsCount,
    data?.totalCountMedicalGroup || 0
  );
  totalDisplayedProviderCardsCount = getDisplayedProviderCardsCount(
    totalDisplayedProviderCardsCount,
    data?.totalCountOrganizations || 0
  );

  return totalDisplayedProviderCardsCount;
};

export const getProviderTypesFromDisplayedResults = (data) => {
  let providerTypesFromDisplayedProviderCards = '';
  if (data?.totalCountPractitioners && data?.totalCountPractitioners > 0) {
    providerTypesFromDisplayedProviderCards = convertProviderTypeToAdobeType(
      ProviderTypes.PRACTITIONER
    );
  }
  if (data?.totalCountOrganizations && data?.totalCountOrganizations > 0) {
    providerTypesFromDisplayedProviderCards =
      providerTypesFromDisplayedProviderCards !== ''
        ? `${providerTypesFromDisplayedProviderCards}|${convertProviderTypeToAdobeType(
            ProviderTypes.ORGANIZATION
          )}`
        : convertProviderTypeToAdobeType(ProviderTypes.ORGANIZATION);
  }
  if (data?.totalCountMedicalGroup && data?.totalCountMedicalGroup > 0) {
    providerTypesFromDisplayedProviderCards =
      providerTypesFromDisplayedProviderCards !== ''
        ? `${providerTypesFromDisplayedProviderCards}|${convertProviderTypeToAdobeType(
            ProviderTypes.MEDICAL_GROUP
          )}`
        : convertProviderTypeToAdobeType(ProviderTypes.MEDICAL_GROUP);
  }

  return providerTypesFromDisplayedProviderCards;
};
