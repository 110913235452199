import React, { useEffect, useState } from 'react';

import { Footer } from '../../../frontends/ProviderSearch/routes/Footer';
import { useLagoon } from '../../../hooks/useLagoon';
import { useProviderDetailsWidget } from '../../../hooks/useProviderDetails';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { ProviderType } from '../../../models/ProviderDetails';
import {
  UserDDPCode,
  getNetworkStatus,
} from '../../../utils/providerDetails.utils';
import { getNameAttrs } from '../../../utils/providerNames.utils';
import { CardWrapper, CardWrapperDetailsPage } from '../../CardWrapper';
import { ProviderTabs } from '../../Constants';
import { DisclaimersContainer } from '../../DisclaimersContainer/DisclaimersContainer';
import { DetailsWidgetHeader } from './DetailsWidgetHeader';
import { DetailsWidgetSkeleton } from './DetailsWidgetSkeleton';
import { DetailsWidgetTabs } from './DetailsWidgetTabs';
import { WidgetDisclaimersWrapper } from './DetailsWidgetView.styled';
import { ErrorHandlingWidget } from './ErrorHandlingWidget';
import { About } from './WidgetPages/About';
import { Locations } from './WidgetPages/Locations';
import { Quality } from './WidgetPages/Quality';

type Props = {
  coverageType: string;
  ddpUserCode: UserDDPCode;
  latitude: string;
  lob: string;
  locationId: string;
  longitude: string;
  outOfPocketCost?: string;
  planVariationCode: string;
  planYear: string;
  policyId: string;
  providerId: string;
  providerType: ProviderType;
  reciprocityId: string;
  totalCost?: string;
};

export const DetailsWidgetView = ({
  coverageType,
  ddpUserCode,
  latitude,
  lob,
  locationId,
  longitude,
  outOfPocketCost,
  planVariationCode,
  planYear,
  policyId,
  providerId,
  providerType,
  reciprocityId,
  totalCost,
}: Props) => {
  const defaultActiveTab =
    providerType === ProviderType.ORGANIZATION
      ? ProviderTabs.LOCATIONS
      : ProviderTabs.ABOUT;
  const [activeTab, setActiveTab] = useState<ProviderTabs>(defaultActiveTab);
  const [retry, toggleRetry] = useState<boolean>(false);

  const suppressionLagoonData = useLagoon('feature-supression')();
  const setUIFeatureSuppressionState = useStore(
    StoreKeys.SET_UI_FEATURE_SUPPRESSION
  );

  const {
    data: providerData,
    error,
    isLoading,
  } = useProviderDetailsWidget({
    coverageType,
    latitude,
    lob,
    locationId,
    longitude,
    planVariationCode,
    planYear,
    policyId,
    providerId,
    providerType,
    reciprocityId,
  });

  const handleRetry = () => toggleRetry(!retry);

  useEffect(() => {
    setUIFeatureSuppressionState({
      suppressionLagoonData,
      memberPolicy: policyId,
    });
  }, []);

  if (error) {
    return <ErrorHandlingWidget handleRetry={handleRetry} />;
  }

  const providerDetails =
    providerData.facilityDetails?.providerDetails ||
    providerData?.providerDetails?.providerDetails;

  if (isLoading || !providerDetails) {
    return <DetailsWidgetSkeleton />;
  }

  const {
    areaOfExpertise,
    biographicData,
    boardCertifications,
    culturalCompetencies,
    educationInfo,
    ethnicity,
    gender,
    hospitalAffiliations,
    imageLocation,
    healthGradeRating,
    languagesSpoken,
    licenseNumber,
    npi,
    organizationType = [],
    primaryDegrees,
    providerLocations = [],
    providerName,
    specialties,
    virtualCareOffered,
  } = providerDetails;

  const selectedLocation = providerLocations?.[0] || {};
  const {
    address,
    ddpCode: ddpFacilityCode,
    facilityProviderSpecialties = {},
    network,
  } = selectedLocation;
  const { isPreferredLabNetwork, isFreeStandingFacility } =
    facilityProviderSpecialties;
  const networkStatus = getNetworkStatus(network?.status);
  const nameAttrs = getNameAttrs(providerDetails);

  const getActiveTabContent = () => {
    switch (activeTab) {
      case ProviderTabs.ABOUT:
        return (
          <About
            areaOfExpertise={areaOfExpertise}
            biographicData={biographicData}
            culturalCompetencies={culturalCompetencies}
            educationInfo={educationInfo}
            ethnicity={ethnicity}
            gender={gender}
            hospitalAffiliations={hospitalAffiliations}
            languagesSpoken={languagesSpoken}
            licenseNumber={licenseNumber}
            npi={npi}
            providerName={providerName}
            specialties={specialties}
            userState={address?.state}
          />
        );
      case ProviderTabs.LOCATIONS:
        return (
          <Locations
            latitude={latitude}
            locationId={locationId}
            longitude={longitude}
            providerDetails={providerDetails}
          />
        );
      case ProviderTabs.QUALITY:
        return (
          <Quality
            boardCertifications={boardCertifications}
            providerName={providerName}
            providerType={providerType}
            selectedLocation={selectedLocation}
          />
        );
      default:
        return <React.Fragment />;
    }
  };

  return (
    <React.Fragment>
      <CardWrapperDetailsPage>
        <DetailsWidgetHeader
          coverageType={coverageType}
          ddpFacilityCode={ddpFacilityCode}
          ddpUserCode={ddpUserCode}
          imageLocation={imageLocation}
          inNetwork={networkStatus}
          isFreeStandingFacility={isFreeStandingFacility}
          isPreferredLabNetwork={isPreferredLabNetwork}
          nameAttrs={nameAttrs}
          organizationType={organizationType?.[0]}
          outOfPocketCost={outOfPocketCost}
          primarySpeciality={specialties?.[0]}
          professionalDesignation={primaryDegrees?.[0]}
          providerName={providerName}
          providerType={providerType}
          rating={healthGradeRating}
          totalCost={totalCost}
          virtualCareOffered={virtualCareOffered}
        />
      </CardWrapperDetailsPage>
      <CardWrapperDetailsPage>
        <DetailsWidgetTabs
          providerType={providerType}
          setActiveTab={setActiveTab}
        />
        {getActiveTabContent()}
      </CardWrapperDetailsPage>
      <WidgetDisclaimersWrapper>
        <DisclaimersContainer
          isVisionCare={coverageType === 'V'}
          showPremiumCareDisclaimer={coverageType !== 'B'}
        />
      </WidgetDisclaimersWrapper>
      <CardWrapper backgroundColor="rgb(244, 246, 250)" isWidget>
        <Footer />
      </CardWrapper>
    </React.Fragment>
  );
};
