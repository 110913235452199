import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useToast } from '@abyss/web/hooks/useToast';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Drawer } from '@abyss/web/ui/Drawer';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';

import { emailRegex } from '../../frontends/ProviderSearch/routes/MapView/ShareDrawer/utils';
import { useLagoon } from '../../hooks/useLagoon';
import { useSendEmail } from '../../hooks/useSendEmail';
import { ProviderLocation, ProviderType } from '../../models/ProviderDetails';
import { capitalizeEachWord } from '../../utils/providerNames.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants, ProviderTypes } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { phoneOnly } from '../ConstantsStyles';
import { getCurrentDateWithDashFormatting } from '../Utils/datesUtils';
import {
  ShareThisProviderButton,
  ShareThisProviderButtonWrapper,
  ShareThisProviderHeadingName,
  ShareThisProviderHeadingWrapper,
  ShareThisProviderRoot,
  ShareThisProviderText,
  ShareThisProviderTextInput,
  ShareThisProviderTextInputURL,
  toastSuccessStyle,
} from './ShareThisProvider.style';

type Props = {
  showShareThisProviderDrawer: boolean;
  setShowShareThisProviderDrawer: Function;
  providerName: string;
  providerType: ProviderType;
  speciality: string[];
  professionalDesignation?: string;
  providerLocations: ProviderLocation[];
};

export const ShareThisProviderDrawer = ({
  showShareThisProviderDrawer,
  setShowShareThisProviderDrawer,
  providerName,
  providerType,
  speciality,
  professionalDesignation,
  providerLocations,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const [, sendEmail] = useSendEmail({});
  const { toast } = useToast();
  const sendEmailTestId = 'send-email';

  const { providerId, sectionType } = tokenData;

  const updatedToken = tokenizer.create({
    providerId,
    sectionType,
  });

  const detailsRoute =
    sectionType === Constants.RESULT_SECTION.FACILITY_CAPITAL
      ? ConstantsRoutes.FACILITY_DETAILS.path
      : ConstantsRoutes.PROVIDER_DETAILS.path;

  const shareUrl = `${window.location.origin}/findcare${detailsRoute}${updatedToken}`;
  const data = useLagoon('ui-messaging')();
  const modalContent = find(data, {
    key: ConstantsLagoon.SHARE_THIS_PROVIDER.SHARE_THIS_PROVIDER_MESSAGE,
  });
  const form = useForm();

  const validEmail = find(data, {
    key: ConstantsLagoon.VALID_EMAIL_ADDRESS.VALID_EMAIL_ADDRESS_MESSAGE,
  })?.message;

  const handleDrawerClose = () => {
    adobeLinkTrackEvent({
      name: 'cancel',
      location: 'modal:share-this-provider',
      type: 'internal',
    });
    form.reset();
    setShowShareThisProviderDrawer(false);
  };
  const formatEmails = () => {
    const formValues: string[] = Object.values(form.getValues());
    const emails: string[] = formValues.filter((val: string) => val.length);
    return emails;
  };

  const subjectText = () =>
    // eslint-disable-next-line no-nested-ternary
    providerType?.toLowerCase() === ProviderTypes.PRACTITIONER.toLowerCase()
      ? t('SHARE_THIS_PROVIDER.PROVIDER')
      : providerType?.toLowerCase() === ProviderTypes.ORGANIZATION.toLowerCase()
      ? t('SHARE_THIS_PROVIDER.FACILITY')
      : t('SHARE_THIS_PROVIDER.PROVIDER_GROUP');

  const handleDrawerSubmit = async () => {
    adobeLinkTrackEvent({
      name: sendEmailTestId,
      location: 'modal:share-this-provider',
      type: 'internal',
    });
    let text = `<div><div><b>${subjectText()} ${providerName} ${t(
      'SHARE_THIS_PROVIDER.ON'
    )} ${getCurrentDateWithDashFormatting()}</b><br><br>${providerName}${
      professionalDesignation ? `, ${professionalDesignation}` : ''
    }${speciality ? `${speciality}` : ''}<br>
    </div>`;

    providerLocations.forEach((provider, index) => {
      text += `<div style="margin-top:10px"><br>Location ${index + 1}:<br>${
        provider.address.line[0]
      }<br>${provider.address?.city}, ${
        provider.address?.state
      }, ${provider.address?.postalCode.slice(0, 5)}<br>${
        provider.phones?.phone?.[0] || ''
      }</div>`;
    });
    text += `<div style="margin-top:10px">[${shareUrl}]<div>`;
    if (formatEmails().length) {
      const response = await sendEmail({
        variables: {
          recipientEmails: formatEmails(),
          subject: `${subjectText()} ${providerName} ${t(
            'SHARE_THIS_PROVIDER.ON'
          )} ${getCurrentDateWithDashFormatting()}`,
          text: '',
          htmlText: text,
        },
      });
      if (response.status === 200) {
        toast.show({
          message: (
            <Text color="$white">
              {t('TOAST.YOUR_RESULTS_HAVE_BEEN_SHARED')}
            </Text>
          ),
          variant: 'success',
          icon: <IconMaterial color="$white !important" icon="check_circle" />,
          css: toastSuccessStyle,
        });
        form.reset();
        setShowShareThisProviderDrawer(false);
      }
    }
  };

  return (
    <Drawer
      css={{
        'abyss-modal-close-button': {
          top: '25px',
        },
      }}
      isOpen={showShareThisProviderDrawer}
      onClose={handleDrawerClose}
      position={mobileScreen ? 'bottom' : 'right'}
      size={mobileScreen ? window.innerHeight - 104 : 408}
      title={
        <Heading
          color="$neutralGray7"
          css={{
            'abyss-heading-root': {
              fontSize: '25.63px !important',
              lineHeight: '32px !important',
            },
          }}
          data-auto-testid="share-this-provider-heading"
          data-testid="share-this-provider-heading"
          fontStyle="normal"
          offset={3}
        >
          {t('SHARE_THIS_PROVIDER.SHARE_THIS_PROVIDER')}
        </Heading>
      }
    >
      <FormProvider
        data-auto-testid="suggest-edit-form"
        data-testid="suggest-edit-form"
        descriptorsDisplay="column"
        state={form}
      >
        <ShareThisProviderRoot direction="column">
          <ShareThisProviderHeadingWrapper
            alignItems="flex-start"
            direction="column"
          >
            <ShareThisProviderHeadingName
              color="$neutralGray7"
              fontStyle="normal"
              offset={5}
            >
              {capitalizeEachWord(providerName)}
              {professionalDesignation && `, ${professionalDesignation}`}
            </ShareThisProviderHeadingName>
            <ShareThisProviderText color="$gray7">
              {capitalizeEachWord(speciality?.[0])}
            </ShareThisProviderText>
            <ShareThisProviderTextInputURL
              data-auto-testid="share-url"
              data-testid="share-url"
              hideLabel
              value={shareUrl}
            />
          </ShareThisProviderHeadingWrapper>
          <ShareThisProviderText color="$neutralGray">
            {t('SHARE_THIS_PROVIDER.LOCATIONS_SHARE_TEXT')}
          </ShareThisProviderText>
          <ShareThisProviderTextInput
            data-auto-testid="email-address"
            data-testid="email-address"
            label={t('SHARE_THIS_PROVIDER.EMAIL_ADDRESS')}
            model="email"
            onBlur={() => {
              form.trigger('email');
            }}
            validators={{
              required: true,
              pattern: {
                value: emailRegex,
                message: validEmail,
              },
            }}
          />

          {[...Array(4)].map((element, i) => (
            <ShareThisProviderTextInput
              data-auto-testid={`optional-email-address-${i}`}
              data-testid={`optional-email-address-${i}`}
              hideLabel={i > 0}
              label={
                i === 0 ? t('SHARE_THIS_PROVIDER.OPTIONAL_EMAIL_ADDRESS') : ''
              }
              model={`optionalEmail${i}`}
              onBlur={() => {
                form.trigger(`optionalEmail${i}`);
              }}
              validators={{
                required: false,
                // eslint-disable-next-line consistent-return
                validate: (v) => {
                  if (v && !emailRegex.test(v)) {
                    return 'Please input a valid email address';
                  }
                },
              }}
            />
          ))}
          <ShareThisProviderButtonWrapper
            alignItems="flex-start"
            direction="row"
            justify="flex-end"
          >
            <ShareThisProviderButton
              data-auto-testid="cancel"
              data-testid="cancel"
              onClick={handleDrawerClose}
              size="$md"
              type="cancel"
              variant="outline"
            >
              {t('SHARE_THIS_PROVIDER.CANCEL')}
            </ShareThisProviderButton>
            <ShareThisProviderButton
              data-auto-testid={sendEmailTestId}
              data-testid={sendEmailTestId}
              onClick={handleDrawerSubmit}
              size="$md"
              type="submit"
            >
              {t('SHARE_THIS_PROVIDER.SEND_EMAIL')}
            </ShareThisProviderButton>
          </ShareThisProviderButtonWrapper>
          <ShareThisProviderText
            color="$neutralGray"
            data-auto-testid="provider-text"
            data-testid="provider-text"
          >
            {modalContent?.message}
          </ShareThisProviderText>
        </ShareThisProviderRoot>
      </FormProvider>
    </Drawer>
  );
};
