import { Link } from '@abyss/web/ui/Link';

import { findAllMatches } from '../../../Utils/findAllMatches/findAllMatches';
import {
  ProviderSpeciality,
  physicianLinkStyles,
} from '../PhysicianDirectory.styled';

type Props = {
  physician: {
    providerId?: string | undefined;
    providerName?: string;
    specialities: string;
  };
  linkOnClick: (a: any) => void;
  search: string;
};

export const DirectoryLink = ({ physician, linkOnClick, search }: Props) => (
  <Link
    css={physicianLinkStyles}
    data-auto-testid="physician-link"
    data-testid="physician-link"
    isStandardAnchor
    key={physician.providerId}
    onClick={() => linkOnClick(physician)}
  >
    {search.length > 0
      ? findAllMatches(search, physician.providerName).map((x) =>
          x.isMatch ? <mark>{x.text}</mark> : x.text
        )
      : physician.providerName}
    <ProviderSpeciality>{physician.specialities}</ProviderSpeciality>
  </Link>
);
