import { config } from '@abyss/web/tools/config';
import { useEffect } from 'react';

import { useCustomQuery } from '../useCustomQuery';
import CARE_PATH_QUERY from './CarePath.graphql';

export const useCarePath = (options) =>
  useCustomQuery(CARE_PATH_QUERY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    clearCache: [
      {
        key: 'carePath',
        variables: [
          'coverageType',
          'carePathStepCategory',
          'lob',
          'providerLocationKey',
          'providerType',
          'pageSize',
          'pageNumber',
          'reciprocityId',
          'sortOrder',
          'providerId',
          'locale',
        ],
      },
    ],
    requestPolicy: 'no-cache',
    accessor: 'carePath',
  });

export const useCarePathsSearch = (variables) => {
  const [carePathData, getCarePaths] = useCarePath({});
  useEffect(() => {
    if (variables?.providerId) {
      getCarePaths({
        variables,
      });
    }
  }, [variables?.providerId, variables?.providerLocationKey]);

  return carePathData;
};
