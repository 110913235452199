import { styled } from '@abyss/web/tools/styled';
import { Badge } from '@abyss/web/ui/Badge';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';

export const LocationCardWrapper = styled('button', {
  background: '$primary2',
  border: '1px solid #E5E5E6',
  boxShadow: '0px 1px 4px rgba(25, 25, 26, 0.16)',
  borderRadius: '12px',
  cursor: 'pointer',
  width: '408px',
  textAlign: 'left',
  padding: '12px $md 12px 12px',
  '@screen < $sm': {
    height: 'auto',
  },
  '&.highlight': {
    border: '3px solid $secondary1',
  },
  '&.location-card-map-view': {
    width: '320px',
    height: 'auto',
    '.location-details': {
      '>div': {
        width: 'auto',
        height: 'inherit',
      },
    },
  },
});

export const LocationCardGroup = styled(Layout.Group, {
  '&.abyss-layout-group': {
    alignItems: 'flex-start',
  },
});

export const LocationDetailsStack = styled(Flex, {
  flex: 1,
  gap: '7px',
  lineHeight: '23px',
  '@screen < $sm': {
    lineHeight: '18px',
  },
  '>span': {
    overflowWrap: 'anywhere',
    width: '100%',
  },
});

export const LocationDetailsAddress = styled(Text, {
  lineHeight: '24px',
  fontSize: '18px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '342px',
  '@screen < $sm': {
    lineHeight: '20px',
    fontSize: '14.22px',
    maxWidth: '258px',
  },
});

export const LocationBadgeRow = styled(Flex, {
  '&.abyss-flex-root': { flex: '1' },
});

export const MilesButton = styled('button', {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

export const AcceptingNewPatientsBadge = styled(Badge, {
  fontSize: '12px',
});

export const StyledCheckIcon = styled('div', {
  height: '24px',
});

export const UpdateButtonMobileView = styled(Button, {
  '&.abyss-button-root': {
    display: 'flex',
    padding: '8px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    height: '42px',
    fontWeight: '$semibold',
    fontSize: '16px',
    lineHeight: '20px',
    '.abyss-insert-element-after': {
      paddingLeft: 0,
    },
  },
});
