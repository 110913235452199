import { config } from '@abyss/web/tools/config';
import { storage } from '@abyss/web/tools/storage';
import { useEffect, useState } from 'react';

import { Constants } from '../../common/Constants';
import { getCategoryFromChipCategoriesCount } from '../../common/RecentActivity/helper';
import { useCustomQuery } from '../useCustomQuery';
import GetRecentSearches from './RecentSearches.graphql';
import SaveRecentSearch from './SaveRecentSearch.graphql';

export interface RecentSearchesParams {
  psxSearchTerm: string;
  pesSearchTerm: string;
  searchType: string;
  category: string;
  location: string;
  latitude: string;
  longitude: string;
  coverageType: string;
  isMixedSearchSuggestion?: boolean;
}

export const useGetRecentSearches = (options) => {
  const [recentSearchesData, setRecentSearchesData] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GetRecentSearches, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'recentSearches',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setRecentSearchesData(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: recentSearchesData,
  };

  return [parsedResult, queryAction];
};

export const useSaveRecentSearches = (options) => {
  const [recentSearchesData, setRecentSearchesData] = useState({});
  const [queryResult, queryAction] = useCustomQuery(SaveRecentSearch, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'saveRecentSearch',
    clearCache: [
      {
        key: 'RecentSearches',
      },
    ],
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setRecentSearchesData(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: recentSearchesData,
  };

  return [parsedResult, queryAction];
};

export const useGetRecentSearchesResults = () => {
  const [recentSearches, getRecentSearches] = useGetRecentSearches({});
  const isSuperUser =
    storage.session.get(Constants.STORAGE_KEYS.SESSION.SUPER_USER) || false;

  useEffect(() => {
    if (!isSuperUser) {
      getRecentSearches({});
    }
  }, [isSuperUser]);

  return recentSearches;
};

export const useSaveRecentSearchesResults = ({
  ...variables
}: RecentSearchesParams) => {
  const [saveRecentSearches, getSaveRecentSearches] = useSaveRecentSearches({});
  const isSuperUser =
    storage.session.get(Constants.STORAGE_KEYS.SESSION.SUPER_USER) || false;

  const { pesSearchTerm, category } = variables;

  useEffect(() => {
    if (pesSearchTerm && category && !isSuperUser) {
      getSaveRecentSearches({
        variables: {
          ...variables,
          category: getCategoryFromChipCategoriesCount(category),
          coverageType: variables.coverageType || 'M',
          isMixedSearchSuggestion: variables.isMixedSearchSuggestion || false,
        },
      });
    }
  }, [pesSearchTerm, isSuperUser, category]);

  return saveRecentSearches;
};
