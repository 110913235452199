export const getTextForWaitTime = (waitTime): string => {
  switch (waitTime) {
    case 1:
      return 'Under 10 minutes';
    case 2:
      return '10 - 15 minutes';
    case 3:
      return '16 - 30 minutes';
    case 4:
      return '31 - 45 minutes';
    case 5:
      return 'Over 45 minutes';
    default:
      return 'N/A';
  }
};
