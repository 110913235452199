import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Divider } from '@abyss/web/ui/Divider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Modal } from '@abyss/web/ui/Modal';
import find from 'lodash/find';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { ModalWithTracking } from '../ModalWithTracking/ModalWithTracking';
import { VisionDirectoryDataDisclaimer } from '../VisionCare/VisionDirectoryDataDisclaimer';
import {
  HealthGradesStyled,
  StyledDisclaimerText,
  StyledIcon,
  StyledMarkdown,
  disclaimerLinkStyles,
} from './DisclaimersContainer.styled';

const LinkOverride = ({ children, ...props }: any) => (
  <span {...props}>{children}</span>
);

interface HGProps {
  hideDivider?: boolean;
  showPremiumCareDisclaimer?: boolean;
  isVisionCare?: boolean;
}
export const DisclaimersContainer = ({
  hideDivider,
  showPremiumCareDisclaimer = false,
  isVisionCare = false,
}: HGProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const mobile = useMediaQuery(phoneOnly);
  const lagoonData = useLagoon('ui-messaging')();
  const { t } = useTranslation();
  const modalDisclaimerShort = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.MODAL_DISCLAIMER_SHORT,
  });
  const modalDisclaimerFull = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.MODAL_DISCLAIMER_FULL,
  });
  const disclaimerInPage = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.DISCLAIMER_IN_PAGE,
  });
  const suppressHeatlhGradesDisclaimer = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const suppressPremiumCareDisclaimer = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );
  const navigationURL =
    find(lagoonData, {
      key: ConstantsLagoon.CONTENT_FILTERS.PREMIUM_CARE_POPOVER_URL,
    })?.message ?? '';

  const isWidget = useStore(StoreKeys.IS_WIDGET);

  return (
    <React.Fragment>
      {!hideDivider && (
        <Divider
          color="$gray3"
          data-auto-testid="disclaimer-line"
          data-testid="disclaimer-line"
          height="1"
          orientation="horizontal"
        />
      )}

      <HealthGradesStyled
        cssProps={{ isWidget }}
        data-auto-testid="standard-disclaimer-content"
        data-testid="standard-disclaimer-content"
      >
        <ContentWrapper isWidget={isWidget}>
          <Layout.Stack alignItems="left" space="0">
            <StyledMarkdown>{disclaimerInPage?.message}</StyledMarkdown>
            {isVisionCare && <VisionDirectoryDataDisclaimer />}

            {/* Premium Care Disclaimer starts here */}
            {showPremiumCareDisclaimer && !suppressPremiumCareDisclaimer ? (
              <React.Fragment>
                <StyledMarkdown
                  data-auto-testid="premium-care-disclaimer-content"
                  data-testid="premium-care-disclaimer-content"
                >
                  {t('PREMIUM_CARE_DISCLAIMER.CONTENT')}
                </StyledMarkdown>
                <LinkWithTracking
                  analyticsInfo={{
                    location: 'body:premium care disclaimer',
                    type: 'download',
                  }}
                  css={disclaimerLinkStyles}
                  data-auto-testid="premium-care-disclaimer-link-text"
                  data-testid="premium-care-disclaimer-link-text"
                  href={navigationURL}
                >
                  {t('PREMIUM_CARE_DISCLAIMER.LINK_TEXT')}
                  <IconMaterial icon="chevron_right" size="$xs" />
                </LinkWithTracking>
              </React.Fragment>
            ) : null}
            {/* Premium Care Disclaimer ends here */}

            {/* HealthGrades Disclaimer starts here */}
            {!suppressHeatlhGradesDisclaimer && (
              <Layout.Stack alignItems="left" space="0">
                <StyledIcon
                  icon="healthgrades"
                  isScreenReadable
                  size={`${mobile ? 132 : 165}`}
                  title={t('HEALTH_GRADES_LOGO_TITLE')}
                />
                <StyledDisclaimerText data-testid="patient-reviews-disclaimer">
                  {`${modalDisclaimerShort?.message} ` ?? ''}
                  <Link
                    css={disclaimerLinkStyles}
                    data-auto-testid="healthgrades-link-text"
                    data-testid="healthgrades-link-text"
                    href={null}
                    onClick={() => setIsOpen(true)}
                  >
                    {t('HEALTHGARDE_DISCLAIMER.LINK_TEXT')}
                    <IconMaterial icon="chevron_right" size="$xs" />
                  </Link>
                </StyledDisclaimerText>
                <ModalWithTracking
                  analyticsInfo={{
                    linkName: 'healthgrades disclaimer',
                    modalName: 'healthgrades disclaimer',
                    location: `body:healthgrades`,
                  }}
                  data-auto-testid="healthgrades-modal"
                  data-testid="healthgrades-modal"
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                  title={`${t(
                    'HEALTHGARDE_DISCLAIMER.HEALTHGRADES_INFORMATION'
                  )}`}
                  trackingType={
                    Constants.ADOBE_TRACKING.MODAL_WITH_LINK_TRACKING
                  }
                >
                  <Modal.Section>
                    <Markdown
                      options={{
                        overrides: {
                          Link: {
                            component: Link,
                          },
                          a: {
                            component: LinkOverride,
                            props: { href: null, target: null },
                          },
                        },
                      }}
                    >
                      {modalDisclaimerFull?.message}
                    </Markdown>
                  </Modal.Section>
                </ModalWithTracking>
              </Layout.Stack>
            )}
            {/* HealthGrades Disclaimer ends here */}
          </Layout.Stack>
        </ContentWrapper>
      </HealthGradesStyled>
    </React.Fragment>
  );
};
