import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import escapeRegExp from 'lodash/escapeRegExp';

import { phoneOnly } from '../../../ConstantsStyles';
import { DisplayCoverageTypes } from './DisplayCoverageTypes';
import {
  isPrimaryActiveSuggestion,
  keywordSearchDropdownIconColor,
} from './KeywordSearchDropdown.styled';

type Props = {
  activeSuggestion: number;
  keywordSearchTerm: string;
  getCoverageType: Array<string>;
  onClick: any;
  index: number;
  value: any;
};
export const SuggestionItem = ({
  activeSuggestion,
  keywordSearchTerm,
  getCoverageType,
  onClick,
  value,
  index,
}: Props) => {
  const escapedKeywordSearchTerm = escapeRegExp(keywordSearchTerm);
  const isPhone = useMediaQuery(phoneOnly);
  return (
    // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
    <li
      aria-posinset={index}
      aria-selected={activeSuggestion === index}
      id={
        activeSuggestion === index ? 'primary-search-bar-result-suggestion' : ''
      }
      role="option"
    >
      <Link
        before={
          <IconMaterial color={keywordSearchDropdownIconColor} icon="search" />
        }
        css={
          value?.coverageType.some((coverageCode) =>
            getCoverageType.includes(coverageCode)
          )
            ? {
                '&.abyss-link-root':
                  activeSuggestion === index ? isPrimaryActiveSuggestion : '',
              }
            : { pointerEvents: 'none' }
        }
        data-testid="search-input-box-common-suggestion"
        key={value.id}
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          onClick(value);
        }}
        style={{ whiteSpace: isPhone ? '' : 'pre' }}
        tabIndex={-1}
      >
        <div
          style={{
            ...{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            },
          }}
        >
          <Text style={{ marginRight: '8px' }}>
            {value?.suggestion
              ?.split(new RegExp(`(${escapedKeywordSearchTerm})`, 'gi'))
              .map((part) => {
                if (part.toLowerCase() === keywordSearchTerm.toLowerCase()) {
                  return (
                    <Text
                      color={
                        !value?.coverageType.some((coverageCode) =>
                          getCoverageType.includes(coverageCode)
                        )
                          ? '$gray6'
                          : '$interactive1'
                      }
                      size="18px"
                    >
                      {part}
                    </Text>
                  );
                }
                return (
                  <Text
                    color={
                      !value?.coverageType.some((coverageCode) =>
                        getCoverageType.includes(coverageCode)
                      )
                        ? '$gray6'
                        : '$interactive1'
                    }
                    fontWeight="bold"
                    size="18px"
                  >
                    {part}
                  </Text>
                );
              })}
          </Text>
          <DisplayCoverageTypes
            getCoverageType={getCoverageType}
            item={value}
          />
        </div>
      </Link>
    </li>
  );
};
