export const themeOverride = {
  theme: {
    colors: {
      cardBorder: '#E5E5E6',
      neutralGray: '#4B4D4F',
      neutralGray2: '#F2F2F2',
      neutralGray5: '#6E7072',
      descriptionBlack: '#19191A',
      neutralGray7: '#323334',
      neutralGray4: '#CBCCCD',
      starBorder: '#D2800F',
    },
    fontSizes: {
      extraSmallText: '12.64px',
      smallText: '14.24px',
      mediumText: '20.25px',
      largeText: '25.63px',
    },
    lineHeights: {
      lg: '24px',
      xl: '32px',
      md: '20px',
      sm: '16px',
    },
  },
};

export const optumThemeOverride = {};
