"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDynamicContentLocale = exports.getCurrentLocale = exports.getModuleOrDefault = void 0;
function getModuleOrDefault(localeCode, fileName) {
    try {
        return require("../data/".concat(localeCode, "/").concat(fileName, ".json"));
    }
    catch (error) {
        if ((error === null || error === void 0 ? void 0 : error.code) === "MODULE_NOT_FOUND") {
            return require("../data/en/".concat(fileName, ".json"));
        }
        throw error;
    }
}
exports.getModuleOrDefault = getModuleOrDefault;
/**
 * Get the current two-character locale (e.g. 'en' or 'es')
 */
function getCurrentLocale(locale) {
    if (locale === void 0) { locale = "en"; }
    return locale.slice(0, 2);
}
exports.getCurrentLocale = getCurrentLocale;
/**
 * Get the current locale with fallback to 'en'.
 * @description
 * Since we currently only have english and spanish files for dynamic content,
 * we should default to english so content will still appear for users with other locales set
 */
function getDynamicContentLocale(locale) {
    var localeCode = getCurrentLocale(locale);
    if (["en", "es"].includes(localeCode)) {
        return localeCode;
    }
    return "en";
}
exports.getDynamicContentLocale = getDynamicContentLocale;
function getDynamicContentPaths(locale) {
    var localeCode = getDynamicContentLocale(locale);
    return {
        accessibility: getModuleOrDefault(localeCode, "accessibilityTranslations"),
        age: getModuleOrDefault(localeCode, "ageTranslations"),
        aoe: getModuleOrDefault(localeCode, "aoeTranslations"),
        behaviouralPrograms: getModuleOrDefault(localeCode, "behaviouralProgramsTranslations"),
        culturalCompetency: getModuleOrDefault(localeCode, "culturalCompetencyTranslations"),
        degree: getModuleOrDefault(localeCode, "degreeTranslations"),
        ethnicityCode: getModuleOrDefault(localeCode, "ethnicityTranslations"),
        facilityServiceCode: getModuleOrDefault(localeCode, "facilityServiceCodeTranslations"),
        facilitySubCategoryCode: getModuleOrDefault(localeCode, "facilitySubCategoryCodeTranslations"),
        gender: getModuleOrDefault(localeCode, "genderTranslations"),
        language: getModuleOrDefault(localeCode, "languageTranslations"),
        pesSpecialty: getModuleOrDefault(localeCode, "pesSpecialityTranslations"),
        qualityRating: getModuleOrDefault(localeCode, "qualityRatingTranslations"),
        leapFrogRatings: getModuleOrDefault(localeCode, "leapFrogRatingCategories"),
        sourceSystemSpecialty: getModuleOrDefault(localeCode, "sourceSystemSpecialityTranslations"),
        servicesOffered: getModuleOrDefault(localeCode, "servicesOfferedTranslations"),
        methodsOfService: getModuleOrDefault(localeCode, "methodsOfServiceTranslations"),
        uesSpecialty: getModuleOrDefault(localeCode, "uesSpecialityTranslations"),
        virtualCare: getModuleOrDefault(localeCode, "virtualCareTranslations"),
        ddp: getModuleOrDefault(localeCode, "ddpTranslations"),
    };
}
exports.default = getDynamicContentPaths;
