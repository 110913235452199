import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../hooks/useLagoon';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../models/FacilityDetails';
import { getTokenData } from '../../../utils/common';
import {
  getSelectedLocationIndex,
  providerHasLocations,
  shortenZipCode,
} from '../../../utils/providerDetails.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants, ReverseCoverageTypesCodes } from '../../Constants';
import { FeatureFlags } from '../../ConstantsFeatureFlags';
import { h2SmallerFontForResponsive, phoneOnly } from '../../ConstantsStyles';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
import { MapViewLocationsWrap } from '../../MapViewLocationsWrap/MapViewLocationsWrap';
import { MapViewUpdateLocationButton } from '../../MapViewUpdateLocationButton/MapViewUpdateLocationButton';
import { Distance } from '../../ProviderDetailTabs/ProviderLocationsDetails/Distance';
import { InfoProvider } from '../../ProviderDetailTabs/ProviderLocationsDetails/InfoProvider';
import { LocationsContentForPopOver } from '../../ProviderDetailTabs/ProviderLocationsDetails/LocationsContentForPopOver';
import { PopOver } from '../../ProviderDetailTabs/ProviderLocationsDetails/PopOver';
import {
  AddressContainer,
  ChipContainer,
  Container,
  DividerContainer,
  IconAlignmentContainer,
  InnerContainer,
  LocationsHeader,
  MapViewBoxContainer,
  SectionContainer,
  ViewOnMapLabel,
} from '../../ProviderDetailTabs/ProviderLocationsDetails/ProviderLocation.style';
import { doShowNonTieredAlert } from '../../ProviderDetailTabs/ProviderLocationsDetails/ProviderLocationsDetails';
import { FlexContainer } from '../../ProviderDetailTabs/ProviderLocationsDetails/ProviderLocationsDetails.style';
import { TierOneAlert } from '../../ProviderDetailTabs/ProviderLocationsDetails/TierOneAlert';
import { getFeatureFlag } from '../../Utils';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  convertSpecialtiesList,
  getSearchBlock,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { extractRollupCodesParts } from '../Utils';

type Props = {
  facilityDetails: FacilityDetails;
  setUpdateLocation: (a: boolean) => void;
  navigateToDirections?: boolean;
  setNavigateToDirections: (a: boolean) => void;
  tabTitle?: string;
  isMapView?: boolean;
  setIsMapView: (a: boolean) => void;
  coverageType: string;
  isTiered?: boolean;
};
const getIndicatorsForImpressions = (selectedLocation) => {
  const indicators: string[] = [];

  if (selectedLocation?.acceptingNewPatients)
    indicators.push(Constants.ADOBE_TRACKING.NEW_PATIENTS);
  if (
    selectedLocation?.virtualCareOffered &&
    selectedLocation?.virtualCareOffered.length
  )
    indicators.push(Constants.ADOBE_TRACKING.VIRTUAL_CARE);

  if (selectedLocation?.hasPCPServiceAvailability)
    indicators.push(Constants.ADOBE_TRACKING.PCP_SERVICES);

  if (selectedLocation?.hasEveningAppointments)
    indicators.push(Constants.ADOBE_TRACKING.EVENING_APPOINTMENT);

  if (selectedLocation?.ddpCode)
    indicators.push(`ddp ${selectedLocation.defaultTranslatedValues.ddpCode}`);
  return indicators?.join('|').toLocaleLowerCase();
};

const handleSetSelectLocation = (
  locations,
  setSelectedLocation,
  selectedAddressIndex
) => {
  if (locations) {
    setSelectedLocation(locations[selectedAddressIndex]);
  }
};

export const FacilityLocation = ({
  facilityDetails,
  setUpdateLocation,
  navigateToDirections = false,
  setNavigateToDirections,
  tabTitle,
  isMapView = false,
  setIsMapView,
  coverageType,
  isTiered,
}: Props) => {
  const locations = facilityDetails?.providerLocations;
  const hasMultipleLocations = providerHasLocations(facilityDetails);

  const [selectedId, setSelectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );
  const [directionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  const defaultTranslatedOrgTypes = extractRollupCodesParts(
    facilityDetails?.defaultTranslatedValues?.organizationType
  );

  const { navigate } = useRouter();

  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);

  const facilityIndex = getSelectedLocationIndex(locations, selectedId);

  const [currentIndex, setCurrentIndex] = useState<number>(facilityIndex);
  const [selectedAddressIndex, setSelectedAddressIndex] =
    useState<number>(facilityIndex);

  const [selectedLocation, setSelectedLocation] =
    useState<FacilityLocationsResponse>(locations?.[selectedAddressIndex]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mobileRouteView, setMobileRouteView] = useState<boolean>(false);
  const [isAlertVisible, setAlertVisible] = useState(true);

  const tokenData = getTokenData();
  const isVisionCare =
    tokenData?.coverageType === ReverseCoverageTypesCodes.VISION;

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      facilityDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(facilityDetails.providerType),
      indicator: getIndicatorsForImpressions(selectedLocation),
    },
    providerBlock: {
      type: convertCoverageType(coverageType),
      specialty: convertSpecialtiesList(defaultTranslatedOrgTypes),
      role: 'facility',
    },
    searchBlock: getSearchBlock(tokenData),
  });

  useEffect(() => {
    handleSetSelectLocation(
      locations,
      setSelectedLocation,
      selectedAddressIndex
    );
  }, [locations]);

  useEffect(() => {
    if (selectedLocation && !isMapView) adobePageTrackEvent();
  }, [selectedLocation, isMapView]);

  useEffect(() => {
    if (locations?.[selectedAddressIndex]) {
      const selectedIndex: string = selectedAddressIndex.toString();
      const location = locations[selectedIndex];
      const { locationId } = location;
      setSelectedLocation(location);
      setSelectedId(locationId);
    }
  }, [selectedAddressIndex]);

  useEffect(() => {
    if (locations) {
      const index = locations.findIndex(
        (item) => item.locationId === selectedLocation?.locationId
      );
      setSelectedAddressIndex(index);
      setCurrentIndex(index);
    }
  }, [selectedLocation]);

  useEffect(() => {
    setIsMapView(navigateToDirections);
  }, [navigateToDirections]);

  const handleHideMap = () => {
    setNavigateToDirections(false);
    setIsMapView(false);
    adobeLinkTrackEvent({
      name: 'back',
      location: `body:view on map`,
      type: 'internal',
    });
    if (
      directionsTriggeredFrom !==
      Constants.DETAILS_PAGE_HEADER_DIRECTIONS_BUTTON
    ) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!mobileRouteView && mobileScreen && navigateToDirections)
      handleHideMap();
  }, [mobileRouteView]);

  const form = useForm({
    defaultValues: {
      'radio-form': '',
    },
  });

  const handleViewMap = () => {
    setIsMapView(true);
  };

  const handleUpdateLocation = () => {
    adobeLinkTrackEvent({
      name: 'save location',
      location: `body:${Constants.PROVIDER_DETAILS.VIEWONMAP}`,
      type: 'internal',
    });
    setUpdateLocation(true);
    setIsMapView(false);
    setSelectedAddressIndex(currentIndex);
    setNavigateToDirections(false);
  };

  const handleSelectedLocation = (location, index) => {
    setSelectedLocation(location);
    setSelectedAddressIndex(index);
    setAlertVisible(true);
  };

  if (!selectedLocation) {
    return null;
  }

  const useStickyButtonRow = hasMultipleLocations && !mobileScreen;
  const isSameLocationSelected = selectedAddressIndex === currentIndex;
  const {
    line: addressLine,
    city,
    state,
    postalCode,
  } = selectedLocation.address;

  const featureFlags = useLagoon('feature-flags')();
  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const displayTieredAlert = doShowNonTieredAlert(
    isTiered,
    selectedLocation.isTieredProvider,
    showTierProviderTag
  );

  return !isMapView ? (
    <Container
      data-auto-testid="facility-locations-details-section"
      data-testid="facility-locations-details-section"
    >
      {!isVisionCare && (
        <TierOneAlert
          isVisible={isAlertVisible}
          setIsVisible={setAlertVisible}
          showNonTieredAlert={displayTieredAlert}
        />
      )}
      <FormProvider state={form}>
        <SectionContainer>
          <LocationsHeader justify="flex-end">
            <Heading
              css={h2SmallerFontForResponsive}
              data-auto-testid="directory-update-title"
              data-testid="directory-update-title"
              display="h4"
              offset={1}
            >
              {t('PROVIDER_DETAILS.LOCATIONS')}
              {` (${facilityDetails.providerLocations.length})`}
            </Heading>
            <Layout.Group
              css={{ marginTop: '10px', '@screen < $md': { marginTop: '0px' } }}
            >
              <LinkWithTracking
                analyticsInfo={{
                  location: 'body:locations',
                  name: Constants.PROVIDER_DETAILS.VIEWONMAP,
                }}
                data-auto-testid="view-facility-locations-on-map"
                data-testid="view-facility-locations-on-map"
                href="#"
                isStandardAnchor
                onClick={handleViewMap}
              >
                <IconMaterial
                  color="$interactive1"
                  css={{
                    width: '24px',
                    height: '24px',
                    marginRight: '4px',
                    '@screen < $sm': {
                      width: '18px',
                      height: '18px',
                    },
                  }}
                  icon="map"
                />
                <ViewOnMapLabel color="$interactive1">
                  {t('PROVIDER_DETAILS.VIEWONMAP')}
                </ViewOnMapLabel>
              </LinkWithTracking>
            </Layout.Group>
          </LocationsHeader>
          <PopOver
            isOpen={isOpen}
            locationsContentForPopOver={
              <LocationsContentForPopOver
                isOpen={isOpen}
                locationsDetails={locations}
                mobileScreen={mobileScreen}
                selectedIndex={selectedAddressIndex}
                selectedLocation={selectedLocation}
                setIsOpen={setIsOpen}
                setSelectedLocation={handleSelectedLocation}
              />
            }
            locationsDetails={locations}
            selectedLocation={selectedLocation}
            setIsOpen={setIsOpen}
          />
        </SectionContainer>
      </FormProvider>
      <InnerContainer>
        <ChipContainer>
          <Heading
            css={{
              '&.abyss-heading-root': {
                fontSize: '$extraSmallText !important',
                fontWeight: '$medium !important',
                color: '$neutralGray !important',
              },
            }}
            data-auto-testid="heading-selected-location"
            data-testid="heading-selected-location"
            display="h6"
            offset={2}
          >
            {t('FACILITY_DETAILS.LOCATION_TAB.SELECTED_LOCATION')}
          </Heading>
        </ChipContainer>
        <FlexContainer
          data-auto-testid="facility-provider-location-details"
          data-testid="facility-provider-location-details"
        >
          <IconAlignmentContainer>
            <IconMaterial color="$primary1" icon="business" size={20} />
            <Flex direction="column">
              <AddressContainer> {addressLine?.join(', ')}</AddressContainer>
              <AddressContainer>
                {city}, {state} {shortenZipCode(postalCode)}
              </AddressContainer>
            </Flex>
          </IconAlignmentContainer>
          <DividerContainer>
            <Divider height="100%" orientation="vertical" width={1} />
          </DividerContainer>
          <Distance
            ariaLabel={t('ACCESSIBILITY_LABELS.INFORMATION_ABOUT_DISTANCE')}
            content={t('DISTANCE_POPOVER_CONTENT')}
            selectedLocation={selectedLocation}
            title={t('CONTENT_FILTERS.DISTANCE')}
          />
        </FlexContainer>

        <InfoProvider
          detailType={facilityDetails?.providerType}
          facilityDetails={facilityDetails}
          selectedLocation={selectedLocation}
        />
      </InnerContainer>
    </Container>
  ) : (
    <Container
      data-auto-testid="facility-locations-details-section"
      data-testid="facility-locations-details-section"
    >
      <MapViewBoxContainer>
        <MapViewLocationsWrap
          directionsButtonClicked={navigateToDirections}
          handleGoBack={handleHideMap}
          handleUpdateLocation={handleUpdateLocation}
          hasMultipleLocations={hasMultipleLocations}
          isMapView={isMapView}
          mobileRouteView={mobileRouteView}
          mobileScreen={mobileScreen}
          providerDetails={facilityDetails}
          setCurrentIndex={setCurrentIndex}
          setIsMapView={setIsMapView}
          setMobileRouteView={setMobileRouteView}
          tabTitle={tabTitle}
          updateLocationButton={
            <MapViewUpdateLocationButton
              handleSaveLocation={handleUpdateLocation}
              isSameLocationSelected={isSameLocationSelected}
            />
          }
          useStickyButtonRow={useStickyButtonRow}
        />
      </MapViewBoxContainer>
    </Container>
  );
};
