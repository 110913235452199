import React from 'react';
import { ScrollRestoration, createBrowserRouter } from 'react-router-dom';

export const createRouter = (Routes) => {
  let router;
  if (typeof window !== 'undefined') {
    const createRouterType = createBrowserRouter;
    const createRouterConfig = { basename: '/findcare' };
    const rootRoute = {
      path: '*',
      element: React.createElement(
        React.Fragment,
        null,
        React.createElement(Routes),
        React.createElement(ScrollRestoration)
      ),
    };
    router = createRouterType([rootRoute], createRouterConfig);
  }

  return router;
};
