import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { ContentWrapper } from '../../../../common/ContentWrapper';
import {
  BoxStyles,
  ButtonStyles,
  ContainerForNullSections,
  ContentContainer,
  HeadingStyles,
  IconStyles,
  TextStyled,
} from './NullSpecialtyPage.styles';

export type NullPageProps = {
  searchName: string;
};

export const NoSpecialtyResult = ({ searchName }: NullPageProps) => {
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { drawerName, chipValue, coverageType } = tokenData;
  const referralRequiredDrawerState = () => {
    let state = false;
    if (drawerName === 'referral required specialties') {
      state = true;
      return state;
    }
    return state;
  };

  const AllSpecialtyDrawerState = () => {
    let state = false;
    if (drawerName === 'all specialties') {
      state = true;
      return state;
    }
    return state;
  };
  const handleBackButton = () => {
    adobeLinkTrackEvent({
      name: 'back button',
      location: 'modal:null result page for specialty',
      type: 'internal',
    });

    const updatedToken = tokenizer.create({
      chipValue,
      coverageType,
      openSpecialtyDrawer: referralRequiredDrawerState(),
      openAllSpecialtyDrawer: AllSpecialtyDrawerState(),
    });
    navigate(`/${updatedToken}`);
  };

  return (
    <ContainerForNullSections>
      <Box
        color="$white"
        css={BoxStyles}
        data-auto-testid="no-specialty-page"
        data-testid="no-specialty-page"
      >
        <ContentWrapper>
          <ContentContainer>
            <Heading
              css={HeadingStyles}
              data-auto-testid="no-specialty-results-heading"
              data-testid="no-specialty-results-heading"
            >
              {t('NULL_PAGE.NULL_RESULT_FOR_SPECIALTY_HEADING')} {searchName}
            </Heading>
            <Layout.Stack alignItems="left">
              <Text css={TextStyled}>
                {t('NULL_PAGE.NULL_RESULT_FOR_SPECIALTY_TEXT')}
              </Text>
              <ul
                style={{
                  listStyle: 'disc',
                  paddingLeft: '26px',
                  fontWeight: '600',
                }}
              >
                <li>
                  <Text css={TextStyled}>
                    {' '}
                    {t('NULL_PAGE.NULL_RESULT_FOR_SPECIALTY_1')}
                  </Text>
                </li>
                <li>
                  <Text css={TextStyled}>
                    {t('NULL_PAGE.NULL_RESULT_FOR_SPECIALTY_2')}
                  </Text>
                </li>
              </ul>
            </Layout.Stack>
            <Button
              css={ButtonStyles}
              data-auto-testid="back-to-medical-specialists"
              data-testid="back-to-medical-specialists"
              onClick={handleBackButton}
              size="$md"
            >
              {t(`NULL_PAGE.NULL_RESULTS_BACK_BUTTON.${chipValue}`)}
              <IconMaterial
                color="$white"
                css={IconStyles}
                icon="chevron_right"
                isScreenReadable
                size="24px"
              />
            </Button>
          </ContentContainer>
        </ContentWrapper>
      </Box>
    </ContainerForNullSections>
  );
};
