import { config } from '@abyss/web/tools/config';
import { useEffect } from 'react';

import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useCustomQuery } from '../useCustomQuery';
import GET_SPECIALTIES_QUERY from './Specialties.graphql';

export const useSpecialties = (options) =>
  useCustomQuery(GET_SPECIALTIES_QUERY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'Specialties',
  });

export const useSpecialtiesResult = (variables) => {
  const [specialtiesResult, getSpecialties] = useSpecialties({});
  const { isReferralRequired, userState } = variables;
  useEffect(() => {
    const locale = getLanguage()?.code;
    getSpecialties({
      variables: {
        locale,
        isReferralRequired: isReferralRequired ?? false,
        userState: userState ?? '',
      },
    });
  }, [userState, isReferralRequired]);

  return { data: specialtiesResult.data?.specialties };
};
