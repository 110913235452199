import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { event } from '@abyss/web/tools/event';
import { storage } from '@abyss/web/tools/storage';

import { Constants } from '../../common/Constants';
import { phoneOnly } from '../../common/ConstantsStyles';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { getPlanSelectorVariables } from '../../frontends/ProviderSearch/context/PlanSelector/helpers';
import { getLoggedInMember } from '../../utils/user.utils';
import { StoreKeys } from '../useStore/state';
import { useStore } from '../useStore/useStore';

export const useAdobePageTrackEvent = (input) => {
  const { accountId } = getPlanSelectorVariables();
  const { bigN } = useStore(StoreKeys.PLAN_SELECTOR);
  const mobileScreen = useMediaQuery(phoneOnly);
  const loggedInMember = getLoggedInMember();
  const loggedInMemberRelationshipTypeCode =
    loggedInMember?.demographics?.relationshipTypeCode?.code.toLowerCase() ||
    '';

  const isSuperUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.SUPER_USER
  );
  let language = '';
  const locale = getLanguage()?.code || 'en';
  if (locale === 'es') {
    language = 'spanish';
  } else language = 'english';

  const { impressionBlock, providerBlock, searchBlock } = input;

  const adobePageTrackEvent = () => {
    event('PAGE_TRACK_EVENT', {
      page: {
        experienceType: mobileScreen ? 'mobile' : 'desktop',
        is404: false,
        lang: language,
        name: input?.pageName?.toLowerCase(),
        sitesection1: 'secure',
        sitesection2: 'find care',
        sitesection3: input?.sitesectionLevel1?.toLowerCase() ?? '',
        sitesection4: input?.sitesectionLevel2?.toLowerCase() ?? '',
        test: '',
        referringSite: '',
        group: 'search',
      },
      user: {
        loginStatus: 'logged in',
        userType: `member>${loggedInMember?.lineOfBusiness?.toLowerCase()}`,
        groupId: bigN?.GroupNumber,
        carrierId: '',
        hsid: accountId,
        rallyId: loggedInMember?.rallyId,
        patientId: '',
        payerGroup: loggedInMember?.lineOfBusiness?.toLowerCase(),
        accountId,
        userCategory: isSuperUser
          ? 'superuser'
          : loggedInMemberRelationshipTypeCode,
      },
      ...(impressionBlock && { impression: impressionBlock }),
      ...(providerBlock && { provider: providerBlock }),
      ...(searchBlock && { search: searchBlock }),
    });
  };

  return { adobePageTrackEvent };
};
