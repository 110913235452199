import { styled } from '@abyss/web/tools/styled';

export const WelcomeMessageScreen = styled('p', {
  fontSize: '22.78px',
  color: '$primary1',
  fontWeight: '$bold',
  fontFamily: '$heading',
  '@screen < $md': {
    lineHeight: '28px',
  },
  '.abyss-button-root': {
    color: '$info1',
    fontSize: 'md',
  },
  '.abyss-dropdown-menu-trigger': {
    paddingLeft: '$xs',
    paddingRight: 0,
  },
});

export const Dob = styled('div', {
  marginBottom: '15px',
  color: '#4B4D4F',
  fontSize: '18px',
  lineHeight: '24px',
  '&.active': { fontWeight: 'bold' },
  '@screen < $md': {
    lineHeight: '20px',
  },
});

export const memberSelectorDrawerStyle = {
  'abyss-modal-close-button': {
    marginTop: '15px',
    marginRight: '10px',
  },
  'abyss-modal-content-title': {
    color: '#323334 !important',
    fontSize: '22px !important',
    fontWeight: '700',
    lineHeight: '24px !important',
  },
  'abyss-modal-content-container': {
    height: 'auto',
  },
};

export const memberSelectorGridStyle = {
  marginLeft: '12px',
  'input[type="radio"]:checked+label': { fontWeight: 'bold' },
  '.abyss-radio-group-container': {
    overflowY: 'scroll',
    maxHeight: '500px',
    padding: '4px',
  },
};

export const memberSelectorWebIconStyle = {
  'abyss-icon': {
    marginLeft: '5px',
  },
};

export const memberSelectorPopoverStyle = {
  'abyss-popover-trigger': {
    background: 'none',
    color: '$primary1',
    fontWeight: '600',
    fontFamily: '$heading',
    fontSize: '36.48px',
    lineHeight: '48px',
    letterSpacing: '0px',
    textAlign: 'left',
  },

  'abyss-popover-content-container': {
    overflow: 'hidden',
  },
  'abyss-popover-close-button': {
    margin: '10px 0',
    marginRight: '5px',
  },
  'abyss-popover-title': {
    fontStyle: 'normal !important',
    fontWeight: '700 !important',
    fontSize: '25.63px !important',
    lineHeight: '32px',
    margin: '4px 0 20px 0',
    marginLeft: '10px',
  },
};
export const memberSelectorRadioButtonStyle = {
  'abyss-radio-label': {
    fontWeight: '500',
    color: '#4B4D4F',
    fontSize: '18px',
    lineHeight: '24px',
  },
};
export const memberSelectorWebGridStyle = {
  'input[type="radio"]:checked+label': { fontWeight: 'bold' },
  '.abyss-radio-group-container': {
    overflowY: 'scroll',
    maxHeight: '600px',
    padding: '4px',
    marginLeft: '6px',
  },
};
export const memberSelectorButtonStyle = {
  'abyss-button-root': {
    height: 'auto',
    background: 'none',
    border: 'none',
    borderRadius: '0px',
    paddingLeft: '0px',
    '&:hover': {
      background: 'none',
      border: '0',
    },
  },
  'abyss-button-content-container': {
    color: '$primary1',
    display: 'block',
  },
};
export const memberSelectorChangeButtonStyle = {
  marginBottom: '15px',
  marginTop: '12px',
  width: '90%',
};
