import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProviderResults } from '../../hooks/useProviderSearch';
import { getCurrentMember } from '../../utils/user.utils';
import { EditPCPMenu } from '../PrimaryCareProvider/EditPCPMenu';
import {
  LayoutContainer,
  YourPCPContainer,
  YourPCPMessageDetailsContainer,
} from './DetailsPageHeader.styled';

export type Props = {
  providerPCPId: string;
  memberPCPId: string;
};

export const ProviderDetailBanner = ({ providerPCPId, memberPCPId }: Props) => {
  const { t } = useTranslation();
  const [providersResult] = useProviderResults();
  const { data } = providersResult;
  const currentMember = getCurrentMember();

  const displayProviderBanner = (providerPCP, memberPCP) => {
    if (providerPCP === memberPCP) {
      return true;
    }
    return false;
  };

  const YourPCPMessage = () => {
    if (!displayProviderBanner(providerPCPId, memberPCPId)) return null;
    return (
      <React.Fragment>
        <YourPCPContainer>
          <LayoutContainer>
            <YourPCPMessageDetailsContainer>
              <IconMaterial color="$primary1" icon="check" size="$sm" />
              <Text
                css={{
                  'abyss-text-root': {
                    fontSize: '12.64px',
                    fontWeight: '700',
                    fontStyle: 'normal',
                    lineHeight: '16px',
                    color: '$primary1 ! important',
                  },
                }}
                data-testid="pcp-banner-content"
              >
                {t('PCP_BANNER.CONTENT')}
              </Text>
            </YourPCPMessageDetailsContainer>
            <EditPCPMenu
              badge
              dependentSeqNbr={currentMember?.demographics?.dependentSeqNbr}
              locationForAnalytics="Edit PCP"
              providerID={providerPCPId}
              providerType={
                data?.providers?.[0]?.providerType ||
                data?.medicalGroup?.[0]?.providerType
              }
            />
          </LayoutContainer>
        </YourPCPContainer>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <YourPCPMessage />
    </React.Fragment>
  );
};
