import { createStore } from '@abyss/web/tools/createStore';

import {
  State,
  StoreKeys,
  featureSuppressionUIState,
  intialUIState,
} from './state';

const initialState: State = {
  [StoreKeys.SESSION_ID]: '',
  [StoreKeys.PLAN_SELECTOR]: {
    bigN: {
      DOB: '',
      FirstName: '',
      GroupNumber: '',
      LastName: '',
      MemberNumber: '',
    },
  },
  [StoreKeys.OBAPI_MEMBERS]: [],
  [StoreKeys.UI_STATE]: intialUIState,
  [StoreKeys.UI_FEATURE_SUPPRESSION]: featureSuppressionUIState,
  [StoreKeys.NAVIGATION_HISTORY]: {
    previousPageName: '',
    previousPageRank: 0,
    currentPageName: '',
    currentPageRank: 0,
  },
  [StoreKeys.BREADCRUMB_CLICKED_FLAG]: false,
  [StoreKeys.MEMBER_SELECTION_POPUP_FLAG]: false,
  [StoreKeys.TIER1_PLAN_FLAG]: false,
  [StoreKeys.IS_WIDGET]: false,
  [StoreKeys.COVERAGE_TYPE]: '',
  [StoreKeys.USER_DDP_CODE]: 'N',
};

export const useStore = createStore((set) => ({
  ...initialState,
  [StoreKeys.SET_SESSION_ID]: (sessionId) => set(() => ({ sessionId })),
  [StoreKeys.SET_PLAN_SELECTOR]: (planSelector) =>
    set(() => ({ planSelector })),
  [StoreKeys.SET_OBAPI_MEMBERS]: (memberInfo) =>
    set(() => ({
      memberInfo: memberInfo.filter(
        (member) =>
          !!member.eligibility[0]?.memberHealthCoverage?.coverageType?.length
      ),
    })),
  [StoreKeys.SET_UI_STATE]: (uiState) => set(() => ({ uiState })),
  [StoreKeys.SET_UI_FEATURE_SUPPRESSION]: (featureSuppressionUIstate) =>
    set(() => ({ featureSuppressionUIstate })),
  [StoreKeys.SET_NAVIGATION_HISTORY]: (navigationHistory) =>
    set(() => ({ navigationHistory })),
  [StoreKeys.SET_BREADCRUMB_CLICKED_FLAG]: (hasBreadCrumbClickedFlag) =>
    set(() => ({ hasBreadCrumbClickedFlag })),
  [StoreKeys.SET_MEMBER_SELECTION_POPUP_FLAG]: (memberSelectionPopupFlag) =>
    set(() => ({ memberSelectionPopupFlag })),
  [StoreKeys.SET_TIER1_PLAN_FLAG]: (isTier1Plan) =>
    set(() => ({ isTier1Plan })),
  [StoreKeys.SET_IS_WIDGET]: (isWidget) => set(() => ({ isWidget })),
  [StoreKeys.SET_COVERAGE_TYPE]: (coverageType) =>
    set(() => ({ coverageType })),
  [StoreKeys.SET_PRE_PROD_TOGGLE]: (enablePreProd) =>
    set(() => ({ enablePreProd })),
  [StoreKeys.SET_USER_DDP_CODE]: (userDDPCode) => set(() => ({ userDDPCode })),
}));
