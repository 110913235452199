import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { ProviderType } from '../../models/ProviderDetails';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { getDistance } from '../../utils/card.utils';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import { getProviderSpecialtyOrOrgTypeCode } from '../../utils/providerDetails.utils';
import {
  getNameAttrs,
  parseProviderName,
} from '../../utils/providerNames.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants, ProviderTypes } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { phoneOnly } from '../ConstantsStyles';
import { ImageComponent } from '../ImageComponent';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { NationalGroupProvidersVirtualBiteCard } from '../NationalGroupProvidersVirtualBiteCard';
import { getFeatureFlag } from '../Utils';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import {
  CustomAttributesBlock,
  appendCoverageTypes,
  convertProviderTypeToAdobeType,
  formatProviderId,
  getNameForLinkTrack,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { Rating } from './Rating';
import { VoiceOnlyText } from './Rating.styles';
import {
  Avatar,
  DistanceDrawerStyles,
  Miles,
  MilesContent,
  MilesText,
  PopoverContainer,
  SnackAvatarContainer,
  SnackContainer,
  SnackDescription,
  SnackDetails,
  SnackInfoCard,
  SnackName,
  SnackRole,
  displayVirtualVisitStyle,
} from './SnackCard.styles';
import { SnackCardLoader } from './SnackCardLoader';
import { SnackCardWithExternalLink } from './SnackCardWithExternalLink';

export type SnackCardProps = {
  coverageType?: string;
  providerName?: string;
  professionalDesignation?: string;
  speciality?: string;
  rating?: number;
  distance?: string;
  imgSrc?: string;
  providerId?: string;
  providerType?: string;
  isLoading: boolean;
  isFixes?: boolean;
  locationForAnalytics?: string;
  isVirtualCareOffered?: boolean;
  hasPhysicalAddress?: boolean;
  index?: number;
  isTieredProvider?: boolean;
  organizationType?: string[];
  website?: string;
  headers?: ResponseHeaders;
  snackCardProvider?: SnackCardProvider | any;
  locationId?: string;
};

export const SnackCard = ({
  coverageType,
  providerName,
  professionalDesignation,
  speciality,
  rating,
  distance,
  imgSrc,
  providerId,
  providerType,
  isLoading,
  isFixes = false,
  locationForAnalytics,
  isVirtualCareOffered,
  hasPhysicalAddress,
  index,
  isTieredProvider,
  organizationType,
  website,
  snackCardProvider = {},
  headers = { correlationId: '' },
  locationId,
}: SnackCardProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const nameAttrs = getNameAttrs(snackCardProvider);
  const displayName = parseProviderName(
    providerName,
    providerType,
    professionalDesignation,
    nameAttrs
  );
  const analyticsName = getNameForLinkTrack(displayName, providerType);
  const suppressPatientReviews = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token);
  const [milesdata, setMileseData] = useState(0);
  const { SELECTED_PROVIDER_ID } = Constants.STORAGE_KEYS.SESSION;
  const providerDetailsMilesInfo = 'get-provider-details-miles';
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isOpen, setIsOpen] = useState(false);
  const snackCardModalTestId: string = 'snack-card-miles-info-popover';
  const indexForAnalytics = index !== undefined ? index + 1 : 0;

  const navigateToSnackDetails = (
    heading: string,
    showDirection,
    isTiered?
  ) => {
    const updatedToken = tokenizer.update(token, {
      coverageType: coverageType ?? tokenData?.coverageType,
      specialtySearch: speciality,
      search: heading,
      sectionType: Constants.SEARCH_TYPES.PROVIDER,
      pcpIndicator: true,
      providerType: ProviderTypes.PRACTITIONER,
      searchType: Constants.SEARCH_TYPES.LOCATION,
      providerId,
      showDirection,
      isTieredSnackCard: isTiered,
      locationId,
    });
    storage.session.set(SELECTED_PROVIDER_ID, providerId);
    let path;
    if (providerType === ProviderType.PRACTITIONER) {
      path = `${ConstantsRoutes.PROVIDER_DETAILS.path}${updatedToken}`;
    } else if (providerType === ProviderType.MEDICAL_GROUP) {
      path = `${ConstantsRoutes.PROVIDER_GROUP_DETAILS.path}${updatedToken}`;
    } else path = `${ConstantsRoutes.FACILITY_DETAILS.path}${updatedToken}`;
    navigate(path);
  };
  const gotoDirection = (header) => {
    navigateToSnackDetails(header, true, isTieredProvider);
  };

  useEffect(() => {
    const data = getDistance(distance || '');
    setMileseData(data);
  }, [distance]);

  const { t } = useTranslation();
  const dataTestId = `get-provider-details-name-${providerType}`.replace(
    / /g,
    '-'
  );

  const featureFlags = useLagoon('feature-flags')();
  const virtualCareIndicatorEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VIRTUAL_CARE_INDICATOR_ENABLED
  );

  const customAttributesBlock: CustomAttributesBlock = {
    correlationId: headers?.correlationId,
    providerId: formatProviderId(
      appendCoverageTypes(snackCardProvider, tokenData)
    ),
    providerType: convertProviderTypeToAdobeType(providerType),
  };

  const handleAdobeLinkTrack = (name: string) => {
    adobeLinkTrackEvent({
      name,
      location: `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`,
      type: 'internal',
      customAttributesBlock,
    });
  };
  useEffect(() => {
    if (isOpen) {
      const linkName: string = snackCardModalTestId;
      const linkLocation: string = `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`;
      const modalName: string = Constants.CONTENT_FILTERS.DISTANCE;
      handleLinkAndModalTrack(
        linkName,
        linkLocation,
        modalName,
        undefined,
        customAttributesBlock
      );
    }
  }, [isOpen]);

  const displayVirtualVisit =
    virtualCareIndicatorEnabled &&
    hasPhysicalAddress &&
    isVirtualCareOffered &&
    providerType === ProviderType.PRACTITIONER;

  const displayVirtualProviderGroup =
    isVirtualCareOffered &&
    !hasPhysicalAddress &&
    providerType === 'Medical Group';

  const starRatingSize = 16;

  const directionsIconSize = 18;

  if (displayVirtualProviderGroup) {
    return (
      <NationalGroupProvidersVirtualBiteCard
        data-auto-testid="virtual-provider-group-snack-card"
        data-testid="virtual-provider-group-snack-card-tag"
        handleOnClick={() => {
          navigateToSnackDetails(
            Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM,
            false,
            isTieredProvider
          );
        }}
        isFixes={isFixes}
        isLoading={isLoading}
        providerName="oshiHealth"
        speciality={speciality}
      />
    );
  }
  const { surveyCount } = snackCardProvider;
  const { address } = snackCardProvider;
  const isSnackCardWithExternalLink =
    providerType === ProviderType.ORGANIZATION &&
    website &&
    !address?.line?.[0];

  const providerSpeciality = getProviderSpecialtyOrOrgTypeCode(
    providerType,
    organizationType,
    speciality
  );

  const getLabel = () => `${displayName}${providerSpeciality}`;

  if (isSnackCardWithExternalLink)
    return (
      <SnackCardWithExternalLink
        displayName={displayName}
        handleClick={() => {
          adobeLinkTrackEvent({
            name: getNameForLinkTrack(website || '', providerType),
            location: `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`,
            type: 'external',
            destinationUrl: website,
            customAttributesBlock,
          });
          window.open(website, '_blank');
        }}
        isFixes={isFixes}
        providerSpeciality={providerSpeciality}
        providerType={providerType}
      />
    );

  return (
    <React.Fragment>
      {isLoading ? (
        <SnackCardLoader isLoading={isLoading} mobileScreen={mobileScreen} />
      ) : (
        <SnackContainer>
          <SnackInfoCard
            alignContent="center"
            alignItems="center"
            data-auto-testid={`snack-card-${providerType}`.replace(/ /g, '-')}
            data-testid={`snack-card-${providerType}`.replace(/ /g, '-')}
            direction="column"
            isFixes={isFixes}
          >
            <Flex alignContent="center" alignItems="center" direction="column">
              <SnackAvatarContainer>
                <Avatar
                  alignContent="center"
                  alignItems="center"
                  data-testid={`snack-card-${providerType}-profile`.replace(
                    / /g,
                    '-'
                  )}
                >
                  {ImageComponent({ imgRef, providerType, imgSrc })}
                </Avatar>
                {displayVirtualVisit && (
                  <Flex color="$primary1" css={displayVirtualVisitStyle}>
                    <VoiceOnlyText>
                      {t('DETAILS_PAGE.OFFERS_VIRTUAL_CARE')}
                    </VoiceOnlyText>
                    <IconMaterial
                      color="white"
                      data-testid={`snack-card-${providerType}-virtual-visit-icon`.replace(
                        / /g,
                        '-'
                      )}
                      icon="videocam"
                      size={16}
                      variant="filled"
                    />
                  </Flex>
                )}
              </SnackAvatarContainer>
              <SnackDescription
                alignContent="center"
                alignItems="center"
                direction="column"
                justify="space-between"
              >
                <Tooltip content={displayName}>
                  <SnackName
                    color="$accent1"
                    data-auto-testid={dataTestId}
                    data-testid={dataTestId}
                    onClick={() => {
                      navigateToSnackDetails(
                        Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM,
                        false,
                        isTieredProvider
                      );
                    }}
                  >
                    <LinkWithTracking
                      analyticsInfo={{
                        name: analyticsName,
                        location: `body:${locationForAnalytics} snackcard:position ${indexForAnalytics}`,
                        customAttributesBlock,
                      }}
                      aria-label={getLabel()}
                      data-auto-testid={`${dataTestId}-link`}
                      data-testid={`${dataTestId}-link`}
                      isStandardAnchor
                    >
                      {displayName}
                    </LinkWithTracking>
                  </SnackName>
                </Tooltip>
              </SnackDescription>
            </Flex>

            <SnackDetails
              alignContent="center"
              alignItems="center"
              direction="column"
            >
              <Tooltip content={providerSpeciality}>
                <SnackRole
                  color="$neutralGray"
                  data-auto-testid="get-provider-details-role"
                  data-testid="get-provider-details-role"
                >
                  {providerSpeciality}
                </SnackRole>
              </Tooltip>
              {providerType === ProviderType.PRACTITIONER &&
                !suppressPatientReviews && (
                  <Rating
                    data-auto-testid="get-provider-details-rating"
                    data-testid="get-provider-details-rating"
                    navigateToSnackDetails={() => {
                      handleAdobeLinkTrack('get-provider-details-rating');
                      navigateToSnackDetails(
                        Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM,
                        false,
                        isTieredProvider
                      );
                    }}
                    rating={rating}
                    size={starRatingSize}
                    surveyCount={surveyCount}
                  />
                )}
              <Miles
                alignContent="center"
                alignItems="center"
                data-auto-testid="get-provider-details-miles"
                data-testid="get-provider-details-miles"
              >
                {mobileScreen && (
                  <Drawer
                    css={DistanceDrawerStyles}
                    data-auto-testid={snackCardModalTestId}
                    data-testid={snackCardModalTestId}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    position="bottom"
                    size="200"
                    title={t('CONTENT_FILTERS.DISTANCE')}
                  >
                    {t('DISTANCE_POPOVER_CONTENT')}
                  </Drawer>
                )}
                <MilesContent
                  data-auto-testid={providerDetailsMilesInfo}
                  data-testid={providerDetailsMilesInfo}
                  onClick={() => {
                    handleAdobeLinkTrack(providerDetailsMilesInfo);
                    gotoDirection(
                      Constants.TOP_PRIMARY_CARD_PROVIDERS.SEARCH_TERM
                    );
                  }}
                >
                  <IconMaterial
                    icon="directions"
                    size={mobileScreen ? 'sm' : directionsIconSize}
                  />
                  <MilesText aria-label={`${milesdata} ${t('miles')}`}>
                    {milesdata} {t('miles')}
                  </MilesText>
                  {mobileScreen && (
                    <IconMaterial
                      css={{
                        'abyss-icon': {
                          display: 'inline-block',
                          verticalAlign: 'middle',
                        },
                      }}
                      data-auto-testid="snack-card-miles-Info"
                      data-testid="snack-card-miles-Info"
                      icon="info"
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsOpen(true);
                      }}
                      size={16}
                      variant="outlined"
                    />
                  )}
                </MilesContent>
                {!mobileScreen && (
                  <PopoverContainer>
                    <Popover
                      aria-label={t(
                        'ACCESSIBILITY_LABELS.INFORMATION_ABOUT_DISTANCE'
                      )}
                      content={t('DISTANCE_POPOVER_CONTENT')}
                      data-auto-testid={snackCardModalTestId}
                      data-testid={snackCardModalTestId}
                      onOpenChange={() => {
                        setIsOpen(!isOpen);
                      }}
                      open={isOpen}
                      title={
                        <Text size="$sm">{t('CONTENT_FILTERS.DISTANCE')}</Text>
                      }
                      width={220}
                    >
                      <IconMaterial
                        data-auto-testid="snack-card-miles-Info"
                        data-testid="snack-card-miles-Info"
                        icon="info"
                        onClick={() => setIsOpen(true)}
                        size={16}
                        variant="outlined"
                      />
                    </Popover>
                  </PopoverContainer>
                )}
              </Miles>
            </SnackDetails>
          </SnackInfoCard>
        </SnackContainer>
      )}
    </React.Fragment>
  );
};
