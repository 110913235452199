import { Flex } from '@abyss/web/ui/Flex';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import isArray from 'lodash/isArray';
import React from 'react';
import { useTranslation } from 'react-i18next';

import translationsEnJson from '../../../../../public/locale/en/translation.json';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { ProviderSearchFilters } from '../../../../models/ProviderSearch';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  FilterCollectionModel,
  ReverseCoverageTypesCodes,
} from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';

const { PROVIDER, PROVIDER_GROUPS, FACILITY } = Constants.RESULT_SECTION;
const BEHAVIORAL_HEALTH = ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];
const { MEDICAL, VISION } = ReverseCoverageTypesCodes;

interface Props {
  sectionType: string;
  selectedFilters: Omit<
    ProviderSearchFilters,
    'PageNumber' | 'AutoSearchRadius'
  >;
  setSelectedFilters: (data: ProviderSearchFilters) => void;
  selectedFiltersContainerHeight: number;
}

export const AllFilterToggleSwitchGroup = ({
  sectionType,
  selectedFilters,
  setSelectedFilters,
  selectedFiltersContainerHeight,
}: Props) => {
  const { t } = useTranslation();

  const [tierOneFilterCompareFeatureFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.TIER_ONE_FILTER_COMPARE,
  ]);

  const isTier1Plan = useStore(StoreKeys.TIER1_PLAN_FLAG);

  const suppressPremiumCare: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const coverageType = useCoverageType();

  const filters = [
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.EXPRESS_ACCESS_PROVIDER'),
      allFilterDrawerOrder: 5,
      model: FilterCollectionModel.EXPRESS_ACCESS_PROVIDER_INDICATOR,
      sections: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER
          .EXPRESS_ACCESS_PROVIDER,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.TIER_INDICATOR'),
      model: FilterCollectionModel.TIERED_PROVIDER,
      allFilterDrawerOrder: 1,
      sections: {
        [MEDICAL]: [PROVIDER, FACILITY, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.TIER_INDICATOR,
      // If the feature_flag TIER_ONE_FILTER_COMPARE is ON, then suppress Tier1
      // identifier based on 'isTier1Plan' field from snackCard api response
      show: tierOneFilterCompareFeatureFlag ? !!isTier1Plan : true,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.PREMIUM_CARE_PHYSICIAN'),
      model: FilterCollectionModel.PREMIUM_CARE_PHYSICIAN,
      allFilterDrawerOrder: 2,
      sections: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER
          .PREMIUM_CARE_PHYSICIAN,
      show: !suppressPremiumCare,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.PLATINUM_PROVIDER'),
      model: FilterCollectionModel.PLATINUM_PROVIDER_INDICATOR,
      allFilterDrawerOrder: 4,
      sections: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.PLATINUM_PROVIDER,
    },
    {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.VIRTUAL_CARE'),
      model: FilterCollectionModel.VIRTUAL_CARE,
      allFilterDrawerOrder: 3,
      sections: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [],
      },
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.VIRTUAL_CARE,
    },
  ];

  const toggleFilters = filters.filter(
    ({ sections, show = true }) =>
      sections[coverageType]?.includes(sectionType) && show
  );

  const isToggleOn = (model) =>
    isArray(model) ? model[0]?.value : model?.value || false;

  return (
    /**
     * This logic is to push down the container in a dynamic way trigger by selected filters current height,
     * It will set marginTop to push container down.
     * */
    <div
      style={{
        marginTop: `${selectedFiltersContainerHeight - 12}px`,
      }}
    >
      {toggleFilters.length ? (
        <Flex
          css={{
            '&.abyss-flex-root': {
              width: '100%',
              display: 'flex',
              gap: '24px',
              flexDirection: 'column',
              padding: '$lg',
              borderBottom: '1px solid $gray4',
            },
            '.abyss-toggle-switch-root': {
              margin: 0,
            },
            '.abyss-toggle-switch-label': {
              lineHeight: '20px',
            },
          }}
        >
          {toggleFilters
            .sort((a, b) => a.allFilterDrawerOrder - b.allFilterDrawerOrder)
            .map(({ model, label, dnt_label }) => (
              <ToggleSwitch
                data-auto-testid="switch"
                data-testid={`${model}_test`}
                isChecked={isToggleOn(selectedFilters[model])}
                key={label}
                label={label}
                onChange={(e) => {
                  adobeLinkTrackEvent({
                    name: `${dnt_label}:${
                      e.target.checked ? 'check' : 'uncheck'
                    }`,
                    location: `modal:all filters drawer`,
                    type: 'checkbox',
                  });
                  setSelectedFilters({
                    ...selectedFilters,
                    [model]: {
                      label,
                      value: e.target.checked,
                      dnt_label,
                    },
                  });
                }}
                size="$sm"
              />
            ))}
        </Flex>
      ) : null}
    </div>
  );
};
