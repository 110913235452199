import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import GetProviderReview from './ProviderReview.graphql';

export const useHealthgradesGetProviderReview = (options) =>
  useCustomQuery(GetProviderReview, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'healthgradesGetProviderReview',
  });
