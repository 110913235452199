import React from 'react';

import { FacilityDetails } from '../../../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderType,
} from '../../../../../models/ProviderDetails';
import { InfoProvider } from '../../../../ProviderDetailTabs/ProviderLocationsDetails/InfoProvider';
import {
  ContentWrapper,
  InfoWrapper,
  LocationContainer,
} from './Locations.styled';
import { LocationsWidgetMapView } from './LocationsWidgetMapView';

type Props = {
  providerDetails: ProviderDetails | FacilityDetails;
  latitude: string;
  locationId?: string;
  longitude: string;
};

export const Locations = ({
  providerDetails,
  latitude,
  locationId,
  longitude,
}: Props) => {
  const { providerLocations, providerType } = providerDetails;

  const findLocation = (loc) => loc.locationId === locationId;
  const passedLocation = locationId
    ? (providerLocations as any).find(findLocation)
    : undefined;
  const selectedLocation = passedLocation || providerLocations[0];
  const totalLocations = providerLocations.length;

  return (
    <LocationContainer>
      {providerType === ProviderType.ORGANIZATION ? (
        <React.Fragment>
          <ContentWrapper data-testid="widget-info-facility-wrapper">
            <LocationsWidgetMapView
              latitude={latitude}
              longitude={longitude}
              providerType={providerType}
              selectedLocation={selectedLocation}
              totalLocations={totalLocations}
            />
          </ContentWrapper>
          <ContentWrapper>
            <InfoWrapper>
              <InfoProvider
                detailType={providerDetails.providerType}
                facilityDetails={providerDetails as FacilityDetails}
                selectedLocation={selectedLocation}
              />
            </InfoWrapper>
          </ContentWrapper>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ContentWrapper data-testid="widget-info-provider-wrapper">
            <LocationsWidgetMapView
              latitude={latitude}
              longitude={longitude}
              providerType={providerType}
              selectedLocation={selectedLocation}
              totalLocations={totalLocations}
            />
          </ContentWrapper>
          <ContentWrapper>
            <InfoWrapper>
              <InfoProvider
                detailType={providerDetails.providerType}
                providerDetails={providerDetails as ProviderDetails}
                selectedLocation={selectedLocation}
              />
            </InfoWrapper>
          </ContentWrapper>
        </React.Fragment>
      )}
    </LocationContainer>
  );
};
