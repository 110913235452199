import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import find from 'lodash/find';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../../hooks/useLagoon';
import { ProviderType } from '../../../models/ProviderDetails';
import { returnSuppressFlag } from '../../../utils/featureSuppress';
import {
  UserDDPCode,
  getSuppressFacilityFlags,
  isShowPreferredFacilityBadge,
} from '../../../utils/providerDetails.utils';
import { parseProviderName } from '../../../utils/providerNames.utils';
import { widgetModalEvent } from '../../../utils/widgetEvents.utils';
import { Constants, ReverseCoverageTypesCodes } from '../../Constants';
import { FeatureFlags } from '../../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { mobileOnly, phoneOnly } from '../../ConstantsStyles';
import { BenefitsBadge } from '../../DetailsPageHeader/BenefitsBadge';
import { AvatarDetailsPage } from '../../DetailsPageHeader/DetailsPageHeader.styled';
import { InNetworkStatusLabel } from '../../DetailsPageHeader/InNetworkStatusLabel';
import { ProviderRatings } from '../../DetailsPageHeader/ProviderRatings';
import { VirtualCareOfferedLabel } from '../../DetailsPageHeader/VirtualCareOfferedLabel';
import { ImageComponent } from '../../ImageComponent';
import { getFeatureFlag } from '../../Utils';
import {
  BenefitsBadgeContainer,
  CostEstimateContainer,
  OutOfPocketContainer,
  ProviderInfo,
  ProviderName,
  ProviderNameAndCost,
  ProviderNameContainer,
  ProviderSpecialty,
  TotalContainer,
  WidgetHeader,
  YouPayContainer,
} from './DetailsWidgetHeader.styled';

type Props = {
  coverageType: string;
  ddpFacilityCode?: string;
  ddpUserCode?: UserDDPCode;
  imageLocation: string;
  inNetwork: string;
  isFreeStandingFacility?: boolean;
  isPreferredLabNetwork?: boolean;
  nameAttrs?: any;
  primarySpeciality: string;
  professionalDesignation: string;
  providerName: string;
  providerType?: ProviderType;
  rating: string;
  virtualCareOffered: boolean;
  organizationType: string;
  outOfPocketCost?: string;
  totalCost?: string;
};
export const DetailsWidgetHeader = ({
  coverageType,
  ddpFacilityCode,
  ddpUserCode,
  imageLocation,
  inNetwork,
  isFreeStandingFacility,
  isPreferredLabNetwork,
  nameAttrs,
  primarySpeciality,
  organizationType,
  professionalDesignation,
  providerName,
  providerType,
  rating,
  virtualCareOffered,
  outOfPocketCost,
  totalCost,
}: Props) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const featureFlags = useLagoon('feature-flags')();
  const uiMessaging = useLagoon('ui-messaging')();
  const mobileScreen = useMediaQuery(mobileOnly);
  const phoneScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();

  const virtualCareIndicatorEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VIRTUAL_CARE_INDICATOR_ENABLED
  );
  const showPreferredProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.FACILITIES
    ].PREFERRED_FACILITY_IDENTIFICATION
  );
  const suppressReviews = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const suppressFacilityFlags = getSuppressFacilityFlags();

  const { preferredFacilityContent } =
    find(uiMessaging, {
      key: ConstantsLagoon.PREFERRED_FACILITY.PREFERRED_FACILITY_CONTENT,
    }) || {};

  const displayVirtualCareIcon =
    virtualCareOffered &&
    virtualCareIndicatorEnabled &&
    (coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] ||
      coverageType === ReverseCoverageTypesCodes['BEHAVIORAL CARVE-OUT'] ||
      coverageType === ReverseCoverageTypesCodes.MEDICAL);

  const preferredFacilityMessage =
    preferredFacilityContent?.message ??
    t('PREFERRED_FACILITY_BENEFITS.CONTENT');

  const showBenefitsBadge = isShowPreferredFacilityBadge(
    suppressFacilityFlags,
    showPreferredProviderTag,
    ddpFacilityCode,
    ddpUserCode,
    isPreferredLabNetwork,
    isFreeStandingFacility
  );

  const trackPreferredFacilityClick = () => {
    widgetModalEvent({
      modalName: 'preferred facility',
    });
  };

  return (
    <WidgetHeader>
      <Flex>
        <AvatarDetailsPage data-testid="widget-profile-picture">
          {ImageComponent({
            imgRef,
            providerType,
            imgSrc: imageLocation,
          })}
        </AvatarDetailsPage>

        <ProviderInfo grow space="0">
          <ProviderNameAndCost alignItems="top" alignLayout="between">
            <ProviderNameContainer alignItems="left" space="4">
              {showBenefitsBadge ? (
                <BenefitsBadgeContainer
                  data-testid="benefits-badge-container"
                  onClick={trackPreferredFacilityClick}
                >
                  <BenefitsBadge
                    content={preferredFacilityMessage}
                    testid="preferred-facility-widget-header"
                    title={t('Preferred Facility')}
                  />
                </BenefitsBadgeContainer>
              ) : null}
              <ProviderName fontWeight="$bold" size="$lg">
                {parseProviderName(
                  providerName,
                  providerType,
                  professionalDesignation,
                  nameAttrs
                )}
              </ProviderName>
              <ProviderSpecialty
                fontWeight="$normal"
                size="$md"
                transform="capitalize"
              >
                {providerType === ProviderType.ORGANIZATION
                  ? organizationType
                  : primarySpeciality}
              </ProviderSpecialty>
            </ProviderNameContainer>
            {!phoneScreen && (outOfPocketCost || totalCost) ? (
              <CostEstimateContainer alignItems="left" space="2">
                <YouPayContainer>
                  {t('DETAILS_WIDGET.YOU_PAY_IN_NETWORK')}
                </YouPayContainer>
                {outOfPocketCost ? (
                  <OutOfPocketContainer>{`$${outOfPocketCost}`}</OutOfPocketContainer>
                ) : null}

                {totalCost ? (
                  <TotalContainer>
                    {`$${totalCost} `} {t('DETAILS_WIDGET.TOTAL')}
                  </TotalContainer>
                ) : null}
              </CostEstimateContainer>
            ) : null}
          </ProviderNameAndCost>
          <Layout.Group space={mobileScreen ? '0' : '-8'}>
            <Layout.Stack alignItems="left" space="4">
              <Layout.Group space="16">
                <InNetworkStatusLabel inNetwork={inNetwork} />
                {!suppressReviews ? (
                  <ProviderRatings
                    providerType={providerType}
                    rating={rating}
                  />
                ) : null}
              </Layout.Group>
              <VirtualCareOfferedLabel
                virtualCareOffered={displayVirtualCareIcon}
              />
            </Layout.Stack>
          </Layout.Group>
        </ProviderInfo>
      </Flex>
    </WidgetHeader>
  );
};
