import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';

// Do not translate this json file
import uesSpecialityCode from '../../../../translation/data/en/uesSpecialityTranslations.json';
import {
  CareCategories,
  Constants,
  L2_CATEGORIES,
  RecentSearchesSearchTypes,
  TypeaheadChipCategoriesMapping,
  TypeaheadProviderTypes,
  TypeaheadProviderTypesMapping,
} from '../Constants';
import { ConstantsRoutes } from '../ConstantsRoutes';

export const handleRecentSearchesChipClick = (
  item,
  navigate,
  analyticsLinkName,
  token
) => {
  if (
    item?.searchType === RecentSearchesSearchTypes.SPECIALITY ||
    item?.searchType === RecentSearchesSearchTypes.NAME ||
    item?.searchType === RecentSearchesSearchTypes.VISION
  ) {
    const updatedToken = tokenizer.update(token, {
      originLinkNameForAnalytics: analyticsLinkName,
      search: item?.psxSearchTerm,
      keyword: item?.psxSearchTerm,
      searchType: item?.searchType,
      coverageType: item?.coverageType,
      pesKeyword: item?.pesSearchTerm,
      includeSpecialityRollupCodes: item?.pesSearchTerm,
      isMixedSuggestions: item?.isMixedSearchSuggestion,
      onlineRetailers: item?.searchType === RecentSearchesSearchTypes.VISION,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}/${updatedToken}`);
  } else {
    const detailRoutesPath = () => {
      switch (item?.searchType) {
        case RecentSearchesSearchTypes.MEDICAL_GROUP_DETAIL:
          return ConstantsRoutes.PROVIDER_GROUP_DETAILS.path;
        case RecentSearchesSearchTypes.PRACTITIONER_DETAIL:
          return ConstantsRoutes.PROVIDER_DETAILS.path;
        default:
          return ConstantsRoutes.FACILITY_DETAILS.path;
      }
    };
    const detailsRoute = detailRoutesPath();
    const providerId = item?.pesSearchTerm;
    const updatedToken = tokenizer.update(token, {
      providerId,
      coverageType: item?.coverageType,
      chipValue: item?.category,
    });

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      providerId
    );
    navigate(`${detailsRoute}${updatedToken}`);
  }
};

export const mappingRollUpCodeToCategory = (code) => {
  if (code) {
    const L2Code = code
      .split(',')
      ?.find((item) => item?.split('-')[0] !== '52');
    return L2_CATEGORIES[L2Code?.split('-')[0]] || '';
  }
  return '';
};

export const getOrgL2Category = (code) => {
  if (code && code.includes('-')) {
    const L2Code = code
      .split(',')
      ?.find(
        (item) => item?.split('-')?.length === 5 && item?.split('-')[0] === '52'
      );
    const parseL2Code = L2Code
      ? L2Code.split('-')[3].concat('-', L2Code.split('-')[4])
      : '';
    const L2Key =
      Object.keys(uesSpecialityCode).find((key) => key.includes(parseL2Code)) ||
      '';
    return L2_CATEGORIES[L2Key?.split('-')[0]] || '';
  }
  return '';
};

export const mappingSpecialityRollUpCodeToCategory = (
  speciality,
  providerType
) => {
  if (speciality?.length > 0) {
    const categories = [] as any;

    speciality.forEach((item) => {
      const category =
        providerType === TypeaheadProviderTypes.PROVIDER_GROUP
          ? getOrgL2Category(item?.orgCode)
          : TypeaheadProviderTypesMapping[item?.providerType];
      if (!categories.includes(category)) {
        categories.push(category);
      }
    });

    return categories.join();
  }
  return '';
};

export const getCategoryFromChipCategoriesCount = (chipCategories) => {
  if (Array.isArray(chipCategories)) {
    const categories = [] as string[];
    chipCategories?.forEach((chipCategory) => {
      if (chipCategory?.chipEnabled)
        categories.push(
          TypeaheadChipCategoriesMapping[chipCategory?.chipCategory]
        );
    });
    return categories.join() || CareCategories.ALL;
  }
  return chipCategories || 'category';
};
