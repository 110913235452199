import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import get from 'lodash/get';
import { useContext, useEffect, useMemo, useState } from 'react';

import {
  AUTO_INCREMENT_SEARCH_RADIUS,
  Constants,
  DEFAULT_SEARCH_RADIUS,
  FilterCollectionModel,
  OPTUM_CORRELATION_ID_HEADER,
  SortOptionValues,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { ProviderType } from '../../models/ProviderDetails';
import { ProviderSearchRequestParams } from '../../models/ProviderSearch';
import {
  getProviderType,
  getProviderResults as parseProviderResults,
} from '../../utils/providerDetails.utils';
import {
  getCurrentMemberData,
  getLocale,
  getOrgTypeCodes,
  getSpecialityRollupCodes,
  getTokenData,
  parseProviderSearchFilters,
} from '../../utils/providerSearch.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getDependentInfo,
  getNetworkIdsForPES,
  getOnlineRetailers,
  getPlanVariationCode,
} from '../../utils/user.utils';
import { useCoverageType } from '../useCoverageType';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import GET_PROVIDER_SEARCH_QUERY from './ProviderSearch.graphql';

export const getFirstNumber = (str: string) => str?.split(',')[0];

export const filterSearchResults = (searchResult) => {
  const providerSearchResult = get(searchResult, 'providerSearch', {});

  const providersData = providerSearchResult.providers;

  const practitionersFiltered = providersData?.filter(
    (data) => data?.providerType === ProviderType.PRACTITIONER
  );

  const facilitiesFiltered = providersData?.filter(
    (data) => data?.providerType === ProviderType.ORGANIZATION
  );

  const medicalGroup = providersData?.filter(
    (data) => data?.providerType === ProviderType.MEDICAL_GROUP
  );

  return {
    totalCountPractitioners: providerSearchResult.totalCountPractitioners,
    totalCountOrganizations: providerSearchResult.totalCountOrganizations,
    totalCountMedicalGroup: providerSearchResult.totalCountMedicalGroup,
    providers: practitionersFiltered || [],
    facilities: facilitiesFiltered || [],
    medicalGroup: medicalGroup || [],
    practitionerSearchRadius: providerSearchResult.practitionerSearchRadius,
    organizationSearchRadius: providerSearchResult.organizationSearchRadius,
    medicalGroupSearchRadius: providerSearchResult.medicalGroupSearchRadius,
    networkStatus: providerSearchResult.networkStatus,
    isTieredPlan: providerSearchResult.isTieredPlan,
  };
};

export const useProviderSearch = (options) => {
  const [filteredResult, setFilteredResult] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [headers, setHeaders] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GET_PROVIDER_SEARCH_QUERY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'providerSearch',
    onCalled: () => {
      setIsLoading(true);
    },
    /* istanbul ignore next */
    onCompleted: (result) => {
      const data = filterSearchResults(result?.data);
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
      setFilteredResult(data);
      setIsLoading(false);
      setHeaders({
        correlationId: result.headers[OPTUM_CORRELATION_ID_HEADER],
      });
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const parsedResult = {
    ...queryResult,
    isLoading,
    data: filteredResult,
    headers,
  };

  return [parsedResult, queryAction];
};

export const useProviderResults = (customParams: any) => {
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { dependentSeqNbr } = tokenData;
  const { longitude, latitude, stateCode } = useGeoLocationStorage();
  const coverageType = useCoverageType();

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );
  const llmFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM
  );

  const rollupCodeMapLagoonData = useLagoon(
    Constants.LAGOON_TABLE.ROLLUPCODE_MAPPING
  )();

  const rollupCodeMap = rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (item.source && item.destination) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});

  const variables = {
    lob: currentMember?.lineOfBusiness,
    coverages: getCoverageTypes(currentMember),
    planYear: getCurrentPlanYear(),
    planVariationCode: getPlanVariationCode(currentMember, coverageType),
    policyID: currentMember?.policyNumber,
    reciprocityId: getNetworkIdsForPES(
      currentMember,
      customParams?.coverageType,
      featureFlags
    ),
    rollupCodeMap,
    locale: getLocale(),
    latitude,
    longitude,
    stateCode,
    memberDemographics: {
      gender: currentMember?.demographics?.gender,
      dateOfBirth: currentMember?.demographics?.dateOfBirth,
    },
    coverageType: customParams?.coverageType,
    isExtendedSearchEnabled: customParams?.isExtendedSearchEnabled,
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    ddpCode: currentMember?.ddpCode,
  };

  // to store all the search variables including default variables and selected filter params
  const [queryParams, setQueryParams] = useState(variables);

  const [providerResults, getProviders] = useProviderSearch({});

  const getProviderResults = (params) => {
    const planVariationCodeNew = getPlanVariationCode(
      currentMember,
      params?.coverageType
    );
    const searchParams = {
      ...variables,
      ...params,
      planVariationCode: variables?.planVariationCode || planVariationCodeNew,
      llmFlag,
    };
    const {
      includeSpecialityRollupCodes: includeSpecialityRollupCodesFromParams,
    } = searchParams;
    const includeSpecialityRollupCodes = [
      ...new Set(
        Array.isArray(includeSpecialityRollupCodesFromParams)
          ? includeSpecialityRollupCodesFromParams
          : includeSpecialityRollupCodesFromParams?.split(',') || []
      ),
    ];
    if (JSON.stringify(queryParams) !== JSON.stringify(searchParams)) {
      setQueryParams(searchParams);
      getProviders({
        variables: {
          ...searchParams,
          includeSpecialityRollupCodes,
        },
      });
    }
  };

  return [providerResults, getProviderResults];
};

export const useFilterProviderResults = (params) => {
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = getTokenData(token);
  const {
    useMockData = false,
    search = '',
    providerType = '',
    category,
    searchType,
    pcpIndicator = false,
    includeOrgTypeCodes,
    requestType = '',
    coverageType,
    dependentSeqNbr,
    sectionType = '',
    specialtyCode,
    includeSpecialityRollupCodes,
    acceptingNewPatients,
    keyword = '',
    onlineRetailers,
  } = tokenData;

  const { longitude, latitude, stateCode } = useGeoLocationStorage();
  const {
    pageNumber = 1,
    pageSize = 10,
    selectedFilters = {},
    shouldGetHGData,
  } = params;
  const currentMember = getCurrentMemberData(dependentSeqNbr);

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );
  const llmFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM
  );

  const rollupCodeMapLagoonData = useLagoon(
    Constants.LAGOON_TABLE.ROLLUPCODE_MAPPING
  )();

  const rollupCodeMap = rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (item.source && item.destination) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});

  const rollupCodes = useMemo(() => {
    const codes = getSpecialityRollupCodes(
      specialtyCode,
      includeSpecialityRollupCodes,
      category
    );
    return codes.split(',');
  }, [specialtyCode, includeSpecialityRollupCodes, category]);

  const variables = {
    lob: currentMember?.lineOfBusiness,
    coverages: getCoverageTypes(currentMember),
    planYear: getCurrentPlanYear(),
    planVariationCode: getPlanVariationCode(currentMember, coverageType),
    policyID: currentMember?.policyNumber,
    reciprocityId: getNetworkIdsForPES(
      currentMember,
      coverageType,
      featureFlags
    ),
    rollupCodeMap,
    locale: getLocale(),
    shouldGetHGData,
    latitude,
    longitude,
    stateCode,
    memberDemographics: {
      gender: currentMember?.demographics?.gender,
      dateOfBirth: currentMember?.demographics?.dateOfBirth,
    },
    coverageType,
    providerType: getProviderType(providerType, sectionType),
    search,
    includeSpecialityRollupCodes: [...new Set(rollupCodes)],
    requestType,
    searchType,
    pcpIndicator,
    llmFlag,
    keyword,
    onlineRetailers: getOnlineRetailers(onlineRetailers),
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    ddpCode: currentMember?.ddpCode,
  };

  const { searchFilters, setSearchFilters, setDefaultSearchFilters } =
    useContext(SearchFilterContext);
  const { AutoSearchRadius: autoSearchRadius } = searchFilters;
  const filterParams = parseProviderSearchFilters(selectedFilters);
  const queryParams = {
    pageNumber,
    pageSize,
    includeOrgTypeCodes: getOrgTypeCodes(includeOrgTypeCodes),
    // remove distance limit for Virtual Care filter for BH providers (US6465032),
    // backend logic will handle -1 for no distance limit search
    searchRadius:
      coverageType === 'B' && filterParams?.virtualCare === true
        ? -1
        : autoSearchRadius,
    ...filterParams,
  };

  const [defaultVariables, setDefaultVariables] = useState(variables);
  const [searchParams, setSearchParams] = useState(filterParams);
  const [practitionersResult, getPractitionersResult] = useProviderSearch({});

  useEffect(() => {
    // auto increment search and reset filters on change of location, current member, etc
    if (JSON.stringify(defaultVariables) !== JSON.stringify(variables)) {
      const updatedParams: ProviderSearchRequestParams = {
        pageNumber: 1,
        pageSize: 10,
        includeOrgTypeCodes: getOrgTypeCodes(includeOrgTypeCodes),
        searchRadius: DEFAULT_SEARCH_RADIUS,
        sortBy: SortOptionValues.BEST_MATCH,
      };
      // reset filters
      const defaultFilters: FilterCollectionModel[] = [
        FilterCollectionModel.AUTO_SEARCH_RADIUS,
      ];
      if (acceptingNewPatients) {
        updatedParams.acceptingNewPatients = true;
        defaultFilters.push(FilterCollectionModel.ACCEPTING_NEW_PATIENTS);
      }
      getPractitionersResult({
        variables: {
          autoIncrementRadius: AUTO_INCREMENT_SEARCH_RADIUS,
          ...variables,
          ...updatedParams,
        },
      });
      setDefaultSearchFilters(defaultFilters);
      setSearchParams(updatedParams);
      setDefaultVariables(variables);
    }
  }, [JSON.stringify(variables)]);

  useEffect(() => {
    if (JSON.stringify(searchParams) !== JSON.stringify(queryParams)) {
      setSearchParams(queryParams);
      getPractitionersResult({
        variables: {
          ...variables,
          ...queryParams,
        },
      });
    }
  }, [JSON.stringify(queryParams)]);

  const { results, totalResultsCount, providerSearchRadius, isLoading, error } =
    parseProviderResults(sectionType, practitionersResult, useMockData);

  useEffect(() => {
    if (providerSearchRadius && autoSearchRadius !== providerSearchRadius) {
      setSearchFilters({
        ...searchFilters,
        AutoSearchRadius: providerSearchRadius,
      });
    }
  }, [providerSearchRadius]);

  return {
    results,
    totalResultsCount,
    searchRadius: autoSearchRadius,
    isLoading,
    isTieredPlan: practitionersResult?.data?.isTieredPlan,
    error,
    headers: practitionersResult?.headers,
  };
};

export const useProviderSummaryResult = ({ providerId, isOHBS }) => {
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = getTokenData(token);
  const { coverageType } = tokenData;
  const currentMember = getCurrentMember();
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const featureFlags: [{ key: string; active: boolean }] = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_FLAGS
  )();

  const variables = {
    longitude,
    latitude,
    stateCode,
    providerId,
    providerType: '',
    lob: currentMember?.lineOfBusiness,
    coverages: getCoverageTypes(currentMember),
    planYear: getCurrentPlanYear(),
    planVariationCode: getPlanVariationCode(currentMember, coverageType),
    policyID: currentMember?.policyNumber,
    reciprocityId: isOHBS
      ? '100'
      : getNetworkIdsForPES(currentMember, coverageType, featureFlags),
    coverageType,
    locale: getLocale(),
    ddpCode: currentMember?.ddpCode,
  };

  const [providerResult, getProviderResult] = useProviderSearch({});

  useEffect(() => {
    if (providerId) {
      getProviderResult({
        variables,
      });
    }
  }, [providerId]);

  return providerResult;
};
