import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { ProviderType } from '../../models/ProviderDetails';
import { getCarePathCategoriesSortOrder } from '../../utils/providerDetails.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import GET_FACILITY_DETAILS from '../useFacilityDetails/FacilityDetails.graphql';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import GET_PROVIDER_DETAILS from './ProviderDetails.graphql';

export const useProviderDetailsSearch = (
  options,
  providerType = ProviderType.PRACTITIONER
) => {
  const query =
    providerType === ProviderType.ORGANIZATION
      ? GET_FACILITY_DETAILS
      : GET_PROVIDER_DETAILS;
  const accessor =
    providerType === ProviderType.ORGANIZATION
      ? 'facilityDetails'
      : 'providerDetails';
  const [providerDetails, setProviderDetails] = useState({});
  const [queryResult, queryAction] = useCustomQuery(query, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor,
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setProviderDetails(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: providerDetails,
  };

  return [parsedResult, queryAction];
};

export const useProviderDetails = (
  variables,
  setSelectedPCP?: Dispatch<SetStateAction<boolean>>
) => {
  const locale = getLanguage()?.code || 'en';
  const {
    providerId = '',
    providerType,
    isOHBS,
    locationId,
    shouldGetHGData,
  } = variables;
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { coverageType } = tokenData;
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const currentMember = getCurrentMember();

  const [providerDetails, getProviderDetails] = useProviderDetailsSearch({});

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const costCareCategories = useLagoon('cost-care-categories')();

  useEffect(() => {
    if (
      typeof setSelectedPCP !== 'undefined' &&
      currentMember?.demographics?.primaryCare?.providerID === providerId
    ) {
      setSelectedPCP(true);
    }
    getProviderDetails({
      variables: {
        latitude,
        longitude,
        stateCode,
        locale,
        lob: currentMember?.lineOfBusiness,
        coverages: getCoverageTypes(currentMember),
        planYear: getCurrentPlanYear(),
        planVariationCode: getPlanVariationCode(currentMember, coverageType),
        policyId: currentMember?.policyNumber,
        providerId,
        providerType: providerType ?? ProviderType.PRACTITIONER,
        reciprocityId: isOHBS
          ? '100'
          : getNetworkIdsForPES(currentMember, coverageType, featureFlags),
        coverageType,
        locationId: locationId || undefined,
        carePathCategoriesSortOrder: getCarePathCategoriesSortOrder(
          ProviderType.PRACTITIONER === providerType,
          locale,
          costCareCategories
        ),
        shouldGetHGData,
      },
    });
  }, [latitude, longitude, stateCode, providerId, coverageType]);

  return providerDetails;
};

export const useProviderDetailsWidget = (variables) => {
  const locale = getLanguage()?.code || 'en';
  const {
    coverageType,
    latitude,
    lob,
    locationId,
    longitude,
    reciprocityId,
    planVariationCode,
    planYear,
    policyId,
    providerId,
    providerType,
  } = variables;
  const { stateCode } = useGeoLocationStorage();

  const [providerDetails, getProviderDetails] = useProviderDetailsSearch(
    {},
    providerType
  );

  useEffect(() => {
    getProviderDetails({
      variables: {
        latitude,
        longitude,
        stateCode,
        locale,
        lob,
        coverages: [coverageType],
        planYear,
        planVariationCode,
        policyId,
        policyID: policyId,
        providerId,
        providerType: providerType ?? ProviderType.PRACTITIONER,
        reciprocityId,
        coverageType,
        locationId,
      },
    });
  }, [latitude, longitude, stateCode, providerId, coverageType]);

  return providerDetails;
};
