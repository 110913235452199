import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';

import {
  HeadingWrapper,
  providerNameDataCardTextStyles,
  providerNameToolTipStyles,
} from './DataCard.style';

type Props = {
  sectionTypeDerived;
  name;
  handleOnHeadingBlur;
  handleDetailsOnClick;
  handleHoverOnCard;
  providerId;
};

export const NameHeading = ({
  sectionTypeDerived,
  name,
  handleOnHeadingBlur,
  handleDetailsOnClick,
  handleHoverOnCard,
  providerId,
}: Props) => (
  <HeadingWrapper
    alignLayout="left"
    data-auto-testid={`data-card-heading-${sectionTypeDerived}`}
    data-testid={`data-card-heading-${sectionTypeDerived}`}
    onBlur={handleOnHeadingBlur}
    onClick={handleDetailsOnClick}
    onFocus={handleHoverOnCard}
  >
    <Tooltip
      asChild={false}
      content={name}
      css={providerNameToolTipStyles}
      position="top"
      positionOffset={8}
    >
      <Text
        color="$info1"
        css={providerNameDataCardTextStyles}
        data-auto-testid={`data-card-detail-button-${sectionTypeDerived}`.replace(
          / /g,
          '-'
        )}
        data-testid={`data-card-detail-button-${sectionTypeDerived}`.replace(
          / /g,
          '-'
        )}
        fontWeight="$bold"
        size="$lg"
      >
        {name}
      </Text>
    </Tooltip>
    <IconMaterial
      className="arrow_forward"
      css={{
        width: '24px',
        height: '12px',
      }}
      data-auto-testid={`data-card-arrow-forward-icon-${sectionTypeDerived}-${providerId}`}
      data-testid={`data-card-arrow-forward-icon-${sectionTypeDerived}-${providerId}`}
      icon="arrow_forward_ios"
    />
  </HeadingWrapper>
);
