import { Card } from '@abyss/web/ui/Card';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DisclaimerContainer,
  StyledVisionDisclaimer,
} from './VisionDisclaimer.styled';

type Props = {
  page?: Boolean;
};
export const VisionDirectoryDataDisclaimer = ({ page = true }: Props) => {
  const { t } = useTranslation();
  return (
    <StyledVisionDisclaimer data-testid="vision-disclaimer">
      <DisclaimerContainer page={page}>
        <Card>
          <Card.Section>{t('VISION_DICTIONARY_DISCLAIMER.TEXT')}</Card.Section>
        </Card>
        <Card>
          <Card.Section>
            {t('VISION_DICTIONARY_DISCLAIMER.ADDL_TEXT')}
          </Card.Section>
        </Card>
      </DisclaimerContainer>
    </StyledVisionDisclaimer>
  );
};
