import { styled } from '@abyss/web/tools/styled';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { returnSuppressFlag } from '../../utils/featureSuppress';
import { Constants, SortOptionValues } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { PopoverWithTracking } from '../PopoverWithTracking/PopoverWithTracking';
import { RadioGroup } from '../RadioGroup';

type Radio = {
  label: string | ReactNode;
  value: string;
};

type Props = {
  defaultValue?: string | number | boolean;
  title: string;
  label: string | ReactNode;
  options: Radio[];
  model: string;
  onApply: (value: string) => void;
};

// To bring popover over map and other content
export const FilterPopOverWrapper = styled('div', {
  '.abyss-popover-trigger ~ div': {
    zIndex: '99999 !important',
  },
  '.abyss-popover-trigger': {
    borderRadius: '24px',
  },
});

export const SortBy = ({
  defaultValue,
  title,
  label,
  options,
  model,
  onApply,
}: Props) => {
  const [showFilter, setShowFilter] = useState(false);
  const { t } = useTranslation();

  const suppressPatientReviews = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );

  function filteredOptions() {
    return suppressPatientReviews
      ? options.filter(
          (option) => option.value !== SortOptionValues.HIGHEST_RATING
        )
      : options;
  }

  const handOnApply = (value: string) => {
    setShowFilter(false);
    if (value) {
      onApply(value);
    }
  };

  const handleOnOpenChange = () => {
    setShowFilter(!showFilter);
  };

  const renderSortOptions = () => (
    <RadioGroup
      defaultValue={defaultValue}
      labelFontSize="18px"
      locationForAnalytics={`modal:${title}`}
      model={model}
      onApply={(value) => handOnApply(value)}
      options={filteredOptions()}
      submitBtnName={t('Sort') as string}
    />
  );

  return (
    <FilterPopOverWrapper>
      <PopoverWithTracking
        analyticsInfo={{
          linkName: title,
          location: 'body:sort by',
          modalName: title,
        }}
        content={renderSortOptions()}
        css={{
          'abyss-popover-content': {
            border: 'none',
            borderRadius: '12px',
            padding: '24px',
            width: '308px',
          },
          'abyss-popover-header': {
            marginLeft: '4px',
            marginBottom: '20px',
            padding: '7px 0px',
          },
          'abyss-popover-close-button': {
            top: '29px',
            right: '24px',
          },
          'abyss-popover-title': {
            marginTop: '0',
            fontSize: '$largeText !important',
            fontWeight: '$bold !important',
          },
        }}
        data-auto-testid="{title.replace(/ /g, '-').toLocaleLowerCase()}"
        data-testid={title.replace(/ /g, '-').toLocaleLowerCase()}
        onOpenChange={handleOnOpenChange}
        open={showFilter}
        position="bottom"
        title={title}
        trackingType={Constants.ADOBE_TRACKING.MODAL_WITH_LINK_TRACKING}
        width={375}
      >
        {label}
      </PopoverWithTracking>
    </FilterPopOverWrapper>
  );
};
