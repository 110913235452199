import { styled } from '@abyss/web/tools/styled';
import { Link } from '@abyss/web/ui/Link';

export const ReferralBannerBoxBanner = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  lineHeight: '20px',
  padding: '12px 16px',
  width: 'fit-content',
  columnGap: '16px',
  alignSelf: 'stretch',
  borderRadius: '4px',
  backgroundColor: ' #EDF3FB',
  '.abyss-popover-trigger ~ div': {
    zIndex: '9 !important',
  },

  '@screen < $sm': {
    width: '100%',
  },
});

export const ReferralBanner = styled('div', {
  display: 'flex',
  lineHeight: '20px',
});

export const ReferralRequiredIcon = styled('div', {
  width: '24px',
  height: '24px',
  marginRight: '8px',
});

export const ContactMyPCP = styled('div', {
  width: '117px',
  '@screen < $sm': {
    width: '93px',
  },
});
export const ArrowIcon = styled('div', {
  width: '24px',
  height: '24px',
  marginLeft: '4px',
  '@screen < $sm': {
    width: '18px',
    height: '18px',
  },
});
export const ReferralBannerReferralRequired = styled('div', {
  width: '312px',
  display: 'flex',
  flexWrap: 'nowrap',
  fontWeight: '$medium',
  fontSize: '16px',
  lineHeight: '19px',
  color: '$primary1',
  marginRight: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  '@screen < $sm': {
    width: '279px',
    fontSize: '14.22px',
    paddingLeft: '0',
    lineHeight: '17px',
  },
});

export const PopOverStyle = {
  'abyss-popover-trigger-icon': {
    width: '16px',
    height: '16px',
    marginLeft: '4px',
    marginTop: '4px',
  },
};

export const ReferralBannerTextContainer = styled('div', {
  fontWeight: '$semibold',
  fontSize: '16px',
  lineHeight: '20px',
  color: '$primary1',
  '@screen < $sm': {
    fontSize: '14.22px',
    width: '259px',
  },
});
export const ReferralBannerSpecialtyContainer = styled('div', {
  fontWeight: '$medium',
  fontSize: '16px',
  lineHeight: '20px',
  color: '#4B4D4F',
  marginRight: '16px',
  marginTop: '2px',
  'li:first-child': {
    listStyle: 'none',
    marginLeft: '0 !important',
  },
  'li:last-child': {
    marginRight: '0 !important',
  },
  '@screen < $sm': {
    fontSize: '14.22px',
    lineHeight: '20px',
    marginTop: '4px',
  },
});

export const ReferralBannerContactPCP = styled('button', {
  marginLeft: '16px',
  cursor: 'pointer',
  display: 'flex',
  color: '#196ECF',
  fontWeight: '$bold',
  fontSize: '$md',
  lineHeight: '20px',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  '@screen < $sm': {
    marginLeft: '0px',
    fontSize: '12.64px',
    lineHeight: '16px',
  },
});

export const ReferralBannerNeedHelp = styled(Link, {
  '&.abyss-link-root': {
    height: '26px',
    width: '177px',
    color: '#196ECF',
    fontSize: '12.64px',
    display: 'flex',
    padding: '5px 8px 5px 10px',
    background: '#FFFFFF',
    borderRadius: '4px',
    alignItems: 'center',
    fontWeight: '$bold',
    marginLeft: '16px',
    '@screen < $sm': {
      margin: '0px 8px 0px 8px',
      lineHeight: '16px',
    },
  },
  '.abyss-popover-trigger ~ div': {
    zIndex: '9 !important',
  },
});
