import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Pagination } from '@abyss/web/ui/Pagination';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';

import { HealthgradesProviderReview, Survey } from '../../models/Healthgrades';
import { phoneOnly } from '../ConstantsStyles';
import { DisclaimersContainer } from '../DisclaimersContainer/DisclaimersContainer';
import {
  ContainerSubHeading,
  PaginationComponentStyled,
  RatingSectionContainerStyles,
  SectionContainer,
  TextSubHeadingWhatPatientsAreSaying,
  showingResultsCountTextStyles,
  showingVerbatimCountTextStyles,
  sortButtonMobileStyles,
  verbatimHeader,
  viewAllVerbatimFlexStyles,
} from '../ProviderDetailTabs/About/PatientReviews/PatientReviews.styled';
import { PatientReviewsOverviewSection } from '../ProviderDetailTabs/About/PatientReviews/PatientReviewsOverviewSection';
import { PatientVerbatimCard } from '../ProviderDetailTabs/About/PatientReviews/PatientVerbatimCard';
import { swapIconStyles } from '../ProviderDetailTabs/PhysicianDirectory/PhysicianDirectory.styled';
import { AllPatientReviewsSortPopover } from './AllPatientReviewsSort';
import { PatientReviewsSortOrder } from './AllPatientReviewsSortOrder';

export const sortReviewsMostRecent = (a: Survey, b: Survey) => {
  if (
    !a.submittedDate ||
    !b.submittedDate ||
    new Date(a.submittedDate) === new Date(b.submittedDate)
  ) {
    return 0;
  }
  return new Date(a.submittedDate) > new Date(b.submittedDate) ? -1 : 1;
};

export const sortReviewsHighestRating = (a: Survey, b: Survey) => {
  if (!a.overallScore || !b.overallScore || a.overallScore === b.overallScore)
    return 0;
  return a.overallScore > b.overallScore ? -1 : 1;
};

export const sortReviewsLowestRating = (a: Survey, b: Survey) => {
  if (!a.overallScore || !b.overallScore || a.overallScore === b.overallScore)
    return 0;
  return a.overallScore < b.overallScore ? -1 : 1;
};

type Props = {
  healthgradesReview: HealthgradesProviderReview;
  handleOnClick: () => void;
  setReviewModal: (a: Survey) => void;
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  currentPage: number;
  pageIndex: number;
  pageCount: number;
  gotoPage: (a: number) => void;
  pageSize: number;
  setPageSize: (a: number) => void;
  paginationProps: any;
  setSortOrder: (a: PatientReviewsSortOrder) => void;
  sortOrder: PatientReviewsSortOrder;
};
export const RatingsSection = ({
  healthgradesReview,
  handleOnClick,
  setReviewModal,
  isOpen,
  setIsOpen,
  currentPage,
  pageIndex,
  pageCount,
  gotoPage,
  pageSize,
  setPageSize,
  paginationProps,
  setSortOrder,
  sortOrder,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const form = useForm({
    defaultValues: {
      'dropdown-menu-radios': 10,
    },
  });

  const showingFrom = () => currentPage * pageSize - pageSize + 1;
  const showingTo = (limit: number) =>
    currentPage * pageSize > limit ? limit : currentPage * pageSize;

  const sortCompareFn = () => {
    switch (sortOrder) {
      case PatientReviewsSortOrder.MOST_RECENT:
        return sortReviewsMostRecent;
      case PatientReviewsSortOrder.HIGHEST_RATING:
        return sortReviewsHighestRating;
      default:
        return sortReviewsLowestRating;
    }
  };

  const sortedReviews = (healthgradesReview.surveyResults?.surveys || []).sort(
    sortCompareFn()
  );

  return (
    <div style={RatingSectionContainerStyles}>
      {healthgradesReview?.surveyResults ? (
        <div>
          <PatientReviewsOverviewSection
            healthgradesReview={healthgradesReview}
          />
          {healthgradesReview?.surveyResults?.surveys?.length ? (
            <div>
              <Flex
                css={verbatimHeader}
                direction="row"
                justify="space-between"
              >
                <ContainerSubHeading>
                  <TextSubHeadingWhatPatientsAreSaying>
                    {t(
                      'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING'
                    )}
                  </TextSubHeadingWhatPatientsAreSaying>
                </ContainerSubHeading>
                {mobileScreen && (
                  <div style={{ display: 'flex' }}>
                    <Button
                      css={sortButtonMobileStyles}
                      data-auto-testid="result-sort-mobile"
                      data-testid="result-sort-mobile"
                      onClick={handleOnClick}
                      variant="outline"
                    >
                      <Layout.Group>
                        <IconMaterial css={swapIconStyles} icon="swap_vert" />
                        <Text color="$primary1" fontWeight={600}>
                          {t('Sort')}
                        </Text>
                      </Layout.Group>
                    </Button>
                  </div>
                )}
              </Flex>
              <Flex
                alignItems="flex-end"
                css={{
                  '@screen < $sm': {
                    marginBottom: '0px',
                  },
                }}
                direction="row"
                justify="space-between"
              >
                <SectionContainer>
                  <Flex alignItems="center" css={{ gap: '$sm' }}>
                    <FormProvider
                      data-auto-testid="set-page-size-reviews"
                      data-testid="set-page-size-reviews"
                      onSubmit={setPageSize}
                      state={form}
                    >
                      {!mobileScreen && (
                        <Layout.Group>
                          <Text css={showingVerbatimCountTextStyles}>
                            {t('Results per page')}
                          </Text>
                          <DropdownMenu
                            after={<IconMaterial icon="keyboard_arrow_down" />}
                            label={pageSize}
                            menuItems={[
                              {
                                value: pageSize,
                                onChange: setPageSize,
                                model: 'dropdown-menu-radios',
                                radios: [
                                  {
                                    label: 5,
                                    value: 5,
                                  },
                                  {
                                    label: 10,
                                    value: 10,
                                  },
                                  {
                                    label: 25,
                                    value: 25,
                                  },
                                ],
                              },
                            ]}
                            outline
                          />
                        </Layout.Group>
                      )}
                    </FormProvider>
                    {!mobileScreen && (
                      <Pagination
                        {...paginationProps}
                        data-auto-testid="pagination-component-desktop"
                        data-testid="pagination-component-desktop"
                        gotoPage={gotoPage}
                        pageSize={pageSize}
                        variant="compact"
                      />
                    )}
                  </Flex>
                  {!mobileScreen && (
                    <div style={{ marginTop: '10px', lineHeight: '18px' }}>
                      <Text css={showingResultsCountTextStyles}>
                        {`${t('Showing')} ${showingFrom()} - ${showingTo(
                          healthgradesReview.surveyResults?.surveys?.length
                        )} ${t('of')} ${
                          healthgradesReview.surveyResults?.surveys?.length
                        } ${t('results')}`}
                      </Text>
                    </div>
                  )}
                </SectionContainer>

                <AllPatientReviewsSortPopover
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setSortOrder={setSortOrder}
                  sortOrder={sortOrder}
                />
              </Flex>

              <Flex css={viewAllVerbatimFlexStyles}>
                {sortedReviews
                  .slice(pageIndex * pageSize, currentPage * pageSize)
                  .map((survey) => (
                    <PatientVerbatimCard
                      key={survey.id}
                      setReviewModal={setReviewModal}
                      survey={survey}
                    />
                  ))}
              </Flex>
              {pageCount >= 1 && (
                <PaginationComponentStyled
                  {...paginationProps}
                  data-auto-testid="pagination-component-mobile"
                  data-testid="pagination-component-mobile"
                  gotoPage={gotoPage}
                  pageSize={pageSize}
                  variant="extended"
                />
              )}
              <DisclaimersContainer hideDivider />
            </div>
          ) : null}
        </div>
      ) : (
        <Text>
          {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.NO_REVIEWS_FOUND')}
        </Text>
      )}
    </div>
  );
};
