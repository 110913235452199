import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { CountySearchContext } from '../../../context/CountySearchContext';
import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useAutoCompleteQuery } from '../../../hooks/useAutoCompleteQuery';
import { useEventCapture } from '../../../hooks/useEventCapture';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../hooks/useLagoon';
import {
  getCoverageTypes,
  getCurrentMember,
  getMemberCoverage,
} from '../../../utils/user.utils';
import { adobeImpressionTrackEvent } from '../../AdobeTagging/adobeImpressionTrackEvent';
import { Constants, OPTUM_CORRELATION_ID_HEADER } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { mobileOnly } from '../../ConstantsStyles';
import { isCounty } from '../../SnackCardContainer/utils';
import { convertTypeaheadProviderIdAndType } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { getIntValue } from '../../Utils/getIntValue';
import { Breadcrumb } from '../Breadcrumb';
import { KeywordSearchDropdown } from './KeywordSearchDropdown';
import { LocationDropdown } from './LocationDropdown';
import { ChangeLocationForm } from './LocationDropdown/ChangeLocation';
import { ChangeLocationDrawer } from './LocationDropdown/LocationDropdown.styled';
import { LocationInput } from './LocationInput';
import { MobileSetLocation } from './MobileSetLocation';
import { DarkeningOverlay, InputBox, PositioningBox } from './SearchBar.styled';
import { SearchPlacement } from './SearchPlacement';
import {
  getGeoLocationFromStorage,
  getTypeAheadResultTrackingData,
  handleKeywordSuggestionKeyDownHelper,
  setGeoLocationToStorage,
  setTypeAheadResults,
} from './utils';

type Props = {
  choosePCPHeader?: boolean;
  memberLocation?: string;
  showSearchInputBackButton?: boolean;
  breadcrumbs?: any[];
};

export const SearchBar = ({
  choosePCPHeader,
  memberLocation = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.MEMBER_LOCATION
  ) ?? '',
  showSearchInputBackButton = false,
  breadcrumbs = [],
}: Props) => {
  let network: string;
  const language = getLanguage()?.code || 'en';
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const keywordSearchInputId = useId();
  const currentMember = getCurrentMember();
  const mobileScreen = useMediaQuery(mobileOnly);
  const data = useLagoon('ui-messaging')();
  const typeaheadParams = useLagoon(Constants.LAGOON_TABLE.TYPEAHEAD_PARAMS)();
  const limitSpl = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_SPECIALTIES,
    Constants.TYPEAHEAD_PARAMS.LIMIT_SPECIALTIES_DEFAULT
  );
  const limitOrg = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_ORG,
    Constants.TYPEAHEAD_PARAMS.LIMIT_ORG_DEFAULT
  );
  const limitPrn = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.LIMIT_PRACTITIONER,
    Constants.TYPEAHEAD_PARAMS.LIMIT_PRACTITIONER_DEFAULT
  );
  const radius = getIntValue(
    typeaheadParams,
    Constants.TYPEAHEAD_PARAMS.RADIUS,
    Constants.TYPEAHEAD_PARAMS.RADIUS_DEFAULT
  );

  const { getRouteParams, navigate } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { search, searchTerm, fromViewAll } = tokenData;
  const defaultSearchValue = fromViewAll ? '' : search;
  const [isFocusedOnKeywordSearchInput, setIsFocusedOnKeywordSearchInput] =
    useState(false);
  const [isFocusedOnLocationSearch, setIsFocusedOnLocationSearch] =
    useState(false);
  const [isOpenMobileLocation, setIsOpenMobileLocation] = useState(false);
  const [keywordSearchTerm, setKeywordSearchterm] = useState(searchTerm || '');
  const { setIsCountySearch } = useContext(CountySearchContext);
  let searchText: string;
  const {
    id,
    name = '',
    latitude: geoLat,
    longitude: geoLong,
    zipCode,
    stateCode,
  } = getGeoLocationFromStorage();
  const [locationInputVal, setLocationInputVal] = useState(name);
  const [headers, setHeaders] = useSessionStorage<any>(
    Constants.STORAGE_KEYS.SESSION.TYPE_AHEAD_HEADERS,
    {}
  );
  if (isCounty(id)) setIsCountySearch(true);
  const [results, setResults] = useState<any>('');
  const [combineRollUpCodes, setCombineRollUpCodes] = useState<any>('');
  const [enter, setEnter] = useState(false);
  let typeaheadRequestData = {};

  const [locationSuggestions, setLocationSuggestions] = useState<object[]>();
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [isKeywordSearchLoading, setIsKeywordSearchLoading] = useState(false);
  const [searchButtonResults, setSearchButtonResults] = useState<any>([]);
  const [autoCompleteCallCompleted, setAutoCompleteCallCompleted] =
    useState<any>(false);
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const commonSearchesLagoon: () => any = useLagoon('common-searches');
  const commonSearchesData = commonSearchesLagoon();
  const memberCoverages = getCoverageTypes(currentMember);
  const dentalCoverage = memberCoverages?.find((cov) => cov === 'D');
  const visionCoverage = memberCoverages?.find((cov) => cov === 'V');
  const behavioralCoverage = memberCoverages?.find((cov) => cov === 'B');
  const medicalCoverage = memberCoverages?.find((cov) => cov === 'M');
  const getCoverageType = [
    dentalCoverage,
    visionCoverage,
    medicalCoverage,
    behavioralCoverage,
  ];
  const [captureResults] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.AUTOCOMPLETE_CAPTURE_RESULTS,
  ]);

  const searchInputOptionLocation = 'search-input-option-list';

  const [cursor, setCursor] = useState(-1);

  const searchLabelText = find(data, {
    key: ConstantsLagoon.SEARCH_LABEL,
  });

  const isShowingKeywordSearchDropdown = isFocusedOnKeywordSearchInput;
  const isShowingLocationDropdown = isFocusedOnLocationSearch && !mobileScreen;

  const setKeywordSearchInputFocused = () => {
    setIsFocusedOnKeywordSearchInput(true);
    setIsFocusedOnLocationSearch(false);
  };

  const setKeywordSearchInputBlur = () => {
    setIsFocusedOnKeywordSearchInput(false);
  };

  const setLocationInputBlur = () => {
    setIsFocusedOnLocationSearch(false);
  };

  const handleKeywordSearchInputFocus = (value) => {
    setKeywordSearchInputFocused();
    setCursor(-1);
    setEnter(false);
    setResults([]);
    setAutoCompleteCallCompleted(false);
    handleKeywordSearchInputChange(value);
  };

  const handleCloseLocationDropdown = () => {
    setKeywordSearchInputBlur();
    setLocationInputBlur();
  };

  const handleCloseLocationDrawer = () => {
    setKeywordSearchInputBlur();
    setLocationInputBlur();
  };

  const setLocationInputFocused = () => {
    setIsFocusedOnLocationSearch(true);
    setIsFocusedOnKeywordSearchInput(false);
  };

  const handleLocationSearchInputFocus = () => {
    setLocationInputFocused();
    setCursor(-1);
  };

  const handleLocationSearchTextChange = (value: string) => {
    setCursor(-1);
    setIsFocusedOnLocationSearch(true);
    setLocationInputVal(value);
  };
  const [, SendEventCapture] = useEventCapture({
    onCompleted: (result) => result,
    onError: () => {},
  });
  const handleEventCapture = async (
    typeAheadSource,
    typeAheadFilterDataResponse,
    typeAheadOpperation,
    typeAheadCorrelationId,
    typeAheadRequestForEventCapture
  ) => {
    SendEventCapture({
      variables: {
        source: typeAheadSource,
        results: JSON.stringify(
          getTypeAheadResultTrackingData(typeAheadFilterDataResponse)
        ),
        operationName: typeAheadOpperation,
        correlationId: typeAheadCorrelationId,
        request: JSON.stringify(typeAheadRequestForEventCapture),
      },
    });
  };
  const [typeAheadData, GetSearchSuggestions] = useAutoCompleteQuery({
    onCompleted: (response) => {
      setIsKeywordSearchLoading(false);
      const suggestions =
        response?.data?.autoComplete?.lang_provider?.langProvider;
      const concatenatedRollupCodes =
        response?.data?.autoComplete?.lang_provider?.specialityRollUpCodes;
      const providers =
        response?.data?.autoComplete?.practitioners_uhc?.provData;
      const facilities =
        response?.data?.autoComplete?.organizations_uhc?.orgData;
      setSearchButtonResults(suggestions?.concat(providers).concat(facilities));
      const parsedResults = setTypeAheadResults(
        suggestions,
        providers,
        facilities,
        network
      );
      const correlationId = response?.headers[OPTUM_CORRELATION_ID_HEADER];

      setHeaders({
        correlationId,
      });
      setCombineRollUpCodes(concatenatedRollupCodes);
      setResults(parsedResults);
      setAutoCompleteCallCompleted(true);
      adobeImpressionTrackEvent({
        searchTerm: searchText,
        type: 'typeahead search',
        message:
          parsedResults.length === 0
            ? 'no results found for your search'
            : 'results found for your search',
        customAttributesBlock: {
          correlationId,
          searchLocation: zipCode || name,
        },
      });

      const filterData = response?.data?.autoComplete;

      if (captureResults) {
        handleEventCapture(
          'UES',
          filterData,
          'typeahead',
          correlationId,
          typeaheadRequestData
        );
      }
    },
    onError: () => {
      setResults(null);
    },
  });

  const handleKeywordSearchInputChange = useCallback(
    debounce(async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setKeywordSearchterm(value.trim());
      setEnter(false);
      searchText = value.trim();
      setCursor(-1);
      setResults([]);
      if (value.replace(/\s/g, '').length > 1 && /[A-Za-z0-9]/.test(value)) {
        network = getMemberCoverage(currentMember, featureFlags);

        typeaheadRequestData = {
          query: encodeURIComponent(value.trim()),
          network,
          latitude: geoLat,
          longitude: geoLong,
          lang: language,
          limitSpl,
          limitOrg,
          limitPrn,
          radius,
        };
        GetSearchSuggestions({
          variables: typeaheadRequestData,
        });
      }
    }, 400),
    [geoLat, geoLong, currentMember]
  );

  const isShowingLocationDrawer = useMemo(
    () => isFocusedOnLocationSearch && mobileScreen,
    [isFocusedOnLocationSearch, mobileScreen]
  );

  const getTypeaheadData = () =>
    cursor === -1 ? searchButtonResults?.[0] : searchButtonResults?.[cursor];

  const handleKeywordSuggestionKeyDown = (ev: {
    key: string;
    preventDefault: () => void;
    target: { value: string };
  }) => {
    handleKeywordSuggestionKeyDownHelper(ev, {
      keywordSearchTerm,
      commonSearchesData,
      isKeywordSearchLoading,
      searchButtonResults,
      setEnter,
      cursor,
      results,
      combineRollUpCodes,
      token,
      navigate,
      getTypeaheadData,
      headers,
      setCursor,
      convertTypeaheadProviderIdAndType,
      setIsFocusedOnKeywordSearchInput,
      searchInputOptionLocation,
      memberCoverages,
    });
  };

  const searchInputRef = useRef<HTMLInputElement>(null);
  const blurSearchInput = () => {
    searchInputRef?.current?.blur();
  };

  useEffect(() => {
    if (searchInputRef.current) searchInputRef.current.value = '';
  }, [currentMember]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutsideInput = wrapperRef.current
        ? !wrapperRef.current.contains(event.target)
        : true;
      if (isOutsideInput && !isShowingLocationDrawer) {
        setKeywordSearchInputBlur();
        setLocationInputBlur();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleClickOutside);
    };
  }, [wrapperRef, isShowingLocationDrawer]);

  return (
    <React.Fragment>
      {mobileScreen && isShowingKeywordSearchDropdown && <DarkeningOverlay />}
      <PositioningBox ref={wrapperRef}>
        {mobileScreen && showSearchInputBackButton ? (
          <Breadcrumb breadcrumbs={breadcrumbs} />
        ) : null}
        <InputBox
          hasShadow={isShowingKeywordSearchDropdown}
          isSharpBottomRightCorner={isShowingLocationDropdown}
          mobileScreen={mobileScreen}
        >
          <SearchPlacement
            choosePCPHeader={choosePCPHeader}
            commonSearchesData={commonSearchesData}
            defaultSearchValue={defaultSearchValue}
            dentalCoverage={dentalCoverage}
            handleKeywordSearchInputChange={handleKeywordSearchInputChange}
            handleKeywordSearchInputFocus={handleKeywordSearchInputFocus}
            handleKeywordSuggestionKeyDown={handleKeywordSuggestionKeyDown}
            isFocusedOnKeywordSearchInput={isFocusedOnKeywordSearchInput}
            keywordSearchInputId={keywordSearchInputId}
            keywordSearchTerm={keywordSearchTerm}
            searchButtonResults={searchButtonResults}
            searchInputRef={searchInputRef}
            searchLabelText={searchLabelText}
            setIsKeywordSearchLoading={setIsKeywordSearchLoading}
            setResults={setResults}
            setSearchButtonResults={setSearchButtonResults}
            visionCoverage={visionCoverage}
          />

          {isShowingKeywordSearchDropdown && (
            <KeywordSearchDropdown
              activeSuggestion={cursor}
              autoCompleteCallCompleted={autoCompleteCallCompleted}
              blurKeywordSearchInput={blurSearchInput}
              dentalCoverage={dentalCoverage}
              enter={enter}
              getCoverageType={getCoverageType}
              headers={headers}
              isKeywordSearchLoading={isKeywordSearchLoading}
              isLoading={typeAheadData?.isLoading}
              keywordSearchTerm={keywordSearchTerm}
              medicalCoverage={medicalCoverage}
              setIsFocusedOnKeywordSearchInput={
                setIsFocusedOnKeywordSearchInput
              }
              typeAheadSuggestions={results}
              visionCoverage={visionCoverage}
            />
          )}
        </InputBox>

        <LocationInput
          combinedRollupCodes={combineRollUpCodes}
          cursor={cursor}
          handleCloseLocationDrawer={handleCloseLocationDrawer}
          handleCloseLocationDropdown={handleCloseLocationDropdown}
          headers={headers}
          isOpenMobileLocation={isOpenMobileLocation}
          isShowingLocationDrawer={isShowingLocationDrawer}
          isShowingLocationDropdown={isShowingLocationDropdown}
          locationValue={locationInputVal}
          memberLocation={memberLocation}
          onLocationSearchInputFocus={handleLocationSearchInputFocus}
          onLocationSearchInputTextChange={handleLocationSearchTextChange}
          placeHolderValue={name}
          searchTerm={keywordSearchTerm}
          setCursor={setCursor}
          setIsFocusedOnKeywordSearchInput={setIsFocusedOnKeywordSearchInput}
          setIsLocationLoading={setIsLocationLoading}
          setIsOpenMobileLocation={setIsOpenMobileLocation}
          setLocationInputVal={setLocationInputVal}
          setLocationSuggestions={setLocationSuggestions}
          typeAheadSuggestions={results}
        />

        {isShowingLocationDropdown && (
          <LocationDropdown
            activeSuggestion={cursor}
            data-testid="location-search-bar-dropdown"
            isLocationLoading={isLocationLoading}
            locationInputVal={locationInputVal}
            locationSuggestions={locationSuggestions}
            onClose={handleCloseLocationDropdown}
            setLocationInputVal={setLocationInputVal}
            setSelectedLocation={(targetLocation) => {
              const {
                id: locationId,
                center = [],
                place_name: placeName,
                stateCode: targetStateCode,
                zipCode: targetZipCode,
              } = targetLocation;
              const [selectedLong, selectedLat] = center;
              if (isCounty(locationId)) setIsCountySearch(true);
              else setIsCountySearch(false);
              setGeoLocationToStorage({
                id: locationId,
                name: placeName,
                longitude: selectedLong,
                latitude: selectedLat,
                stateCode: targetStateCode,
                zipCode: targetZipCode,
              });
            }}
          />
        )}

        <ChangeLocationDrawer
          isOpen={isShowingLocationDrawer}
          onClose={handleCloseLocationDrawer}
          position="bottom"
          size="$lg"
          title={t('Change Location')}
        >
          <ChangeLocationForm
            data-testid="change-location-form"
            onLocationSelect={handleCloseLocationDrawer}
          />
        </ChangeLocationDrawer>
      </PositioningBox>
      {mobileScreen && (
        <MobileSetLocation
          locationInputVal={locationInputVal}
          setIsOpenMobileLocation={setIsOpenMobileLocation}
          stateCode={stateCode}
          zipCode={zipCode}
        />
      )}
    </React.Fragment>
  );
};
