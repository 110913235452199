import { config } from '@abyss/web/tools/config';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';

import { LagoonProvider } from '../../../../lagoon';
import { IChildrenProp } from '../../types';
import { getLanguage } from '../Internationalization/helpers';

const localeCode = getLanguage()?.code || 'en';

const lagoonEnvironment = config('LAGOON_ENV');

export const Configuration = ({ children }: IChildrenProp) => (
  <LagoonProvider
    app="global-provider-search"
    env={lagoonEnvironment}
    loadingIcon={<LoadingSpinner ariaLoadingLabel="Importing data" isLoading />}
    locale={localeCode}
  >
    {children}
  </LagoonProvider>
);
