import { adobeLinkTrackEvent } from '../../common/AdobeTagging/adobeLinkTrackEvent';
import {
  pageRanksForBrowserBackButton,
  testIdAndPageNameMap,
} from '../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { StoreKeys } from '../useStore/state';
import { useStore } from '../useStore/useStore';

export const useAdobeBrowserBackButtonTrack = () => {
  const {
    currentPageName,
    currentPageRank,
    previousPageName,
    previousPageRank,
  } = useStore(StoreKeys.NAVIGATION_HISTORY);
  const setNavigationHistory = useStore(StoreKeys.SET_NAVIGATION_HISTORY);
  const hasBreadCrumbClickedFlag = useStore(StoreKeys.BREADCRUMB_CLICKED_FLAG);
  const setHasBreadCrumbClickedFlag = useStore(
    StoreKeys.SET_BREADCRUMB_CLICKED_FLAG
  );

  const adobeBrowserBackButtonTrack = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(testIdAndPageNameMap)) {
      const pageSelector = document?.querySelector(`
        [data-auto-testid=${key}]`);

      const newPageRank = pageRanksForBrowserBackButton?.find(
        (p) => p.pageName === value
      )?.rank;

      if (pageSelector) {
        // 1. if, breadcrumb link clicked, skip browser back button check
        // 2. else, check the new page rank lessthan previous page -> consider browser back button
        // OR
        // if the user selects breadcrum in between, checking again pageRank might not enough. so compare with previousPageName & previousPageRank
        if (hasBreadCrumbClickedFlag) {
          setHasBreadCrumbClickedFlag(false);
        } else if (
          (newPageRank && newPageRank < currentPageRank) ||
          (newPageRank &&
            value === previousPageName &&
            newPageRank === previousPageRank)
        ) {
          adobeLinkTrackEvent({
            name: 'browser back button',
            location: `${currentPageName} page`,
            type: 'internal',
          });
        }

        setNavigationHistory({
          previousPageName: currentPageName,
          previousPageRank: currentPageRank,
          currentPageName: value,
          currentPageRank: newPageRank,
        });

        break;
      }
    }
  };

  return { adobeBrowserBackButtonTrack };
};
