import { Fragment, useEffect, useState } from 'react';

import { I18nextProvider, i18n } from '../../../../common/Utils/i18n/i18n';
import { IChildrenProp } from '../../types';
import { initializeLanguage } from './helpers';

export const Internationalization = ({ children }: IChildrenProp) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const language = initializeLanguage();
    i18n.changeLanguage(language.locale);
    setIsLoaded(true);
  }, []);

  if (!isLoaded) return null;

  return (
    <I18nextProvider i18n={i18n}>
      <Fragment>{children}</Fragment>
    </I18nextProvider>
  );
};
