import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';
import { useState } from 'react';
import React from 'react';

import { StoreKeys } from '../../../../../hooks/useStore/state';
import { useStore } from '../../../../../hooks/useStore/useStore';
import {
  HealthgradesProviderReview,
  Survey,
} from '../../../../../models/Healthgrades';
import { getPatientCardLength } from '../../../../../utils/providerDetails.utils';
import { widgetModalEvent } from '../../../../../utils/widgetEvents.utils';
import { Constants } from '../../../../Constants';
import { ContentWrapper } from '../../../../ContentWrapper';
import { PatientReviewMobileCarousel } from '../../../../ProviderDetailTabs/About/PatientReviews/PatientReviewMobileCarousel';
import { PatientReviewsModal } from '../../../../ProviderDetailTabs/About/PatientReviews/PatientReviewModal';
import {
  RatingSectionContainerStyles,
  StyledIcon,
  modalStyles,
  verbatimContainerStyles,
} from '../../../../ProviderDetailTabs/About/PatientReviews/PatientReviews.styled';
import { PatientReviewsOverviewSection } from '../../../../ProviderDetailTabs/About/PatientReviews/PatientReviewsOverviewSection';
import { PatientReviewsViewAll } from '../../../../ProviderDetailTabs/About/PatientReviews/PatientReviewsViewAll';
import { PatientVerbatimCard } from '../../../../ProviderDetailTabs/About/PatientReviews/PatientVerbatimCard';
import {
  AboutHeading,
  ReviewsContainer,
  ReviewsContentContainer,
} from './About.styled';
import { PatientReviewsErrorHandlingWidget } from './PatientReviewsErrorHandlingWidget';

type Props = {
  healthgradesReview: HealthgradesProviderReview;
  isLoading: boolean;
  providerName: string;
  isReviewError: boolean;
  setViewAllReviews(val: boolean): void;
};
export const PatientReviews = ({
  healthgradesReview,
  isLoading,
  providerName,
  setViewAllReviews,
  isReviewError,
}: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const [reviewModal, setReviewModal] = useState<Survey | null>(null);

  const reviewsLength = healthgradesReview.surveyResults?.surveys?.length || 0;
  const displayCarousel = getPatientCardLength(reviewsLength, isWidget);

  const displayViewAll = healthgradesReview?.surveyResults?.surveyCount
    ? healthgradesReview?.surveyResults?.surveyCount > 4
    : false;

  const handleViewAllOnClick = () => {
    widgetModalEvent({ pageName: 'reviews view all' });
    setViewAllReviews(true);
  };

  const noReview = () =>
    !isReviewError ? (
      <Text>
        {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.NO_REVIEWS_FOUND')}
      </Text>
    ) : (
      <PatientReviewsErrorHandlingWidget />
    );

  return (
    <ReviewsContainer>
      <ContentWrapper isWidget={isWidget}>
        <ReviewsContentContainer data-testid="patient-review-section">
          {reviewModal ? (
            <Modal
              css={modalStyles}
              isOpen={!!reviewModal}
              onClose={() => setReviewModal(null)}
            >
              <Modal.Section>
                <PatientReviewsModal review={reviewModal} />
              </Modal.Section>
            </Modal>
          ) : null}
          <StyledIcon
            icon="healthgrades"
            isScreenReadable
            size={132}
            title={t('HEALTH_GRADES_LOGO_TITLE')}
          />
          <AboutHeading
            data-auto-testid="patient-review-heading"
            data-testid="patient-review-heading"
            display="h4"
            offset={1}
          >
            {`${t(
              'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING_ABOUT'
            )} ${providerName}`}
          </AboutHeading>

          {isLoading ? (
            <LoadingSpinner
              ariaLoadingLabel="loading results"
              isLoading={isLoading}
              size="$sm"
            />
          ) : (
            <div
              data-auto-testid="reviews-container"
              data-testid="reviews-container"
              style={RatingSectionContainerStyles}
            >
              {healthgradesReview.surveyResults ? (
                <div>
                  <PatientReviewsOverviewSection
                    healthgradesReview={healthgradesReview}
                  />
                  {healthgradesReview.surveyResults?.surveys?.length ? (
                    <div>
                      <Flex
                        alignContent="space-between"
                        alignItems="baseline"
                        css={{ gap: '16px' }}
                        justify="start"
                      >
                        <Heading
                          css={{
                            '&.abyss-heading-root': {
                              height: '20px !important',
                              fontSize: '16px !important',
                              lineHeight: '20px !important',
                            },
                          }}
                          data-auto-testid="what-patients-are-saying-subtitle"
                          data-testid="what-patients-are-saying-subtitle"
                          display="h6"
                          offset={2}
                        >
                          {t(
                            'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING'
                          )}
                        </Heading>
                        <PatientReviewsViewAll
                          displayViewAll={displayViewAll}
                          handleViewAllOnClick={handleViewAllOnClick}
                          locationForAnalytics={`body:${Constants.PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING}`}
                        />
                      </Flex>
                      {displayCarousel ? (
                        <PatientReviewMobileCarousel
                          healthgradesReview={healthgradesReview}
                          setReviewModal={setReviewModal}
                        />
                      ) : (
                        <Flex
                          alignItems="flex-end"
                          css={verbatimContainerStyles}
                        >
                          {healthgradesReview.surveyResults.surveys
                            .slice(0, 4)
                            .map((survey) => (
                              <PatientVerbatimCard
                                key={survey.id}
                                setReviewModal={setReviewModal}
                                survey={survey}
                              />
                            ))}
                        </Flex>
                      )}
                    </div>
                  ) : null}
                </div>
              ) : (
                noReview()
              )}
            </div>
          )}
        </ReviewsContentContainer>
      </ContentWrapper>
    </ReviewsContainer>
  );
};
