import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../../../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../../../../../../common/ConstantsStyles';
import { TierProviderText } from '../../../../../../../../../common/ProviderDetailTabs/ProviderLocationsDetails/ProviderLocationsDetails.style';
import { PSXIcon } from '../../../../../../../../../common/PSXIcon';
import { TierOneSnackCardPopoverStyles } from '../../../../../../../../../common/SnackCard/SnackCard.styles';
import { TieredDrawerStyles } from '../../../../../../../../../common/TierBenefitsCard/TierBenefitsCard.style';
import { handleLinkAndModalTrack } from '../../../../../../../../../common/Utils/adobeTrackUtils';
import { useLagoon } from '../../../../../../../../../hooks/useLagoon';
import { getStatusText } from '../../../../../ShareDrawer/utils';
import {
  StyledNullAttributeDiv,
  popoverStyle,
} from '../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../utility/compareDrawerConstants';

type Props = {
  isTieredProvider: boolean;
  indexForAnalytics: number | undefined;
};

export const TierOneProviderText = ({
  isTieredProvider,
  indexForAnalytics,
}: Props) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(phoneOnly);
  const [isOpen, setIsOpen] = useState(false);
  const indexAdobe =
    indexForAnalytics && indexForAnalytics >= 0 ? indexForAnalytics + 1 : 1;
  const locationForAdobe = `modal:compare card:position ${indexAdobe}`;
  const tierOnePopOverDataTestId: string = mobile
    ? 'Tier1-provider-Drawer'
    : 'Tier1-provider-popover';

  const tierOneProviderText = getStatusText(
    isTieredProvider,
    ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.TRUE,
    ATTRIBUTE_STATUS_TEXT.TIER_ONE_PROVIDER.FALSE,
    NULL_ATTRIBUTE_STATE.TIER_ONE_PROVIDER
  );

  useEffect(() => {
    if (isOpen) {
      const linkName: string = tierOnePopOverDataTestId;
      const modalName: string = 'tier 1 providers';
      handleLinkAndModalTrack(linkName, locationForAdobe, modalName);
    }
  }, [isOpen]);

  const data = useLagoon('ui-messaging')();
  const tierOneContentOne = find(data, {
    key: ConstantsLagoon.MEDICAL_SPECIALISTS.TIER_ONE_BENEFITS_ONE,
  });
  const tierContent = tierOneContentOne?.message;

  if (isTieredProvider) {
    return (
      <TierProviderText>
        {!mobile ? (
          <Popover
            content={tierContent ?? t('TIERED_BENEFITS.TIER_CONTENT')}
            css={TierOneSnackCardPopoverStyles}
            data-auto-testid={tierOnePopOverDataTestId}
            data-testid={tierOnePopOverDataTestId}
            onOpenChange={() => {
              setIsOpen(!isOpen);
            }}
            title="Tier 1 Providers"
            width={214}
          >
            <Layout.Group>
              <Layout.Group css={{ backgroundColor: '#F0F8E4' }}>
                <PSXIcon icon="Group 204976975" size={16} />
                <Text
                  css={{ fontWeight: 'bold' }}
                  data-auto-testid="Tier1-provider-popover-text"
                  data-testid="Tier1-provider-popover-text"
                >
                  {t(tierOneProviderText)}
                </Text>
              </Layout.Group>
              <IconMaterial
                data-auto-testid="Tier1-provider-popover-textInfo"
                data-testid="Tier1-provider-popover-textInfo"
                icon="info"
                size={16}
                variant="outlined"
              />
            </Layout.Group>
          </Popover>
        ) : (
          <React.Fragment>
            <Drawer
              css={TieredDrawerStyles}
              data-auto-testid={tierOnePopOverDataTestId}
              data-testid={tierOnePopOverDataTestId}
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              position="bottom"
              size="200"
              title="Tier 1 Providers"
            >
              {tierContent ?? t('TIERED_BENEFITS.TIER_CONTENT')}
            </Drawer>
            <Layout.Group>
              <Layout.Group css={{ backgroundColor: '#F0F8E4' }}>
                <PSXIcon icon="Group 204976975" size={16} />
                <Text
                  css={{ fontSize: '14px', fontWeight: 'bold' }}
                  data-auto-testid="Tier1-text"
                  data-testid="Tier1-text"
                  onClick={() => setIsOpen(true)}
                >
                  {t(tierOneProviderText)}
                </Text>
              </Layout.Group>
              <IconMaterial
                data-auto-testid="Tier1-textInfo"
                data-testid="Tier1-textInfo"
                icon="info"
                onClick={() => setIsOpen(true)}
                size={16}
                variant="outlined"
              />
            </Layout.Group>
          </React.Fragment>
        )}
      </TierProviderText>
    );
  }

  // Not a tier one provider or information not available
  return (
    <React.Fragment>
      {isTieredProvider === false ? (
        <Text css={{ fontWeight: 'bold' }}>{t(tierOneProviderText)}</Text>
      ) : mobile ? (
        <Popover
          content={t(tierOneProviderText)}
          css={popoverStyle}
          showClose={false}
          tabIndex="-1"
          width={150}
        >
          <StyledNullAttributeDiv>
            {t(tierOneProviderText)}
          </StyledNullAttributeDiv>
        </Popover>
      ) : (
        <StyledNullAttributeDiv>
          {t(tierOneProviderText)}
        </StyledNullAttributeDiv>
      )}
    </React.Fragment>
  );
};
