import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../../../hooks/useLagoon';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { mobileOnly, phoneOnly } from '../../../ConstantsStyles';
import {
  GoodToKnowText,
  StyledButton,
  StyledIcon,
  StyledModalFooter,
  StyledSectionContainer,
  StyledSupportingText,
  StyledText,
} from './MemberSelectionConfirmationModal.styled';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (key: boolean) => void;
  handleConfirmMemberSelectionChange: () => void;
  adobeLocation: string;
};

export const MemberSelectionConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  handleConfirmMemberSelectionChange,
  adobeLocation,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const tabletScreen = useMediaQuery(mobileOnly);
  const data = useLagoon('ui-messaging')();

  const memberSelectionConfirmationModalHeader = find(data, {
    key: ConstantsLagoon.MEMBER_SELECTION_CONFIRMATION_MODAL
      .MEMBER_SELECTION_MODAL_BODY_HEADER,
  });

  const pcpSectionContent = find(data, {
    key: ConstantsLagoon.MEMBER_SELECTION_CONFIRMATION_MODAL
      .MEMBER_SELECTION_MODAL_PCP_CONTENT,
  });

  const careSectionContent = find(data, {
    key: ConstantsLagoon.MEMBER_SELECTION_CONFIRMATION_MODAL
      .MEMBER_SELECTION_MODAL_CARE_SECTION_CONTENT,
  });
  const schedulingContent = find(data, {
    key: ConstantsLagoon.MEMBER_SELECTION_CONFIRMATION_MODAL
      .MEMBER_SELECTION_MODAL_SCHEDULING_SECTION_CONTENT,
  });

  return (
    <React.Fragment>
      <Modal
        closeOnClickOutside={false}
        closeOnEscPress={false}
        css={{
          'abyss-modal-header-container': {
            marginTop: 0,
            padding: 0,
          },
          'abyss-modal-close-button': {
            height: '52px',
            display: 'flex',
            padding: '16px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            right: '0px',
            top: '0px',
          },
          'abyss-modal-content-title': {
            display: 'none',
          },
          'abyss-modal-content-container': {
            height: mobileScreen ? '526px' : 'auto',
          },
          'abyss-modal-close-icon': {
            fill: '#1F2937',
            height: mobileScreen ? '20px' : '24px',
            width: mobileScreen ? '20px' : '24px',
          },
          'abyss-modal-body': {
            display: 'flex',
            gap: '418px',
            paddingTop: '52px',
          },
          '.abyss-modal-section-root': {
            paddingTop: mobileScreen ? '0px' : '16px',
          },
          '.abyss-modal-footer': {
            position: 'absolute',
            bottom: 0,
          },
        }}
        data-auto-testid="member-selection-confirmation-modal"
        data-testid="member-selection-confirmation-modal"
        footer={
          <StyledModalFooter>
            <StyledButton
              data-testid="member-selection-modal-continue-button"
              onClick={() => {
                handleConfirmMemberSelectionChange();
              }}
            >
              {t('MEMBER_SELECTION_CONFIRMATION_MODAL.CONTINUE_BUTTON')}
            </StyledButton>
            <StyledButton
              data-testid="member-selection-modal-cancel-button"
              onClick={() => {
                adobeLinkTrackEvent({
                  name: 'cancel',
                  location: `modal:${adobeLocation}`,
                  type: 'internal',
                  destinationUrl: '',
                });
                setIsModalOpen(false);
              }}
              variant="outline"
            >
              {t('MEMBER_SELECTION_CONFIRMATION_MODAL.CANCEL_BUTTON')}
            </StyledButton>
          </StyledModalFooter>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size={mobileScreen ? '327px' : '624px'}
        title="Modal Title"
      >
        <StyledSupportingText>
          <StyledIcon>
            <IconMaterial
              css={{ textAlign: 'center' }}
              icon="person"
              size={mobileScreen ? '50px' : '84px'}
            />
          </StyledIcon>
          <Text
            css={{
              'abyss-text-root': {
                fontSize: mobileScreen ? '18px' : '22.78px',
                textAlign: 'center',
                paddingTop: tabletScreen ? '20px' : '0px',
              },
            }}
            fontWeight={700}
          >
            {memberSelectionConfirmationModalHeader?.message}
          </Text>
          <GoodToKnowText fontWeight={700}>
            {t('MEMBER_SELECTION_CONFIRMATION_MODAL.Good-to-know')}
          </GoodToKnowText>
          <StyledSectionContainer>
            <StyledText fontWeight={700}>
              {t('MEMBER_SELECTION_CONFIRMATION_MODAL.PCP_SECTION_TITLE')}
            </StyledText>
            <StyledText fontWeight={500}>
              {pcpSectionContent?.message}
            </StyledText>
          </StyledSectionContainer>
          <StyledSectionContainer>
            <StyledText fontWeight={700}>
              {t('MEMBER_SELECTION_CONFIRMATION_MODAL.CARE_EXP_SECTION_TITLE')}
            </StyledText>
            <StyledText fontWeight={500}>
              {careSectionContent?.message}
            </StyledText>
          </StyledSectionContainer>
          <StyledSectionContainer
            data-auto-testid="member-selection-scheduling-content"
            data-testid="member-selection-scheduling-content"
          >
            <StyledText fontWeight={700}>
              {t(
                'MEMBER_SELECTION_CONFIRMATION_MODAL.SCHEDULING_SECTION_TITLE'
              )}
            </StyledText>
            <StyledText fontWeight={500}>
              {schedulingContent?.message}
            </StyledText>
          </StyledSectionContainer>
        </StyledSupportingText>
      </Modal>
    </React.Fragment>
  );
};
