import { returnSuppressFlag } from '../../utils/featureSuppress';
import { FilterCollectionModel } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';

export const suppressFiltersByFeatureAndPolicy = (filters) => {
  const suppressReviews = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  if (suppressReviews) {
    const reviewsIndex = filters.findIndex(
      (index) => index.model === FilterCollectionModel.PATIENT_REVIEWS
    );
    filters.splice(reviewsIndex, 1);
  }

  // Premium Care is not a part of useProviderFilters.ts now,
  //   but if it gets added in the future, this is here to account
  //   for feature suppression by policy
  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );
  if (suppressPremiumCare) {
    const premiumCareIndex = filters.findIndex(
      (index) => index.model === FilterCollectionModel.PREMIUM_CARE_PHYSICIAN
    );
    filters.splice(premiumCareIndex, 1);
  }

  return filters;
};
