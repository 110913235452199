/* eslint-disable no-nested-ternary */
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { returnSuppressFlag } from '../../../../../utils/featureSuppress';
import {
  bhSpecificDefaultItems,
  defaultItems,
  dentalSpecificDefaultItems,
  visionSpecificDefaultItems,
} from '../../MapView/CompareDrawer/utility/compareDrawerConstants';

export const getDefaultCompareAttributes = (coverageType: string) => {
  let compareAttributeItems;
  switch (coverageType) {
    case 'B':
      compareAttributeItems = bhSpecificDefaultItems;
      break;
    case 'V':
      compareAttributeItems = visionSpecificDefaultItems;
      break;
    case 'D':
      compareAttributeItems = dentalSpecificDefaultItems;
      break;
    default:
      compareAttributeItems = defaultItems;
      break;
  }

  const suppressPremiumCare: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const suppressPatientReviews: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );

  return compareAttributeItems.filter(
    (item) =>
      (!suppressPremiumCare || item.name !== 'premiumCareProvider') &&
      (!suppressPatientReviews || item.name !== 'reviews')
  );
};
