import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { Provider } from '../../models/Provider';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import {
  getPremiumCarePhysician,
  getSuppressFacilityFlags,
  isShowPreferredFacilityBadge,
} from '../../utils/providerDetails.utils';
import { getLoggedInMember } from '../../utils/user.utils';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import {
  displayPremiumCareIdentifier,
  displayTier1Identifier,
} from '../DataCard/DataCardUtils';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { getFeatureFlag } from '../Utils';
import {
  convertProviderTypeToAdobeType,
  formatProviderId,
  handleAdobeSearchResultClickAndModalEvent,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  PremiumCareDrawerLinkStyle,
  PremiumCareDrawerStyles,
  PremiumCarePopoverLinkStyle,
  PremiumCareSnackCardPopoverStyles,
  PremiumInfoBox,
  PremiumPopoverText,
} from './PremiumCare.styles';
import { TierCardText } from './SnackCard.styles';
import { TierOne } from './TierOne';

export enum CardInfoVariants {
  PREMIUM_CARE = 'premium-care',
  PREFERRED_FACILITY = 'preferred-facility',
}

type Props = {
  cardType: string;
  headers?: ResponseHeaders;
  indexForAnalytics?: number;
  practitioner?: Provider | SnackCardProvider;
  locationForAnalytics?: string;
  selectedFilters?: string;
  searchTerm?: string;
  landingPage?: boolean;
  onClosePopup?: () => void;
};

export const CardInfo = ({
  cardType,
  selectedFilters,
  indexForAnalytics,
  locationForAnalytics,
  headers = { correlationId: '' },
  practitioner = {},
  searchTerm,
  landingPage = false,
  onClosePopup,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const featureFlags = useLagoon('feature-flags')();
  const uiMessaging = useLagoon('ui-messaging')();

  const memberHasRPK = useStore(StoreKeys.TIER1_PLAN_FLAG);
  const ddpCodeUser = getLoggedInMember()?.ddpCode;

  const {
    providerType,
    isTieredProvider,
    isPremiumCarePhysician,
    isPreferredLab = false,
    isFreeStandingFacility = false,
    ddpCode = '',
  } = practitioner;

  const preferredFacilityFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PREFERRED_FACILITY_IDENTIFICATION
  );

  const isDisplayTier1Identifier = displayTier1Identifier(
    memberHasRPK,
    isTieredProvider
  );

  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const showPremiumCare = displayPremiumCareIdentifier(
    !suppressPremiumCare,
    getPremiumCarePhysician(isPremiumCarePhysician),
    isTieredProvider,
    memberHasRPK
  );

  const suppressFacilityFlags = getSuppressFacilityFlags();
  const showBenefitsBadge = isShowPreferredFacilityBadge(
    suppressFacilityFlags,
    preferredFacilityFlag,
    ddpCode,
    ddpCodeUser,
    isPreferredLab,
    isFreeStandingFacility
  );

  let variant: CardInfoVariants | null = null;
  if (showPremiumCare) {
    variant = CardInfoVariants.PREMIUM_CARE;
  } else if (showBenefitsBadge && !isDisplayTier1Identifier) {
    variant = CardInfoVariants.PREFERRED_FACILITY;
  }

  const premiumContent = find(uiMessaging, {
    key: ConstantsLagoon.CONTENT_FILTERS.PREMIUM_CARE_PHYSICIAN_CONTENT,
  })?.message;

  const preferredFacilityContent = find(uiMessaging, {
    key: ConstantsLagoon.CONTENT_FILTERS.PREFERRED_FACILITY_CONTENT,
  })?.message;

  const modalTestId: string = `${variant}-popover`;
  const linkTestId: string = `${variant}-benefits-link`;
  const modalEventName: string =
    variant === CardInfoVariants.PREMIUM_CARE
      ? Constants.PREMIUM_CARE_PAGE_LOCATION
      : Constants.PREFERRED_FACILITY_PAGE_LOCATION;
  const resultCardLocationInfoPopOverDataTestId: string = mobileScreen
    ? `provider-${variant}-drawer`
    : `provider-${variant}-popover`;

  const linkUrl =
    find(uiMessaging, {
      key: ConstantsLagoon.CONTENT_FILTERS.PREMIUM_CARE_POPOVER_URL,
    })?.message ?? '';

  useEffect(() => {
    if (isMobileDrawerOpen) {
      handleAdobeSearchResultClickAndModalEvent({
        filters: selectedFilters,
        linkName: resultCardLocationInfoPopOverDataTestId,
        linkPosition: indexForAnalytics,
        linkLocation: locationForAnalytics,
        modalName: modalEventName,
        customAttributesBlock: {
          correlationId: headers?.correlationId,
          providerId: formatProviderId(practitioner),
          providerType: convertProviderTypeToAdobeType(providerType),
        },
        term: searchTerm ?? '',
      });
    }
  }, [isMobileDrawerOpen]);

  useEffect(() => {
    if (isOpen) {
      handleAdobeSearchResultClickAndModalEvent({
        filters: selectedFilters,
        linkName: resultCardLocationInfoPopOverDataTestId,
        linkPosition: indexForAnalytics,
        linkLocation: locationForAnalytics,
        modalName: modalEventName,
        customAttributesBlock: {
          correlationId: headers?.correlationId,
          providerId: formatProviderId(practitioner),
          providerType: convertProviderTypeToAdobeType(providerType),
        },
        term: searchTerm ?? '',
      });
    }
  }, [isOpen]);

  const ICON_MAP = {
    [CardInfoVariants.PREMIUM_CARE]: (
      <>
        <IconMaterial color="#002677" icon="favorite" size={16} />
        <IconMaterial color="#002677" icon="favorite" size={16} />
      </>
    ),
    [CardInfoVariants.PREFERRED_FACILITY]: null,
  };

  const LINK_MAP = {
    [CardInfoVariants.PREMIUM_CARE]: (
      <LinkWithTracking
        analyticsInfo={{
          name: linkTestId,
          location: `modal:${Constants.PREMIUM_CARE_PHYSICIANS.TAG.toLowerCase()}`,
          type: 'external',
          destinationUrl: linkUrl,
        }}
        css={PremiumCarePopoverLinkStyle}
        data-auto-testid={linkTestId}
        data-testid={linkTestId}
        href={linkUrl}
      >
        {t('PREMIUM_CARE_BENEFITS.PREMIUM_CARE_LINK')}
      </LinkWithTracking>
    ),
    [CardInfoVariants.PREFERRED_FACILITY]: null,
  };

  const TAG_MAP = {
    [CardInfoVariants.PREMIUM_CARE]: t(
      'PREMIUM_CARE_BENEFITS.PREMIUM_CARE_TAG'
    ),
    [CardInfoVariants.PREFERRED_FACILITY]: t('PREFERRED_FACILITY_BENEFITS.TAG'),
  };

  const POPOVER_CONTENT_MAP = {
    [CardInfoVariants.PREMIUM_CARE]:
      premiumContent ?? t('PREMIUM_CARE_BENEFITS.PREMIUM_CARE_CONTENT'),
    [CardInfoVariants.PREFERRED_FACILITY]:
      preferredFacilityContent ?? t('PREFERRED_FACILITY_BENEFITS.CONTENT'),
  };

  const POPOVER_HEADING_MAP = {
    [CardInfoVariants.PREMIUM_CARE]: t(
      'PREMIUM_CARE_BENEFITS.PREMIUM_CARE_HEADING'
    ),
    [CardInfoVariants.PREFERRED_FACILITY]: t(
      'PREFERRED_FACILITY_BENEFITS.HEADING'
    ),
  };

  const POPOVER_WIDTH_MAP = {
    [CardInfoVariants.PREMIUM_CARE]: 363,
    [CardInfoVariants.PREFERRED_FACILITY]: 220,
  };

  return (
    <>
      <TierOne
        data-auto-testid={`tier-one-${cardType}-tag`}
        data-testid={`tier-one-${cardType}-tag`}
        isTierOne={isDisplayTier1Identifier}
      />
      {variant ? (
        <div
          data-auto-testid={`${variant}-${cardType}-tag`}
          data-testid={`${variant}-${cardType}-tag`}
          style={{ height: '24px' }}
        >
          <Drawer
            css={PremiumCareDrawerStyles}
            data-auto-testid={resultCardLocationInfoPopOverDataTestId}
            data-testid={resultCardLocationInfoPopOverDataTestId}
            isOpen={isMobileDrawerOpen}
            onClose={() => {
              setIsMobileDrawerOpen(false);
              if (onClosePopup) onClosePopup();
            }}
            position="bottom"
            size="200"
            title={POPOVER_HEADING_MAP?.[variant]}
          >
            {POPOVER_CONTENT_MAP?.[variant]}
            {variant === CardInfoVariants.PREMIUM_CARE ? (
              <LinkWithTracking
                analyticsInfo={{
                  name: linkTestId,
                  location: `modal:${Constants.PREMIUM_CARE_PHYSICIANS.TAG.toLowerCase()}`,
                  type: 'external',
                  destinationUrl: linkUrl,
                }}
                css={PremiumCareDrawerLinkStyle}
                data-auto-testid={linkTestId}
                data-testid={linkTestId}
                href={linkUrl}
              >
                {t('PREMIUM_CARE_BENEFITS.PREMIUM_CARE_LINK')}
              </LinkWithTracking>
            ) : null}
          </Drawer>
          {mobileScreen ? (
            <PremiumInfoBox
              cssProps={{ landingPage }}
              data-auto-testid={modalTestId}
              data-testid={modalTestId}
              onClick={() => setIsMobileDrawerOpen(true)}
            >
              {ICON_MAP[variant]}
              <TierCardText>{TAG_MAP?.[variant]}</TierCardText>
            </PremiumInfoBox>
          ) : (
            <PremiumCareSnackCardPopoverStyles
              content={
                <div>
                  <PremiumPopoverText>
                    {POPOVER_CONTENT_MAP?.[variant]}
                  </PremiumPopoverText>
                  {LINK_MAP[variant]}
                </div>
              }
              cssProps={{ landingPage }}
              data-auto-testid={resultCardLocationInfoPopOverDataTestId}
              data-testid={resultCardLocationInfoPopOverDataTestId}
              onOpenChange={() => setIsOpen(!isOpen)}
              open={isOpen}
              title={POPOVER_HEADING_MAP?.[variant]}
              width={POPOVER_WIDTH_MAP?.[variant]}
            >
              <PremiumInfoBox
                data-auto-testid={modalTestId}
                data-testid={modalTestId}
                onClick={() => setIsOpen(true)}
              >
                {ICON_MAP[variant]}
                <TierCardText>{TAG_MAP?.[variant]}</TierCardText>
              </PremiumInfoBox>
            </PremiumCareSnackCardPopoverStyles>
          )}
        </div>
      ) : null}
    </>
  );
};
