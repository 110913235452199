import { styled } from '@abyss/web/tools/styled';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs } from '@abyss/web/ui/Tabs';
import { Text } from '@abyss/web/ui/Text';

export const WidgetHeader = styled('div', {
  backgroundColor: '#FBFCFE',
  maxWidth: '1200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '16px 32px',
  '@screen < $sm': {
    padding: '16px',
  },
});

export const WidgetTabs = styled(Tabs, {
  '.abyss-tabs-tab-content': {
    fontSize: '$lg',
    fontWeight: '$bold',
    lineHeight: '$lg',
  },
  '.abyss-tabs-tab-active-line': {
    borderColor: '$gray7',
    borderRadius: '6px 6px 0px 0px',
  },
  '.abyss-tabs-list': {
    border: '0',
    outline: '1px solid $cardBorder',
    display: 'flex',
    gap: '24px',
    paddingLeft: '16px',
    paddingRight: '16px',
    button: {
      height: '52px',
      minHeight: '52px',
      padding: '20px 12px $sm 12px',
      width: '100%',
    },
  },
});

export const ProviderInfo = styled(Layout.Stack, {
  paddingLeft: '24px',
  width: 'calc(100% - 84px)',
});

export const ProviderNameAndCost = styled(Layout.Group, {
  minHeight: '60px',
});

export const ProviderNameContainer = styled(Layout.Stack, {
  lineHeight: '20px',
});

export const ProviderName = styled(Text, {
  display: 'inline-block',
  maxWidth: '275px',
});

export const ProviderSpecialty = styled(Text, {
  display: 'inline-block',
  paddingBottom: '12px',
});

export const BenefitsBadgeContainer = styled('div', {
  margin: '-4px 0 4px',
  '@screen < $sm': {
    marginBottom: '8px',
  },
});

export const CostEstimateContainer = styled(Layout.Stack, {
  lineHeight: '16px',
  '&.abyss-layout-stack': {
    marginBottom: '-3px',
  },
});

export const YouPayContainer = styled('div', {
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '18px',
});
export const OutOfPocketContainer = styled('div', {
  fontWeight: '700',
  fontSize: '20.25px',
  lineHeight: '24px',
});
export const TotalContainer = styled('div', {
  fontWeight: '500',
  fontSize: '12.64px',
  lineHeight: '16px',
});
export const WidgetSkeletonContainerHeader = styled('div', {
  background: '#002677',
  padding: '16px',
});

export const WidgetSkeletonContainer = styled('div', {
  background: 'white',
  maxWidth: '1200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
});

export const WidgetSkeletonSubContainer = styled(Layout.Stack, {
  padding: '24px 16px 24px 16px',
});

export const WidgetSkeletonLayoutContainer = styled(Layout.Group, {
  paddingTop: '30px',
  borderBottom: '1px solid #E5E5E6',
  borderWidth: '2px',
  margin: 0,
});
