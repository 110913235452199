import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { config } from '@abyss/web/tools/config';
import { Button } from '@abyss/web/ui/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CostIndicatorPopover } from '../../../../common/DataCard/CostIndicatorPopover';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useLagoon } from '../../../../hooks/useLagoon';
import { CarePathsResponse } from '../../../../models/CarePaths';
import { ProviderLocation } from '../../../../models/ProviderDetails';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { phoneOnly } from '../../../ConstantsStyles';
import { CostIndicator } from '../../../CostIndicator';
import { getFeatureFlag } from '../../../Utils/getFeatureFlag';
import {
  CarePathCost,
  CarePathCostContainer,
  CarePathCostText,
  CarePathEstimationButtonStyles,
  CarePathResultContainer,
  CarePathResultName,
  CarePathResultsList,
  CostIndicatorStyled,
  CostIndicatorText,
} from './CarePaths.styled';

type Props = {
  carePathsData: CarePathsResponse | undefined;
  selectedLocation: ProviderLocation;
};

export const CarePathsResults = ({
  carePathsData,
  selectedLocation,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(phoneOnly);
  const coverageType = useCoverageType();

  const { longitude, latitude, zipCode } = useGeoLocationStorage();
  const locationKey = selectedLocation?.locationKey || '';
  const locationLatitude = selectedLocation?.latitude || latitude;
  const locationLongitude = selectedLocation?.longitude || longitude;
  const locationZipcode =
    selectedLocation?.address?.postalCode.split('-')[0] || zipCode;

  const featureFlags = useLagoon('feature-flags')();

  const enableCEX: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CEX_GET_FULL_COST_ESTIMATE
  );

  const getCostIndicatorText = (indicatorValue) => {
    if (indicatorValue === '$')
      return t('PROVIDER_DETAILS.COST_TAB.COST_AVERAGES.BELOW_AVERAGE');
    if (indicatorValue === '$$')
      return t('PROVIDER_DETAILS.COST_TAB.COST_AVERAGES.AVERAGE');
    if (indicatorValue === '$$$')
      return t('PROVIDER_DETAILS.COST_TAB.COST_AVERAGES.ABOVE_AVERAGE');

    return '';
  };

  const getCostIndicatorColor = (indicatorValue) => {
    if (indicatorValue === '$') return '$success1';
    if (indicatorValue === '$$') return '#0248AC';
    if (indicatorValue === '$$$') return '#AA2E23';

    return '';
  };

  const getCoverageType = (coverageType) => {
    if (coverageType === 'M') return 'medical';
    if (coverageType === 'V') return 'vision';
    if (coverageType === 'D') return 'dental';
    if (coverageType === 'B') return 'behavioral';

    return '';
  };

  const getProviderId = (providerId) => {
    if (coverageType === 'V' && providerId.startsWith('0')) {
      const providerIntValue = parseInt(providerId, 10);
      return (
        providerIntValue.toString().replace('P', '') ||
        providerIntValue.toString().replace('O', '')
      );
    }
    if (coverageType === 'M' && providerId.startsWith('0')) {
      const providerIntValue = parseInt(providerId, 10);
      return providerIntValue.toString();
    }

    return providerId;
  };
  const getLocationId = (locationId) => {
    if (coverageType === 'V') {
      if (locationId.charAt(locationId.length - 1) === 'P') {
        locationId = locationId.replace(
          locationId.substring(locationId.length - 1),
          ''
        );
        return locationId;
      }
    }
    return locationId;
  };

  const getCostEstimateLink = (carePath) =>
    `${config('BASE_URL')}/${enableCEX ? 'costestimate' : 'care'}/carePath/${
      carePath.carePathIdentifier
    }?coverageType=${getCoverageType(coverageType)}&providerId=${getProviderId(
      locationKey.split('-')[0]
    )}&locationId=${getLocationId(
      locationKey.split('-')[3]
    )}&zipCode=${locationZipcode}&providerType=person&carePathStepId=${
      carePath.carePathIdentifier
    }_1&lat=${locationLatitude}&long=${locationLongitude}`;

  const listOfCarePaths = carePathsData?.carePathSteps.map(
    (carePath, index) => (
      <li
        data-testid={`care-path-${carePath.carePathName}`}
        key={`care-path-${carePath.carePathName}-${index}`}
      >
        {!isMobile && index === 0 && (
          <Divider color="#E5E5E6" margin="$lg" orientation="horizontal" />
        )}
        <CarePathResultContainer
          data-testid="care-path-result-container"
          direction="row"
          justify="space-between"
        >
          <CarePathResultName data-testid="care-path-result-container-heading">
            {carePath.carePathName}
          </CarePathResultName>
          <CarePathCostContainer data-testid={`care-path-avg-cost-${index}`}>
            <CarePathCostText>
              {t('PROVIDER_DETAILS.COST_TAB.CARE_PATH.ESTIMATED_AVG_COST')}{' '}
              <CarePathCost data-testid={`care-path-cost`}>
                ${carePath?.cost || ''}
              </CarePathCost>
            </CarePathCostText>
            <Flex css={CostIndicatorStyled}>
              <CostIndicator indicatorData={carePath?.costIndicator || ''} />
              <CostIndicatorText
                color={getCostIndicatorColor(carePath?.costIndicator)}
              >
                {getCostIndicatorText(carePath?.costIndicator)}
              </CostIndicatorText>
              <CostIndicatorPopover
                costIndicator={carePath?.costIndicator || ''}
                displayCostIndicator={false}
              />
            </Flex>
          </CarePathCostContainer>
          <Button
            aria-label={`get-full-cost-estimate-for ${
              carePath.carePathName
            } estimated-average-cost-is ${
              carePath?.cost + 'dollars' || 'unavailable'
            }`}
            css={CarePathEstimationButtonStyles}
            data-testid={`full-cost-estimate`}
            href={getCostEstimateLink(carePath)}
            onClick={() => {
              adobeLinkTrackEvent({
                name: 'full cost estimate',
                location: `body:${carePath.carePathName}`,
                type: 'external',
              });
            }}
            openNewWindow={true}
            variant="outline"
          >
            {t('PROVIDER_DETAILS.COST_TAB.CARE_PATH.GET_FULL_COST_ESTIMATE')}
          </Button>
        </CarePathResultContainer>
        {index <= carePathsData?.carePathSteps.length - 1 && (
          <Divider color="#E5E5E6" margin="$lg" orientation="horizontal" />
        )}
      </li>
    )
  );

  return (
    <React.Fragment>
      <CarePathResultsList data-testid="single-referral-banner">
        {listOfCarePaths}
      </CarePathResultsList>
    </React.Fragment>
  );
};
