import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import sortBy from 'lodash/sortBy';

import translationsEnJson from '../../public/locale/en/translation.json';
import {
  AUTO_INCREMENT_SEARCH_RADIUS,
  DEFAULT_SEARCH_RADIUS,
  FilterCollectionModel,
  FilterKeysAndParamsMapping,
  ReverseCoverageTypesCodes,
} from '../common/Constants';
import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { getFeatureFlag } from '../common/Utils';
import {
  addDaysToDate,
  dateFormatUTC,
} from '../common/Utils/datesUtils/formatDate';
import { getLanguage } from '../frontends/ProviderSearch/context/Internationalization/helpers';
import { useLagoon } from '../hooks/useLagoon';
import {
  FiltersData,
  LastModifiedFilter,
  Option,
  ProviderFiltersType,
  ProviderSearchFilters,
  ProviderSearchRequestParams,
} from '../models/ProviderSearch';
import { getCurrentMember, getDependentInfo } from './user.utils';

const specialtyCodes = require('../common/PSXHeader/specialtyCodes.json');

export const getValuesFromOptionsArray = (filter: Option[] = []) => {
  const values: any[] = [];
  filter?.forEach((option: Option) => {
    values.push(option.value);
  });
  return values;
};

const getArrayTypeFilterValues = (
  params: ProviderSearchFilters,
  pickFirstValue = false
) => {
  const filterParams = {};
  Object.keys(params).forEach((key) => {
    if (params[key]?.length) {
      const options = getValuesFromOptionsArray(params[key]);
      filterParams[FilterKeysAndParamsMapping[key]] = pickFirstValue
        ? options[0]
        : options;
    }
  });
  return filterParams;
};

export const getDateRangeFromFilterValuesArray = (
  params: ProviderSearchFilters
) => {
  const filterParams = {};
  let filterParamsDateRange = {};
  const startDate: any = new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate()
  );
  const formattedDocAsapStartDate = dateFormatUTC(startDate, 'yyyy-MM-dd');
  Object.keys(params).forEach((key) => {
    if (params[key]?.length) {
      const options = getValuesFromOptionsArray(params[key]);
      options.sort((a, b) => a - b);
      const farthestDate = options[options.length - 1];
      const endDate = addDaysToDate(startDate, farthestDate - 1);
      const formattedEndDate = dateFormatUTC(endDate, 'yyyy-MM-dd');
      filterParams[FilterKeysAndParamsMapping[key]] = formattedEndDate;
      filterParamsDateRange = {
        docAsapStDate: formattedDocAsapStartDate,
        ...filterParams,
      };
    }
  });
  return filterParamsDateRange;
};

const getObjectTypeFilterValues = (params: ProviderSearchFilters) => {
  const filterParams = {};
  Object.keys(params).forEach((key) => {
    if (params[key]?.value)
      filterParams[FilterKeysAndParamsMapping[key]] = params[key].value;
  });
  return filterParams;
};

export const parseProviderSearchFilters = (
  selectedFilters: ProviderSearchFilters
): ProviderSearchRequestParams => {
  const {
    AcoName,
    ExpressAccessProviderIndicator,
    TieredProvider,
    PremiumCarePhysician,
    PlatinumProviderIndicator,
    VirtualCare,
    Distance,
    FacilityType,
    SpecialtyCode,
    AreaOfExpertise,
    PatientReviews,
    HealthSuppliesOffered,
    LanguageCode,
    Medical,
    HospitalAffiliations,
    AcceptingNewPatients,
    EveningAndWeekend,
    AccessibilityCode,
    GenderCode,
    PatientSafetyInfoAvailable,
    SortBy,
    EthnicityCode,
    BehavioralProgramIndicator,
    ServicesOffered,
    UpcomingAvailability,
  } = selectedFilters;

  const objectTypeFilterValues = getObjectTypeFilterValues({
    ExpressAccessProviderIndicator,
    TieredProvider,
    PremiumCarePhysician,
    PlatinumProviderIndicator,
    VirtualCare,
    SortBy,
  });

  const arrayTypeFilterValues = getArrayTypeFilterValues({
    AcoName,
    FacilityType,
    SpecialtyCode,
    AreaOfExpertise,
    HealthSuppliesOffered,
    LanguageCode,
    AccessibilityCode,
    Medical,
    HospitalAffiliations,
    EthnicityCode,
    BehavioralProgramIndicator,
    ServicesOffered,
  });

  const arrayTypeFilterValuesFromFirstIndex = getArrayTypeFilterValues(
    {
      PatientReviews,
      GenderCode,
      Distance,
      VirtualCare,
    },
    true
  );

  const arrayTypeFilterDateRange = getDateRangeFromFilterValuesArray({
    UpcomingAvailability,
  });

  return {
    ...objectTypeFilterValues,
    ...arrayTypeFilterValues,
    ...arrayTypeFilterValuesFromFirstIndex,
    ...arrayTypeFilterDateRange,
    ...(AcceptingNewPatients?.length && {
      acceptingNewPatients:
        getValuesFromOptionsArray(AcceptingNewPatients).includes('Y'),
    }),
    ...(EveningAndWeekend?.length &&
      getValuesFromOptionsArray(EveningAndWeekend).includes('E') && {
        eveningAvailability: true,
      }),
    ...(EveningAndWeekend?.length &&
      getValuesFromOptionsArray(EveningAndWeekend).includes('W') && {
        weekendAvailability: true,
      }),
    ...(PatientSafetyInfoAvailable?.length &&
      getValuesFromOptionsArray(PatientSafetyInfoAvailable).includes('Y') && {
        patientSafetyInfoAvailable: true,
      }),
  };
};

const getEveningAndWeekendFilter = (
  { EveningAvailability, WeekendAvailability }: FiltersData,
  translate,
  suppressVisionWeekendEveningsFilter
) => {
  if (suppressVisionWeekendEveningsFilter) {
    return [];
  }
  const eveningAvailability = getValuesFromOptionsArray(
    EveningAvailability?.options
  ).includes('Y')
    ? [
        {
          label: translate('FILTER_OPTIONS.EveningAvailability.Y'),
          value: 'E',
          dnt_label: translationsEnJson.FILTER_OPTIONS.EveningAvailability.Y,
        },
      ]
    : [];

  const weekendAvailability = getValuesFromOptionsArray(
    WeekendAvailability?.options
  ).includes('Y')
    ? [
        {
          label: translate('FILTER_OPTIONS.WeekendAvailability.Y'),
          value: 'W',
          dnt_label: translationsEnJson.FILTER_OPTIONS.WeekendAvailability.Y,
        },
      ]
    : [];

  return [...eveningAvailability, ...weekendAvailability];
};

const readOptionsFromFilterCollection = () => {
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const upcomingAvailability: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.UPCOMING_AVAILABILITY
  );
  const filterResults: string[] = [
    FilterCollectionModel.DISTANCE,
    FilterCollectionModel.VIRTUAL_CARE,
    FilterCollectionModel.PATIENT_REVIEWS,
    upcomingAvailability ? FilterCollectionModel.UPCOMING_AVAILABILITY : '',
  ];
  return filterResults;
};

const isVisionWeekendEveningsFilterEnabled = (coverageType) => {
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const visionWeekendEveningsFilterEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VISION_WEEKEND_EVENINGS_FILTER_ENABLED
  );

  return (
    coverageType === ReverseCoverageTypesCodes.VISION &&
    !visionWeekendEveningsFilterEnabled
  );
};

const getModifiedFilterOptions = (lastModifiedFilter) =>
  lastModifiedFilter?.options || [];

export const transformFiltersData = (
  translate,
  filtersCollection: ProviderFiltersType[],
  filtersData: FiltersData = {},
  lastModifiedFilter: LastModifiedFilter = {}
) => {
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token);
  const { coverageType } = tokenData;
  const suppressVisionWeekendEveningsFilter =
    isVisionWeekendEveningsFilterEnabled(coverageType);

  const readLabelFromTranslations: string[] = [
    FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
    FilterCollectionModel.GENDER_CODE,
  ];

  const filters: ProviderFiltersType[] = [];

  filtersCollection.forEach((filter) => {
    let filterOptions: Option[] = [];
    if (!!filter.isDisabled) return;
    if (filter.model === lastModifiedFilter?.model) {
      filterOptions.push(...getModifiedFilterOptions(lastModifiedFilter));
    } else if (filter.model === FilterCollectionModel.EVENING_AND_WEEKEND) {
      const options = getEveningAndWeekendFilter(
        filtersData,
        translate,
        suppressVisionWeekendEveningsFilter
      );
      filterOptions.push(...options);
    } else if (readOptionsFromFilterCollection().includes(filter.model)) {
      filterOptions.push(...filter.options);
    } else {
      filtersData[filter.model]?.options?.forEach((option) => {
        let label = '';
        if (readLabelFromTranslations.includes(filter.model)) {
          label = translate(`FILTER_OPTIONS.${filter.model}.${option.value}`);
        } else {
          label = option.label;
        }
        if (label)
          filterOptions.push({
            label,
            value: option.value,
            dnt_label: option.dnt_label,
          });
      });
    }
    if (
      filter?.type !== 'radio' &&
      filter?.model !== FilterCollectionModel.UPCOMING_AVAILABILITY
    ) {
      filterOptions = sortBy(filterOptions, 'label');
    }
    if (filterOptions.length)
      filters.push({ ...filter, options: [...filterOptions] });
  });

  return filters;
};

export const getLagoonConfigValue = (allConfigs, key) => {
  const config = allConfigs(key);
  return config ? config.value : '';
};

export const getCurrentMemberData = (dependentSeqNbr) =>
  dependentSeqNbr ? getDependentInfo(dependentSeqNbr) : getCurrentMember();

export const getLocale = () => getLanguage()?.code || 'en';

export const getTokenData = (token) => tokenizer.parse(token) || {};

export const getOrgTypeCodes = (includeOrgTypeCodes) =>
  includeOrgTypeCodes ? JSON.parse(includeOrgTypeCodes) : [];

export const getSearchRadius = (searchRadius) =>
  searchRadius ? +searchRadius : DEFAULT_SEARCH_RADIUS;

export const getSpecialityRollupCodes = (
  specialtyCode,
  includeSpecialityRollupCodes,
  category
) =>
  (specialtyCode ||
    includeSpecialityRollupCodes ||
    specialtyCodes[category?.toUpperCase()]) ??
  '';

export const getAutoIncrementRadius = (autoIncrementRadius) =>
  autoIncrementRadius
    ? autoIncrementRadius.split(',')
    : AUTO_INCREMENT_SEARCH_RADIUS;

export const getProviderSearchParams = (
  customParams,
  selectedCategoryPayload,
  searchType,
  choosePCP
) => {
  const params = {
    ...customParams,
    includeSpecialityRollupCodes: choosePCP
      ? customParams?.includeSpecialityRollupCodes
      : selectedCategoryPayload?.includeSpecialityRollupCodes,
    searchType,
    pcpIndicator: choosePCP || selectedCategoryPayload?.pcpIndicator,
    coverageType: choosePCP
      ? customParams?.coverageType
      : selectedCategoryPayload?.coverages?.[0],
    reciprocityId: choosePCP
      ? customParams?.reciprocityId
      : selectedCategoryPayload?.reciprocityId,
  };
  const practitionerParams = {
    ...params,
    providerType: 'Practitioner',
  };

  const orgParams = {
    ...params,
    providerType: 'organization',
  };

  const medGrpParams = {
    ...params,
    providerType: 'Medical_Group',
  };
  return { practitionerParams, orgParams, medGrpParams };
};
