/**
 * CONSTANTS
 */

import { HoursOfOperation } from '../models/HoursOfOperation';

export const APIConstants = {
  INCLUDE_GRAPHQL_QUERIES: [
    'ProviderSearch',
    'SnackCardProviderSearch',
    'ProviderFilterCount',
  ],
  URGENT_CARE: 'URGENT_CARE',
  PES: {
    NAME: 'name',
    SPECIALTY: 'speciality',
    LOCATION: 'location',
    CAP_LIMIT: 9500, // Currently, PES Search support only records till offset of 9500.
  },
  PROVIDER_TYPES: {
    practitioner: 'practitioner',
    organization: 'organization',
    medical_group: 'Medical_Group',
    providerGroup: 'Provider Group',
  },
  MEMBER_RELEVANCY_CONFIG: {
    ROLLUPCODES_FEMALE_SPECIALITIES: 'ROLLUPCODES_FEMALE_SPECIALITIES',
    ROLLUPCODES_UNDER21_SPECIALITIES: 'ROLLUPCODES_UNDER21_SPECIALITIES',
    ROLLUPCODES_ABOVE21_SPECIALITIES: 'ROLLUPCODES_ABOVE21_SPECIALITIES',
  },
  DEFAULT_ROLLUP_CODES: {
    EXCLUDE_CODES: {
      ORGANIZATION: ['52-526-050', '52-526-033'],
      ORGANIZATION_ORG_TYPE_CODES: ['050', '033'],
    },
    INCLUDE_CODES: {
      MEDICAL_GROUP: ['050', '033'],
      URGENT_CARES: ['50-524-057', '53-527-057', '50-524-030', '53-527-030'],
    },
    URGENT_CARE_ORG_TYPE_CODES: ['030', '057'],
  },
  MEMBER_RELEVANCY_CODES: {
    ROLLUPCODES_FEMALE_SPECIALITIES: {
      PRACTITIONER:
        '02-033-250,01-051-250,02-033-230,01-051-230,02-033-251,01-051-251,02-026-069,02-033-069,01-051-069',
      MEDICAL_GROUP:
        '52-526-033-033-250,52-526-033-051-250,52-526-033-033-230,52-526-033-051-230,52-526-033-033-251,52-526-033-051-251,52-526-033-026-069,52-526-033-033-069,52-526-033-051-069,' +
        '52-526-050-033-250,52-526-050-051-250,52-526-050-033-230,52-526-050-051-230,52-526-050-033-251,52-526-050-051-251,52-526-050-026-069,52-526-050-033-069,52-526-050-051-069',
      ORGANIZATION:
        '02-033-250,01-051-250,02-033-230,01-051-230,02-033-251,01-051-251,02-026-069,02-033-069,01-051-069',
    },
    ROLLUPCODES_UNDER21_SPECIALITIES: {
      PRACTITIONER:
        '02-043-037,01-051-037,02-043-258,01-051-258,02-044-079,01-051-079,02-043-274,02-044-274,01-051-274,02-030-389,02-031-389,02-043-389,01-051-389,' +
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077',
      MEDICAL_GROUP:
        '52-526-033-043-037,52-526-033-051-037,52-526-033-043-258,52-526-033-051-258,52-526-033-044-079,52-526-033-051-079,52-526-033-043-274,52-526-033-044-274,52-526-033-051-274,52-526-033-030-389,52-526-033-031-389,52-526-033-043-389,52-526-033-051-389,' +
        '52-526-050-043-037,52-526-050-051-037,52-526-050-043-258,52-526-050-051-258,52-526-050-044-079,52-526-050-051-079,52-526-050-043-274,52-526-050-044-274,52-526-050-051-274,52-526-050-030-389,52-526-050-031-389,52-526-050-043-389,52-526-050-051-389,' +
        '52-526-033-015-008,52-526-033-051-008,52-526-033-015-339,52-526-033-030-339,52-526-033-031-339,52-526-033-051-339,52-526-033-015-275,52-526-033-051-275,52-526-033-017-001,52-526-033-051-001,52-526-033-017-272,52-526-033-051-272,52-526-033-048-338,52-526-033-051-338,52-526-033-031-077,52-526-033-051-077,52-526-033-030-077,' +
        '52-526-050-015-008,52-526-050-051-008,52-526-050-015-339,52-526-050-030-339,52-526-050-031-339,52-526-050-051-339,52-526-050-015-275,52-526-050-051-275,52-526-050-017-001,52-526-050-051-001,52-526-050-017-272,52-526-050-051-272,52-526-050-048-338,52-526-050-051-338,52-526-050-031-077,52-526-050-051-077,52-526-050-030-077',
      ORGANIZATION:
        '02-043-037,01-051-037,02-043-258,01-051-258,02-044-079,01-051-079,02-043-274,02-044-274,01-051-274,02-030-389,02-031-389,02-043-389,01-051-389,' +
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077',
    },
    ROLLUPCODES_ABOVE21_SPECIALITIES: {
      PRACTITIONER:
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077,' +
        '02-024-011,01-051-011',
      MEDICAL_GROUP:
        '52-526-033-015-008,52-526-033-051-008,52-526-033-015-339,52-526-033-030-339,52-526-033-031-339,52-526-033-051-339,52-526-033-015-275,52-526-033-051-275,52-526-033-017-001,52-526-033-051-001,52-526-033-017-272,52-526-033-051-272,52-526-033-048-338,52-526-033-051-338,52-526-033-031-077,52-526-033-051-077,52-526-033-030-077,' +
        '52-526-050-015-008,52-526-050-051-008,52-526-050-015-339,52-526-050-030-339,52-526-050-031-339,52-526-050-051-339,52-526-050-015-275,52-526-050-051-275,52-526-050-017-001,52-526-050-051-001,52-526-050-017-272,52-526-050-051-272,52-526-050-048-338,52-526-050-051-338,52-526-050-031-077,52-526-050-051-077,52-526-050-030-077,' +
        '52-526-033-024-011,52-526-033-051-011,52-526-050-024-011,52-526-050-051-011',
      ORGANIZATION:
        '02-015-008,01-051-008,02-015-339,02-030-339,02-031-339,01-051-339,02-015-275,01-051-275,02-017-001,01-051-001,02-017-272,01-051-272,02-048-338,01-051-338,02-031-077,01-051-077,02-030-077,' +
        '02-024-011,01-051-011',
    },
  },

  OBAPI: {
    RALLYCONNECT: 'RALLYCONNECT',
    RECIPROCITYID: 'RECIPROCITYID',
    DDP: 'DDP',
    LAWW: 'LAWW',
    VISION: 'VISION',
    DENTAL: 'DENTAL',
    ADDRESS: {
      PERMANENT: 'PERMANENT',
      MAILING: 'MAILING',
    },
  },
  Primary_Care: {
    Actions: {
      VIEW: 'VIEW',
      UPDATE: 'UPDATE',
    },
  },
  HEADERS: {
    CONTENT_TYPE: 'application/json',
  },
  RECENT_VISIT_CATEGORIES: {
    PRIMARY_CARE: 'primaryCare',
    MEDICAL_SPECIALISTS: 'medicalSpecialists',
    HEALTH_SUPPLIES: 'healthSupplies',
    FACILITIES: 'facilities',
    VISION: 'vision',
    DENTAL: 'dental',
  },
  LANGUAGE: {
    PSLOCALE: 'PS_Locale',
  },
};

export const AcceptingPatientStatus = {
  YES: 'Accepting new patients',
  NO: 'Not accepting new patients',
};

export const msgWhenNoDataPresent = {
  msg: 'Sorry, there was an internal error retrieving data, please try again.',
};

export const tierOneContent = {
  msg: 'Members may pay less when they visit providers identified with the Tier 1 symbol. When a member chooses a Tier 1 provider, they’re receiving value from their benefit plan and are often saving money.',
};

export const NETWORK_STATUS = {
  IN: 'In-network',
  OUT: 'Out-of-network',
};

export const ResultType = {
  LIST: 'List',
  DETAILS: 'Details',
};

enum CareCategories {
  ALL = 'All',
  BEHAVIORAL_HEALTH = 'Behavioral Health',
  MEDICAL_GROUPS = 'Medical Groups',
  MEDICAL_SPECIALISTS = 'Medical Specialists',
  FACILITIES = 'Facilities',
  HEALTH_SUPPLIES = 'Health Supplies',
  PRIMARY_CARE = 'Primary Care',
  DENTAL = 'Dental',
  VISION = 'Vision',
}

export const L2_CATEGORIES = {
  '01': CareCategories.PRIMARY_CARE,
  '02': CareCategories.MEDICAL_SPECIALISTS,
  '03': CareCategories.BEHAVIORAL_HEALTH,
  '04': CareCategories.DENTAL,
  '05': CareCategories.VISION,
  '50': CareCategories.FACILITIES,
  '51': CareCategories.HEALTH_SUPPLIES,
  '53': CareCategories.FACILITIES,
};

export const TranslationFiles = {
  DEGREE: 'degreeTranslations',
  PES_SPECIALITY: 'pesSpecialityTranslations',
  COST_EXPERIENCE_CARE_PATHS: 'costExperienceCarePathsTranslations',
};

/**
 * INTERFACES / TYPES
 */
export interface DirectoryPDFProvider {
  numberOfReviews: String;
  providerId: String;
  providerName: String;
  providerType: String;
  acceptingNewPatients: Boolean;
  primaryDegrees: String[];
  specialities: String[];
  healthGradeRating: Number;
  languagesSpoken: String[];
  distance: Number;
  address: Address;
  networkStatus: String;
}

export interface ProviderDetailsResponse {
  providerId: String;
  providerName: String;
  gender: String;
  professionalDesignation: String;
  primarySpeciality: [String];
  providerLocations: [ProviderLocationsResponse];
  UHPDRating: String;
  accreditations: [String];
  accessibility: [String];
  hospitalAffiliationList: [HospitalAffiliation];
  healthGradeRating: String;
  education: [Education];
  providerSpecialities: [String];
  providerType: String;
  otherSpecialities: [String];
  boardCertifications: [String];
  servicesProvided: [String];
  networkStartDate: String;
  networkEndDate: String;
  networkStatus: String;
  otherProviderDetails: [String];
  lastUpdatedDate: String;
  website: String;
  organizationType: [String];
  lastVerifiedDate: String;
  languagesSpoken: [String];
  providerGroups: [String];
  preventingMedicationErrors: String;
  rateOfEpisiotomy: String;
  managingSeriousErrors: String;
  reduceCLInfectionsInICUs: String;
  highRiskDeliveries: String;
  qualityOutcomeTypeCode: String;
  efficiencyOutcomeTypeCode: String;
  biography: String;
  primaryDegrees: [String];
}
export interface ProviderLocationsResponse {
  locationId: String;
  latitude: String;
  longitude: String;
  hoursOfOperations: [HoursOfOperation];
  distance: String;
  emailAddress: [String];
  phones: PhoneNumbers;
  defaultLocation: Boolean;
  acceptingNewPatients: Boolean;
  virtualCareOffered: Boolean;
  offersEveningAppointment: Boolean;
  website: String;
  specialities: [String];
  digestId: String;
  pcpId: String;
  networkStartDate: String;
  networkEndDate: String;
  address: Address;
  servicesOffered?: string[];
  medicalInterpreterInOffice?: boolean;
  languageLineAvailable?: boolean;
  methodsOfService?: string[];
  publicTransportationMethods?: string[];
  isSedationOffered: boolean;
  electronicPrescribingOffered: boolean;
}
export interface Education {
  school: String;
  degrees: [String];
}
export interface HospitalAffiliation {
  name: String;
  facilityId: String;
  imageId: String;
}
export interface Address {
  line: string[];
  city: string;
  district?: string;
  state: string;
  postalCode: string;
}
export interface PhoneNumbers {
  phone: [String];
  emergency: [String];
  appointment: [String];
  fax: [String];
}

export interface Hours {
  sunday: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
}

export const SortBy = {
  BEST_MATCH: 'BEST_MATCH',
};

export const TypeAheadCoverageTypeMapping = {
  md: 'M',
  bh: 'B',
  vn: 'V',
  dt: 'D',
};

export const TypeaheadVisionMapping = {
  FACILITY: 'Facility',
  VISION: 'Vision',
  vn: 'vn',
  VISION_ROLLUPCODE: '52-526-033',
  OPTICIAN: 'Optician',
};
export const Localization = {
  DEFAULT_LOCALE: 'en-US',
};

export const TranslationType = {
  SPECIALTY: 'specialty',
  DEGREE: 'degree',
  AREA_OF_EXPERTISE: 'aoe',
  CULTURAL_COMPETENCY: 'culturalCompetency',
  LANGUAGE: 'language',
  ACCESSIBILITY: 'accessibility',
  PRACTICE_LIMITATION: 'practiceLimitation',
  VIRTUAL_CARE: 'virtualCare',
  FACILITY_SERVICE_CODE: 'medicalEquipment',
  DDP: 'ddp',
  FACILITY_SUB_CATEGORY_CODE: 'organizationType',
  LEAPFROG_RATING: 'leapFrogRating',
  BEHAVIORAL_PROGRAM: 'behavioralProgram',
  ETHNICITY: 'ethnicity',
  AUTO_COMPLETE: 'autoComplete',
  SERVICES_OFFERED: 'servicesOffered',
  METHODS_OF_SERVICE: 'methodsOfService',
  CARE_PATH_STEPS: 'carePathSteps',
};

export enum MethodsOfService {
  CODE1 = 'Mobile Clinic (call for location information)',
  CODE2 = 'Online Scheduling',
  CODE3 = 'In-Office Telemedicine',
  CODE4 = 'Online Store',
}

export enum PublicTransportationMethods {
  Bus = '1',
  Boat = '2',
  Train = '3',
  Subway = '4',
  Yes = 'Yes',
  No = 'No',
}

export const VISION_DETAILS_API_CONSTANTS = {
  MethodsOfService,
  PublicTransportationMethods,
};

export const FEMALE_SPECIALITIES_MINIMUM_AGE = 13;
export const FEMALE_SPECIALITIES_MAXIMUM_AGE = 20;
