import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Label } from '@abyss/web/ui/Label';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProviderLocation, ProviderType } from '../../models/ProviderDetails';
import { mobileOnly } from '../ConstantsStyles';
import { SuggestEditChooseLocation } from './SuggestEditChooseLocation';
import {
  CheckBoxContainer,
  SuggestEditCheckBox,
  SuggestEditCheckBoxGroup,
  SuggestEditLocationText,
} from './SuggestEditDrawerCard.styled';

export type Props = {
  providerLocations: ProviderLocation[];
  providerType: ProviderType;
};

export const SuggestEditInputs = ({
  providerLocations,
  providerType,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [radioValue, setRadioValue] = useState('');
  const mobileScreen = useMediaQuery(mobileOnly);

  const getLabel = (item, index) =>
    `Clinic name ${index + 1},${item.address.line[0]},${item.address.city}, ${
      item.address.state
    } ${item.address.postalCode}`;
  const [selectedLocationValue, setSelectedLocationValue] = useState('');
  const locationsList = providerLocations.map((item, index) => ({
    label: (
      <Flex
        css={{
          '.abyss-text-root': {
            color:
              selectedLocationValue === getLabel(item, index)
                ? '#323334'
                : '#4B4D4F',
            fontWeight:
              selectedLocationValue === getLabel(item, index)
                ? '$bold'
                : '$semibold',
          },
        }}
        direction="column"
      >
        <Text>{getLabel(item, index).split(',')[1]}</Text>
        <Text>{getLabel(item, index).split(',').slice(2).join(',')}</Text>
      </Flex>
    ),
    value: getLabel(item, index),
  }));
  const locationsListRadios = providerLocations.map((item, index) => ({
    title: (
      <Flex
        css={{
          '.abyss-text-root': {
            color:
              selectedLocationValue === getLabel(item, index)
                ? '#323334'
                : '#4B4D4F',
            fontWeight:
              selectedLocationValue === getLabel(item, index)
                ? '$bold'
                : '$semibold',
          },
          paddingTop: '10px',
          fontSize: '$md',
        }}
        direction="column"
      >
        <Text>{getLabel(item, index).split(',')[1]}</Text>
        <Text>{getLabel(item, index).split(',').slice(2).join(',')}</Text>
      </Flex>
    ),
    icon: (
      <IconMaterial
        icon={
          getLabel(item, index) === selectedLocationValue
            ? 'radio_button_checked'
            : 'radio_button_unchecked'
        }
      />
    ),
    onClick: () => {
      setSelectedLocationValue(getLabel(item, index));
    },
  }));

  const chooseLocationLabel = {
    title: (
      <Text
        css={{
          fontSize: '25.63px',
          fontWeight: '$bold',
          color: '#323334',
          marginLeft: '-25px',
          paddingBottom: '20px',
          marginBottom: '0px',
        }}
      >
        {t('SUGGEST_EDIT.CHOOSE_LOCATION')}
      </Text>
    ),
    icon: null,
    onClick: () => {},
  };
  const locationsRadioGroup = [chooseLocationLabel, ...locationsListRadios];
  return (
    <Flex
      alignItems="flex-start"
      css={{
        'abyss-flex-root': {
          padding: 0,
        },
      }}
      direction="column"
    >
      <SuggestEditCheckBoxGroup
        label={
          providerType === ProviderType.PRACTITIONER
            ? t('SUGGEST_EDIT.PROVIDERS_INFO')
            : t('SUGGEST_EDIT.FACILITY_INFO')
        }
        model="checkbox-group"
        size="sm"
      >
        <SuggestEditCheckBox
          label={
            providerType === ProviderType.PRACTITIONER
              ? t('SUGGEST_EDIT.PROVIDER_NAME')
              : t('SUGGEST_EDIT.FACILITY_NAME')
          }
          value="provider name"
        />
        <SuggestEditCheckBox
          label={t('SUGGEST_EDIT.SPECIALTY')}
          value="specialty"
        />
        <SuggestEditCheckBox
          label={t('SUGGEST_EDIT.NETWORK_STATUS')}
          value="network status"
        />
        <SuggestEditCheckBox
          label={t('SUGGEST_EDIT.AFFILIATION')}
          value="medical affiliation"
        />
      </SuggestEditCheckBoxGroup>
      <SuggestEditCheckBoxGroup
        label={t('SUGGEST_EDIT.LOCATION_INFO')}
        model="checkbox-group"
        size="sm"
      >
        <Layout.Group style={{ paddingBottom: '15px' }}>
          {locationsList.length === 1 && (
            <SuggestEditLocationText
              aria-haspopup="dialog"
              data-auto-testid="suggst-edit-choose-location"
              data-testid="suggst-edit-choose-location"
            >
              {locationsList[0].value.split(',')[0]}
            </SuggestEditLocationText>
          )}

          {locationsList.length > 1 && mobileScreen && (
            <React.Fragment>
              <SuggestEditLocationText
                data-auto-testid="suggst-edit-choose-location"
                data-testid="suggst-edit-choose-location"
                onClick={() => {
                  if (locationsList?.length > 1) {
                    setIsOpen(true);
                  }
                }}
              >
                {selectedLocationValue.split(',')[0] ||
                  t('SUGGEST_EDIT.CHOOSE_LOCATION')}
              </SuggestEditLocationText>
              <IconMaterial
                data-auto-testid="choose-location-carot"
                data-testid="choose-location-carot"
                icon="keyboard_arrow_down"
                onClick={() => setIsOpen(true)}
              />
              <Drawer
                footer={
                  <Button
                    css={{
                      'abyss-button-root': {
                        width: '343px',
                        height: '42px',
                        textAlign: 'center',
                        margin: '0 auto',
                        display: 'block',
                      },
                    }}
                    data-auto-testid="choose-button"
                    data-testid="choose-button"
                    onClick={() => {
                      setSelectedLocationValue(radioValue);
                      setIsOpen(false);
                    }}
                  >
                    Choose
                  </Button>
                }
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                position="bottom"
                size={window.innerHeight - 530}
                title={
                  <Label color="#323334" size="20.25px" weight="$bold">
                    {t('SUGGEST_EDIT.CHOOSE_LOCATION')}
                  </Label>
                }
              >
                <Drawer.Section>
                  <SuggestEditChooseLocation
                    providerLocations={providerLocations}
                    radioValue={radioValue}
                    setRadioValue={setRadioValue}
                  />
                </Drawer.Section>
              </Drawer>
            </React.Fragment>
          )}
          {locationsList.length > 1 && !mobileScreen && (
            <DropdownMenu
              after={<IconMaterial icon="keyboard_arrow_down" />}
              css={{
                'abyss-dropdown-menu-content': {
                  padding: '$md 12px',
                  border: '1px solid #D0D0D0',
                  borderRadius: '5px',
                  width: '300px',
                },
                'abyss-dropdown-menu-trigger': {
                  color: '$primary1',
                },
                'abyss-dropdown-menu-item': {
                  height: '70px',
                  marginLeft: '12px',
                },
              }}
              data-auto-testid="choose-location-dropdown"
              data-testid="choose-location-dropdown"
              label={
                selectedLocationValue.split(',')[0] ||
                t('SUGGEST_EDIT.CHOOSE_LOCATION')
              }
              menuItems={locationsRadioGroup}
            />
          )}
        </Layout.Group>
        <CheckBoxContainer
          css={{
            backgroundColor: selectedLocationValue ? '$white' : '$gray2',
          }}
        >
          <SuggestEditCheckBox
            isDisabled={!selectedLocationValue}
            label={t('SUGGEST_EDIT.ADDRESS')}
            model="disabled"
            value="address"
          />
          <SuggestEditCheckBox
            isDisabled={!selectedLocationValue}
            label={t('SUGGEST_EDIT.HOURS')}
            model="disabled"
            value="hours"
          />
          <SuggestEditCheckBox
            isDisabled={!selectedLocationValue}
            label={t('SUGGEST_EDIT.PHONE_NO')}
            model="disabled"
            value="phone number"
          />
          <SuggestEditCheckBox
            isDisabled={!selectedLocationValue}
            label={t('SUGGEST_EDIT.WEBSITE')}
            model="disabled"
            value="website"
          />
          <SuggestEditCheckBox
            isDisabled={!selectedLocationValue}
            label={t('SUGGEST_EDIT.PATIENT_STATUS')}
            model="disabled"
            value="patient status"
          />
          <SuggestEditCheckBox
            isDisabled={!selectedLocationValue}
            label={t('SUGGEST_EDIT.OPEN_BUSINESS')}
            model="disabled"
            value="open business"
          />
        </CheckBoxContainer>
      </SuggestEditCheckBoxGroup>

      <SuggestEditCheckBoxGroup
        label={t('SUGGEST_EDIT.OTHER')}
        model="checkbox-group"
        size="sm"
      >
        <SuggestEditCheckBox
          label={t('SUGGEST_EDIT.SOMETHING_INCORRECT')}
          value="something incorrect"
        />
      </SuggestEditCheckBoxGroup>
    </Flex>
  );
};
