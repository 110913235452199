import { Divider } from '@abyss/web/ui/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Provider } from '../../models/Provider';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import {
  BottomWrapper,
  BottomWrapperChoosePCP,
  ChooseAsPCPButton,
} from './DataCard.style';
import { Direction } from './Direction';
import { Phone } from './Phone';
import { Schedule } from './Schedule';

type Props = {
  choosePCP: boolean;
  providerId: string;
  sectionTypeDerived: string;
  handleChoosePCPClick: any;
  headers: ResponseHeaders;
  getLinkPositionIndex: any;
  linkLocationName: string;
  index?: number;
  primaryPhone: string;
  practitioner?: Provider;
  providerType?: string;
  searchTerm?: string;
  handleAdobeSearchResultClickEvent: any;
  selectedFilters?: string;
  handleNavigationOnClick: any;
  npi;
  docasapPracticeId: string;
};
export const ChoosePCP = ({
  selectedFilters,
  choosePCP,
  providerId,
  sectionTypeDerived,
  handleChoosePCPClick,
  headers,
  getLinkPositionIndex,
  linkLocationName,
  index,
  primaryPhone,
  practitioner,
  providerType,
  searchTerm,
  handleAdobeSearchResultClickEvent,
  handleNavigationOnClick,
  npi,
  docasapPracticeId,
}: Props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {choosePCP ? (
        <BottomWrapperChoosePCP alignItems="center" justify="space-between">
          <ChooseAsPCPButton
            data-auto-testid="choose-pcp-button"
            data-providerid={providerId}
            data-testid={`${'choose-pcp-button'}-${sectionTypeDerived.replace(
              / /g,
              '-'
            )}`}
            onClick={handleChoosePCPClick}
            size="$sm"
            variant="outline"
          >
            {t('PRIMARY_CARE_PROVIDER.CHOOSE_PCP')}
          </ChooseAsPCPButton>
          <Phone
            headers={headers}
            id={providerId}
            indexForAnalytics={getLinkPositionIndex(index)}
            locationForAnalytics={linkLocationName}
            phone={primaryPhone}
            practitioner={practitioner}
            providerType={providerType}
            searchTerm={searchTerm}
            sectionType={sectionTypeDerived}
            selectedFilters={selectedFilters}
          />
        </BottomWrapperChoosePCP>
      ) : (
        <BottomWrapper justify="space-evenly">
          <Direction
            handleClick={() => {
              handleAdobeSearchResultClickEvent('address');
              handleNavigationOnClick();
            }}
            sectionType={sectionTypeDerived}
          />
          <div style={{ height: 'auto' }}>
            <Divider margin={0} orientation="vertical" width={1} />
          </div>
          <Phone
            headers={headers}
            id={providerId}
            indexForAnalytics={getLinkPositionIndex(index)}
            locationForAnalytics={linkLocationName}
            phone={primaryPhone}
            practitioner={practitioner}
            providerType={providerType}
            searchTerm={searchTerm}
            sectionType={sectionTypeDerived}
            selectedFilters={selectedFilters}
          />
          <Schedule
            handleSearchResultClick={handleAdobeSearchResultClickEvent}
            id={providerId}
            npi={npi}
            phone={primaryPhone}
            practiceId={docasapPracticeId}
            sectionType={sectionTypeDerived}
          />
        </BottomWrapper>
      )}
    </React.Fragment>
  );
};
