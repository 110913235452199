import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useCoverageType } from '../../hooks/useCoverageType';
import { useLagoon } from '../../hooks/useLagoon';
import { ProviderLocation, ProviderType } from '../../models/ProviderDetails';
import {
  getFormattedPhoneNumber,
  getPrimaryPhone,
} from '../../utils/phone.utils';
import { getSelectedLocationIndex } from '../../utils/providerDetails.utils';
import { getIsCurrentMemberSubscriber } from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import {
  CareCategories,
  Constants,
  ReverseCoverageTypesCodes,
} from '../Constants';
import { ScheduleModal } from '../ScheduleModal';
import { getFeatureFlag } from '../Utils';
import { InteractButton } from './DetailsPageHeader.styled';

export type Props = {
  chip: string;
  npi: string;
  practiceId: string;
  providerLocations: ProviderLocation[];
  providerType: string;
  category: string;
};

export const DetailsPageChips = ({
  chip,
  npi,
  practiceId,
  providerLocations,
  providerType,
  category,
}: Props) => {
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const { t } = useTranslation();
  const coverageType = useCoverageType();
  const featureFlags = useLagoon('feature-flags')();
  const showScheduleButton = getFeatureFlag(
    featureFlags,
    'ENABLE_SCHEDULE_OPTION'
  );
  const schedulingEnabled = getFeatureFlag(featureFlags, 'ONLINE_SCHEDULING');
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const index = getSelectedLocationIndex(providerLocations, selectedId);

  const phone = providerLocations?.[index]?.phones?.phone;
  const appointment = providerLocations?.[index]?.phones?.appointment;
  const primaryPhoneNumber = getPrimaryPhone(phone, appointment);
  const website = providerLocations?.[index]?.website;
  const email = providerLocations?.[index]?.emailAddress;
  const isCurrentMemberSubscriber = getIsCurrentMemberSubscriber();
  const docAsapEnabled = schedulingEnabled && practiceId;

  const handleScheduleButtonClick = () => {
    adobeLinkTrackEvent({
      name: Constants.DETAILS_CHIP_NAMES.Schedule,
      location: 'body:details header',
      type: 'internal',
    });
    adobeModalTrackEvent(
      `schedule a visit:${docAsapEnabled ? 'schedule' : 'call'}`
    );
    setIsOpenScheduleModal(true);
  };

  const chipText = (chipValue) => {
    let chipContent;
    const phonePrimaryTestId = 'phone-primary-detailes-header';

    switch (chipValue) {
      case Constants.DETAILS_CHIP_NAMES.Directions:
        chipContent = Constants.DETAILS_CHIP_NAMES.Directions;
        break;

      case Constants.DETAILS_CHIP_NAMES.Schedule:
        chipContent =
          !showScheduleButton ||
          !isCurrentMemberSubscriber ||
          [
            ReverseCoverageTypesCodes.DENTAL,
            ReverseCoverageTypesCodes.VISION,
          ].includes(coverageType) ||
          providerType !== ProviderType.PRACTITIONER ? null : (
            <React.Fragment>
              <InteractButton
                before={<IconMaterial color="$primary1" icon="date_range" />}
                data-auto-testid="provider-details-chips-schedule"
                data-testid="provider-details-chips-schedule"
                key={`provider-details-chips-${chipValue}`}
                onClick={handleScheduleButtonClick}
                variant="outline"
              >
                {t('DETAILS_CHIP_NAMES.Schedule')}
              </InteractButton>
              <ScheduleModal
                isOpen={isOpenScheduleModal}
                npi={npi}
                phone={primaryPhoneNumber}
                practiceId={practiceId}
                setIsOpen={setIsOpenScheduleModal}
              />
            </React.Fragment>
          );

        break;

      case Constants.DETAILS_CHIP_NAMES.Call:
        chipContent =
          !primaryPhoneNumber ||
          category === CareCategories.DENTAL ||
          category === CareCategories.VISION ? null : (
            <InteractButton
              before={<IconMaterial color="$primary1" icon="call_end" />}
              data-auto-testid={phonePrimaryTestId}
              data-testid={phonePrimaryTestId}
              href={`tel:${getFormattedPhoneNumber(primaryPhoneNumber)}`}
              key={`provider-details-chips-${chipValue}`}
              onClick={() =>
                adobeLinkTrackEvent({
                  name: phonePrimaryTestId,
                  location: 'body:details header',
                  type: 'phone',
                })
              }
              variant="outline"
            >
              {t('DETAILS_CHIP_NAMES.Call')}
            </InteractButton>
          );

        break;

      case Constants.DETAILS_CHIP_NAMES.Email:
        chipContent = email ? (
          <InteractButton
            before={<IconMaterial color="$primary1" icon="email" />}
            color="$primary1"
            data-auto-testid={`${email}-email-address`}
            data-testid={`${email}-email-address`}
            fontWeight="$semibold"
            href={`mailto:${email}`}
            key={`provider-details-chips-${chipValue}`}
            onClick={() =>
              adobeLinkTrackEvent({
                name: 'email chip',
                location: 'body:details header',
                type: 'email',
                destinationUrl: `mailto:${email}`,
              })
            }
            variant="outline"
          >
            {t('DETAILS_CHIP_NAMES.Email')}
          </InteractButton>
        ) : null;
        break;

      case Constants.DETAILS_CHIP_NAMES.Website:
        chipContent = website ? (
          <InteractButton
            before={<IconMaterial color="$primary1" icon="web" />}
            color="$primary1"
            data-auto-testid={`provider-website${website}`}
            data-testid={`provider-website${website}`}
            fontWeight="$semibold"
            href={website}
            key={`provider-details-chips-${chipValue}`}
            onClick={() =>
              adobeLinkTrackEvent({
                name: 'website chip',
                location: 'body:details header',
                type: 'external',
                destinationUrl: website,
              })
            }
            variant="outline"
          >
            {t('DETAILS_CHIP_NAMES.Website')}
          </InteractButton>
        ) : null;
        break;
    }
    return chipContent;
  };
  return chipText(chip);
};
