import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';

import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { LeapFrogRating as LeapFrogRatings } from '../../../../models/ProviderDetails';
import { phoneOnly } from '../../../ConstantsStyles';
import {
  FillBar,
  RatingHeader,
  RatingText,
  ratingContainerStyle,
} from './LeapFrogRating.styles';

type Props = {
  leapFrogRatings: LeapFrogRatings[];
};

export const LeapFrogRating = ({ leapFrogRatings }: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;

  return (
    <Flex
      css={{
        'abyss-flex-root': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          backgroundColor: 'white',
          boxSizing: 'border-box',
        },
      }}
      direction="row"
    >
      {leapFrogRatings.map((rating) => {
        const ratingAsInt = parseInt(rating.rawValue, 10);
        return (
          <Flex
            css={ratingContainerStyle(phoneScreen)}
            direction="column"
            key={`leapfrog-rating-${rating.rawValue}`}
          >
            <Text css={RatingHeader}>{rating.displayField}</Text>
            <Flex direction="row">
              {[0, 1, 2, 3].map((num: number) => (
                <Box
                  color={
                    num < ratingAsInt && ratingAsInt <= 4
                      ? '$success1'
                      : '$gray4'
                  }
                  css={{
                    'abyss-box-root': {
                      ...FillBar,
                      marginRight: num === 3 ? '0' : '$sm',
                      '@screen < $sm': {
                        width: '79.75px',
                      },
                    },
                  }}
                  key={num}
                />
              ))}
            </Flex>
            <Text css={RatingText}>{rating.ratingValue}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
