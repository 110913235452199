import { useForm } from '@abyss/web/hooks/useForm';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';
import React from 'react';

import {
  HealthgradesProviderReview,
  Survey,
} from '../../../../../models/Healthgrades';
import { AllPatientReviewsSortMobile } from '../../../../AllPatientReviews/AllPatientReviewsSortMobile';
import { PatientReviewsSortOrder } from '../../../../AllPatientReviews/AllPatientReviewsSortOrder';
import {
  sortReviewsHighestRating,
  sortReviewsLowestRating,
  sortReviewsMostRecent,
} from '../../../../AllPatientReviews/RatingsSection';
import { DisclaimersContainer } from '../../../../DisclaimersContainer/DisclaimersContainer';
import { PatientReviewsOverviewSection } from '../../../../ProviderDetailTabs/About/PatientReviews/PatientReviewsOverviewSection';
import { PatientVerbatimCard } from '../../../../ProviderDetailTabs/About/PatientReviews/PatientVerbatimCard';
import {
  PaginationComponentStyled,
  RatingSectionContainerStyles,
  TextSubHeadingWhatPatientsAreSaying,
  sortButtonMobileStyles,
  viewAllVerbatimFlexStyles,
} from './RatingsSection.styled';

type Props = {
  healthgradesReview: HealthgradesProviderReview;
  handleOnClick: () => void;
  setReviewModal: (a: Survey) => void;
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  currentPage: number;
  pageIndex: number;
  pageCount: number;
  gotoPage: (a: number) => void;
  pageSize: number;
  setPageSize: (a: number) => void;
  paginationProps: any;
  setSortOrder: (a: PatientReviewsSortOrder) => void;
  sortOrder: PatientReviewsSortOrder;
};
export const RatingsSection = ({
  healthgradesReview,
  handleOnClick,
  setReviewModal,
  isOpen,
  setIsOpen,
  currentPage,
  pageIndex,
  pageCount,
  gotoPage,
  pageSize,
  setPageSize,
  paginationProps,
  setSortOrder,
  sortOrder,
}: Props) => {
  const form = useForm({
    defaultValues: {
      'dropdown-menu-radios': 10,
    },
  });

  const sortedReviews = (healthgradesReview.surveyResults?.surveys || []).sort(
    // eslint-disable-next-line no-nested-ternary
    sortOrder === PatientReviewsSortOrder.MOST_RECENT
      ? sortReviewsMostRecent
      : sortOrder === PatientReviewsSortOrder.HIGHEST_RATING
      ? sortReviewsHighestRating
      : sortReviewsLowestRating
  );

  return (
    <div style={RatingSectionContainerStyles}>
      {healthgradesReview?.surveyResults ? (
        <div>
          <PatientReviewsOverviewSection
            healthgradesReview={healthgradesReview}
          />
          {healthgradesReview?.surveyResults?.surveys?.length ? (
            <div>
              <Flex alignItems="center" direction="row" justify="space-between">
                <TextSubHeadingWhatPatientsAreSaying>
                  {t(
                    'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING'
                  )}
                </TextSubHeadingWhatPatientsAreSaying>
                <Button
                  before={<IconMaterial icon="swap_vert" />}
                  css={sortButtonMobileStyles}
                  data-auto-testid="result-sort-mobile"
                  data-testid="result-sort-mobile"
                  onClick={handleOnClick}
                  variant="outline"
                >
                  <Text color="$primary1" fontWeight={600}>
                    {t('Sort')}
                  </Text>
                </Button>
              </Flex>
              <Flex
                alignItems="flex-end"
                css={{
                  marginBottom: '0px',
                }}
                direction="row"
                justify="space-between"
              >
                <div>
                  <Flex alignItems="center" css={{ gap: '$sm' }}>
                    <FormProvider
                      data-auto-testid="set-page-size-reviews"
                      data-testid="set-page-size-reviews"
                      onSubmit={setPageSize}
                      state={form}
                    />
                  </Flex>
                </div>
                <AllPatientReviewsSortMobile
                  isOpen={isOpen}
                  onApply={setIsOpen}
                  setIsOpen={setIsOpen}
                  setSortOrder={setSortOrder}
                  sortOrder={sortOrder}
                />
              </Flex>

              <Flex css={viewAllVerbatimFlexStyles}>
                {sortedReviews
                  .slice(pageIndex * pageSize, currentPage * pageSize)
                  .map((survey) => (
                    <PatientVerbatimCard
                      key={survey.id}
                      setReviewModal={setReviewModal}
                      survey={survey}
                    />
                  ))}
              </Flex>
              {pageCount >= 1 && (
                <PaginationComponentStyled
                  {...paginationProps}
                  data-auto-testid="pagination-component-mobile"
                  data-testid="pagination-component-mobile"
                  gotoPage={gotoPage}
                  pageSize={pageSize}
                  variant="extended"
                />
              )}
              <DisclaimersContainer hideDivider />
            </div>
          ) : null}
        </div>
      ) : (
        <Text>
          {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.NO_REVIEWS_FOUND')}
        </Text>
      )}
    </div>
  );
};
