import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { CollapseProvider } from '@abyss/web/ui/CollapseProvider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import {
  PhoneNumbers,
  ProviderDetails,
  ProviderType,
} from '../../../models/ProviderDetails';
import { getTokenData } from '../../../utils/common';
import {
  getAdditionalPhone,
  getFormattedPhoneNumber,
  getPrimaryPhone,
} from '../../../utils/phone.utils';
import { ReverseCoverageTypesCodes } from '../../Constants';
import { phoneOnly } from '../../ConstantsStyles';
import {
  ArrowContainer,
  CollapsePhonesContainer,
  CollapsePhonesHeader,
  IconAlignmentContainer,
  PhonesContainer,
} from './ProviderLocation.style';
import {
  CollapsePhoneContainer,
  CollapsePhoneNumberContainer,
  IconMaterialContainer,
  PhoneAllContainer,
  infoSubTextContainer,
} from './ProviderLocationsDetails.style';

export type Props = {
  collapseHeader: string;
  phoneNumbers?: PhoneNumbers | null;
  providerDetails?: ProviderDetails;
  tty: string;
};

const mobileScreen = (isWidget) => useMediaQuery(phoneOnly) || isWidget;

export const CollapsePhones = ({
  collapseHeader,
  phoneNumbers,
  providerDetails,
  tty,
}: Props) => {
  const { t } = useTranslation();

  const tokenData = getTokenData();
  const coverageType = tokenData?.coverageType;
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = mobileScreen(isWidget);
  const showAdditionalPhones =
    coverageType !== ReverseCoverageTypesCodes.VISION &&
    coverageType !== ReverseCoverageTypesCodes.DENTAL;
  const showExamPhone =
    coverageType === ReverseCoverageTypesCodes.VISION &&
    providerDetails?.providerType === ProviderType.PRACTITIONER;

  const { phone, appointment, emergency } = phoneNumbers ?? {};
  const appointmentPhones = getAdditionalPhone(phone, appointment);
  const primaryPhones = getPrimaryPhone(phone, appointment);
  const [showMorePhoneNumbers, setShowMorePhoneNumbers] =
    useState<boolean>(false);

  return (
    <React.Fragment>
      <div
        data-auto-testid="provider-locations-collapse-phones"
        data-testid="provider-locations-collapse-phones"
      >
        <PhoneAllContainer css={{ margin: '4px 0 0 0' }}>
          <IconMaterialContainer color="$primary1" icon="call" size={18} />
          <CollapsePhoneContainer>
            {t('PROVIDER_LOCATIONS_DETAILS.PRIMARY_PHONE')}

            <CollapsePhoneNumberContainer>
              {primaryPhones ? (
                <a
                  data-auto-testid="phone-primary"
                  data-testid="phone-primary"
                  href={`tel:${primaryPhones}`}
                >
                  {getFormattedPhoneNumber(primaryPhones)}
                </a>
              ) : (
                <Text css={infoSubTextContainer(phoneScreen)}>
                  {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                </Text>
              )}
            </CollapsePhoneNumberContainer>
          </CollapsePhoneContainer>
        </PhoneAllContainer>
        <div>
          <CollapseProvider>
            <CollapsePhonesContainer
              aria-expanded={showMorePhoneNumbers}
              data-testid={collapseHeader}
              onClick={() => {
                setShowMorePhoneNumbers(!showMorePhoneNumbers);
              }}
              role="button"
              tabIndex="0"
            >
              <CollapsePhonesHeader>{collapseHeader}</CollapsePhonesHeader>
              <ArrowContainer>
                {showMorePhoneNumbers ? (
                  <IconMaterial icon="keyboard_arrow_up" size={20} />
                ) : (
                  <IconMaterial icon="keyboard_arrow_down" size={20} />
                )}
              </ArrowContainer>
            </CollapsePhonesContainer>
            <PhonesContainer cssProps={{ showMorePhoneNumbers }}>
              {showAdditionalPhones ? (
                <div>
                  <IconAlignmentContainer css={{ marginTop: '13px' }}>
                    <PhoneAllContainer>
                      <IconMaterialContainer
                        color="$primary1"
                        icon="call"
                        size={18}
                      />
                      <CollapsePhoneContainer>
                        {t('PROVIDER_LOCATIONS_DETAILS.APPOINTMENT_PHONE')}
                        <CollapsePhoneNumberContainer>
                          {appointmentPhones ? (
                            <a
                              data-auto-testid={appointmentPhones}
                              data-testid={appointmentPhones}
                              href={`tel:${appointmentPhones}`}
                            >
                              {getFormattedPhoneNumber(appointmentPhones)}
                            </a>
                          ) : (
                            <Text css={infoSubTextContainer(phoneScreen)}>
                              {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                            </Text>
                          )}
                        </CollapsePhoneNumberContainer>
                      </CollapsePhoneContainer>
                    </PhoneAllContainer>
                  </IconAlignmentContainer>
                  <IconAlignmentContainer>
                    <PhoneAllContainer>
                      <IconMaterialContainer
                        color="$primary1"
                        icon="call"
                        size={18}
                      />
                      <CollapsePhoneContainer>
                        {t('PROVIDER_LOCATIONS_DETAILS.EMERGENCY_PHONE')}
                        <CollapsePhoneNumberContainer>
                          {emergency ? (
                            <a
                              data-auto-testid={`emergency-phone-${emergency}`}
                              data-testid={`emergency-phone-${emergency}`}
                              href={`tel:${emergency[0]}`}
                            >
                              {getFormattedPhoneNumber(emergency[0])}
                            </a>
                          ) : (
                            <Text css={infoSubTextContainer(phoneScreen)}>
                              {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                            </Text>
                          )}
                        </CollapsePhoneNumberContainer>
                      </CollapsePhoneContainer>
                    </PhoneAllContainer>
                  </IconAlignmentContainer>
                </div>
              ) : null}
              {showExamPhone ? (
                <IconAlignmentContainer>
                  <PhoneAllContainer>
                    <IconMaterialContainer
                      color="$primary1"
                      icon="call"
                      size={18}
                    />
                    <CollapsePhoneContainer>
                      {t('OTHER_CARE_DETAILS.EXAM_PHONE')}
                      <CollapsePhoneNumberContainer>
                        {emergency ? (
                          <a
                            data-auto-testid={`emergency-phone-${emergency}`}
                            data-testid={`emergency-phone-${emergency}`}
                            href={`tel:${emergency[0]}`}
                          >
                            {getFormattedPhoneNumber(emergency[0])}
                          </a>
                        ) : (
                          <Text css={infoSubTextContainer(phoneScreen)}>
                            {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                          </Text>
                        )}
                      </CollapsePhoneNumberContainer>
                    </CollapsePhoneContainer>
                  </PhoneAllContainer>
                </IconAlignmentContainer>
              ) : null}
              {showAdditionalPhones ? (
                <IconAlignmentContainer>
                  <PhoneAllContainer>
                    <IconMaterialContainer
                      color="$primary1"
                      icon="call"
                      size={18}
                    />
                    <CollapsePhoneContainer>
                      {t('PROVIDER_LOCATIONS_DETAILS.AFTER_HOURS_PHONE')}
                      <CollapsePhoneNumberContainer>
                        <Text css={infoSubTextContainer(phoneScreen)}>
                          {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                        </Text>
                      </CollapsePhoneNumberContainer>
                    </CollapsePhoneContainer>
                  </PhoneAllContainer>
                </IconAlignmentContainer>
              ) : null}
              <IconAlignmentContainer>
                <PhoneAllContainer css={{ marginBottom: '0px' }}>
                  <IconMaterialContainer
                    color="$primary1"
                    icon="call"
                    size={18}
                  />
                  <CollapsePhoneContainer>
                    {t('PROVIDER_LOCATIONS_DETAILS.TTY')}
                    <CollapsePhoneNumberContainer>
                      {tty ? (
                        <a
                          data-auto-testid={tty}
                          data-testid={tty}
                          href={`tel:${tty}`}
                        >
                          {tty}
                        </a>
                      ) : (
                        <Text css={infoSubTextContainer(phoneScreen)}>
                          {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                        </Text>
                      )}
                    </CollapsePhoneNumberContainer>
                  </CollapsePhoneContainer>
                </PhoneAllContainer>
              </IconAlignmentContainer>
            </PhonesContainer>
          </CollapseProvider>
        </div>
      </div>
    </React.Fragment>
  );
};
