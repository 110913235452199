import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';

import { ReverseCoverageTypesCodes } from '../../common/Constants';
import { StoreKeys } from '../useStore/state';
import { useStore } from '../useStore/useStore';

export const useCoverageType = () => {
  const { getRouteParams } = useRouter();
  const storeCoverageType = useStore(StoreKeys.COVERAGE_TYPE);

  const { token } = getRouteParams();
  const { coverageType } = tokenizer.parse(token) || {};

  if (coverageType) {
    return coverageType;
  } else if (typeof storeCoverageType === 'string' && storeCoverageType) {
    // Check type to not adversely affect tests
    return storeCoverageType;
  }

  // Default
  return ReverseCoverageTypesCodes.MEDICAL;
};
