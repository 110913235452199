import { MemberInfo } from '../../PSXHeader/ObapiDemo/memberProfile';
import { dateFormatUTC } from '../datesUtils/formatDate';

interface Big5 {
  FirstName: string;
  LastName: string;
  DOB: string;
  MemberNumber: string;
  GroupNumber: string;
}
export const findLoggedInMember = (
  profiles: MemberInfo[],
  big5: Big5
): number => {
  if (
    !profiles ||
    !big5 ||
    !big5.FirstName ||
    !big5.LastName ||
    big5.MemberNumber?.length === 0
  ) {
    return 0;
  }
  const index = profiles.findIndex((profile) => {
    const { firstName, lastName } = profile.demographics?.name;
    const { dateOfBirth } = profile.demographics;
    return (
      firstName.toUpperCase() === big5.FirstName.toUpperCase() &&
      lastName.toUpperCase() === big5.LastName.toUpperCase() &&
      dateOfBirth === dateFormatUTC(big5.DOB, 'yyyy-MM-dd')
    );
  });
  return index === -1 ? 0 : index;
};
