import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { OPTUM_CORRELATION_ID_HEADER } from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { Address } from '../../models/ProviderDetails';
import {
  getAutoIncrementRadius,
  getLagoonConfigValue,
  getSearchRadius,
} from '../../utils/providerSearch.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getOnlineRetailers,
  getPlanVariationCode,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import GET_SNACK_CARD_PROVIDER_SEARCH_QUERY from './SnackCardProviderSearch.graphql';

export interface SnackCardProvider {
  providerId: string;
  providerName: string;
  providerType: string;
  primaryDegrees?: string[];
  specialities?: string[];
  distance: string;
  locationId: string;
  networkStatus?: string;
  npi: string;
  healthGradeRating?: string;
  organizationType?: string[];
  speciality: string;
  imageLocation: string;
  isTieredProvider?: boolean;
  isPreferredLab?: boolean;
  isFreeStandingFacility?: boolean;
  ddpCode?: string;
  isPremiumCarePhysician?: string;
  website?: string;
  address?: Address;
  virtualCareOffered?: string[];
  firstName?: string;
  middleName?: string;
  lastName?: string;
  surveyCount?: number;
}

const filterSearchResults = (searchResult) => {
  const providerSearchResult = get(searchResult, 'snackCardProviderSearch', {});
  const filteredResult = providerSearchResult.snackCardList;
  return {
    isTieredPlan: providerSearchResult.isTieredPlan,
    totalCount: providerSearchResult.totalCount,
    snackCardList: filteredResult,
    searchRadius: providerSearchResult?.searchRadius,
  };
};

export const useSnackCardProviderSearch = (options) => {
  const [filtredResult, setFiltredResultResult] = useState({});
  const [headers, setHeaders] = useState({});
  const [queryResult, queryAction] = useCustomQuery(
    GET_SNACK_CARD_PROVIDER_SEARCH_QUERY,
    {
      ...options,
      url: config('GRAPHQL_API_URL'),
      accessor: 'snackCardProviderSearch',
      onCompleted: async (result) => {
        const data = filterSearchResults(result?.data);
        if (options?.onCompleted) {
          options.onCompleted(data);
        }
        setFiltredResultResult(data);
        setHeaders({
          correlationId: result.headers[OPTUM_CORRELATION_ID_HEADER],
        });
      },
    }
  );

  const parsedResult = {
    ...queryResult,
    data: filtredResult,
    headers,
  };

  return [parsedResult, queryAction];
};

export const useSnackCardProviderResults = (variables) => {
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );

  const rollupCodeMapLagoonData = useLagoon('rollupcode-mapping')();

  const rollupCodeMap = rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (item.source && item.destination) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});

  const locale = getLanguage()?.code || 'en';
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const {
    providerType,
    includeSpecialityRollupCodes,
    pcpIndicator,
    coverageType = tokenData?.coverageType,
    onlineRetailers,
    shouldGetHGData = false,
  } = variables;

  const { longitude, latitude, stateCode } = useGeoLocationStorage();
  const allConfigs = useLagoon('config');
  const searchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const autoIncrementRadius = getLagoonConfigValue(
    allConfigs,
    'AUTO_INCREMENT_SEARCH_RADIUS'
  );

  const currentMember = getCurrentMember();

  const [snackCardProviderResults, getSnackCardProviderResults] =
    useSnackCardProviderSearch({ onCompleted: variables.onCompleted });

  useEffect(() => {
    getSnackCardProviderResults({
      variables: {
        locale,
        longitude,
        latitude,
        stateCode,
        pcpIndicator,
        includeSpecialityRollupCodes: includeSpecialityRollupCodes ?? '',
        providerType: providerType ?? '',
        lob: currentMember?.lineOfBusiness,
        planYear: getCurrentPlanYear(),
        planVariationCode: getPlanVariationCode(currentMember, coverageType),
        reciprocityId: getNetworkIdsForPES(
          currentMember,
          coverageType,
          featureFlags
        ),
        rollupCodeMap,
        coverages: getCoverageTypes(currentMember),
        policyNumber: currentMember?.policyNumber,
        searchRadius: getSearchRadius(searchRadius),
        autoIncrementRadius: getAutoIncrementRadius(autoIncrementRadius),
        memberDemographics: {
          gender: currentMember.demographics.gender,
          dateOfBirth: currentMember.demographics.dateOfBirth,
        },
        coverageType,
        onlineRetailers: getOnlineRetailers(onlineRetailers),
        useMedicalNewRollUpCodes,
        useBHNewRollUpCodes,
        useVisionNewRollUpCodes,
        useDentalNewRollUpCodes,
        shouldGetHGData,
        ddpCode: currentMember?.ddpCode,
      },
    });
  }, [
    providerType,
    longitude,
    latitude,
    stateCode,
    pcpIndicator,
    currentMember.demographics.gender,
    currentMember.demographics.dateOfBirth,
    coverageType,
    onlineRetailers,
  ]);

  return snackCardProviderResults;
};
