export interface PhysicianDirectoryInterface {
  providerName?: string;
  providerId?: string;
  specialities: string;
}

export const renderDesktopItems = (physicianList, rows) => {
  // 3 columns in desktop
  const totalLength = physicianList?.length;
  const firstColumnLength = Math.ceil(totalLength / 3);
  const secondColumnLength = Math.ceil((totalLength - firstColumnLength) / 2);
  const firstColumnItems = physicianList?.slice(0, firstColumnLength);
  const secondColumnItems = physicianList?.slice(
    firstColumnLength,
    firstColumnLength + secondColumnLength
  );
  const thirdColumnItems = physicianList?.slice(
    firstColumnLength + secondColumnLength,
    totalLength
  );

  const items: PhysicianDirectoryInterface[][] = [];
  for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
    const rowItems: PhysicianDirectoryInterface[] = [];
    const firstColumnItem = firstColumnItems.shift();
    const secondColumnItem = secondColumnItems.shift();
    const thirdColumnItem = thirdColumnItems.shift();
    if (firstColumnItem) rowItems.push(firstColumnItem);
    if (secondColumnItem) rowItems.push(secondColumnItem);
    if (thirdColumnItem) rowItems.push(thirdColumnItem);
    items.push(rowItems);
  }
  return items;
};

export const renderTabletItems = (physicianList, rows) => {
  const columns: PhysicianDirectoryInterface[][] = [];
  const firstColumns: PhysicianDirectoryInterface[] = [];
  const secondColumns: PhysicianDirectoryInterface[] = [];
  physicianList.forEach((item, index) => {
    if (index < rows) {
      firstColumns.push(physicianList[index]);
    }
    if (index > rows - 1) {
      secondColumns.push(physicianList[index]);
    }
  });
  columns.push(firstColumns);
  columns.push(secondColumns);
  return columns;
};

export const searchProviderName = (physician, search) =>
  search.length === 0 ||
  physician.providerName
    ?.toLocaleLowerCase()
    .includes(search.toLocaleLowerCase());
