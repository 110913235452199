import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useLagoon } from '../../../../../hooks/useLagoon';
import {
  ProviderLocation,
  ProviderType,
} from '../../../../../models/ProviderDetails';
import { Directions } from '../../../../../models/RouteDirections';
import {
  getDistanceString,
  getDurationString,
  getNavTypeString,
  getStepDistanceString,
  getStepIcon,
} from '../../../../../utils/generalMap.utils';
import { getRoute, loadRoute } from '../../../../../utils/map.utils';
import {
  widgetClickEvent,
  widgetModalEvent,
} from '../../../../../utils/widgetEvents.utils';
import { Constants } from '../../../../Constants';
import { phoneOnly } from '../../../../ConstantsStyles';
import { MapDisplay } from '../../../../MapDisplay';
import { MapDirections } from '../../../../MapDisplay/MapDirections';
import { StyledAddressLine } from '../../../../ProviderDetailTabs/ProviderLocationsDetails/PopOver.style';
import {
  CantChangeLocationContainer,
  CantChangeLocationSpan,
  DrawerContentContainer,
  Heading,
  MapContainer,
  MapDirectionsContainer,
  MapViewWrap,
  PopoverContentContainer,
  PopoverContentStyle,
  StyledLocationContainer,
  StyledLocationsDropdownOption,
  locationDrawerIcon,
  locationStyle,
} from './Locations.styled';

type Props = {
  latitude: string;
  longitude: string;
  providerType: string;
  selectedLocation: ProviderLocation;
  totalLocations: number;
};

export const LocationsWidgetMapView = ({
  latitude,
  longitude,
  providerType,
  selectedLocation,
  totalLocations,
}: Props) => {
  const [directions] = useState<Directions>({
    userPlaceName: '',
    endPlaceName: '',
    routes: [],
  });

  const map = React.useRef<mapboxgl.Map | null>(null);
  useSessionStorage<any>(Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID, {
    providerId: '',
    from: '',
  });

  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);

  const [currentRoute, setCurrentRoute] = useState<number>(0);
  const [navType, setNavType] = useState<string>('driving-traffic');
  const [mapDirections, setMapDirections] = useState<Directions>(
    directions || {
      userPlaceName: '',
      endPlaceName: '',
      routes: [],
    }
  );
  const [showStepInstructions, setShowStepInstructions] =
    useState<boolean>(false);

  const mapContainerRef = React.useRef<any>(null);

  const { address, distance } = selectedLocation;
  const { postalCode } = address;
  const routeEndCoords: [number | null, number | null] = [
    parseFloat(selectedLocation.longitude),
    parseFloat(selectedLocation.latitude),
  ];

  const setNavAndTrack = (navType: string) => {
    widgetClickEvent({
      componentId: `navigation-${navType}`,
      componentLocation: 'body:locations',
      componentName: `${navType} navigation button`,
      componentType: 'button',
    });
    setNavType(navType);
  };

  const setShowStepsAndTrack = (show: boolean) => {
    widgetClickEvent({
      componentId: 'see-directions',
      componentLocation: 'body:locations',
      componentName: 'expand nav directions',
      componentType: 'button',
    });
    setShowStepInstructions(show);
  };

  const trackChangeLocationClick = () => {
    widgetModalEvent({
      modalName: 'cant change locations',
    });
  };

  useEffect(() => {
    const getMapRoute = async () => {
      const routeDirections = await getRoute(
        map.current,
        longitude,
        latitude,
        routeEndCoords[0],
        routeEndCoords[1],
        false,
        navType
      );
      if (routeDirections) setMapDirections(routeDirections);
    };
    if (routeEndCoords[0]) {
      setCurrentRoute(0);
      getMapRoute();
    }
  }, [navType, map.current]);

  useEffect(() => {
    if (directions) {
      setMapDirections(directions);
      setShowStepInstructions(false);
      setCurrentRoute(0);
    }
  }, [directions]);
  const locationPopoverContent = useLagoon('details-widget')();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <React.Fragment>
      <MapContainer data-testid="details-page-location-widget">
        <Flex css={locationStyle}>
          <Heading
            data-testid="locations-count-in-location-widget"
            display="h4"
            offset={1}
          >
            {t('PROVIDER_DETAILS.LOCATIONS')}
            {` (${totalLocations})`}
          </Heading>
          <CantChangeLocationContainer
            data-testid="cant-change-location-container"
            onClick={trackChangeLocationClick}
          >
            {mobileScreen ? (
              <CantChangeLocationSpan>
                {t('FACILITY_DETAILS.WHY_CANT_I_CHANGE_LOCATION_MOBILE')}
              </CantChangeLocationSpan>
            ) : (
              <CantChangeLocationSpan>
                {t('FACILITY_DETAILS.WHY_CANT_I_CHANGE_LOCATION')}
              </CantChangeLocationSpan>
            )}
            {mobileScreen ? (
              <React.Fragment>
                <Drawer
                  css={locationDrawerIcon}
                  data-testid="abyss-drawer-trigger-location-widget"
                  isOpen={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                  position="bottom"
                  size="200"
                  title={locationPopoverContent[0].heading}
                >
                  {providerType === ProviderType.ORGANIZATION ? (
                    <DrawerContentContainer>
                      {locationPopoverContent[0].facilityPopoverContent}
                    </DrawerContentContainer>
                  ) : (
                    <DrawerContentContainer>
                      {locationPopoverContent[0].providerPopoverContent}
                    </DrawerContentContainer>
                  )}
                </Drawer>
                <IconMaterial
                  data-testid="why-cant-i-change-location-drawer-info"
                  icon="info"
                  onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                  size={18}
                  variant="outlined"
                />
              </React.Fragment>
            ) : (
              <Popover
                aria-describedby="memberSelectionButton"
                aria-label={t('FACILITY_DETAILS.INFORMATION_FOR_LABEL')}
                content={
                  providerType === ProviderType.ORGANIZATION ? (
                    <PopoverContentContainer>
                      {locationPopoverContent[0].facilityPopoverContent}
                    </PopoverContentContainer>
                  ) : (
                    <PopoverContentContainer>
                      {locationPopoverContent[0].providerPopoverContent}
                    </PopoverContentContainer>
                  )
                }
                css={PopoverContentStyle}
                data-testid="abyss-popover-trigger-location-widget"
                position="bottom"
                positionOffset={10}
                title={locationPopoverContent[0].heading}
                width={343}
              >
                <IconMaterial
                  data-testid="why-cant-i-change-location-info"
                  icon="info"
                  size={18}
                  variant="outlined"
                />
              </Popover>
            )}
          </CantChangeLocationContainer>
        </Flex>
        <StyledLocationContainer data-testid="provider-locations-widget-box">
          <StyledLocationsDropdownOption>
            <StyledAddressLine>{address.line}</StyledAddressLine>
            {address.city}, {address.state} {postalCode} (
            {Number(distance).toFixed(1)} {t('PROVIDER_DETAILS.MILES')})
          </StyledLocationsDropdownOption>
        </StyledLocationContainer>

        <MapViewWrap>
          <MapDisplay
            coords={{ longitude, latitude }}
            directions={directions}
            locationResults={[selectedLocation]}
            map={map}
            routeEndCoords={routeEndCoords}
            setRouteEndCoords={() => {}}
          />
        </MapViewWrap>
        <MapDirectionsContainer>
          <MapDirections
            currentRoute={currentRoute}
            getDistanceString={getDistanceString}
            getDurationString={getDurationString}
            getNavTypeString={getNavTypeString}
            getStepDistanceString={getStepDistanceString}
            getStepIcon={getStepIcon}
            handleCloseNav={() => {}}
            latitude={latitude}
            loadRoute={loadRoute}
            longitude={longitude}
            map={map}
            mapContainerRef={mapContainerRef}
            mapContainerTestId="widget-map-view-container"
            mapDirections={mapDirections}
            mobileScreen={mobileScreen}
            navType={navType}
            routeEndCoords={routeEndCoords}
            setCurrentRoute={setCurrentRoute}
            setNavType={setNavAndTrack}
            setShowStepInstructions={setShowStepsAndTrack}
            showStepInstructions={showStepInstructions}
          />
        </MapDirectionsContainer>
      </MapContainer>
    </React.Fragment>
  );
};
