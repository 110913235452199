import React from 'react';

import { HealthGradesContainerStyled } from '../../frontends/ProviderSearch/routes/PSXHome/HealthGradesContainer.styled';
import * as LazyComponents from '../../frontends/ProviderSearch/routes/PSXHome/lazyComponents';
import { CareCategories } from '../Constants';
import { DisclaimersContainer } from '../DisclaimersContainer/DisclaimersContainer';

export type Props = {
  selectedChipValue: string;
};

export const DisclaimerContent = ({ selectedChipValue }: Props) => {
  const getDisclaimer = (selectValue) => {
    switch (selectValue) {
      case CareCategories.PRIMARY_CARE:
      case CareCategories.MEDICAL_SPECIALISTS:
      case CareCategories.BEHAVIORAL_HEALTH:
      case CareCategories.DENTAL:
        return (
          <HealthGradesContainerStyled css={{ marginTop: '0px' }}>
            <DisclaimersContainer hideDivider />
          </HealthGradesContainerStyled>
        );
      case CareCategories.VISION:
        return (
          <HealthGradesContainerStyled>
            <LazyComponents.DisclaimersContainer hideDivider isVisionCare />
          </HealthGradesContainerStyled>
        );
      default:
        return null;
    }
  };

  return getDisclaimer(selectedChipValue);
};
