import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { ConstantsLagoon } from '../ConstantsLagoon';
import {
  RatingContainer,
  RatingText,
  RatingsStar,
  SpanStyle,
  VoiceOnlyText,
} from './Rating.styles';
import { NoRatingText } from './SnackCard.styles';

type Props = {
  hideNumber?: boolean;
  rating?: number;
  size?: number;
  navigateToSnackDetails?: () => void;
  surveyCount?: number;
};

export const Rating = ({
  hideNumber,
  rating,
  navigateToSnackDetails,
  surveyCount,
}: Props) => {
  const { t } = useTranslation();
  const [shouldGetHGData] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_HEALTH_GRADES_INFO_FOR_PRACTIONER,
  ]);
  const surveyRating = shouldGetHGData ? ` (${surveyCount})` : '';

  if (rating === undefined || rating === null) {
    return (
      <NoRatingText color="$neutralGray" data-testid="rating-container">
        {t('Ratings not available')}
      </NoRatingText>
    );
  }

  const ratingTextComponent = (
    <RatingText color="$accent1">
      <SpanStyle aria-hidden="true">
        {`${rating.toFixed(1)}${surveyRating}`}
      </SpanStyle>
      <VoiceOnlyText>{`${rating.toFixed(1)} stars`}</VoiceOnlyText>
    </RatingText>
  );

  return (
    <RatingContainer
      alignContent="center"
      alignItems="center"
      data-auto-testid="rating-container"
      data-testid="rating-container"
      onClick={navigateToSnackDetails}
    >
      <Flex.Content aria-hidden="true">
        <RatingsStar
          color="$accent1"
          fontWeight="$normal"
          size="$extraSmallText"
          variant="native"
        >
          <IconMaterial
            css={{
              'abyss-icon': {
                position: 'relative',
                top: rating ? '0px' : '1px',
                fill: rating ? '$secondary2' : '$black',
                stroke: rating ? '$starBorder' : '',
              },
            }}
            icon={rating ? 'star_rate' : 'star_border'}
            size={rating ? 16 : '16'}
          />
        </RatingsStar>
      </Flex.Content>
      <Flex.Content>{!hideNumber ? ratingTextComponent : null}</Flex.Content>
    </RatingContainer>
  );
};
