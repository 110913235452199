/* eslint-disable */
/* Component only used for testing details widget */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Button } from '@abyss/web/ui/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { TextInput } from '@abyss/web/ui/TextInput';
import { ProviderDetailsWidget } from 'parcels/src/frontends/ProviderDetailsWidget';
import { ProviderType } from 'parcels/src/models/ProviderDetails';
import { UserDDPCode } from 'parcels/src/utils/providerDetails.utils';
import React, { useState } from 'react';

export const DetailsDrawer = () => {
  const { navigate } = useRouter();
  const [input, setInput] = useState<string>('005847827');
  const [policyId, setPolicyId] = useState<string>('0704201');
  const [providerId, setProviderId] = useState<string>('');
  const [providerType, setProviderType] = useState<ProviderType>(
    ProviderType.PRACTITIONER
  );
  const [userDDP, setUserDDP] = useState<UserDDPCode>(UserDDPCode.L);
  const [outOfPocketCost, setOutOfPocketCost] = useState<string>('');
  const [totalCost, setTotalCost] = useState<string>('');
  const [coverageType, setCoverageType] = useState<string>('M');

  const onSubmit = () => {
    setProviderId(input);
    setPolicyId(policyId);
    setOutOfPocketCost('109');
    setTotalCost('3600');
    window.scrollTo(0, 0);
  };

  const handleClick = (e) => {
    setInput(e.target.innerHTML);
  };

  if (process.env.APP_ENV === 'prod') {
    navigate('/');
  }

  const getSampleVals = () => {
    switch (coverageType) {
      case 'M':
        switch (providerType) {
          case ProviderType.ORGANIZATION:
            return {
              providerIds: [
                '002787576',
                '007212473',
                '000731852',
                '006609285',
                '000576590',
              ],
              reciprocityId: '52',
            };
          default:
            return {
              providerIds: ['005847827', '00001938905', '003645503'],
              reciprocityId: '52',
            };
        }
      case 'B':
        switch (providerType) {
          case ProviderType.ORGANIZATION:
            return {
              providerIds: ['FAC000011600'],
              reciprocityId: 'Employer%20Guest',
            };
          default:
            return {
              providerIds: ['000768779', '001071641'],
              reciprocityId: 'Employer%20Guest',
            };
        }
      case 'V':
        switch (providerType) {
          case ProviderType.ORGANIZATION:
            return {
              providerIds: ['00L22500O'],
              reciprocityId: '1',
            };
          default:
            return {
              providerIds: ['00089597P', '00017135P'],
              reciprocityId: '1',
            };
        }
      case 'D':
        return {
          providerIds: ['000009426388'],
          reciprocityId: '1',
        };
      default:
        return {};
    }
  };

  const sampleVals = getSampleVals();

  return (
    <Flex>
      <Drawer
        hideClose
        isOpen={providerId}
        onClose={() => {
          setProviderId('');
        }}
        size="600px"
      >
        <ProviderDetailsWidget
          coverage-type={coverageType}
          ddp-code={userDDP}
          latitude={'41.88169'}
          lob={'E&I'}
          longitude={'-87.6228'}
          out-of-pocket-cost={outOfPocketCost}
          plan-variation-code={'0001'}
          plan-year={'2024'}
          policy-id={policyId}
          provider-id={providerId}
          provider-type={providerType}
          reciprocity-id={sampleVals.reciprocityId}
          total-cost={totalCost}
        />
      </Drawer>
      <div style={{ margin: '24px' }}>
        <RadioGroup
          display="row"
          label="Coverage type"
          onChange={(e) => {
            setCoverageType(e.target.value);
            setInput('');
          }}
          value={coverageType}
        >
          <RadioGroup.Radio label="Medical" value="M" />
          <RadioGroup.Radio label="Behavioral" value="B" />
          <RadioGroup.Radio label="Vision" value="V" />
          {/*<RadioGroup.Radio label="Dental" value="D"/>*/}
        </RadioGroup>
        <RadioGroup
          display="row"
          label="Provider type"
          onChange={(e) => {
            setProviderType(e.target.value);
            setInput('');
          }}
          value={providerType}
        >
          <RadioGroup.Radio
            label="Practitioner"
            value={ProviderType.PRACTITIONER}
          />
          {coverageType !== 'D' && (
            <RadioGroup.Radio
              label="Facility"
              value={ProviderType.ORGANIZATION}
            />
          )}
        </RadioGroup>
        <RadioGroup
          display="row"
          label="User DDP Code"
          onChange={(e) => {
            setUserDDP(e.target.value);
          }}
          size="18"
          value={userDDP}
        >
          <RadioGroup.Radio label="L" value={UserDDPCode.L} />
          <RadioGroup.Radio label="R" value={UserDDPCode.R} />
          <RadioGroup.Radio label="A" value={UserDDPCode.A} />
          <RadioGroup.Radio label="N" value={UserDDPCode.N} />
        </RadioGroup>
        <Divider />
        <div>
          <strong>Sample IDs</strong>
        </div>
        {sampleVals?.providerIds?.map((id) => (
          <div key={id} onClick={handleClick}>
            {id}
          </div>
        ))}
        <Divider />
        <TextInput
          data-testid="details-drawer-input"
          isClearable
          label="Set provider ID"
          onChange={(e) => setInput(e.target.value)}
          onClear={() => setInput('')}
          value={input}
        />

        <TextInput
          data-testid="details-drawer-policy-input"
          isClearable
          label="Set policy ID"
          onChange={(e) => setPolicyId(e.target.value)}
          onClear={() => setPolicyId('')}
          value={policyId}
        />
        <Divider />
        <Button
          data-testid="details-drawer-policy-submit-button"
          onClick={onSubmit}
        >
          Load Details
        </Button>
      </div>
    </Flex>
  );
};

export default DetailsDrawer;
