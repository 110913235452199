import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { HealthgradesProviderReview } from '../../../../models/Healthgrades';
import { mobileOnly } from '../../../ConstantsStyles';
import { Rating } from '../../../SnackCard/Rating';
import { RatingSectionHeader } from './PatientReviews.styled';

type Props = {
  healthgradesReview: HealthgradesProviderReview;
};

export const ProviderAndOfficeAndStaffExperienceSectionDesktop = ({
  healthgradesReview,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;
  const headerSize = mobileScreen ? '$xs' : '$sm';

  return (
    <React.Fragment>
      {healthgradesReview.providerExperienceRating ? (
        <Flex
          css={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'flex-start',
            '.abyss-text-root': {
              width: '250px',
              ...(mobileScreen && { width: 'auto' }),
            },
          }}
          direction="column"
          justify="center"
        >
          <RatingSectionHeader size={headerSize}>
            {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.PROVIDER_EXPERIENCE')}
          </RatingSectionHeader>
          <Rating
            hideNumber
            rating={healthgradesReview.providerExperienceRating}
            size={16.67}
          />
        </Flex>
      ) : null}
      {healthgradesReview.officeAndStaffExperienceRating ? (
        <Flex
          css={{
            display: 'flex',
            gap: '8px',
            marginLeft: '$lg',
            justifyContent: 'flex-start',
            ...(mobileScreen && {
              paddingLeft: '$sm',
            }),
            '.abyss-text-root': {
              width: '250px',
              ...(mobileScreen && { width: 'auto' }),
            },
          }}
          direction="column"
          justify="center"
        >
          <RatingSectionHeader size={headerSize}>
            {t(
              'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.OFFICE_AND_STAFF_EXPERIENCE'
            )}
          </RatingSectionHeader>
          <Rating
            hideNumber
            rating={healthgradesReview.officeAndStaffExperienceRating}
            size={16.67}
          />
        </Flex>
      ) : null}
    </React.Fragment>
  );
};
