export const getDocAsapTargetUrl = (
  docAsapBaseUrl: string,
  docAsapPartnerCode: string,
  docAsapCredentialCode: string,
  docAsapChannelId: string,
  providerNpi: string,
  practiceId: string,
  isEAPEligible: boolean = false
) =>
  `${docAsapBaseUrl}/wl/key_npi/${providerNpi}/key_prac_id/${practiceId}/key_level/3/key_type/LINK/key_partner_code/${docAsapPartnerCode}?credentialCode=${docAsapCredentialCode}&channelId=${docAsapChannelId}&eapEligibility=${isEAPEligible}&returnTo=${docAsapBaseUrl}/wl/key_npi/${providerNpi}/key_prac_id/${practiceId}/key_level/3/key_type/LINK/key_partner_code/${docAsapPartnerCode}`;
