import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import { getStatusText } from '../../../../../../ShareDrawer/utils';
import {
  StyledOverflowAttributeDiv,
  popoverStyle,
} from '../../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../../utility/compareDrawerConstants';

type Props = {
  accessProvider: boolean;
  mobile: boolean;
};

export const ExpressAccessProvider = ({ accessProvider, mobile }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);
  const expressAccessProviderText = t(
    getStatusText(
      accessProvider,
      ATTRIBUTE_STATUS_TEXT.EXPRESS_ACCESS_PROVIDER.TRUE,
      ATTRIBUTE_STATUS_TEXT.EXPRESS_ACCESS_PROVIDER.FALSE,
      NULL_ATTRIBUTE_STATE.EXPRESS_ACCESS_PROVIDER
    )
  );

  const isAttributeEmpty =
    expressAccessProviderText === NULL_ATTRIBUTE_STATE.EXPRESS_ACCESS_PROVIDER;

  const ExpressAccessProvider = (
    <StyledOverflowAttributeDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      ref={containerRef}
    >
      {expressAccessProviderText}
    </StyledOverflowAttributeDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={expressAccessProviderText}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {ExpressAccessProvider}
          </Popover>
        ) : (
          ExpressAccessProvider
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip
          content={expressAccessProviderText}
          position="top"
          positionOffset={8}
        >
          {ExpressAccessProvider}
        </Tooltip>
      ) : (
        ExpressAccessProvider
      )}
    </React.Fragment>
  );
};
