import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { t } from 'i18next';

import { ProviderTypes } from '../../common/Constants';
import { Provider } from '../../models/Provider';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { adobeSearchResultClickEvent } from '../AdobeTagging/adobeSearchResultClickEvent';
import {
  convertProviderTypeToAdobeType,
  formatProviderId,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  BottomWrapper,
  CardContainer,
  HeadingWrapper,
  NetworkWrapper,
  ResponsiveText,
} from './DataCard.style';

type Props = {
  facilityName: string;
  headers?: ResponseHeaders;
  providerSpeciality: string;
  providerId: string;
  networkStatus: any;
  cardRef: any;
  highlightClassName: string;
  css: any;
  setHighlightId: any;
  practitioner?: Provider;
  website: string;
  index: number;
  linkLocationName: string;
  selectedFilters?: string;
  updatePin?: () => void;
};

export const DataCardWithExternalLink = ({
  facilityName,
  headers = { correlationId: '' },
  practitioner = {},
  providerSpeciality,
  providerId,
  networkStatus,
  cardRef,
  highlightClassName,
  css,
  setHighlightId,
  website,
  index,
  linkLocationName,
  selectedFilters,
  updatePin,
}: Props) => (
  <CardContainer
    className={highlightClassName}
    css={{
      ...css,
      '&.abyss-card-root': {
        '@screen < $sm': {
          height: '178px',
        },
      },
    }}
    data-auto-testid="data-card-with-external-link"
    data-testid="data-card-with-external-link"
    id={`data-card-for-map-${providerId}`}
    onMouseEnter={() => {
      if (updatePin) {
        updatePin();
      }
      setHighlightId({ providerId });
    }}
    ref={cardRef}
    style={{ cursor: 'pointer' }}
  >
    <Card.Section css={{ padding: ' 12px 0px 12px', height: '100px' }}>
      <Flex
        css={{ flex: 1, marginLeft: '12px', marginRight: '12px' }}
        direction="column"
      >
        <HeadingWrapper
          alignLayout="left"
          css={{
            marginBottom: '8px',
            marginRight: '-4px',
            paddingLeft: '0px',
            height: '42px',
          }}
          data-auto-testid={`data-card-heading-national-ancillary-facility-${providerId}`}
          data-testid={`data-card-heading-national-ancillary-facility-${providerId}`}
        >
          <Tooltip asChild={false} content={facilityName}>
            <Text
              color="$primary1"
              css={{
                '&.abyss-text-root': {
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden',
                  fontSize: '20.25px',
                  lineHeight: '24px',
                  fontWeight: 700,
                  textAlign: 'left',
                },
              }}
              data-auto-testid={`data-card-detail-button-national-ancillary-facility`.replace(
                / /g,
                '-'
              )}
              data-testid={`data-card-detail-button-national-ancillary-facility`.replace(
                / /g,
                '-'
              )}
              fontWeight="$bold"
              size="$lg"
            >
              {facilityName}
            </Text>
          </Tooltip>
        </HeadingWrapper>
        <Flex justify="space-between">
          <Flex direction="column">
            {providerSpeciality && (
              <Tooltip asChild={false} content={providerSpeciality}>
                <ResponsiveText
                  ancillarySpeciality
                  color="$gray9"
                  data-auto-testid="data-card-specialty-national-ancillary-facility"
                  data-testid="data-card-specialty-national-ancillary-facility"
                >
                  {providerSpeciality}
                </ResponsiveText>
              </Tooltip>
            )}
            <Flex alignItems="center" />
          </Flex>
          <Flex
            alignItems="end"
            data-testid="data-card-network-national-ancillary-facility"
            direction="column"
          >
            <NetworkWrapper>
              <IconMaterial
                className="check_circle"
                classNames
                color={networkStatus ? '$success1' : ''}
                data-auto-testid={`data-card-check-circle-icon-national-ancillary-facility-${providerId}`}
                data-testid={`data-card-check-circle-icon-national-ancillary-facility-${providerId}`}
                icon="check_circle"
              />
              <ResponsiveText
                color="#333"
                data-auto-testid={`data-card-network-national-ancillary-facility-${providerId}`}
                data-testid={`data-card-network-national-ancillary-facility-${providerId}`}
              >
                {networkStatus ? t('In-network') : t('Not-In-Network')}
              </ResponsiveText>
            </NetworkWrapper>
          </Flex>
        </Flex>
      </Flex>
    </Card.Section>
    <BottomWrapper
      css={{
        padding: '8px 12px',
      }}
      justify="left"
    >
      <div
        style={{
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        <Link
          data-auto-testid="get-started-national-ancillary-facility"
          data-testid="get-started-national-ancillary-facility"
          href={website}
          onClick={() =>
            adobeSearchResultClickEvent({
              filters: selectedFilters,
              linkName: 'get-started-national-ancillary-facility',
              linkPosition: index + 1,
              linkLocation: linkLocationName,
              customAttributesBlock: {
                correlationId: headers.correlationId,
                providerId: formatProviderId(practitioner),
                providerType: convertProviderTypeToAdobeType(
                  ProviderTypes.ORGANIZATION
                ),
                destinationUrl: website,
                linkType: 'external',
              },
            })
          }
        >
          <Text
            color="$info1"
            css={{
              'abyss-text-root': {
                fontSize: '16px',
                fontWeight: 700,
              },
            }}
          >
            {t('DONT_KNOW_WHERE_TO_START_CARD.GET_STARTED')}
          </Text>
        </Link>
      </div>
    </BottomWrapper>
  </CardContainer>
);
