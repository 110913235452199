import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { PageSize, Pagination } from '@abyss/web/ui/Pagination';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../../../common/Constants';
import { ShareCompareButton } from '../ViewAll/ShareCompareButton';
import { PaginationControl, PagingationWrapStyled } from './MapView.styled';

type Props = {
  pageSize: number;
  topPaginationRef: any;
  pageSizeOptions: any;
  setPageSizeFunc: any;
  previousPageTop: any;
  sectionType: string;
  paginationProps: any;
  nextPageTop: any;
  openCompare?: boolean;
  setOpenCompare: any;
  setOpenShare: any;
  setSelectedCheckbox: any;
  setSelectedItems: any;
  openShare: boolean;
};

export const MapViewTopPagination = ({
  pageSize,
  topPaginationRef,
  pageSizeOptions,
  setPageSizeFunc,
  previousPageTop,
  sectionType,
  paginationProps,
  nextPageTop,
  openCompare,
  setOpenCompare,
  setOpenShare,
  setSelectedCheckbox,
  setSelectedItems,
  openShare,
}: Props) => {
  const { t } = useTranslation();

  return (
    <PagingationWrapStyled ref={topPaginationRef}>
      <PaginationControl
        className="hide-pagination"
        css={{ paddingBottom: '4px' }}
        data-testid="pagination-results-container"
      >
        <PageSize
          css={{
            'abyss-pagination-page-size-label': {
              fontWeight: '$semibold',
            },
          }}
          data-testid="pagination-page-size"
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          setPageSize={setPageSizeFunc}
        />
        <Pagination
          {...paginationProps}
          css={{
            'abyss-pagination-abbreviated-container': {
              color: 'var(--abyss-colors-gray8)',
              fontWeight: 400,
            },
          }}
          nextPage={nextPageTop}
          previousPage={previousPageTop}
          variant="compact"
        />
      </PaginationControl>
      <Flex>
        <Layout.Group space="15">
          <Layout.Group space="15">
            <React.Fragment>
              {sectionType === Constants.RESULT_SECTION.PROVIDER && (
                <ShareCompareButton
                  isShareButton={false}
                  openCompare={openCompare}
                  sectionType={sectionType}
                  setOpenCompare={setOpenCompare}
                  setOpenShare={setOpenShare}
                  setSelectedCheckbox={setSelectedCheckbox}
                  setSelectedItems={setSelectedItems}
                  text={t('COMPARE_BUTTONS.COMPARE')}
                  view="desktop"
                />
              )}
              <ShareCompareButton
                openShare={openShare}
                sectionType={sectionType}
                setOpenCompare={setOpenCompare}
                setOpenShare={setOpenShare}
                setSelectedCheckbox={setSelectedCheckbox}
                setSelectedItems={setSelectedItems}
                text={t('COMPARE_BUTTONS.SHARE')}
                view="desktop"
              />
            </React.Fragment>
          </Layout.Group>
        </Layout.Group>
      </Flex>
    </PagingationWrapStyled>
  );
};
