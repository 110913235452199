import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { SHA256, enc } from 'crypto-js';
import { flatten, intersection, uniq } from 'lodash';

import { APIConstants } from '../../../../../api/src/services/Constants';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  NULL_RESULTS,
  ReverseCoverageTypesCodes,
  TypeaheadBHType,
  TypeaheadProviderTypes,
  TypeaheadProviderTypesMapping,
} from '../../Constants';
import { ConstantsRoutes } from '../../ConstantsRoutes';
import {
  mappingRollUpCodeToCategory,
  mappingSpecialityRollUpCodeToCategory,
} from '../../RecentActivity/helper';
import { CustomAttributesBlock } from '../../Utils/adobeTrackUtils/adobeTrackUtils';

const { GEO_LOCATION } = Constants.STORAGE_KEYS.SESSION;

export const getGeoLocationFromStorage = () =>
  storage.local.get(GEO_LOCATION) || {};

export const setGeoLocationToStorage = (value) => {
  storage.local.set(GEO_LOCATION, value);
  window.dispatchEvent(new Event('storage'));
};

export const handleCloseLocationOptionsList = (
  isShowingLocationDrawer: boolean | null,
  isShowingLocationDropdown: boolean | null,
  callbackSetCursor: (a: number) => void,
  callbackHandleCloseLocationDrawer: () => void,
  callbackHandleCloseLocationDropdown: () => void
) => {
  if (isShowingLocationDrawer) {
    callbackHandleCloseLocationDrawer();
    callbackSetCursor(-1);
  }
  if (isShowingLocationDropdown) {
    callbackHandleCloseLocationDropdown();
    callbackSetCursor(-1);
  }
};

export const setTypeAheadResults = (
  suggestions,
  providers,
  facilities,
  network: string
) =>
  suggestions
    ?.map((item) => ({ ...item, showSearchIcon: true }))
    .concat(
      network && (providers?.length || facilities?.length)
        ? {
            section: Constants.TYPEAHEAD_SEARCH_HEADER,
            items: providers
              ?.concat(facilities)
              .map((item) => ({ ...item, showSearchIcon: false })),
          }
        : []
    );

export const setToken = (token, value) =>
  tokenizer.update(token, {
    providerId: value?.providerId,
    providerName: value?.displayName,
    coverageType: value?.coverageType,
    choosePCP: value?.choosePCP,
    chipValue: mappingSpecialityRollUpCodeToCategory(
      value?.speciality,
      value?.providerType
    ),
    searchTerm: value?.searchTerm,
    linkName: value?.linkName,
    searchMethod: value?.searchMethod,
    searchByProvider: value.searchByProvider,
    dependentSeqNbr: value?.dependentSeqNbr,
    searchFromTypeAhead: value?.searchFromTypeAhead,
  });

export const setNullToken = (term, analyticsLinkName) =>
  tokenizer.create({
    originLinkNameForAnalytics: analyticsLinkName,
    search: term,
    searchType: '',
    searchMethod: 'typed',
    resultType: NULL_RESULTS,
  });

export const navigateToMixedResultsPage = (value, token, navigate) => {
  const rollUpCodes =
    value?.pesKeyword ||
    value?.vertical?.code ||
    value?.includeSpecialityRollupCodes;

  const searchType =
    value?.searchType?.toLowerCase() || Constants.SEARCH_TYPES.SPECIALTY;

  const updatedToken = tokenizer.update(token, {
    ...value,
    search: value?.psxKeyword || value?.suggestion || value?.search,
    category: value?.category,
    searchType,
    choosePCP: value?.choosePCP,
    chipValue:
      mappingRollUpCodeToCategory(
        value?.pesKeyword || value?.includeSpecialityRollupCodes
      ) || TypeaheadProviderTypesMapping[value?.vertical?.providerType],
    includeSpecialityRollupCodes: rollUpCodes,
    originLinkNameForAnalytics: value?.linkName,
    pesKeyword: value?.pesKeyword,
    searchMethod: value?.searchMethod,
    searchTerm: value?.searchTerm,
    coverageType: value?.coverageType,
    resultType:
      value?.resultType === NULL_RESULTS
        ? NULL_RESULTS
        : Constants.RESULT_SECTION.ALL,
    keyword: value?.psxKeyword || value?.searchTerm || value?.userSearchTerm,
    pcpIndicator: false,
    coverageTypes: uniq(value?.coverageTypes)?.join(','),
  });

  navigate(`/results/${updatedToken}`);
};

export const getReqCoverageType = (
  coverageType: ReverseCoverageTypesCodes[] | ReverseCoverageTypesCodes,
  getCoverageType: Array<string>
) => {
  if (Array.isArray(coverageType)) {
    const filteredCoverages = coverageType.filter(
      (coverage) =>
        !(
          coverage === ReverseCoverageTypesCodes.MEDICAL &&
          !getCoverageType?.includes(ReverseCoverageTypesCodes.MEDICAL)
        )
    );
    if (filteredCoverages.includes(ReverseCoverageTypesCodes.MEDICAL))
      return ReverseCoverageTypesCodes.MEDICAL;
    return filteredCoverages[0];
  }
  return coverageType;
};

export const getReqCoverageTypes = (
  coverageType: ReverseCoverageTypesCodes[] | ReverseCoverageTypesCodes,
  getCoverageType: Array<string>
) => {
  if (Array.isArray(coverageType)) {
    const filteredCoverages = coverageType.filter(
      (coverage) =>
        !(
          coverage === ReverseCoverageTypesCodes.MEDICAL &&
          !getCoverageType?.includes(ReverseCoverageTypesCodes.MEDICAL)
        )
    );
    return filteredCoverages;
  }
  return coverageType;
};

export const handleSearchSelected = (value, token, navigate) => {
  const updateValue = {
    ...value,
    choosePCP: token?.choosePCP,
    dependentSeqNbr: value?.dependentSeqNbr,
  };
  if (value?.providerType === TypeaheadProviderTypes.PRACTITIONER) {
    const updateProviderValue = {
      ...value,
      choosePCP: token?.choosePCP,
      searchByProvider: true,
      searchFromTypeAhead: true,
      coverageType: value?.coverageType,
      dependentSeqNbr: value?.dependentSeqNbr,
    };
    navigate(
      `${ConstantsRoutes.PROVIDER_DETAILS.path}${setToken(
        token,
        updateProviderValue
      )}`
    );
  } else if (
    value?.providerType === TypeaheadProviderTypes.FACILITY ||
    value?.providerType === TypeaheadProviderTypes.MEDICAL_HEALTH_SUPPLIES ||
    (value?.providerType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
      value?.providerId?.startsWith(TypeaheadBHType.FAC))
  ) {
    navigate(
      `${ConstantsRoutes.FACILITY_DETAILS.path}${setToken(token, {
        ...updateValue,
        searchFromTypeAhead: true,
      })}`
    );
  } else if (
    value?.providerType === TypeaheadProviderTypes.PROVIDER_GROUP ||
    (value?.providerType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH &&
      value?.providerId?.startsWith(TypeaheadBHType.GRP))
  ) {
    navigate(
      `${ConstantsRoutes.PROVIDER_GROUP_DETAILS.path}${setToken(token, {
        ...updateValue,
        searchFromTypeAhead: true,
      })}`
    );
  } else {
    navigateToMixedResultsPage(updateValue, token, navigate);
  }
};

export const handleRandomSearch = (navigate, searchTerm, analyticsLinkName) => {
  navigate(
    `${ConstantsRoutes.NULL_SEARCH_RESULTS.path}/${setNullToken(
      searchTerm,
      analyticsLinkName
    )}`
  );
};

export const mapLocationType = (type) => {
  switch (type) {
    case APIConstants.PROVIDER_TYPES.providerGroup:
      return 'medical group';
    case APIConstants.PROVIDER_TYPES.practitioner:
      return 'provider';
    default:
      return 'facilities';
  }
};

export const getProviderResultTrackingData = (result) => {
  const { providerId, providerType, locationId, coverageType } = result;

  const mappedType = mapLocationType(providerType);

  if (locationId.endsWith('NoTin-T-NoAddress')) {
    return {
      providerType: mappedType,
      providerId,
    };
  }

  const locationSplit = locationId.split('-');
  const hashedTin = SHA256(locationSplit[1]).toString(enc.Hex);
  return {
    providerId,
    providerType: mappedType,
    tin: hashedTin,
    locationId: locationSplit[3],
    coverageType: coverageType?.at(0),
  };
};

export const getTypeAheadResultTrackingData = (TypeAheadData) => {
  const typeAheadResults = TypeAheadData.lang_provider.langProvider || [];
  TypeAheadData.organizations_uhc.orgData.map((org) =>
    typeAheadResults.push(getProviderResultTrackingData(org))
  );
  TypeAheadData.practitioners_uhc.provData.map((prac) =>
    typeAheadResults.push(getProviderResultTrackingData(prac))
  );

  return typeAheadResults;
};

export const handleKeywordSuggestionKeyDownHelper = (
  ev: {
    key: string;
    preventDefault: () => void;
    target: { value: string };
  },
  {
    keywordSearchTerm,
    commonSearchesData,
    isKeywordSearchLoading,
    searchButtonResults,
    setEnter,
    cursor,
    results,
    combineRollUpCodes,
    token,
    navigate,
    getTypeaheadData,
    headers,
    setCursor,
    convertTypeaheadProviderIdAndType,
    setIsFocusedOnKeywordSearchInput,
    searchInputOptionLocation,
    memberCoverages,
  }
) => {
  const minCursor = -1;
  const maxCursor =
    keywordSearchTerm?.trim().length < 2
      ? commonSearchesData.length + 1
      : searchButtonResults.length + 1;
  let value: any;

  if (['ArrowUp', 'ArrowDown'].some((key) => key === ev.key)) {
    ev.preventDefault();
  }
  if (ev.key === 'Enter' && !isKeywordSearchLoading) {
    const analyticsLinkNameOnEnter = 'search enter';
    const userSearchTerm = ev.target.value;
    setEnter(true);
    let customAttributesBlock;
    if (keywordSearchTerm?.length > 1) {
      if (cursor === -1) {
        const isMixedSuggestions =
          results.some((suggest) => suggest.suggestion) &&
          results.some((suggest) => suggest.section);

        let searchType = Constants.SEARCH_TYPES.NAME;
        if (results?.length > 0) {
          const isOnlyNamed = results.every((suggest) => suggest.section);
          const isOnlySpecialty = results.every(
            (suggest) => suggest.suggestion
          );

          if (isOnlyNamed || isMixedSuggestions) {
            searchType = Constants.SEARCH_TYPES.NAME;
          }

          if (isOnlySpecialty) {
            searchType = Constants.SEARCH_TYPES.SPECIALTY;
          }
        }

        const combinedCoverageTypes = flatten(
          results
            .filter((suggest) => suggest.suggestion)
            .map((suggest) => [...suggest.coverageType])
        );

        const finalCoverageTypes = combinedCoverageTypes.length
          ? intersection(combinedCoverageTypes, memberCoverages)
          : memberCoverages;

        handleSearchSelected(
          {
            userSearchTerm,
            linkName: analyticsLinkNameOnEnter,
            searchMethod: 'typed',
            resultType: userSearchTerm
              ? Constants.RESULT_SECTION.ALL
              : NULL_RESULTS,
            search: userSearchTerm,
            isMixedSuggestions,
            searchType,
            includeSpecialityRollupCodes: combineRollUpCodes,
            coverageTypes: finalCoverageTypes,
            coverageType:
              memberCoverages.length === 0 || memberCoverages.includes('M')
                ? 'M'
                : memberCoverages?.[0],
          },
          token,
          navigate
        );
      } else {
        value = getTypeaheadData();
        customAttributesBlock = value
          ? ({
              correlationId: headers?.correlationId,
              ...convertTypeaheadProviderIdAndType(value),
            } as CustomAttributesBlock)
          : undefined;
        setIsFocusedOnKeywordSearchInput(false);
        handleSearchSelected(
          {
            ...value,
            searchTerm: keywordSearchTerm,
            resultType: value ? Constants.RESULT_SECTION.ALL : NULL_RESULTS,
            search: keywordSearchTerm,
            linkName: analyticsLinkNameOnEnter,
            searchMethod: 'typed',
            searchType: value?.vertical
              ? Constants.SEARCH_TYPES.SPECIALTY
              : Constants.SEARCH_TYPES.NAME,
            includeSpecialityRollupCodes: value?.vertical || value?.pesKeyword,
          },
          token,
          navigate
        );
      }
    }

    adobeLinkTrackEvent({
      name: analyticsLinkNameOnEnter,
      location: `body:${searchInputOptionLocation}`,
      type: 'internal',
      customAttributesBlock,
    });
  } else if (ev.key === 'ArrowUp' && cursor > minCursor) {
    setCursor((previous) => previous - 1);
  } else if (ev.key === 'ArrowDown' && cursor < maxCursor) {
    setCursor((previous) => previous + 1);
  }
};
