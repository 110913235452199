import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Box } from '@abyss/web/ui/Box';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Modal } from '@abyss/web/ui/Modal';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { useAdobePageTrackEvent } from '../../hooks/adobeHook/useAdobePageTrackEvent';
import { useHealthgradesGetProviderReview } from '../../hooks/useHealthgrades';
import { HealthgradesProviderReview, Survey } from '../../models/Healthgrades';
import { ProviderType } from '../../models/ProviderDetails';
import { getProviderId } from '../../utils/providerDetails.utils';
import { getNameAttrs } from '../../utils/providerNames.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { phoneOnly } from '../ConstantsStyles';
import { DetailsPageHeader } from '../DetailsPageHeader/DetailsPageHeader';
import { PatientReviewsModal } from '../ProviderDetailTabs/About/PatientReviews/PatientReviewModal';
import {
  AllPatientReviewsContainer,
  StyledIcon,
  StyledIconContainer,
  TextHeadingPatientReviews,
  modalStyles,
} from '../ProviderDetailTabs/About/PatientReviews/PatientReviews.styled';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import { PatientReviewsSortOrder } from './AllPatientReviewsSortOrder';
import { BackButton } from './BackButton';
import { RatingsSection } from './RatingsSection';

export const AllPatientReviews = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const sectionHeader: string =
    Constants.PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION
      .WHAT_PATIENTS_ARE_SAYING;
  const patientReviewsDataTestId: string = 'patient-reviews-section';
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const providerId = getProviderId(tokenData);
  const {
    showDirection,
    avatar,
    inNetwork,
    providerName,
    primarySpeciality,
    professionalDesignation,
    rating,
    npi,
    imageLocation,
    providerDetails,
  } = tokenData;
  const [navigateToDirections, setNavigateToDirections] = useState(
    Boolean(showDirection)
  );
  const providerLocations = providerDetails
    ? JSON.parse(providerDetails).providerLocations
    : {};
  const nameAttrs = getNameAttrs(
    providerDetails ? JSON.parse(providerDetails) : {}
  );
  const [reviewModal, setReviewModal] = useState<Survey | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [healthgradesReview, setHealthgradesReview] =
    useState<HealthgradesProviderReview>({});
  const [, getHealthgradesProviderReview] = useHealthgradesGetProviderReview({
    onCompleted: (result) => {
      setIsLoading(false);
      if (result?.data?.healthgradesGetProviderReview) {
        setHealthgradesReview(result.data.healthgradesGetProviderReview);
      }
    },

    onError: (error) => {
      setIsLoading(false);
      throw error;
    },
  });
  const [sortOrder, setSortOrder] = useState<PatientReviewsSortOrder>(
    PatientReviewsSortOrder.MOST_RECENT
  );
  const [pageSize, setPageSize] = useState<number>(10);
  const { gotoPage, state, ...paginationProps } = usePagination({
    pages: Math.ceil(
      (healthgradesReview.surveyResults?.surveys?.length || 0) / pageSize
    ),
  });
  const { currentPage, pageIndex, pageCount } = state;

  const [isOpen, setIsOpen] = useState(false);

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'about:patient reviews',
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `provider details`,
    impressionBlock: {
      type: 'provider',
      indicator: '',
    },
  });

  const [, setDirectionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );
  useEffect(() => {
    if (showDirection) {
      setDirectionsTriggeredFrom('');
      setNavigateToDirections(true);
    }
  }, [showDirection]);

  useEffect(() => {
    if (navigateToDirections) {
      const updatedToken = tokenizer.update(token, {
        showDirection: navigateToDirections,
      });
      navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${updatedToken}`);
    }
  }, [navigateToDirections]);

  useEffect(() => {
    adobeLinkTrackEvent({
      name: `results per page:${pageSize}`,
      location: `body:pagination control:page ${currentPage}`,
    });
  }, [pageSize, currentPage]);

  useEffect(() => {
    if (reviewModal) {
      const linkName: string = patientReviewsDataTestId;
      const linkLocation: string = `body:${sectionHeader}:page ${currentPage}`;
      const modalName: string = 'patient review';
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [reviewModal]);

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  useEffect(() => {
    getHealthgradesProviderReview({
      variables: {
        npi,
      },
    });
  }, []);

  const handleOnClick = () => {
    handleLinkAndModalTrack(
      `all-patient-reviews-sort-order`,
      `modal:all patient reviews sort`,
      'all patient reviews sort'
    );
    setIsOpen(true);
  };

  return (
    <div>
      <Box
        color="#FBFCFE"
        css={{
          'abyss-box-root': {
            height: 'auto',
            padding: '0px',
            borderBottom: '1px solid  var(--abyss-colors-cardBorder)',
          },
        }}
      >
        <DetailsPageHeader
          avatar={avatar}
          imageLocation={imageLocation}
          inNetwork={inNetwork}
          nameAttrs={nameAttrs}
          primarySpeciality={primarySpeciality?.split('|')}
          professionalDesignation={professionalDesignation}
          providerId={providerId}
          providerLocations={providerLocations}
          providerName={providerName}
          providerType={ProviderType.PRACTITIONER}
          rating={rating}
          setNavigateToDirections={setNavigateToDirections}
        />
      </Box>
      <Modal
        css={modalStyles}
        isOpen={reviewModal !== null}
        onClose={() => setReviewModal(null)}
      >
        <Modal.Section
          data-auto-testId={patientReviewsDataTestId}
          data-testId={patientReviewsDataTestId}
        >
          <PatientReviewsModal review={reviewModal} />
        </Modal.Section>
      </Modal>
      <AllPatientReviewsContainer>
        {!mobileScreen && <BackButton />}
        <StyledIconContainer>
          <StyledIcon
            icon="healthgrades"
            isScreenReadable
            size={132}
            title={t('HEALTH_GRADES_LOGO_TITLE')}
          />
        </StyledIconContainer>
        {!mobileScreen && (
          <div style={{ marginBottom: '12px', marginTop: '12px' }}>
            <TextHeadingPatientReviews>
              {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.PATIENT_REVIEWS')}
            </TextHeadingPatientReviews>
          </div>
        )}
        {isLoading ? (
          <LoadingSpinner
            ariaLoadingLabel="loading results"
            isLoading={isLoading}
            size="$sm"
          />
        ) : (
          <RatingsSection
            currentPage={currentPage}
            gotoPage={gotoPage}
            handleOnClick={handleOnClick}
            healthgradesReview={healthgradesReview}
            isOpen={isOpen}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            paginationProps={paginationProps}
            setIsOpen={setIsOpen}
            setPageSize={setPageSize}
            setReviewModal={setReviewModal}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
          />
        )}
      </AllPatientReviewsContainer>
    </div>
  );
};
