import { IconMaterial } from '@abyss/web/ui/IconMaterial';

type Props = {
  sectionTypeDerived: string;
  providerId: string;
  healthGradeRating: string;
};

export const StarRating = ({
  sectionTypeDerived,

  providerId,
  healthGradeRating,
}: Props) => {
  const ratingAsInt = parseInt(healthGradeRating, 10);
  return (
    <span aria-hidden="true">
      <IconMaterial
        css={{
          'abyss-icon': {
            position: 'relative',
            top: ratingAsInt ? '0px' : '1px',
            fill: ratingAsInt ? '$secondary2' : '$black',
            stroke: ratingAsInt ? '$starBorder' : '',
          },
        }}
        data-auto-testid={`data-card-account-star-icon-${sectionTypeDerived}-${providerId}`}
        data-testid={`data-card-account-star-icon-${sectionTypeDerived}-${providerId}`}
        icon={ratingAsInt ? 'star_rate' : 'star_border'}
        size={ratingAsInt ? '13.33px' : '14.33px'}
      />
    </span>
  );
};
