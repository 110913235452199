/* eslint-disable no-nested-ternary */
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledPremiumCareProviderDiv,
  StyledPremiumCareProviderNullStateDiv,
  TextRoot,
} from '../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../utility/compareDrawerConstants';

const isOverflow = (ref: RefObject<HTMLElement>) => {
  const { current } = ref;
  if (current && current?.scrollHeight > current?.clientHeight) {
    return true;
  }
  return false;
};

type Props = {
  mobile: boolean;
  premiumCareProvider: string;
};

export const PremiumCareProviderText = ({
  mobile,
  premiumCareProvider,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  return (
    <StyledPremiumCareProviderDiv>
      {premiumCareProvider === 'YES' ? (
        <Layout.Group
          alignItems="top"
          data-testid="premium-care-provider-badge"
          space={4}
        >
          <Layout.Group space={0}>
            <IconMaterial color="$primary1" icon="favorite" />
            <IconMaterial color="$primary1" icon="favorite" />
          </Layout.Group>
          {t(ATTRIBUTE_STATUS_TEXT.PREMIUM_CARE_PROVIDER.TRUE)}
        </Layout.Group>
      ) : premiumCareProvider === 'NO' ? (
        <TextRoot>
          {t(ATTRIBUTE_STATUS_TEXT.PREMIUM_CARE_PROVIDER.FALSE)}
        </TextRoot>
      ) : (
        <Tooltip
          content={t(NULL_ATTRIBUTE_STATE.PREMIUM_CARE_PROVIDER)}
          position="top"
          positionOffset={8}
        >
          <StyledPremiumCareProviderNullStateDiv
            cssProps={{ isOverflow, mobile }}
            ref={containerRef}
          >
            {t(NULL_ATTRIBUTE_STATE.PREMIUM_CARE_PROVIDER)}
          </StyledPremiumCareProviderNullStateDiv>
        </Tooltip>
      )}
    </StyledPremiumCareProviderDiv>
  );
};
