import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useLagoon } from '../../hooks/useLagoon';
import { usePrimaryCare } from '../../hooks/usePrimaryCare';
import { BreadcrumbSessionStorage } from '../../models/BreadcrumbSessionStorage';
import { ProviderLocation, ProviderType } from '../../models/ProviderDetails';
import { searchResultBreadcrumbTitle } from '../../utils/common';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import {
  getProviderPCPId,
  getSelectedLocationIndex,
  getSuppressFacilityFlags,
  isShowPreferredFacilityBadge,
} from '../../utils/providerDetails.utils';
import { parseProviderName } from '../../utils/providerNames.utils';
import {
  getCurrentMember,
  getCurrentMemberPCPInfo,
  getLoggedInMember,
} from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants, ReverseCoverageTypesCodes } from '../Constants';
import { FeatureFlags } from '../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { hideScrollbar } from '../ConstantsStyles';
import { Breadcrumb } from '../PSXHeader/Breadcrumb';
import { ShareThisProviderDrawer } from '../ShareThisProvider/ShareThisProvider';
import { getFeatureFlag } from '../Utils';
import { BenefitsBadge } from './BenefitsBadge';
import { DetailsPageChips } from './DetailsPageChips';
import {
  ChoosePCPButtonContainer,
  DetailsHeaderSection,
  HeaderContainer,
  HeaderContentWrapper,
  HeaderFlex,
  InteractButton,
  InteractSection,
  PopoverWrapper,
  ProviderUpdatedSubsection,
  SuggestEditLink,
} from './DetailsPageHeader.styled';
import { DetailsSection } from './DetailsSection';
import { ProviderDetailBanner } from './ProviderDetailBanner';
import { SuggestEditDrawerCard } from './SuggestEditDrawerCard';

export type Props = {
  avatar?: string;
  inNetwork: string;
  rating?: string;
  npi?: string;
  practiceId?: string;
  providerId: string;
  providerName?: string;
  providerType?: ProviderType;
  primarySpeciality: string[];
  professionalDesignation?: string;
  providerLocations: ProviderLocation[];
  imageLocation?: string;
  setNavigateToDirections: (a: boolean) => void;
  virtualCareOffered?: boolean;
  coverageType?: string;
  dependentSeqNbr?: string;
  nameAttrs?: any;
  surveyCount?: number;
};

export const DetailsPageHeader = ({
  avatar,
  // NOTE: inNetwork Prop get parsed from RAW GPL responses (INN or OON) in the parent componet in this Fn -> getNetworkStatus() we can simplify this later for now I am using this as designed.
  dependentSeqNbr,
  inNetwork,
  rating,
  npi = '',
  practiceId = '',
  providerId,
  providerName,
  providerType,
  primarySpeciality,
  professionalDesignation,
  providerLocations,
  imageLocation,
  setNavigateToDirections,
  virtualCareOffered = false,
  coverageType,
  nameAttrs,
  surveyCount,
}: Props) => {
  const { t } = useTranslation();
  const featureFlags = useLagoon('feature-flags')();
  const showDoesAnythingLookWrong = getFeatureFlag(
    featureFlags,
    'DOES_ANYTHING_LOOK_WRONG'
  );
  const [costEnableFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED,
  ]);

  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const [, setDirectionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { choosePCP = false, chipValue } = tokenData;
  const ddpCodeUser = getLoggedInMember()?.ddpCode;

  const [suggestEditDrawer, setSuggestEditDrawer] = useState(false);
  const [breadcrumbSessionStorage, setBreadcrumbSessionStorage] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );
  const suppressRatings = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const suppressFacilityFlags = getSuppressFacilityFlags();

  useEffect(() => {
    breadcrumbSessionStorage[ConstantsRoutes.PROVIDER_DETAILS.key] =
      window.location.pathname.replace('/findcare', '');
    setBreadcrumbSessionStorage(breadcrumbSessionStorage);
  }, []);

  const parseBreadcrumbs = (urls) => {
    let breadcrumbs;
    switch (providerType) {
      case ProviderType.ORGANIZATION:
        breadcrumbs = [
          {
            title: t('DETAILS_PAGE.FACILITY_BREADCRUMB'),
            href: '',
          },
        ];
        break;
      case ProviderType.PROVIDER_GROUP:
        breadcrumbs = [
          {
            title: t('DETAILS_PAGE.PROVIDER_GROUP_BREADCRUMB'),
            href: '',
          },
        ];
        break;
      default:
        breadcrumbs = [
          {
            title: t('DETAILS_PAGE.PROVIDER_BREADCRUMB'),
            href: '',
          },
        ];
        break;
    }

    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key]) {
      const breadcrumbToken =
        tokenizer.parse(
          urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key].replace(
            '/results/mapview/',
            ''
          )
        ) || {};
      breadcrumbs.unshift({
        title: t(searchResultBreadcrumbTitle(breadcrumbToken.sectionType)),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key]}`,
      });
    }
    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]) {
      breadcrumbs.unshift({
        title: t('BC Results'),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]}`,
      });
    }
    return breadcrumbs;
  };
  const breadcrumbs = parseBreadcrumbs(breadcrumbSessionStorage);
  const [showShareDrawer, setShowShareDrawer] = useState(false);
  const currentMember = getCurrentMember();
  const { data } = usePrimaryCare();
  const { primaryCare } = data;

  const memberPCPId = getCurrentMemberPCPInfo(
    dependentSeqNbr || currentMember?.demographics?.dependentSeqNbr,
    primaryCare
  )?.providerID;

  const providerPCPId = getProviderPCPId(
    providerLocations,
    providerId,
    memberPCPId
  );

  const ChoosePCPButton = () => {
    const handleChoosePCPClick = () => {
      adobeLinkTrackEvent({
        name: 'choose pcp button',
        location: 'body:details header',
        type: 'internal',
      });
      const updatedToken = tokenizer.update(token, {
        choosePCPId: providerId,
        selectedProviderType:
          providerType === ProviderType.PROVIDER_GROUP
            ? ProviderType.MEDICAL_GROUP
            : providerType,
      });

      navigate(`/choose-pcp/locations/${updatedToken}`);
    };
    const pcpLocation = providerLocations.find(
      (item) => item.acceptingNewPatients && item.hasPCPServiceAvailability
    );
    if (choosePCP && pcpLocation && memberPCPId !== providerPCPId)
      return (
        <ChoosePCPButtonContainer
          before={<IconMaterial color="$white" icon="check" />}
          data-auto-testid="choosePCP-button"
          data-testid="choosePCP-button"
          onClick={handleChoosePCPClick}
          size="$md"
        >
          {t('PRIMARY_CARE_PROVIDER.CHOOSE_PCP')}
        </ChoosePCPButtonContainer>
      );
    return null;
  };

  const virtualCareIndicatorEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VIRTUAL_CARE_INDICATOR_ENABLED
  );

  const displayVirtualCareIcon =
    virtualCareOffered &&
    virtualCareIndicatorEnabled &&
    (coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] ||
      coverageType === ReverseCoverageTypesCodes['BEHAVIORAL CARVE-OUT'] ||
      coverageType === ReverseCoverageTypesCodes.MEDICAL);

  const directionsOnClick = () => {
    if (setNavigateToDirections) {
      setDirectionsTriggeredFrom(
        Constants.DETAILS_PAGE_HEADER_DIRECTIONS_BUTTON
      );
      setNavigateToDirections(true);
    }
  };

  const handleChipsOnClick = (chipName) => {
    if (chipName === Constants.DETAILS_PAGE_HEADER.TABS.DIRECTIONS) {
      directionsOnClick();
    }

    adobeLinkTrackEvent({
      name: chipName,
      location: 'body:details header',
      type: 'internal',
    });
  };
  useEffect(() => {
    if (providerName && providerType) {
      document.title = `${parseProviderName(
        providerName,
        providerType,
        professionalDesignation,
        nameAttrs
      )}  | ${Constants.SITE_NAME}`;
    }
  }, [providerName, providerType, professionalDesignation]);

  if (!providerName || !providerType) {
    return null;
  }
  const lagoonData = useLagoon('ui-messaging')();

  const tierOneContentOne = find(lagoonData, {
    key: ConstantsLagoon.MEDICAL_SPECIALISTS.TIER_ONE_BENEFITS_ONE,
  });

  const preferredFacilityContent = find(lagoonData, {
    key: ConstantsLagoon.PREFERRED_FACILITY.PREFERRED_FACILITY_CONTENT,
  });

  const tierContent = tierOneContentOne?.message ?? '';

  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const showPreferredProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.FACILITIES
    ].PREFERRED_FACILITY_IDENTIFICATION
  );

  const index = getSelectedLocationIndex(providerLocations, selectedId);
  const selectedLocation = providerLocations?.[index];

  const {
    costIndicator,
    ddpCode: ddpFacilityCode,
    facilityProviderSpecialties,
    isTieredProvider,
  } = selectedLocation;

  const { isPreferredLabNetwork, isFreeStandingFacility } =
    facilityProviderSpecialties || {};

  const showBenefitsBadge = isShowPreferredFacilityBadge(
    suppressFacilityFlags,
    showPreferredProviderTag,
    ddpFacilityCode,
    ddpCodeUser,
    isPreferredLabNetwork,
    isFreeStandingFacility
  );

  return (
    <React.Fragment>
      <SuggestEditDrawerCard
        avatar={avatar}
        inNetwork={inNetwork}
        providerLocations={providerLocations}
        providerName={providerName}
        providerType={providerType}
        setSuggestEditDrawer={setSuggestEditDrawer}
        suggestEditDrawer={suggestEditDrawer}
      />
      <ShareThisProviderDrawer
        professionalDesignation={professionalDesignation}
        providerLocations={providerLocations}
        providerName={providerName}
        providerType={providerType}
        setShowShareThisProviderDrawer={setShowShareDrawer}
        showShareThisProviderDrawer={showShareDrawer}
        speciality={primarySpeciality}
      />

      <HeaderContainer>
        <HeaderContentWrapper>
          <HeaderFlex alignItems="center" justify="space-between">
            <Breadcrumb
              breadcrumbs={breadcrumbs}
              onBackButtonClick={() => {
                navigate(-1);
              }}
              showBreadcrumbAtMediumScreenSize={false}
            />
          </HeaderFlex>

          <DetailsHeaderSection>
            <PopoverWrapper>
              {showTierProviderTag && isTieredProvider ? (
                <BenefitsBadge
                  content={tierContent ?? t('TIERED_BENEFITS.TIER_CONTENT')}
                  icon="Group 204976975"
                  testid="tier-one-detail-header"
                  title={t('Tier 1 Provider')}
                />
              ) : null}
              {showBenefitsBadge && !isTieredProvider ? (
                <BenefitsBadge
                  content={
                    preferredFacilityContent?.message ??
                    t('PREFERRED_FACILITY_BENEFITS.CONTENT')
                  }
                  testid="preferred-facility-detail-header"
                  title={t('Preferred Facility')}
                />
              ) : null}
            </PopoverWrapper>
          </DetailsHeaderSection>
          <DetailsSection
            costEnableFlag={costEnableFlag}
            costIndicator={costIndicator}
            displayVirtualCareIcon={displayVirtualCareIcon}
            imageLocation={imageLocation}
            inNetwork={inNetwork}
            nameAttrs={nameAttrs}
            primarySpeciality={primarySpeciality}
            professionalDesignation={professionalDesignation}
            providerName={providerName}
            providerType={providerType}
            rating={rating}
            suppressRatings={suppressRatings}
            surveyCount={surveyCount}
            virtualCareOffered={virtualCareOffered}
          />
          <ProviderDetailBanner
            memberPCPId={memberPCPId}
            providerPCPId={providerPCPId}
          />
          {showDoesAnythingLookWrong && (
            <ProviderUpdatedSubsection>
              <SuggestEditLink
                data-auto-testid="provider-suggest-edit-link"
                data-testid="provider-suggest-edit-link"
                isStandardAnchor
                onClick={() => setSuggestEditDrawer(true)}
                variant="native"
              >
                {t('DETAILS_PAGE.SUGGEST_EDIT')}
              </SuggestEditLink>
            </ProviderUpdatedSubsection>
          )}
          <InteractSection css={hideScrollbar}>
            <ChoosePCPButton />
            {Constants.DETAILS_CHIPS.map((chip) => (
              <InteractButton
                before={<IconMaterial color="$primary1" icon={chip.icon} />}
                data-auto-testid={`provider-details-chips-${chip.text}`}
                data-testid={`provider-details-chips-${chip.text}`}
                key={`provider-details-chips-${chip.text}`}
                onClick={() => handleChipsOnClick(chip.text)}
                variant="outline"
              >
                {t(chip.text)}
              </InteractButton>
            ))}
            {Constants.DETAILS_CHIPS_NAME.map((chip) => (
              <DetailsPageChips
                category={chipValue}
                chip={chip.text}
                key={`details-chip-${chip.text}`}
                npi={npi}
                practiceId={practiceId}
                providerLocations={providerLocations}
                providerType={providerType}
              />
            ))}
          </InteractSection>
        </HeaderContentWrapper>
      </HeaderContainer>
    </React.Fragment>
  );
};
