import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';

import { ChoosePCPAlert } from '../../../../common/Alert/ChoosePCPAlert';
import { CardWrapper } from '../../../../common/CardWrapper';
import {
  CareCategories,
  Constants,
  ProviderTypes,
} from '../../../../common/Constants';
import { DisclaimerContent } from '../../../../common/DisclaimerContent';
import { EAPCode } from '../../../../common/EAPCode';
import { EAPMixResultsContainer } from '../../../../common/EAPCode/EAPCode.styled';
import { ResultSection } from '../../../../common/ResultSection/ResultSection';
import { SearchResultsTitle } from './SearchResultsTitle';

export const SearchedResultSectionData = ({
  acceptingNewPatients,
  dependentSeqNbr,
  providerHeaders,
  medicalGroupHeaders,
  facilitiesHeaders,
  searchTitle,
  searchMethod,
  searchTerm,
  searchType,
  contextValue,
  specialtyCode,
  userZip,
  choosePCP,
  isMixedSuggestions,
  onSpecialtyResultClicked,
  resultType,
  specialtySearch,
  data,
  eapCodeFlag,
  isBHCare,
  providerIsLoading,
  medGrpIsLoading,
  orgIsLoading,
  isPageTracked,
}) => {
  const ResultsSectionMain = (
    res,
    secType,
    count,
    sectionProviderType,
    radius,
    isLoading,
    headers
  ) => (
    <ResultSection
      acceptingNewPatients={acceptingNewPatients}
      dependentSeqNbr={dependentSeqNbr}
      headers={headers}
      isLoading={isLoading}
      providerType={sectionProviderType}
      results={res}
      search={searchTitle}
      searchMethod={searchMethod}
      searchRadius={radius}
      searchTerm={searchTerm}
      searchType={searchType}
      sectionType={secType}
      selectedChip={contextValue}
      specialtyCode={specialtyCode}
      totalCount={count}
      userZip={userZip}
    />
  );

  return (
    <React.Fragment>
      <CardWrapper>
        <Layout.Stack grow space={0}>
          <ChoosePCPAlert />
          <SearchResultsTitle
            choosePCP={choosePCP}
            isMixedSuggestions={isMixedSuggestions}
            offset={1}
            onSpecialtyClick={onSpecialtyResultClicked}
            resultType={resultType}
            searchTitle={searchTitle}
            specialtySearch={specialtySearch}
          />
          {contextValue === CareCategories.BEHAVIORAL_HEALTH &&
            eapCodeFlag &&
            isBHCare &&
            isPageTracked && (
              <EAPMixResultsContainer>
                <EAPCode />
              </EAPMixResultsContainer>
            )}
          {contextValue !== CareCategories.FACILITIES &&
          contextValue !== CareCategories.HEALTH_SUPPLIES
            ? ResultsSectionMain(
                data?.providers,
                Constants.SEARCH_TYPES.PROVIDER,
                data?.totalCountPractitioners,
                ProviderTypes.PRACTITIONER,
                data?.practitionerSearchRadius,
                providerIsLoading,
                providerHeaders
              )
            : null}
          {contextValue !== CareCategories.FACILITIES &&
          contextValue !== CareCategories.HEALTH_SUPPLIES
            ? ResultsSectionMain(
                data?.medicalGroup,
                Constants.SEARCH_TYPES.PROVIDER_GROUP,
                data?.totalCountMedicalGroup,
                ProviderTypes.MEDICAL_GROUP,
                data?.medicalGroupSearchRadius,
                medGrpIsLoading,
                medicalGroupHeaders
              )
            : null}
          {!choosePCP
            ? ResultsSectionMain(
                data?.facilities,
                Constants.SEARCH_TYPES.FACILITY,
                data?.totalCountOrganizations,
                ProviderTypes.ORGANIZATION,
                data?.organizationSearchRadius,
                orgIsLoading,
                facilitiesHeaders
              )
            : null}
        </Layout.Stack>
      </CardWrapper>
      <DisclaimerContent selectedChipValue={contextValue} />
    </React.Fragment>
  );
};
