import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSpecialtiesResult } from '../../../../hooks/useSpecialties';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { Education, Identifiers } from '../../../../models/ProviderDetails';
import { getTokenData } from '../../../../utils/common';
import { Constants, ReverseCoverageTypesCodes } from '../../../Constants';
import { mobileOnly } from '../../../ConstantsStyles';
import {
  collapseContainer,
  collapseContainerOtherProviders,
} from '../About.styled';
import { CollapseProviderDetailsSection } from './CollapseProviderDetailsSection';

const {
  AREAS_OF_EXPERTISE,
  HOSPITAL_AFFILIATIONS,
  PROVIDER_SPECIALTIES,
  EDUCATION,
  OTHER_PROVIDER_DETAILS,
  NA,
} = Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION;

type Props = {
  areaOfExpertise: string[] | undefined;
  culturalCompetencies: string[] | undefined;
  educationInfo: Education[];
  hospitalAffiliations: Identifiers[];
  licenseNumber: string[];
  npi: string;
  specialties: string[];
  userState: string | undefined;
};

export const CollapseDetailsSectionRoot = ({
  areaOfExpertise,
  culturalCompetencies,
  educationInfo,
  hospitalAffiliations,
  licenseNumber,
  npi,
  specialties,
  userState,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;
  const tokenData = getTokenData();
  const isVisionCare =
    tokenData?.coverageType === ReverseCoverageTypesCodes.VISION;
  const isDentalCare =
    tokenData?.coverageType === ReverseCoverageTypesCodes.DENTAL;

  const { data: referralRequiredSpecialties = [] } = useSpecialtiesResult({
    isReferralRequired: true,
    userState,
  });

  return (
    <React.Fragment>
      <Flex css={collapseContainer(mobileScreen)}>
        <CollapseProviderDetailsSection
          collapseConstantKey={PROVIDER_SPECIALTIES}
          collapseContent={
            specialties || [
              t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'),
            ]
          }
          collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.PROVIDER_SPECIALTIES"
          referralRequiredSpecialties={referralRequiredSpecialties}
        />
        {isDentalCare && (
          <CollapseProviderDetailsSection
            collapseConstantKey={EDUCATION}
            collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION"
            culturalCompetencies={culturalCompetencies}
            education={
              educationInfo ||
              (new Array({
                degrees: [
                  {
                    degreeCode: t(
                      'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                    ),
                    isPrimary: true,
                    description: t(
                      'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                    ),
                  },
                ],
                schoolName: t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA'),
              }) as Education[])
            }
            showCulturalCompetencies={!isDentalCare}
          />
        )}
      </Flex>
      {!isVisionCare && !isDentalCare && (
        <React.Fragment>
          <Flex css={collapseContainer(mobileScreen)}>
            <CollapseProviderDetailsSection
              collapseConstantKey={AREAS_OF_EXPERTISE}
              collapseContent={
                areaOfExpertise || [
                  t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'),
                ]
              }
              collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.AREAS_OF_EXPERTISE"
            />
            <CollapseProviderDetailsSection
              collapseConstantKey={EDUCATION}
              collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION"
              culturalCompetencies={culturalCompetencies}
              education={
                educationInfo ||
                (new Array({
                  degrees: [
                    {
                      degreeCode: t(
                        'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                      ),
                      isPrimary: true,
                      description: t(
                        'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE'
                      ),
                    },
                  ],
                  schoolName: t(
                    'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA'
                  ),
                }) as Education[])
              }
            />
          </Flex>
          <Flex css={collapseContainerOtherProviders(mobileScreen)}>
            <CollapseProviderDetailsSection
              collapseConstantKey={HOSPITAL_AFFILIATIONS}
              collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS"
              hospitalAffiliations={
                hospitalAffiliations ||
                ([
                  {
                    id: NA,
                    value: t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA'),
                  },
                ] as Identifiers[])
              }
            />
          </Flex>
        </React.Fragment>
      )}
      <Flex css={collapseContainerOtherProviders(mobileScreen)}>
        <CollapseProviderDetailsSection
          collapseConstantKey={OTHER_PROVIDER_DETAILS}
          collapseHeader="PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.OTHER_PROVIDER_DETAILS"
          licenseNumber={licenseNumber || []}
          npi={npi || ''}
        />
      </Flex>
    </React.Fragment>
  );
};
