import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Button } from '@abyss/web/ui/Button';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  NEW_TAB_CONFIRMATION,
  PRACTITIONER_NAME_FOR_ANALYTICS,
} from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../../common/ConstantsStyles';
import { getGeoLocationFromStorage } from '../../../../../common/PSXHeader/SearchBar/utils';
import { getFeatureFlag } from '../../../../../common/Utils';
import { makeAllSlidesAriaHidden } from '../../../../../common/Utils/a11yUtils/a11yUtils';
import { handleLinkAndModalTrack } from '../../../../../common/Utils/adobeTrackUtils';
import {
  CustomAttributesBlock,
  appendCoverageTypes,
  convertProviderTypeToAdobeType,
  formatProviderId,
} from '../../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { getCurrentDateWithDashFormatting } from '../../../../../common/Utils/datesUtils';
import { useLagoon } from '../../../../../hooks/useLagoon';
import { useSendEmail } from '../../../../../hooks/useSendEmail';
import { useShareAllResults } from '../../../../../hooks/useShareAllResults';
import {
  CompareProvider,
  SelectedProviderList,
} from '../../../../../models/Provider';
import { ResponseHeaders } from '../../../../../models/ResponseHeaders';
import { formatEmails } from '../../../../../utils/email/formatEmails.utils';
import { getEmailBodyHTML } from '../../../../../utils/email/getEmailBodyHTML.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../../../../utils/user.utils';
import { CompareCheckboxInfo } from '../CompareDrawer/utility/compareDrawerConstants';
import { CarouselSelectedProviderSlide } from './CarouselSelectedProviderSlide';
import { MobilePDFShareSection } from './MobilePDFShareSection';
import { ShareContainerSection } from './ShareContainer';
import { LinkTextMobile } from './ShareDrawer.styled';
import { ShareDrawerButtons } from './ShareDrawerContents/ShareDrawerButtons';
import { ShareDrawerEmailInputs } from './ShareDrawerContents/ShareDrawerEmailInputs';
import {
  adobeShareDrawerLinkLocation,
  adobeShareProviders,
  sectionTypeToProviderType,
} from './utils';

type Props = {
  handleDetailsOnClick: (
    providerId: string,
    options?: Object,
    customAttributesBlock?: CustomAttributesBlock
  ) => void;
  compareCheckboxes: CompareCheckboxInfo[];
  selected: number;
  total: number;
  headers?: ResponseHeaders;
  openShare: boolean;
  isShareAllResults: boolean;
  openBoxContents: boolean;
  removeItem: (item: string) => void;
  setOpenShare: (a: boolean) => void;
  setOpenBoxContents: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  showSuccessAlertCallback: () => void;
  setIsShareAllResults: (a: boolean) => void;
  selectedProviderList: SelectedProviderList;
};
const carouselStyles = {
  'abyss-carousel-root': {
    width: '100%',
  },
  'abyss-carousel-swiper-container': {
    display: 'flex',
    willChange: 'transform',
    width: '463px',
  },
  'abyss-carousel-slide-container': {
    position: 'relative',
    width: '163px',
  },
  'abyss-carousel-minimal-controls': {
    padding: '$sm',
    paddingBottom: '0',
    'button.abyss-carousel-minimal-pagination-container': {
      padding: '10px',
      display: 'block',
    },
  },
  'abyss-carousel-minimal-pagination-container': {
    paddingLeft: '$md',
    paddingRight: '$md',
  },
};

export const ShareDrawerMobile = ({
  handleDetailsOnClick,
  selected,
  total,
  headers,
  openShare,
  compareCheckboxes,
  openBoxContents,
  isShareAllResults,
  setIsShareAllResults,
  setOpenBoxContents,
  setSelectedCheckbox,
  setSelectedItems,
  removeItem,
  setOpenShare,
  showSuccessAlertCallback,
  selectedProviderList,
}: Props) => {
  const modal = useOverlay('view-provider-modal');
  const { t } = useTranslation();

  const form = useForm();

  const isMobile = useMediaQuery(phoneOnly);

  const [emailOne, setEmailOne] = useState('');
  const [emailTwo, setEmailTwo] = useState('');
  const [emailThree, setEmailThree] = useState('');
  const [emailFour, setEmailFour] = useState('');
  const [emailFive, setEmailFive] = useState('');
  const [clickedIndex, setClickedIndex] = useState(0);

  const shareAllResultsDataTestId: string = 'mobile-share-all-results-link';

  const { longitude, latitude } = getGeoLocationFromStorage();

  const currentMember = getCurrentMember();

  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { search, sectionType, specialtyCode, coverageType } = tokenData;
  const providerType = sectionTypeToProviderType(sectionType);

  const [openMobilePDFShareSection, setOpenMobilePDFShareSection] =
    useState<boolean>(false);

  const [openFloatSectionToShare, setOpenFloatSectionToShare] =
    useState<boolean>(false);
  const [, sendEmail] = useSendEmail({});
  const [, shareAll] = useShareAllResults({});
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const shareButtonFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.SHARE_RECIPIENT_DISABLE
  );

  const subjectText = () =>
    // eslint-disable-next-line no-nested-ternary
    sectionType?.toLowerCase() ===
    Constants.RESULT_SECTION.PROVIDER.toLowerCase()
      ? t('SHARE_PROVIDERS.PROVIDER')
      : sectionType?.toLowerCase() ===
        Constants.RESULT_SECTION.FACILITY.toLowerCase()
      ? t('SHARE_PROVIDERS.FACILITY')
      : t('SHARE_PROVIDERS.PROVIDER_GROUP');

  const shareTitle = `${subjectText()} ${search} ${t(
    'SHARE_PROVIDERS.SEARCH_ON'
  )} ${getCurrentDateWithDashFormatting()}`;

  const handleShareButtonMobile = () => {
    if (isMobile) {
      setOpenFloatSectionToShare(true);
    } else {
      setOpenFloatSectionToShare(false);
    }
  };

  const customAttributesBlock: CustomAttributesBlock = {
    correlationId: headers?.correlationId,
    providerId: formatProviderId(
      appendCoverageTypes(selectedProviderList[clickedIndex], tokenData)
    ),
    providerType: convertProviderTypeToAdobeType(
      selectedProviderList[clickedIndex]?.providerType
    ),
  };

  const handleProviderNameAndCarrotClick = (index) => {
    setClickedIndex(index);
    handleLinkAndModalTrack(
      PRACTITIONER_NAME_FOR_ANALYTICS,
      `modal:share card:position ${clickedIndex}`,
      NEW_TAB_CONFIRMATION,
      undefined,
      customAttributesBlock
    );
    modal?.open();
  };

  const handleClickCb = () => {
    modal.close();
    handleDetailsOnClick(
      selectedProviderList[clickedIndex]?.providerId,
      { openInNewTab: true },
      customAttributesBlock
    );
  };
  makeAllSlidesAriaHidden();
  const carouselSelectedProviderSlides = Array.from(Array(5).keys()).map(
    (i) => (
      <Slide
        css={{
          'abyss-slide-container': {
            marginLeft: 0,
            backgroundColor: '$white',
          },
        }}
      >
        <Slide.Container
          css={{
            'abyss-slide-content-wrapper': {
              padding: '0',
              visibility: 'visible',
            },
          }}
        >
          <CarouselSelectedProviderSlide
            clickedIndex={clickedIndex}
            customAttributesBlock={customAttributesBlock}
            handleClickCb={handleClickCb}
            handleProviderNameAndCarrotClick={handleProviderNameAndCarrotClick}
            index={i}
            modal={modal}
            removeItem={removeItem}
            selected={selected}
            selectedProviderList={selectedProviderList}
          />
        </Slide.Container>
      </Slide>
    )
  );

  const handleSendEmail = async () => {
    if (isShareAllResults) {
      await shareAll({
        variables: {
          latitude,
          longitude,
          providerType,
          lob: currentMember?.lineOfBusiness,
          coverages: getCoverageTypes(currentMember),
          planYear: getCurrentPlanYear(),
          planVariationCode: getPlanVariationCode(currentMember, coverageType),
          policyID: currentMember?.policyNumber,
          reciprocityId: getNetworkIdsForPES(
            currentMember,
            coverageType,
            featureFlags
          ),
          sendEmail: true,
          linkToSearchResultsPage: window.location.href,
          shareTitle,
          emailAddresses: formatEmails(
            emailOne,
            emailTwo,
            emailThree,
            emailFour,
            emailFive
          ),
          searchKeyword: search,
          includeSpecialityRollupCodes: [specialtyCode],
        },
      });
    } else {
      await sendEmail({
        variables: {
          recipientEmails: formatEmails(
            emailOne,
            emailTwo,
            emailThree,
            emailFour,
            emailFive
          ),
          subject: shareTitle,
          text: '',
          htmlText: getEmailBodyHTML(
            shareTitle,
            selectedProviderList,
            compareCheckboxes
          ),
        },
      });
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const onSubmit = () => {
    showSuccessAlertCallback();
    setOpenFloatSectionToShare(false);
  };

  return (
    <div ref={containerRef}>
      {openMobilePDFShareSection && (
        <MobilePDFShareSection
          includeSpecialityRollupCodes={[specialtyCode]}
          searchParam={search}
          sectionType={sectionType}
          setOpenSection={setOpenMobilePDFShareSection}
          setOpenShare={setOpenShare}
          shareTitle={shareTitle}
        />
      )}
      {openFloatSectionToShare && (
        <FormProvider
          data-auto-testid="share-email-form"
          data-testid="share-email-form"
          onSubmit={onSubmit}
          state={form}
        >
          <FloatingSection
            containerRef={containerRef}
            css={{
              'abyss-floating-section-root': {
                borderRadius: '20px 20px 0px 0px',
                boxShadow: '0px -6px 20px rgba(25, 25, 26, 0.16)',
                width: '100%',
                padding: '24px 30px',
                position: 'fixed',
                bottom: '0',
                zIndex: '1',
                overflowY: 'hidden',
                backgroundColor: '#FAFCFF',
              },
            }}
          >
            <Layout.Stack
              css={{ marginTop: '20px', width: '100%' }}
              grow
              justifyContent="start"
            >
              <ShareDrawerEmailInputs
                emailFive={emailFive}
                emailFour={emailFour}
                emailOne={emailOne}
                emailThree={emailThree}
                emailTwo={emailTwo}
                setEmailFive={setEmailFive}
                setEmailFour={setEmailFour}
                setEmailOne={setEmailOne}
                setEmailThree={setEmailThree}
                setEmailTwo={setEmailTwo}
              />
              <ShareDrawerButtons
                handleSendEmailDesktop={handleSendEmail}
                openShareContents={openShare}
                setOpenShareContents={setOpenShare}
              />
            </Layout.Stack>
          </FloatingSection>
        </FormProvider>
      )}
      <React.Fragment>
        <ShareContainerSection
          openBoxContents={openBoxContents}
          selected={selected}
          selectedProviderList={selectedProviderList}
          setOpenBoxContents={setOpenBoxContents}
          setOpenShare={setOpenShare}
          setSelectedCheckbox={setSelectedCheckbox}
          setSelectedItems={setSelectedItems}
          total={total}
        />
        {openBoxContents && (
          <Carousel
            css={carouselStyles}
            minimal
            nextSlideOnClick={() => makeAllSlidesAriaHidden()}
            noLoop
            prevSlideOnClick={() => makeAllSlidesAriaHidden()}
            slideIndexOnClick={() => makeAllSlidesAriaHidden()}
            slides={carouselSelectedProviderSlides}
            slidesPerView={1}
          />
        )}

        <Layout.Stack
          alignLayout="center"
          css={{
            'abyss-layout-stack': {
              marginTop: '$md',
            },
          }}
          space={12}
        >
          {!shareButtonFlag ? (
            <React.Fragment>
              <Button
                align="start"
                css={{
                  'abyss-button-root': {
                    width: '343px',
                    padding: '12px 15px',
                    backgroundColor: selected > 0 ? '$primary1' : '$gray2',
                    height: '42px',
                    borderRadius: '24px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  },
                }}
                data-auto-testid="share-mobile"
                data-testid="share-mobile"
                onClick={() => {
                  handleLinkAndModalTrack(
                    adobeShareProviders,
                    `modal:${adobeShareDrawerLinkLocation}`,
                    adobeShareProviders
                  );
                  handleShareButtonMobile();
                }}
                variant={selected > 0 ? 'solid' : 'ghost'}
              >
                <Text
                  css={{
                    'abyss-text-root': {
                      fontSize: '$md',
                      paddingLeft: '5px',
                      fontWeight: '$semibold',
                      lineHeight: '18px',
                      textAlign: 'center',
                      color: selected > 0 ? '$primary2' : '$gray5',
                    },
                  }}
                >
                  {t('Share')} ({selected})
                </Text>
              </Button>
              <LinkTextMobile
                data-auto-testid={shareAllResultsDataTestId}
                data-testid={shareAllResultsDataTestId}
                onClick={() => {
                  adobeLinkTrackEvent({
                    name: shareAllResultsDataTestId,
                    location: `modal:${adobeShareDrawerLinkLocation}`,
                    type: 'internal',
                  });
                  try {
                    if (navigator.canShare({ title: shareTitle })) {
                      setOpenMobilePDFShareSection(true);
                    }
                  } catch (error) {
                    setIsShareAllResults(true);
                    setOpenFloatSectionToShare(true);
                  }
                }}
              >
                {t('SHARE_DRAWER_TEXT.SHARE_ALL_RESULTS')}
              </LinkTextMobile>
            </React.Fragment>
          ) : null}
        </Layout.Stack>
      </React.Fragment>
    </div>
  );
};
