import { styled } from '@abyss/web/tools/styled';
import { Alert } from '@abyss/web/ui/Alert';
import { Flex } from '@abyss/web/ui/Flex';

export const StyledPCPWrapper = styled('div', {
  borderBottom: '1px solid $cardBorder',
  marginBottom: '$sm',

  '@screen < $sm': {
    borderTop: '1px solid $cardBorder',
  },
});

export const StyledPCPAlert = styled(Alert, {
  '&.abyss-alert-title': {
    color: '$gray8 !important',
  },
});

export const collapseContainer = (mobileScreen: boolean) => ({
  alignItems: 'start',
  borderWidth: '1px 0px',
  borderStyle: 'solid',
  borderColor: '$cardBorder',
  marginTop: '$sm',
  padding: '$lg calc((100% - 1200px) / 2)',

  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  ...(mobileScreen && {
    padding: '0px !important',
    border: '0px',
    marginBottom: '0px',
    marginTop: '0',
  }),
  '@screen < $sm': { padding: '0px' },
});

export const collapseContainerOtherProviders = (mobileScreen: boolean) => ({
  alignItems: 'start',
  borderWidth: '1px 0px',
  borderStyle: 'solid',
  borderColor: '$cardBorder',
  marginTop: '8px',
  padding: '$lg calc((100% - 1200px) / 2)',

  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  ...(mobileScreen && {
    padding: '0px !important',
    border: '0px',
    marginTop: '0',
  }),
  '@screen < $sm': { padding: '0px' },
});

export const collapseSection = (
  isFullWidth: boolean,
  tabletScreen: boolean,
  mobileScreen: boolean,
  widgetPhoneScreen?: boolean,
  WidgetDesktopScreen?: boolean,
  WidgetPages?: boolean
) => ({
  marginTop: '8px',
  ...(isFullWidth && {
    width: '100%',
    '.abyss-layout-group': {
      display: 'inline-block',
    },
  }),
  ...(!isFullWidth && {
    width: '50%',
    '@media (max-width: 743px)': {
      width: '100%',
    },
  }),
  ...(tabletScreen && {
    display: 'block !important',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '$md',
    gap: '4px',
    width: '100%',
    height: 'auto',
    background: '$white',
    borderWidth: '1px 0px',
    borderStyle: 'solid',
    borderColor: '#E5E5E6',
    borderRadius: '0px',
    flex: 'none',
    order: '4',
    flexGrow: '0',
  }),
  ...(WidgetPages && {
    display: 'block !important',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    width: '100%',
    height: 'auto',
    background: '$white',
    borderWidth: '1px 0px',
    borderStyle: 'solid',
    borderColor: '#E5E5E6',
    borderRadius: '0px',
    flex: 'none',
    order: '4',
    flexGrow: '0',
    margin: ' 8px 0 0 0',
    '.abyss-c-enKwaM-igvblZO-css': {
      margin: '0px',
    },
  }),
  ...(widgetPhoneScreen && {
    padding: ' 0 $md',
  }),
  ...(WidgetDesktopScreen && {
    padding: '0 $lg',
  }),
  ...(mobileScreen && {
    padding: '0 0 4px $md',
  }),
});

export const CollapseSubSection = styled(Flex, {
  gap: '12px',
  flexDirection: 'column',
  '.abyss-text-root': {
    lineHeight: '24px',
    '@screen < $sm': {
      lineHeight: '20px',
    },
  },
});

export const OtherDetailsContentTitle = styled('div', {
  color: '#323334',
  fontWeight: '600',
  fontSize: '$md',
  lineHeight: '24px',
  '@screen < $sm': {
    lineHeight: '20px',
  },
});

export const CollapseCardHeaderStyle = {
  backgroundColor: 'white',
  borderRadius: 'unset',
  border: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  boxShadow: 'none',
  color: '$primary1',
  fontSize: '20.25px',
  fontWeight: '$bold',
  lineHeight: '24px',
  marginBottom: '16px',
  minHeight: 'unset',
  padding: '0',
  '.abyss-card-title': {
    margin: '$md 0',
    maxHeight: '24px',
  },
};

export const CollapseCardSectionStyle = {
  color: '#4B4D4F',
  padding: '0',
  borderTop: 'none !important',
  lineHeight: '20px',
  margin: '6px 0',
};

export const CollapseCardSectionProviderSpecialtyStyle = (
  isWidget?: boolean
) => ({
  'abyss-card-section': {
    margin: '6px 0px',
    color: '#4B4D4F',
    padding: '0',
    borderTop: 'none !important',
    lineHeight: '20px',
  },
  ...(isWidget && {
    'abyss-card-section': {
      margin: '6px 0px 6px 4px',
      color: '#4B4D4F',
      padding: '0',
      borderTop: 'none !important',
      lineHeight: '20px',
    },
  }),
});

export const CollapseHospitalAffiliationsStyle = {
  color: '#196ECF',
  padding: '0',
  borderTop: 'none !important',
  fontWeight: '$bold',
};

export const HospitalAffiliationsContainerStyle = {
  'abyss-flex-root': {
    alignItems: 'center',
    gap: '4px',
    background: '$primary2',
    border: '0.5px solid #DADADA',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    borderRadius: '8px',
    marginBottom: '$xs',
    marginRight: '$md',
    marginTop: '$xs',
    '@screen >= $md': {
      paddingRight: '16px',
      paddingLeft: '24px',
      height: '42px',
    },
    '@screen < $md': {
      paddingRight: '14px',
      paddingLeft: '4px',
      height: '40px',
    },
  },
};
