import { createScript } from '@abyss/web/tools/script';
import { logger } from '@abyss/web/tools/logger';
import { event } from '@abyss/web/tools/event';
import { config } from '@abyss/web/tools/config';

const queue = [];
let queueIntervalIsActive = false;

const triggerQueueInterval = () => {
  if (queue.length && !queueIntervalIsActive) {
    queueIntervalIsActive = true;
    const queueInterval = setInterval(() => {
      const tag = queue.shift();
      if (tag) {
        window.adobeDataLayer.push({
          event: tag.event,
          ...tag.data,
        });
      } else {
        clearInterval(queueInterval);
        queueIntervalIsActive = false;
      }
    }, 200);
  }
};

export const adobeAnalytics = (options) => {
  if (options.enabled !== false) {
    createScript('adobe-query-script', config('ADOBE_QUERY_SCRIPT'));

    event.waitFor(
      () => window._satellite,
      () => {
        window._satellite.pageBottom();
      }
    );
  }

  return {
    id: 'adobe-analytics',
    events: options.events,
    metadata: options.metadata,
    trigger: (tag) => {
      if (options.logging) {
        logger.debug(`Adobe Analytics: ${tag.event}`, tag.data);
      }

      if (window.adobeDataLayer) {
        queue.push(tag);
        triggerQueueInterval();
      } else {
        window.pageDataLayer = tag.data;
        window._satellite.track(tag.event);
      }
    },
  };
};