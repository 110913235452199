import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { storage } from '@abyss/web/tools/storage';

import { Constants, NetworkStatus, ProviderTypes } from '../common/Constants';
import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { ConstantsRoutes } from '../common/ConstantsRoutes';
import {
  fiveRecentSearchCardsScreen,
  fourRecentSearchCardsScreen,
  reviewsFourCardsCarouselScreen,
  reviewsThreeCardsCarouselScreen,
  reviewsTwoCardsCarouselScreen,
  threeRecentSearchCardsScreen,
} from '../common/ConstantsStyles';
import { MockProviderGroupResults } from '../common/MockResults';
import { dateFormatCurrent } from '../common/Utils/datesUtils/formatDate';
import { FacilityDetails } from '../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../models/ProviderDetails';
import { returnSuppressFlag } from './featureSuppress';

//import { log() } from 'console';

export const enum PREMIUM {
  YES = 'YES',
  NO = 'NO',
  INFO_UNAVAILABLE = 'INFO_UNAVAILABLE',
}

export const enum UserDDPCode {
  L = 'L',
  R = 'R',
  A = 'A',
  LR = 'LR',
  N = 'N',
}

export const getProviderSpecialtyOrOrgTypeCode = (
  providerType,
  organizationType,
  speciality
) =>
  (providerType === Constants.RESULT_SECTION.ORGANIZATION &&
  organizationType?.length
    ? organizationType[0]
    : speciality) || '';

export const getProviderDetailTabList = (
  boardCertifications,
  providerLocations,
  isVisionCare,
  isDentalCare
) => {
  const excludeQuality =
    (!boardCertifications?.length && !providerLocations?.length) ||
    isVisionCare ||
    isDentalCare;
  return Constants.PROVIDER_DETAILS.TABS_LIST.filter(
    (tab) => !excludeQuality || tab !== 'Quality'
  );
};

// provider detail tab function.  Need to do this to reduce cognitive complexity in sonar//
export const getFacilityDetailTabList = (isVisionCare) =>
  Constants.FACILITY_DETAILS.TABS_LIST.filter(
    (tab) =>
      !isVisionCare || !(tab === 'Physician Directory' || tab === 'Quality')
  );

export function shortenZipCode(postalCode) {
  return postalCode?.slice(0, 5);
}

export const eveningAndWeekendSubtext = (t, location) => {
  if (location.hasEveningAppointments && location.hasWeekendAppointment) {
    return t('OTHER_CARE_DETAILS.EVENING_WEEKEND_OPTIONS');
  }
  if (location.hasEveningAppointments) {
    return t('OTHER_CARE_DETAILS.EVENING_APPOINTMENTS');
  }
  if (location.hasWeekendAppointment) {
    return t('OTHER_CARE_DETAILS.WEEKEND_APPOINTMENTS');
  }

  return t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE');
};

export const getServicesText = (t, location) => {
  let text = location.servicesOffered?.join(', ');
  if (
    location.servicesOffered &&
    location.servicesOffered?.length > 0 &&
    location.electronicPrescribingOffered
  ) {
    text += `, ${t('OTHER_CARE_DETAILS.ELECTRONIC_PRESCRIBING_OFFERED')}`;
  } else if (location.electronicPrescribingOffered) {
    text += t('OTHER_CARE_DETAILS.ELECTRONIC_PRESCRIBING_OFFERED');
  }
  return text;
};

export const getPracticeLimitations = (age, gender) =>
  `${age || ''} ${gender ? `(${gender})` : ''}`;

export const getInNetworkStartDate = (inNetworkStartingDate: string) => {
  if (inNetworkStartingDate) return dateFormatCurrent(inNetworkStartingDate);
  return undefined;
};

export const getYesNoText = (t, flag: boolean | undefined) =>
  flag ? t('Yes') : t('No');

export const getYesNoNAText = (t, flag: boolean | undefined) => {
  if (flag === undefined) return t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE');
  if (flag) return t('Yes');

  return t('No');
};

export const getSubPCPText = (
  t,
  isPremiumCarePhysician: string | undefined
) => {
  if (isPremiumCarePhysician === PREMIUM.YES) return t('Yes');
  if (isPremiumCarePhysician === PREMIUM.NO) return t('No');
  return t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE');
};

export const getPremiumCarePhysician = (
  isPremiumCarePhysician: string | undefined
) => isPremiumCarePhysician === PREMIUM.YES;

export const isUserDDPEligible = (ddpCode: UserDDPCode | undefined) =>
  ddpCode &&
  [UserDDPCode.L, UserDDPCode.R, UserDDPCode.A, UserDDPCode.LR].includes(
    ddpCode
  );

export const isShowPreferredFacilityBadge = (
  suppressFacilityFlags,
  showPreferredProviderTag: boolean,
  ddpCodeFacility: string | undefined,
  ddpCodeUser: UserDDPCode | undefined,
  isPreferredLabNetwork: boolean | undefined,
  isFreeStandingFacility: boolean | undefined
) => {
  const {
    suppressPreferredFacility,
    suppressPreferredFacilityPLN,
    suppressPreferredFacilityFSF,
    suppressPreferredFacilityDDP,
  } = suppressFacilityFlags || {};

  return (
    !suppressPreferredFacility &&
    showPreferredProviderTag &&
    ((!suppressPreferredFacilityPLN && isPreferredLabNetwork) ||
      (!suppressPreferredFacilityFSF && isFreeStandingFacility) ||
      (!suppressPreferredFacilityDDP &&
        !!ddpCodeFacility &&
        !!isUserDDPEligible(ddpCodeUser)))
  );
};

export const getSuppressFacilityFlags = () => {
  const suppressPreferredFacility = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREFERRED_FACILITY
  );
  const suppressPreferredFacilityPLN = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PLN
  );
  const suppressPreferredFacilityDDP = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.DDP
  );
  const suppressPreferredFacilityFSF = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.FSF
  );

  return {
    suppressPreferredFacility,
    suppressPreferredFacilityPLN,
    suppressPreferredFacilityDDP,
    suppressPreferredFacilityFSF,
  };
};

export function convertTimeToAmPm(time: string) {
  if (time?.length !== 5 || time[2] !== ':') return time;

  // compare as a string instead of casting to a number for resiliency
  const amTimes = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
  ];
  const pmTimes = [
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];

  if (time.slice(0, 2) === '00' || time.slice(0, 2) === '24')
    return `12:${time.slice(3, 5)} AM`;
  if (time.slice(0, 2) === '12') return time.concat(' PM');
  if (amTimes.includes(time.slice(0, 2))) {
    if (time[0] === '0') return time.slice(1, 5).concat(' AM');
    return time.slice(0, 5).concat(' AM');
  }

  if (pmTimes.includes(time.slice(0, 2))) {
    const convertedHour = amTimes[pmTimes.indexOf(time.slice(0, 2))];
    if (convertedHour[0] === '0')
      return convertedHour[1].concat(time.slice(2, 5).concat(' PM'));
    return convertedHour.concat(time.slice(2, 5).concat(' PM'));
  }
  return time;
}

export const getUncommonElements = (array1, array2) => {
  const differenceInResults: string[] = [];
  array1.forEach((e1) => {
    if (!array2?.includes(e1)) {
      differenceInResults.push(e1);
    }
  });
  return differenceInResults;
};

export const getProviderResults = (
  sectionType,
  practitionerResults,
  useMockData = false
) => {
  let filteredPractitionerResults: any;
  let totalResultCount;
  let searchRadius;

  switch (sectionType) {
    case Constants.RESULT_SECTION.PROVIDER:
      totalResultCount = practitionerResults?.data?.totalCountPractitioners;
      searchRadius = practitionerResults?.data?.practitionerSearchRadius;
      filteredPractitionerResults = useMockData
        ? Constants.MOCK_RESULTS.providers
        : practitionerResults?.data?.providers;
      break;
    case Constants.RESULT_SECTION.FACILITY:
      totalResultCount = practitionerResults?.data?.totalCountOrganizations;
      searchRadius = practitionerResults?.data?.organizationSearchRadius;
      filteredPractitionerResults = useMockData
        ? Constants.MOCK_RESULTS.facilities
        : practitionerResults?.data?.facilities;
      break;
    case Constants.RESULT_SECTION.PROVIDER_GROUPS:
      totalResultCount = practitionerResults?.data?.totalCountMedicalGroup;
      searchRadius = practitionerResults?.data?.medicalGroupSearchRadius;
      filteredPractitionerResults = useMockData
        ? MockProviderGroupResults
        : practitionerResults?.data?.medicalGroup;
      break;
    default:
      totalResultCount = MockProviderGroupResults.length;
      filteredPractitionerResults = MockProviderGroupResults;
      break;
  }

  return {
    error: practitionerResults?.error,
    results: filteredPractitionerResults,
    totalResultsCount: totalResultCount,
    providerSearchRadius: searchRadius,
    isLoading: practitionerResults?.isLoading || practitionerResults?.loading,
  };
};

export const getProviderType = (providerType, sectionType) => {
  switch (sectionType) {
    case Constants.SEARCH_TYPES.PROVIDER:
      return ProviderTypes.PRACTITIONER;
    case Constants.SEARCH_TYPES.PROVIDER_GROUP:
      return ProviderTypes.MEDICAL_GROUP;
    case Constants.SEARCH_TYPES.FACILITY:
      return ProviderTypes.ORGANIZATION;
    default:
      return providerType;
  }
};

export const providerHasLocations = (
  providerDetails: ProviderDetails | FacilityDetails
): boolean => providerDetails?.providerLocations?.length > 1;

export const filterNotEmptyHoursOfOperations = (selectedLocation) =>
  selectedLocation?.hoursOfOperation?.filter(
    (str) => str.closingTime !== '' || str.openingTime !== ''
  );

/**
 * groupingClosingAndOpeningTime Fn
 *
 *
 * @param t -> useTranslation hook
 * @param notEmptyHoursOfOperation -> An array with the hours of operation that are not empty.
 * PES return in some cases for some providers hours of operation empty so we have to deal with it.
 * i.e Monday hours of operation could potentially come empty since practitioner or facility is not open on Mondays
 *
 * @returns a grouped by day object with the hours of operation (opening and closing) time of the particular provider or an empty {} object that we expect in front-end to display " 🕔 Not Available "
 */
export const groupingClosingAndOpeningTime = (
  t,
  notEmptyHoursOfOperation
): {} =>
  notEmptyHoursOfOperation?.reduce((group, p) => {
    const filteredGroup = { ...group };
    if (!filteredGroup[p.openingTime]) filteredGroup[p.openingTime] = {};

    if (!filteredGroup[p.openingTime][p.closingTime])
      filteredGroup[p.openingTime][p.closingTime] = [];
    const translatedDay = t(p.day);
    filteredGroup[p.openingTime][p.closingTime].push(translatedDay);
    return filteredGroup;
  }, {});

export const getLocationId = (tokenData) =>
  tokenData?.locationId ||
  storage.session.get(
    Constants.STORAGE_KEYS.SESSION.SELECTED_FACILITY_LOCATION_ID
  );

export const getProviderId = (tokenData) =>
  tokenData?.providerId || storage.session.get('selectedProviderID');

export const getDdpCode = (code) => {
  const codeData = {
    R: 'Radiology',
    L: 'Lab',
    Imaging: 'Imaging',
    Lab: 'Lab',
    'Lab and Imaging': 'Lab and Imaging',
  };
  return codeData[code] || '';
};

export const getNetworkStatus = (status) =>
  NetworkStatus[status] ||
  Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE;

export const getSelectedLocationIndex = (providerLocations, selectedId) => {
  const selectedIdIndex = providerLocations?.findIndex(
    (location) => selectedId === location.locationId
  );
  if (selectedIdIndex !== undefined && selectedIdIndex !== -1)
    return selectedIdIndex;

  const defaultLocationIndex = providerLocations?.findIndex(
    (location) => location.defaultLocation
  );
  return defaultLocationIndex === undefined || defaultLocationIndex === -1
    ? 0
    : defaultLocationIndex;
};

export const getRecentSearchCarouselDisplayValByLength = (
  reviewCardsCount
): boolean => {
  const threeCardsCarousel = useMediaQuery(threeRecentSearchCardsScreen);
  const fourCardsCarousel = useMediaQuery(fourRecentSearchCardsScreen);
  const fiveCardsCarousel = useMediaQuery(fiveRecentSearchCardsScreen);
  if (reviewCardsCount === 3) {
    return threeCardsCarousel;
  }
  if (reviewCardsCount === 4) {
    return fourCardsCarousel;
  }
  if (reviewCardsCount === 5) {
    return fiveCardsCarousel;
  }

  return reviewCardsCount >= 6;
};

export const getPatientCardLength = (reviewCardsCount, isWidget): boolean => {
  const bFourCardsCarouselDisplay =
    useMediaQuery(reviewsFourCardsCarouselScreen) || isWidget;
  const bThreeCardsCarouselDisplay =
    useMediaQuery(reviewsThreeCardsCarouselScreen) || isWidget;
  const bTwoCardsCarouselDisplay =
    useMediaQuery(reviewsTwoCardsCarouselScreen) || isWidget;
  const l = false;
  if (reviewCardsCount === 2) {
    return bTwoCardsCarouselDisplay;
  }
  if (reviewCardsCount === 3) {
    return bThreeCardsCarouselDisplay;
  }
  if (reviewCardsCount >= 4) {
    return bFourCardsCarouselDisplay;
  }
  return l;
};

export const getProviderPCPId = (
  providerLocations: ProviderLocation[],
  providerId: string = '',
  memberPCPId: string = ''
): string => {
  if (!providerLocations && !providerId) {
    return '';
  }
  const providerPCPIdWithAddress = providerLocations?.[0]?.pcpId;

  if (providerPCPIdWithAddress) {
    return providerPCPIdWithAddress.split(' ')[0].trim();
  }

  let pcpId = providerId || '';
  const diff = (memberPCPId?.length || 0) - pcpId.length;

  // The provider id from PES and UES has limited leading zero's when compared to the response from OBAPI.
  // In-order for proper comparison of PCP, concat with leading zero's.
  for (let index = 0; index < diff; index++) {
    pcpId = `0${pcpId}`;
  }

  return pcpId;
};

export const getDetailsPathByType = (providerType: string) => {
  switch (ProviderType[providerType]) {
    case ProviderType.MEDICAL_GROUP:
    case ProviderType.PROVIDER_GROUP:
      return ConstantsRoutes.PROVIDER_GROUP_DETAILS.path;
    case ProviderType.ORGANIZATION:
      return ConstantsRoutes.FACILITY_DETAILS.path;
    default:
      return ConstantsRoutes.PROVIDER_DETAILS.path;
  }
};

// eslint-disable-next-line consistent-return
export const getOfferVirtualCareText = (offerVirtualCareText: string) => {
  let replacedOfferVirtualCareSubtext: string = '';
  if (offerVirtualCareText === undefined) {
    return offerVirtualCareText;
  }
  if (offerVirtualCareText.includes('Audio')) {
    replacedOfferVirtualCareSubtext = offerVirtualCareText.replaceAll(
      'Audio',
      'Phone'
    );
    return replacedOfferVirtualCareSubtext;
  }
};

export const getAcceptingNewPatientsStatus = (
  acceptingNewPatients: boolean | null
) => {
  if (acceptingNewPatients) {
    return {
      icon: 'done',
      acceptingPatientInfo:
        'PROVIDER_LOCATIONS_DETAILS.LOCATION_CARD_ACCEPTING_PATIENTS',
    };
  }
  if (acceptingNewPatients === false) {
    return {
      icon: 'close',
      acceptingPatientInfo:
        'PROVIDER_LOCATIONS_DETAILS.LOCATION_CARD_NOT_ACCEPTING_PATIENTS',
    };
  }

  return {
    icon: 'do_disturb_alt',
    acceptingPatientInfo:
      'PROVIDER_LOCATIONS_DETAILS.LOCATION_CARD_NO_INFO_AVAILABLE',
  };
};

export const getCarePathCategoriesSortOrder = (
  practitioner: boolean,
  userlocale: string,
  costCareCategories: any
) => {
  if (practitioner) {
    return costCareCategories
      .filter((costCareCategory: { locale: string }) =>
        costCareCategory.locale.startsWith(userlocale)
      )
      .map(
        (costCareCategory: { DNT_CostCategory: string }) =>
          costCareCategory.DNT_CostCategory
      );
  }
  return [];
};
