import { useRouter } from '@abyss/web/hooks/useRouter';
/* eslint-disable no-nested-ternary */
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import { useContext } from 'react';

import { CountySearchContext } from '../../context/CountySearchContext';
import { useLagoon } from '../../hooks/useLagoon';
import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { OutsideCountyAlert } from '../DataCard/OutsideCountyAlert';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import { CardInfo } from '../SnackCard/CardInfo';
import { SnackCard } from '../SnackCard/SnackCard';
import {
  carouselStyle,
  slideContainerStyle,
  slideStyleSnack,
} from '../SnackCard/SnackCard.styles';
import { getFeatureFlag } from '../Utils';
import { makeAllSlidesAriaHidden } from '../Utils/a11yUtils/a11yUtils';
import { getImpressionTrackForOutsideCounty } from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { isOutOfCounty } from './utils';

type Props = {
  coverageType?: string;
  snackCardProviders: SnackCardProvider[];
  isLoading: boolean;
  isFixes: boolean;
  locationForAnalytics?: string;
  headers: ResponseHeaders;
};

export const SnackCardCarousel = ({
  coverageType,
  snackCardProviders = [],
  isLoading,
  isFixes,
  locationForAnalytics,
  headers,
}: Props) => {
  const featureFlags = useLagoon('feature-flags')();
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { search, searchMethod } = tokenData;

  const getLinkPositionIndex = (linkPosition?: number) => {
    if (linkPosition !== undefined) {
      return linkPosition + 1;
    }
    return linkPosition;
  };
  const countySearchAlertFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_COUNTY_SEARCH_ALERT
  );
  const { isCountySearch } = useContext(CountySearchContext);
  const { name, stateCode } = getGeoLocationFromStorage();

  const noProvidersFoundData = useLagoon('ui-messaging')();
  const noProvidersFoundDataContent = find(noProvidersFoundData, {
    key: ConstantsLagoon.NO_PROVIDERS_FOUND,
  });
  const linkLocationName = `body:${locationForAnalytics}`;
  const slides = isLoading ? (
    [1, 2, 3, 4, 5].map((item) => (
      <SnackCard isFixes={isFixes} isLoading={isLoading} key={item} />
    ))
  ) : snackCardProviders.length ? (
    // eslint-disable-next-line arrow-body-style
    snackCardProviders.map((snackCardProvider, index) => {
      const isOutOfCnty = isOutOfCounty(
        isCountySearch,
        name,
        stateCode,
        snackCardProvider
      );
      getImpressionTrackForOutsideCounty(isOutOfCnty, searchMethod, search);
      return (
        <div>
          <CardInfo
            cardType="snack-card-carousel"
            headers={headers}
            indexForAnalytics={getLinkPositionIndex(index)}
            landingPage
            locationForAnalytics={linkLocationName}
            practitioner={snackCardProvider}
          />
          <Slide css={slideStyleSnack}>
            <Slide.Container css={slideContainerStyle}>
              <SnackCard
                coverageType={coverageType}
                distance={snackCardProvider.distance}
                hasPhysicalAddress={!!snackCardProvider.address}
                headers={headers}
                imgSrc={snackCardProvider.imageLocation}
                index={index}
                isFixes={isFixes}
                isLoading={isLoading}
                isTieredProvider={snackCardProvider?.isTieredProvider}
                isVirtualCareOffered={
                  !!snackCardProvider.virtualCareOffered?.length
                }
                key={snackCardProvider.locationId}
                locationForAnalytics={locationForAnalytics}
                locationId={snackCardProvider.locationId}
                organizationType={snackCardProvider.organizationType}
                professionalDesignation={
                  snackCardProvider.primaryDegrees?.length
                    ? snackCardProvider.primaryDegrees[0]
                    : ''
                }
                providerId={snackCardProvider.providerId}
                providerName={snackCardProvider.providerName}
                providerType={snackCardProvider.providerType}
                rating={
                  snackCardProvider.healthGradeRating
                    ? parseFloat(
                        parseFloat(snackCardProvider.healthGradeRating).toFixed(
                          1
                        )
                      )
                    : undefined
                }
                snackCardProvider={snackCardProvider}
                speciality={snackCardProvider?.speciality}
                website={snackCardProvider?.website || ''}
              />
            </Slide.Container>
          </Slide>
          {countySearchAlertFlag && (
            <OutsideCountyAlert
              county={name?.split(',')[0]}
              isOutOfCounty={isOutOfCnty}
            />
          )}
        </div>
      );
    })
  ) : (
    <Text
      color="#6E7072"
      css={{
        'abyss-text-root': {
          fontWeight: 500,
        },
      }}
    >
      {noProvidersFoundDataContent?.message ?? ''}
    </Text>
  );

  makeAllSlidesAriaHidden();
  return (
    <Carousel
      css={carouselStyle}
      data-auto-testid="browse-facilities-carousel"
      data-testid="browse-facilities-carousel"
      minimal
      nextSlideOnClick={() => makeAllSlidesAriaHidden()}
      noLoop
      prevSlideOnClick={() => makeAllSlidesAriaHidden()}
      slideIndexOnClick={() => makeAllSlidesAriaHidden()}
      slides={slides}
      slidesPerView={1}
    />
  );
};
