import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';

export const ChipContainer = styled(Flex, {
  gap: '8px',
  alignItems: 'center',
  flexWrap: 'nowrap !important',
  '@screen < $md': {
    gap: '12px',
    flexWrap: 'wrap !important',
  },
  '@screen <$sm': {
    flexWrap: 'wrap !important',
  },
});

export const textContent = {
  margin: '0px !important',
  width: '70%',
  div: {
    margin: '0px !important',
  },
};

export const BoxStyles = {
  'abyss-box-root': {
    padding: '0px',
    borderTop: '1px solid #E5E5E6',
  },
};

export const ContainerStyle = styled('a', {
  display: 'flex',
  textDecoration: 'none',
  '&:hover,&:active': {
    textDecoration: 'none',
  },
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '$primary2',
  border: '1px solid $gray3',
  boxShadow: '0px 1px 4px 0px rgba(25, 25, 26, 0.16)',
  borderRadius: '8px',
  padding: '15px 24px',
  marginRight: '8px',
  '@screen >= $md': {
    padding: '15px 20px',
    width: '45%',
    minHeight: '80px',
  },
  '@screen < $md': {
    padding: '15px 15px',
    height: 'auto',
    width: '100%',
    marginRight: '0px',
  },
});
