import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { config } from '@abyss/web/tools/config';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnnexEncryptedPayload } from '../../hooks/useArcade';
import { useLagoon } from '../../hooks/useLagoon';
import { getFormattedAndValidatedPhoneNumber } from '../../utils/phone.utils';
import { getLagoonConfigValue } from '../../utils/providerSearch.utils';
import { getDocAsapTargetUrl } from '../../utils/scheduling.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { mobileOnly, tabletOnly } from '../ConstantsStyles';
import { getFeatureFlag } from '../Utils/getFeatureFlag';
import { HiddenFormSubmission } from './ScheduleModal.style';

type Props = {
  isOpen?: boolean;
  npi: string;
  phone: string;
  practiceId: string;
  setIsOpen: (a: boolean) => void;
};
const ModalWrap = styled(Modal, {
  '.abyss-modal-header-container': { padding: '0px' },
  '.abyss-modal-content-container': {
    borderRadius: '8px',
  },
  '.abyss-modal-content-title': {
    marginTop: '52px',
    paddingTop: '14px',
    paddingBottom: '14px',
    lineHeight: '0px',
    '@screen >= $lg': {
      marginTop: '32px',
    },
  },
  '.abyss-modal-close-button': {
    right: '16px',
    top: '16px',
    padding: '0px',
    minWidth: '20px',
    minHeight: '20px',
    '@screen >= $lg': {
      minWidth: '24px',
      minHeight: '24px',
    },
    svg: {
      width: '20px',
      height: '20px',

      color: '#1F2937',
      fill: '#1F2937',
      '@screen >= $lg': {
        width: '24px',
        height: '24px',
      },
    },
  },
  '.abyss-modal-overlay': {
    background: 'rgba(0, 0, 0, 0.7)',
  },
});

export const ScheduleModal = ({
  isOpen,
  npi,
  phone,
  practiceId,
  setIsOpen,
}: Props) => {
  const { t } = useTranslation();
  const hiddenSubmit = createRef<HTMLButtonElement>();
  const [memberSchedulingHeader, setmemberSchedulingHeader] =
    useState<string>('');
  const [memberSchedulingOnline, setmemberSchedulingOnline] =
    useState<string>('');
  const [encryptedPayload, setEncryptedPayload] = useState<string>('');
  const mobileScreen = useMediaQuery(mobileOnly);
  const isTab = useMediaQuery(tabletOnly);
  const isSmallScreen = mobileScreen || isTab;

  const featureFlags = useLagoon('feature-flags')();
  const schedulingEnabled = getFeatureFlag(featureFlags, 'ONLINE_SCHEDULING');

  const allConfigs = useLagoon('config');
  const uiMessaging = useLagoon('ui-messaging')();
  const docAsapUrl = getLagoonConfigValue(allConfigs, 'DOCASAP_URL');
  const docAsapPartnerCode = getLagoonConfigValue(
    allConfigs,
    'DOCASAP_PARTNER_CODE'
  );
  const docAsapCredentialCode = getLagoonConfigValue(
    allConfigs,
    'DOCASAP_CREDENTIAL_CODE'
  );
  const docAsapChannelId = getLagoonConfigValue(
    allConfigs,
    'DOCASAP_CHANNEL_ID'
  );
  const vendorId = getLagoonConfigValue(allConfigs, 'DOCASAP_VENDOR_ID');
  const ssoOutUrl = getLagoonConfigValue(allConfigs, 'FPC_SSOOUT_URL');

  const [, getPayload] = useAnnexEncryptedPayload({
    onCompleted: (result) => {
      setEncryptedPayload(result?.data?.annexEncryptedPayload?.payload || '');
    },
  });

  const CALENDAR_SVG = `${config(
    'CDN_BASE_URL'
  )}/cdn/assets/images/calendar.svg`;

  const CHAT_SVG = `${config(
    'CDN_BASE_URL'
  )}/cdn/assets/images/chat_connection.svg`;

  useEffect(() => {
    if (encryptedPayload) {
      hiddenSubmit.current?.click();
    }
  }, [encryptedPayload]);

  const ssoOutTargetUrl = getDocAsapTargetUrl(
    docAsapUrl,
    docAsapPartnerCode,
    docAsapCredentialCode,
    docAsapChannelId,
    npi,
    practiceId
  );
  const doShowDocASAP = schedulingEnabled && practiceId;
  const buttonWidth = isSmallScreen ? '100%' : '226px';

  const iconSize = isSmallScreen ? 16 : 24;

  const handleScheduleClick = () => {
    adobeLinkTrackEvent({
      name: Constants.DETAILS_CHIP_NAMES.Schedule,
      location: 'modal:schedule a visit:schedule',
      type: 'external',
      destinationUrl: ssoOutTargetUrl,
    });
    getPayload();
  };

  const handleCallClick = () => {
    const href = `tel:${getFormattedAndValidatedPhoneNumber(phone)}`;
    adobeLinkTrackEvent({
      name: Constants.DETAILS_CHIP_NAMES.Call,
      location: 'modal:schedule a visit:call',
      destinationUrl: href,
    });
    window.location.href = href;
  };

  useEffect(() => {
    if (uiMessaging) {
      const MEMBER_SCHEDULING_MODAL_HEADER = uiMessaging.find(
        (x) => x.key === 'MEMBER_SCHEDULING_MODAL_HEADER'
      );
      setmemberSchedulingHeader(MEMBER_SCHEDULING_MODAL_HEADER?.message);
    }
  }, [uiMessaging]);

  useEffect(() => {
    if (uiMessaging) {
      const MEMBER_SCHEDULING_MODAL_ONLINE = uiMessaging.find(
        (x) => x.key === 'MEMBER_SCHEDULING_MODAL_ONLINE'
      );
      setmemberSchedulingOnline(MEMBER_SCHEDULING_MODAL_ONLINE?.message);
    }
  }, [uiMessaging]);

  return (
    <ModalWrap
      closeOnClickOutside={false}
      data-auto-testid="schedule-modal-container"
      data-testid="schedule-modal-container"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size={isSmallScreen ? '320px' : '624px'}
      title={
        doShowDocASAP ? (
          <img alt="calendar" src={CALENDAR_SVG} />
        ) : (
          <img alt="chat" src={CHAT_SVG} />
        )
      }
      titleAlign="center"
    >
      <Modal.Section
        css={{
          'abyss-modal-section-root': {
            textAlign: 'center',
            padding: '16px',
            lineHeight: '18px',
          },
        }}
      >
        <div>
          <Text
            css={{
              '&.abyss-text-root': {
                fontWeight: '700',
                fontSize: '14px',
                '@screen >= $lg': {
                  lineHeight: '20px',
                  fontWeight: '700',
                  fontSize: '16px',
                },
              },
            }}
          >
            {t(memberSchedulingHeader)}
          </Text>
        </div>
        <div>
          <Text
            css={{
              '&.abyss-text-root': {
                fontSize: '14px',
                '@screen >= $lg': {
                  lineHeight: '16px',
                },
              },
            }}
            fontWeight="500"
            size="14px"
          >
            {doShowDocASAP
              ? t(memberSchedulingOnline)
              : t('SCHEDULE_MODAL.DESCRIPTION')}
          </Text>
        </div>
      </Modal.Section>
      <Modal.Section
        css={{
          display: 'flex',
          justifyContent: 'center',
          '&.abyss-modal-section-root': {
            paddingTop: isSmallScreen ? '12px' : '0px',
            paddingBottom: isSmallScreen ? '16px' : '24px',
          },
        }}
      >
        {doShowDocASAP ? (
          <React.Fragment>
            <Button
              css={{
                width: buttonWidth,
                borderRadius: '24px',
              }}
              data-auto-testid="schedule-modal-schedule-button"
              data-testid="schedule-modal-schedule-button"
              onClick={handleScheduleClick}
              size="30px"
            >
              {t('SCHEDULE_MODAL.SCHEDULE')}
              <IconMaterial
                css={{ marginLeft: '8px' }}
                icon="launch"
                size={iconSize}
              />
            </Button>
            <HiddenFormSubmission
              action={ssoOutUrl}
              data-testid="docasap-form"
              method="post"
              target="_blank"
            >
              <input
                data-testid="docasap-vendorid"
                name="vendorId"
                type="hidden"
                value={vendorId}
              />
              <input name="rally-caller" type="hidden" value="myuhc_web.psx" />
              <input
                data-testid="annex-payload"
                name="annex"
                type="hidden"
                value={encryptedPayload}
              />
              <input
                data-testid="docasap-target-url"
                name="targetUrl"
                type="hidden"
                value={ssoOutTargetUrl}
              />
              <button ref={hiddenSubmit} type="submit" />
            </HiddenFormSubmission>
          </React.Fragment>
        ) : (
          <Button
            css={{
              width: buttonWidth,
              borderRadius: '24px',
            }}
            data-auto-testid="schedule-modal-call-button"
            data-testid="schedule-modal-call-button"
            onClick={handleCallClick}
            size="30px"
          >
            <IconMaterial
              css={{ marginRight: '8px' }}
              icon="phone"
              size={iconSize}
            />
            {t('SCHEDULE_MODAL.CALL')}
          </Button>
        )}
      </Modal.Section>
    </ModalWrap>
  );
};
