import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';

import { ChoosePCPAlert } from '../../../../common/Alert/ChoosePCPAlert';
import { EAPCode } from '../../../../common/EAPCode';
import { FiltersContainerDesktop } from './FiltersContainerDesktop';
import { EAPStyle } from './MapView.styled';

type Props = {
  pageHeader: string;
  sectionType: string;
  mobileScreen: boolean;
  eapCodeFlag;
  isBHCare;
  isPageTracked: boolean;
};

export const MapViewHeader = ({
  pageHeader,
  sectionType,
  mobileScreen,
  eapCodeFlag,
  isBHCare,
  isPageTracked,
}: Props) => (
  <React.Fragment>
    <Layout.Stack
      alignItems="left"
      css={{ '@screen < $sm': { display: 'none' } }}
      grow="true"
      space="0"
    >
      <Heading
        css={{
          'abyss-heading-root': {
            '@screen < $md': {
              fontSize: '$mediumText !important',
              lineHeight: '$lg !important',
            },
            marginBottom: '$sm',
          },
        }}
        data-auto-testid="results-heading"
        data-testid="results-heading"
        display="h4"
        offset={1}
        textAlign="left"
      >
        {pageHeader}
      </Heading>
      <ChoosePCPAlert isMapView />
      <FiltersContainerDesktop sectionType={sectionType} />
    </Layout.Stack>
    {!mobileScreen && isPageTracked && eapCodeFlag && isBHCare && (
      <EAPStyle>
        <EAPCode />
      </EAPStyle>
    )}
  </React.Fragment>
);
