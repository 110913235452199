import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useSnackCardProviderResults } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { getSnackCardDisplayValByLength } from '../../utils/snackcard.utils';
import { Constants, FilterCollectionModel, ProviderTypes } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import {
  cardSubHeaderContainerStyle,
  cardSubHeaderWithNavigatorStyle,
  landingDrawerIcon,
  landingPopOverIcon,
  phoneOnly,
  viewAllLinkStyle,
} from '../ConstantsStyles';
import { LinkContainer } from '../LinkContainer/LinkContainer';
import { SnackCardCarousel, SnackCardContainer } from '../SnackCardContainer';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';

const CardResultContainer = styled('div', {
  marginTop: '$md',
});

type CardGroup = {
  heading?: string;
  headingNotTranslated?: string;
  popoverContent?: string;
  specialtyCode?: string;
  isOnlineProviders: string;
  coverageType: string;
  providerType: string;
  isActive: boolean;
};

type Props = {
  section: CardGroup;
  onCompleted: (data: any[]) => any;
};

export const VisionCare = ({ section, onCompleted }: Props) => {
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();
  const visionCarePopOverTestId = `vision-care-popover-${section?.headingNotTranslated}`;
  const visionCareModalDrawerTestId: string = 'vision-care-drawer';
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { setDefaultSearchFilters } = useContext(SearchFilterContext);

  useEffect(() => {
    if (isOpen) {
      const linkName: string = visionCarePopOverTestId;
      const linkLocation: string = `body:${section.headingNotTranslated}`;
      const modalName: string =
        typeof section.headingNotTranslated !== 'string'
          ? String(section.headingNotTranslated)
          : section.headingNotTranslated;
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  const [shouldGetHGData] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_HEALTH_GRADES_INFO_FOR_PRACTIONER,
  ]);

  const {
    data: providersData,
    isLoading,
    headers,
  } = useSnackCardProviderResults({
    includeSpecialityRollupCodes: section.specialtyCode,
    providerType: section.providerType,
    coverageType: section.coverageType,
    onlineRetailers: section.isOnlineProviders, // isOnlineProviders that configured in lagoon must be 'Y/N/Blank'
    onCompleted,
    shouldGetHGData,
  });

  const numberOfCards = providersData?.snackCardList?.length;
  const displayCarousel = getSnackCardDisplayValByLength(numberOfCards);
  const displayViewAllLink = isLoading === false && numberOfCards;

  const handleViewAllOnClick = () => {
    const updatedToken = tokenizer.update(token, {
      coverageType: section.coverageType,
      specialtyCode: section.specialtyCode,
      search: section.heading,
      sectionType:
        section.providerType === ProviderTypes.PRACTITIONER
          ? Constants.SEARCH_TYPES.PROVIDER
          : Constants.SEARCH_TYPES.FACILITY,
      providerType: section.providerType,
      searchType: Constants.SEARCH_TYPES.SPECIALTY,
      onlineRetailers: section.isOnlineProviders, // isOnlineProviders that configured in lagoon must be 'Y/N/Blank'
    });
    setDefaultSearchFilters([FilterCollectionModel.AUTO_SEARCH_RADIUS], {
      autoSearchRadius: providersData?.searchRadius,
    });

    navigate(
      `${ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.path}/${updatedToken}`
    );
  };

  return (
    <React.Fragment>
      {section.isActive ? (
        <CardResultContainer>
          <Flex
            css={cardSubHeaderContainerStyle}
            justify="start"
            key={section.heading}
          >
            <div>
              <Heading
                css={cardSubHeaderWithNavigatorStyle}
                data-auto-testid="vision-care-heading"
                data-testid="vision-care-heading"
                display="h6"
                offset={2}
              >
                {section.heading}
              </Heading>
              <span
                style={{
                  marginLeft: '4px',
                  display: 'inline-block',
                }}
              >
                <Drawer
                  css={landingDrawerIcon}
                  isOpen={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                  position="bottom"
                  size="200"
                  title={section?.heading}
                >
                  {section?.popoverContent}
                </Drawer>
                {mobileScreen ? (
                  <IconMaterial
                    css={landingPopOverIcon}
                    data-auto-testid={visionCareModalDrawerTestId}
                    data-testid={visionCareModalDrawerTestId}
                    icon="info"
                    onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                    size={16}
                    variant="outlined"
                  />
                ) : (
                  <Popover
                    aria-label={`${t('INFORMATION_ABOUT')} ${section.heading}`}
                    content={section?.popoverContent}
                    css={{
                      'abyss-popover-content': {
                        zIndex: 9,
                      },
                    }}
                    data-auto-testid={visionCarePopOverTestId}
                    data-testid={visionCarePopOverTestId}
                    onOpenChange={() => setIsOpen(!isOpen)}
                    open={isOpen}
                    title={section?.heading}
                    width={mobileScreen ? 222 : 180}
                  >
                    <IconMaterial
                      css={landingPopOverIcon}
                      data-auto-testid={visionCarePopOverTestId}
                      data-testid={visionCarePopOverTestId}
                      icon="info"
                      onClick={() => setIsOpen(!isOpen)}
                      size={16}
                      variant="outlined"
                    />
                  </Popover>
                )}
              </span>
            </div>
            {displayViewAllLink ? (
              <LinkContainer
                analyticsInfo={{
                  location: `body:${section.headingNotTranslated}`,
                }}
                aria-label={`${t('View all')} ${section.heading}`}
                css={viewAllLinkStyle}
                data-auto-testid="vision-care-view-all-link"
                data-testid="vision-care-view-all-link"
                onClick={handleViewAllOnClick}
                text={`${t('View all')}`}
              />
            ) : null}
          </Flex>

          <div>
            {displayCarousel ? (
              <SnackCardCarousel
                coverageType={section.coverageType}
                headers={headers}
                isFixes
                isLoading={isLoading}
                locationForAnalytics={section.headingNotTranslated}
                snackCardProviders={providersData?.snackCardList}
              />
            ) : (
              <SnackCardContainer
                coverageType={section.coverageType}
                data-auto-testid="vision-snack-card-container"
                dataTestId="vision-snack-card-container"
                headers={headers}
                isLoading={isLoading}
                locationForAnalytics={section.headingNotTranslated}
                snackCardProviders={providersData?.snackCardList}
              />
            )}
          </div>
        </CardResultContainer>
      ) : null}
    </React.Fragment>
  );
};
