import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Tooltip } from '@abyss/web/ui/Tooltip';
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ChipCategoryContext } from '../../context/ChipCategoryContext';
import { Provider } from '../../models/Provider';
import {
  Address as ProviderAddress,
  ProviderType,
} from '../../models/ProviderDetails';
import { ProviderSearchFilters } from '../../models/ProviderSearch';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { clearRoute, setResultIcon } from '../../utils/map.utils';
import { adobeSearchResultClickEvent } from '../AdobeTagging/adobeSearchResultClickEvent';
import { Constants } from '../Constants';
import { ImageComponent } from '../ImageComponent';
import {
  appendCoverageTypes,
  convertProviderTypeToAdobeType,
  formatProviderId,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { Address } from './Address';
import { ChoosePCP } from './ChoosePCP';
import { CostIndicatorPopover } from './CostIndicatorPopover';
import {
  AvatarDataCard,
  CardContainer,
  DataCardAvatarContainer,
  FlexStyles,
  MilesAndAddressWrapper,
  NetworkWrapper,
  ResponsiveText,
  VirtualVisitLabel,
  costIndicatorStyles,
  displayVirtualVisitStyle,
  milesTextStyles,
  specialityNameToolTipStyles,
  virtualVistsToolTipStyles,
} from './DataCard.style';
import {
  getDetailsRoute,
  getLinkNameForSearchResultEvent,
  getLinkPositionIndex,
  handlePatientReviewsView,
  inOrOutOfNetwork,
} from './DataCardUtils';
import { Miles } from './Miles';
import { NameHeading } from './NameHeading';
import { ProviderRating } from './ProviderRating';

type Props = {
  practitioner?: Provider;
  surveyCount?: number;
  updatePin?: (practitioner?: Provider) => void;
  handleDirectionClick?: () => void;
  index?: number;
  enableCost?: boolean;
  pageNumber?: number;
  map?: any;
  selectedFilters?: string;
  setRouteEndCoords?(coords: [number | null, number | null]): void;
  usedAt: string;
  searchTerm?: string;
  setNavigateToDirections?: (a: boolean) => void;
  headers: ResponseHeaders;
  providerType?: string;
  linkLocationName: string;
  tokenData: any;
  sectionTypeDerived: string;
  providerId: string;
  locationId?: string;
  isTieredProvider: boolean;
  speciality?: string;
  setSearchFilters: (a: ProviderSearchFilters) => void;
  searchFilters: ProviderSearchFilters;
  setSelectedId: (a: string | null) => void;
  setHighlightId: (a: { providerId: string; from: string }) => void;
  highlightId: { providerId: string; from: string };
  showBestMatchDetails: boolean;
  setIsOpen: (a: boolean) => void;
  virtualCareIndicatorEnabled: boolean;
  cardRef: React.RefObject<HTMLElement>;
  highlightClassName: string;
  cardContainerCss: any;
  handleHoverOnCard: (a: any) => void;
  imgRef: any;
  name: string;
  providerSpeciality: string;
  suppressPatientReviews: boolean;
  healthGradeRating?: string;
  networkStatus?: string;
  npi: string;
  distance?: string;
  address?: ProviderAddress;
  primaryPhone: string;
  choosePCP: boolean;
  docasapPracticeId: string;
};
export const DataCard = ({
  searchTerm,
  selectedFilters,
  index,
  providerType,
  linkLocationName,
  headers,
  practitioner,
  tokenData,
  sectionTypeDerived,
  providerId,
  locationId,
  isTieredProvider,
  speciality,
  setSearchFilters,
  searchFilters,
  pageNumber,
  handleDirectionClick,
  usedAt,
  setNavigateToDirections,
  setSelectedId,
  map,
  setHighlightId,
  updatePin,
  setRouteEndCoords,
  highlightId,
  showBestMatchDetails,
  setIsOpen,
  virtualCareIndicatorEnabled,
  cardRef,
  highlightClassName,
  cardContainerCss,
  handleHoverOnCard,
  imgRef,
  name,
  providerSpeciality,
  suppressPatientReviews,
  healthGradeRating,
  networkStatus,
  npi,
  enableCost,
  distance,
  address,
  primaryPhone,
  choosePCP,
  docasapPracticeId,
}: Props) => {
  const { t } = useTranslation();
  const { getRouteParams, navigate } = useRouter();
  const { token } = getRouteParams();

  const { value: contextValue } = useContext(ChipCategoryContext);
  const handleAdobeSearchResultClickEvent = (clickSection?: string) => {
    adobeSearchResultClickEvent({
      filters: selectedFilters,
      linkName: getLinkNameForSearchResultEvent(providerType, clickSection),
      linkPosition: getLinkPositionIndex(index),
      linkLocation: linkLocationName,
      customAttributesBlock: {
        correlationId: headers.correlationId,
        providerId: formatProviderId(
          appendCoverageTypes(practitioner, tokenData)
        ),
        providerType: convertProviderTypeToAdobeType(providerType),
      },
      term: searchTerm,
    });
  };
  const handleDetailsOnClick = ({ showDirection }) => {
    handleAdobeSearchResultClickEvent();
    const categoryCoverageType = Constants.CARE_CATEGORIES_NAME_PAYLOAD.find(
      (category) => category.label === contextValue
    )?.coverages?.[0];
    const detailsRoute = getDetailsRoute(sectionTypeDerived);

    const updatedToken = tokenizer.update(token, {
      providerId,
      sectionType: sectionTypeDerived,
      locationId,
      showDirection,
      isTieredDataCard: isTieredProvider,
      specialtySearch: speciality,
      coverageType:
        categoryCoverageType ??
        storage.session.get(Constants.STORAGE_KEYS.SESSION.COVERAGE_TYPE),
      chipValue: contextValue,
    });

    setSearchFilters({
      ...searchFilters,
      PageNumber: pageNumber,
    });

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      providerId
    );

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_FACILITY_LOCATION_ID,
      locationId
    );
    navigate(`${detailsRoute}${updatedToken}`);
  };
  const showDirection = () => {
    if (handleDirectionClick) {
      if (usedAt === Constants.MOBILE_LIST_VIEW) {
        setNavigateToDirections?.(true);
        setSelectedId(locationId || null);
      } else {
        handleDirectionClick();
      }
    } else {
      handleDetailsOnClick({ showDirection: true });
    }
  };
  const handleOnHeadingBlur = () => {
    if (map?.current) {
      setResultIcon(
        map.current,
        +providerId,
        false,
        setHighlightId,
        providerId
      );
      updatePin?.(undefined);
    }
    setHighlightId({ providerId: '', from: usedAt });
  };

  const handleNavigationOnClick = () => {
    if (map?.current && setRouteEndCoords) {
      clearRoute(
        map.current,
        setRouteEndCoords,
        highlightId.providerId,
        setHighlightId
      );
      setSelectedId(null);
    }
    showDirection();
  };

  const onCardClick = () => {
    if (showBestMatchDetails) {
      handleAdobeSearchResultClickEvent('datacard click');
      setIsOpen(true);
    } else {
      handleDetailsOnClick({ showDirection: false });
    }
  };
  const handleChoosePCPClick = (event) => {
    adobeSearchResultClickEvent({
      linkName: 'choose-pcp-button',
      linkPosition: getLinkPositionIndex(index),
      linkLocation: linkLocationName,
      customAttributesBlock: {
        correlationId: headers.correlationId,
        providerId: formatProviderId(
          appendCoverageTypes(practitioner, tokenData)
        ),
        providerType: convertProviderTypeToAdobeType(providerType),
      },
    });
    const updatedToken = tokenizer.update(token, {
      choosePCPId: event.currentTarget.dataset.providerid,
      selectedProviderType: providerType,
      originLinkNameForAnalytics: 'choose-pcp-button',
    });

    navigate(`/choose-pcp/locations/${updatedToken}`);
  };

  const displayVirtualVisit =
    virtualCareIndicatorEnabled &&
    practitioner?.address &&
    !!practitioner?.virtualCareOffered?.length &&
    providerType === ProviderType.PRACTITIONER;

  const imgSrc = practitioner?.imageLocation;

  const enablePatientView = handlePatientReviewsView(
    enableCost,
    practitioner,
    displayVirtualVisit
  );

  return (
    <CardContainer
      className={highlightClassName}
      css={cardContainerCss}
      data-auto-testid="generic-data-card"
      data-testid="generic-data-card"
      id={`data-card-for-map-${providerId}`}
      onMouseEnter={handleHoverOnCard}
      ref={cardRef}
      style={{ cursor: 'pointer' }}
    >
      <Card.Section
        css={{
          marginTop: '12px',
          padding: '0 16px',
          '@screen < $sm': { padding: '0 12px' },
        }}
        data-auto-testid="data-card-details-section"
        data-testid="data-card-details-section"
        onClick={() => onCardClick}
      >
        <Flex>
          <Flex>
            <DataCardAvatarContainer>
              <AvatarDataCard>
                {ImageComponent({ imgRef, providerType, imgSrc })}
              </AvatarDataCard>
              {displayVirtualVisit && (
                <Flex color="$primary1" css={displayVirtualVisitStyle}>
                  <IconMaterial
                    aria-hidden="true"
                    color="white"
                    icon="videocam"
                    size={16}
                    variant="filled"
                  />
                </Flex>
              )}
            </DataCardAvatarContainer>
          </Flex>

          <Flex css={{ flex: 1, marginLeft: '8px' }} direction="column">
            <NameHeading
              handleDetailsOnClick={handleDetailsOnClick}
              handleHoverOnCard={handleHoverOnCard}
              handleOnHeadingBlur={handleOnHeadingBlur}
              name={name}
              providerId={providerId}
              sectionTypeDerived={sectionTypeDerived}
            />
            <Flex css={{ padding: '2px 0 2px $sm' }} justify="space-between">
              <Flex direction="column">
                {providerSpeciality && (
                  <Tooltip
                    content={providerSpeciality}
                    css={specialityNameToolTipStyles}
                    position="top"
                    positionOffset={8}
                  >
                    <ResponsiveText
                      as="div"
                      data-auto-testid={`data-card-specialty-${sectionTypeDerived}`}
                      data-testid={`data-card-specialty-${sectionTypeDerived}`}
                      fontWeight="$medium"
                    >
                      {providerSpeciality}
                    </ResponsiveText>
                  </Tooltip>
                )}
                {enablePatientView && (
                  <Flex alignItems="center" css={FlexStyles}>
                    {sectionTypeDerived === Constants.RESULT_SECTION.PROVIDER &&
                      !suppressPatientReviews && (
                        <ProviderRating
                          healthGradeRating={healthGradeRating}
                          providerId={providerId}
                          sectionTypeDerived={sectionTypeDerived}
                          surveyCount={practitioner?.surveyCount}
                        />
                      )}
                  </Flex>
                )}
                {displayVirtualVisit && (
                  <Layout.Group
                    css={{
                      paddingTop: enablePatientView ? '0px' : '8px',
                      '@screen < $sm': {
                        paddingTop: enablePatientView ? '0px' : '6px',
                        maxWidth: enablePatientView ? '175px' : '100px',
                      },
                      '.abyss-tooltip-trigger': {
                        '@media (max-width: 390px)': {
                          maxWidth: enablePatientView ? '100px' : '75px',
                        },
                      },
                    }}
                  >
                    <IconMaterial color="$primary1" icon="videocam" size={20} />
                    <Tooltip
                      content={t('Offers virtual visits')}
                      css={virtualVistsToolTipStyles}
                      position="top"
                      positionOffset={8}
                    >
                      <VirtualVisitLabel
                        as="div"
                        data-auto-testid={`data-card-virtual-visits-${sectionTypeDerived}`}
                        data-testid={`data-card-virtual-visits-${sectionTypeDerived}`}
                        fontWeight="$medium"
                      >
                        {t('Offers virtual visits')}
                      </VirtualVisitLabel>
                    </Tooltip>
                  </Layout.Group>
                )}
              </Flex>
              <Flex alignItems="end" direction="column">
                <NetworkWrapper
                  data-testid={`data-card-network-${sectionTypeDerived}-section`}
                >
                  <IconMaterial
                    color={networkStatus ? '$success1' : ''}
                    data-auto-testid={`data-card-check-circle-icon-${sectionTypeDerived}-${providerId}`}
                    data-testid={`data-card-check-circle-icon-${sectionTypeDerived}-${providerId}`}
                    icon="check_circle"
                    size={16}
                  />
                  <ResponsiveText
                    data-auto-testid={`data-card-network-${sectionTypeDerived}-${providerId}`}
                    data-testid={`data-card-network-${sectionTypeDerived}-${providerId}`}
                    fontWeight="$medium"
                  >
                    {t(inOrOutOfNetwork(networkStatus))}
                  </ResponsiveText>
                </NetworkWrapper>
                {!enablePatientView && (
                  <Flex css={FlexStyles}>
                    {sectionTypeDerived === Constants.RESULT_SECTION.PROVIDER &&
                      !suppressPatientReviews && (
                        <ProviderRating
                          healthGradeRating={healthGradeRating}
                          providerId={providerId}
                          sectionTypeDerived={sectionTypeDerived}
                          surveyCount={practitioner?.surveyCount}
                        />
                      )}
                  </Flex>
                )}
                {enableCost && practitioner?.costIndicator && (
                  <Flex css={costIndicatorStyles}>
                    <CostIndicatorPopover
                      callFrom="datacard"
                      costIndicator={practitioner.costIndicator}
                      headers={headers}
                      indexForAnalytics={getLinkPositionIndex(index)}
                      locationForAnalytics={linkLocationName}
                      practitioner={practitioner}
                      providerType={providerType}
                      searchTerm={searchTerm}
                      selectedFilters={selectedFilters}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card.Section>

      <MilesAndAddressWrapper
        css={{
          paddingTop: enablePatientView && displayVirtualVisit ? '0' : '20px',
        }}
        data-auto-testid="datacard-address"
        data-testid="datacard-address"
      >
        <IconMaterial
          data-auto-testid={`data-card-location-on-icon-${sectionTypeDerived}-${providerId}`}
          data-testid={`data-card-location-on-icon-${sectionTypeDerived}-${providerId}`}
          icon="location_on"
          onClick={onCardClick}
          size={24}
        />
        <Flex css={milesTextStyles}>
          <Miles
            distance={distance || ''}
            headers={headers}
            id={providerId}
            indexForAnalytics={getLinkPositionIndex(index)}
            locationForAnalytics={linkLocationName}
            onClosePopup={() => setIsOpen(false)}
            practitioner={practitioner}
            providerType={providerType}
            searchTerm={searchTerm}
            sectionType={sectionTypeDerived}
            selectedFilters={selectedFilters}
          />
        </Flex>

        <div
          data-auto-testid={`data-card-location-address-${address}-${providerId}`}
          data-testid={`data-card-location-address-${address}-${providerId}`}
          onClick={onCardClick}
          onKeyDown={onCardClick}
          role="button"
          tabIndex={-1}
        >
          <Address
            address={address}
            id={providerId}
            sectionType={sectionTypeDerived}
          />
        </div>
      </MilesAndAddressWrapper>

      <ChoosePCP
        choosePCP={choosePCP}
        docasapPracticeId={docasapPracticeId}
        getLinkPositionIndex={getLinkPositionIndex}
        handleAdobeSearchResultClickEvent={handleAdobeSearchResultClickEvent}
        handleChoosePCPClick={handleChoosePCPClick}
        handleNavigationOnClick={handleNavigationOnClick}
        headers={headers}
        index={index}
        linkLocationName={linkLocationName}
        npi={npi}
        practitioner={practitioner}
        primaryPhone={primaryPhone}
        providerId={providerId}
        providerType={providerType}
        searchTerm={searchTerm}
        sectionTypeDerived={sectionTypeDerived}
        selectedFilters={selectedFilters}
      />
    </CardContainer>
  );
};
