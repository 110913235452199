import { Modal } from '@abyss/web/ui/Modal';
import isNil from 'lodash/isNil';
import React, { ReactNode } from 'react';

import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import { Constants } from '../Constants';
import {
  CustomAttributesBlock,
  handleLinkAndModalTrack,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';

type AnalyticsInfo = {
  destinationUrl?: string | null;
  location?: string;
  modalName: string;
  linkName?: string | null;
  type?: string | null;
  customAttributesBlock?: CustomAttributesBlock | null;
};

type Props = {
  analyticsInfo?: AnalyticsInfo;
  'aria-label'?: string;
  children: ReactNode;
  closeOnClickOutside?: Boolean;
  closeOnEscPress?: Boolean;
  css?: any;
  'data-testid': string;
  'data-auto-testid'?: string;
  footer?: ReactNode;
  hasLink?: Boolean;
  hideClose?: Boolean;
  isOpen?: Boolean;
  model?: string;
  onClose?: Function;
  scrollableFocus?: Boolean;
  size?: number | string;
  title: string;
  titleAlign?: string;
  trackingType: string;
};

export const ModalWithTracking = ({
  analyticsInfo,
  children,
  closeOnClickOutside = true,
  closeOnEscPress = true,
  css = '',
  'data-auto-testid': dataAutoTestId = '',
  footer,
  hasLink = false,
  hideClose = false,
  isOpen = false,
  model,
  onClose = () => {},
  scrollableFocus = false,
  size,
  title,
  titleAlign,
  trackingType,
  ...props
}: Props) => {
  const onCloseWithTracking = () => {
    if (!isNil(onClose)) onClose();
  };
  if (isOpen) {
    if (trackingType === Constants.ADOBE_TRACKING.MODAL_WITH_LINK_TRACKING) {
      handleLinkAndModalTrack(
        analyticsInfo?.linkName || dataAutoTestId,
        analyticsInfo?.location || dataAutoTestId,
        analyticsInfo?.modalName || dataAutoTestId,
        analyticsInfo?.customAttributesBlock || undefined
      );
    } else {
      adobeModalTrackEvent(analyticsInfo?.modalName || dataAutoTestId);
    }
  }
  return (
    <React.Fragment>
      <Modal
        closeOnClickOutside={closeOnClickOutside}
        closeOnEscPress={closeOnEscPress}
        css={css}
        footer={footer}
        hasLink={hasLink}
        hideClose={hideClose}
        isOpen={isOpen}
        model={model}
        onClose={onClose}
        onCloseWithTracking={onCloseWithTracking}
        scrollableFocus={scrollableFocus}
        size={size}
        title={title}
        titleAlign={titleAlign}
        {...props}
      >
        {children}
      </Modal>
    </React.Fragment>
  );
};
