import { Modal } from '@abyss/web/ui/Modal';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Provider } from '../../models/Provider';
import { ProviderType } from '../../models/ProviderDetails';
import {
  getDdpCode,
  getSubPCPText,
  getYesNoNAText,
} from '../../utils/providerDetails.utils';
import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import { InfoProviderContent } from '../ProviderDetailTabs/ProviderLocationsDetails/InfoProviderContent';
import { InfoProviderContentContainer } from '../ProviderDetailTabs/ProviderLocationsDetails/ProviderLocationsDetails.style';

type Props = {
  provider: Provider;
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
};

export const BestMatchDetails = ({
  provider = {},
  isOpen,
  setIsOpen,
}: Props) => {
  const { t } = useTranslation();
  const {
    providerType,
    isTieredProvider,
    acoProvider: isACOProvider,
    healthGradeRating: consumerRating,
    superPremium: costEfficiencyRating,
    lastName,
    isPremiumCarePhysician,
    isPlatinumProvider,
    llmAffinity = 'N/A',
    tierType = '-',
    isPreferredLab,
    isFreeStandingFacility,
    ddpCode,
  } = provider;

  const isFacility = providerType === ProviderType.ORGANIZATION;

  useEffect(() => {
    if (isOpen) adobeModalTrackEvent('bestmatch details');
  });

  const getTierTypeCode = (code) => {
    const tierTypeCode = {
      'aco high value tier 1': 'HA – High Value ACO',
      'general high value tier 1': 'HV – High Value',
      'uhpd tier 1': 'PD – Premium Designation',
    };
    const tierTypeCodeLowerCase = code?.toLowerCase().trim();

    return tierTypeCode[tierTypeCodeLowerCase] || code;
  };

  return (
    <Modal
      css={{
        'abyss-modal-header-title': {
          color: '$gray7',
        },
      }}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Best Match Details"
    >
      <Modal.Section
        css={{
          'abyss-modal-section-root': {
            paddingBottom: '32px',
          },
        }}
      >
        <div style={{ overflow: 'auto hidden' }}>
          <InfoProviderContentContainer>
            <InfoProviderContent
              icon="group"
              infoSubtext={llmAffinity}
              infoTitle="Claims LLM"
            />
            <InfoProviderContent
              icon="group"
              infoSubtext={getYesNoNAText(t, isTieredProvider)}
              infoTitle="Tiered Providers"
            />
            <InfoProviderContent
              icon="group"
              infoSubtext={getYesNoNAText(t, isACOProvider)}
              infoTitle="ACO Providers"
            />
            {!isFacility ? (
              <InfoProviderContent
                icon="group"
                infoSubtext={getSubPCPText(t, isPremiumCarePhysician)}
                infoTitle="UHPD Providers"
              />
            ) : null}
            <InfoProviderContent
              icon="group"
              infoSubtext={getYesNoNAText(t, isPlatinumProvider)}
              infoTitle="Platinum Providers"
            />
            <InfoProviderContent
              icon="group"
              infoSubtext={costEfficiencyRating}
              infoTitle="Cost Efficiency Rating"
            />
            {!isFacility ? (
              <InfoProviderContent
                icon="group"
                infoSubtext={consumerRating}
                infoTitle="Consumer Rating"
              />
            ) : null}
            <InfoProviderContent
              icon="group"
              infoSubtext={lastName}
              infoTitle="A-Z Name sort"
            />
            <InfoProviderContent
              icon="group"
              infoSubtext={getTierTypeCode(tierType)}
              infoTitle="Tier Type"
            />
            {isFacility ? (
              <>
                <InfoProviderContent
                  icon="group"
                  infoSubtext={getYesNoNAText(t, isPreferredLab)}
                  infoTitle="Preferred Lab"
                />
                <InfoProviderContent
                  icon="group"
                  infoSubtext={getDdpCode(ddpCode) || 'N/A'}
                  infoTitle="Designated Diagnostic Provider"
                />
                <InfoProviderContent
                  icon="group"
                  infoSubtext={getYesNoNAText(t, isFreeStandingFacility)}
                  infoTitle="Freestanding Facility"
                />
              </>
            ) : null}
          </InfoProviderContentContainer>
        </div>
      </Modal.Section>
    </Modal>
  );
};
