import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../../../../common/AdobeTagging/adobeModalTrackEvent';
import {
  AdobeEventMethods,
  Constants,
  ReverseCoverageTypesCodes,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DataCardContainer } from '../../../../common/DataCard/DataCardContainer';
import { MapDisplay } from '../../../../common/MapDisplay';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { getFeatureFlag } from '../../../../common/Utils';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  getIndicatorsForImpressions,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useLagoon } from '../../../../hooks/useLagoon';
import { CompareProvider } from '../../../../models/Provider';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { Directions } from '../../../../models/RouteDirections';
import { getRoute } from '../../../../utils/map.utils';
import { AllPaginationProps } from './AllPaginationProps';
import { CompareDrawerProps } from './CompareDrawerProps';
import { DataCardList, DataCardListStyled } from './DataCardList';
import {
  ListWrap,
  ListWrapColumn,
  MapDataPagination,
  MapViewWrap,
  MapviewWrapper,
} from './MapView.styled';
import { MapViewHeader } from './MapViewHeader';
import { MapViewTopPagination } from './MapViewTopPagination';
import { MobileMapFilter } from './MobileMapFilter';
import { Popup } from './Popup';
import { SidePaneCloseButton } from './SidePaneCloseButton';
import {
  getDataCardMinHeight,
  getFinalProviderResults,
  getSidePanelArrowIcon,
  mapViewupdatePin,
} from './utils';

type Props = {
  allPaginationProps: AllPaginationProps;
  compareDrawerProps: CompareDrawerProps;
  headers: ResponseHeaders;
  isLoading?: boolean;
  openShare?: boolean;
  results: any[];
  sectionType?: string;
  selectedCheckbox?: any;
  totalResultsCount?: number;
  addSelectedProvider: (a: any) => void;
  setOpenMapview: (a: boolean) => void;
  setMobileRouteView: (a: boolean) => void;
  mobileRouteView: boolean;
  setOpenShare: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  search?: string;
  selectedFilters?: string;
  providerType?: string;
  searchTerm?: string;
  navigateToDirections?: boolean;
  setNavigateToDirections?: (a: boolean) => void;
  openMapview: boolean;
  coverageType: string;
  directions?: Directions;
  setDirections: (a: Directions) => void;
  routeEndCoords?: [number | null, number | null];
  setRouteEndCoords(coords: [number | null, number | null]): void;
  searchMethod?: string;
};

export const MapView = ({
  allPaginationProps,
  compareDrawerProps,
  headers,
  isLoading,
  openShare = false,
  results = [],
  sectionType = '',
  selectedCheckbox = { checked: {} },
  addSelectedProvider,
  setOpenMapview,
  setMobileRouteView,
  mobileRouteView,
  setOpenShare,
  setSelectedCheckbox,
  setSelectedItems,
  search,
  selectedFilters,
  providerType,
  searchTerm,
  navigateToDirections = false,
  setNavigateToDirections,
  openMapview = false,
  coverageType,
  directions,
  setDirections,
  setRouteEndCoords,
  routeEndCoords = [null, null],
  searchMethod,
  totalResultsCount,
}: Props) => {
  const [showMapCollapse] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.SHOW_MAP_COLLAPSE,
  ]);
  const {
    selectedItems = [],
    map,
    openCompare,
    setOpenCompare,
  } = compareDrawerProps;

  const listRef = useRef<HTMLDivElement>(null);
  const topPaginationRef = useRef<HTMLDivElement>(null);

  const getPageTrackEventData = () => {
    const pageTrackEvent = {
      pageName: 'overview',
      sitesectionLevel1: Constants.ADOBE_TRACKING.VIEWALL_SITESECTION1,
      sitesectionLevel2: `${convertProviderTypeToAdobeType(
        providerType
      )} results`,
      providerBlock: {
        type: convertCoverageType(coverageType),
      },
      impressionBlock: {
        type: 'provider',
        indicator: getIndicatorsForImpressions(results, false),
      },
    };
    return !searchMethod || searchMethod === AdobeEventMethods.Guided
      ? pageTrackEvent
      : {
          ...pageTrackEvent,
          searchBlock: {
            term: searchTerm?.toLowerCase() ?? '',
            type: 'provider',
            filters: selectedFilters ?? '',
            results: String(totalResultsCount ?? ''),
            method: searchMethod?.toLowerCase() ?? 'guided',
          },
        };
  };

  const { adobePageTrackEvent } = useAdobePageTrackEvent(
    getPageTrackEventData()
  );

  const {
    pageSize,
    pageSizeOptions,
    paginationProps,
    nextPage,
    gotoPage,
    previousPage,
    setPageSize,
    setLastPageSize,
    pageNumber,
  } = allPaginationProps;

  const mapViewViewAllLinkTrack = (name: String, loc: String) => {
    adobeLinkTrackEvent({
      name,
      location: loc,
      type: 'internal',
      destinationUrl: '',
    });
  };
  const nextPageTop = () => {
    mapViewViewAllLinkTrack(
      'next page',
      `body:top pagination control:page ${pageNumber}`
    );
    nextPage();
  };
  const nextPageBottom = () => {
    mapViewViewAllLinkTrack(
      'next page',
      `body:bottom pagination control:page ${pageNumber}`
    );
    nextPage();
    topPaginationRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  const gotoPageFunc = (page) => {
    mapViewViewAllLinkTrack(
      `page ${page + 1}`,
      `body:bottom pagination control:page ${pageNumber}`
    );
    gotoPage(page);
    topPaginationRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  const previousPageTop = () => {
    mapViewViewAllLinkTrack(
      'previous page',
      `body:top pagination control:page ${pageNumber}`
    );
    previousPage();
  };
  const previousPageBottom = () => {
    mapViewViewAllLinkTrack(
      'previous page',
      `body:bottom pagination control:page ${pageNumber}`
    );
    previousPage();
    topPaginationRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const setPageSizeFunc = (pageSizeValue) => {
    mapViewViewAllLinkTrack(
      `results per page:${pageSizeValue}`,
      `body:top pagination control:page ${pageNumber}`
    );
    setLastPageSize(pageSize);
    setPageSize(pageSizeValue);
  };

  const [closeSidePanel, setCloseSidePanel] = useState<boolean>(false);
  const [isPageTracked, setPageTracked] = useState<boolean>(false);
  const sidePanelArrowIcon = getSidePanelArrowIcon(closeSidePanel);

  const mobileScreen = useMediaQuery(phoneOnly);
  const dataCardMinHeight = getDataCardMinHeight(mobileScreen);

  const isOpenCompareShare = openCompare || openShare;
  const { longitude, latitude } = getGeoLocationFromStorage();

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();

  const eapCodeFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.EAP_CODE
  );

  const isBHCare: boolean = coverageType?.includes(
    ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
  );

  const [popupContent, setPopupContent] = useState<any>('');

  const [selectedId, setSelectedId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );

  const [disableCost] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.DEMO_FLAG,
  ]);

  useEffect(() => {
    setSelectedId(null);
  }, []);

  const providerLocationId = useMemo(
    () =>
      results?.length > 0
        ? JSON.stringify(results?.map((x) => x.locationId))
        : '',
    [results]
  );

  useEffect(() => {
    if (providerLocationId) {
      adobePageTrackEvent();
      setPageTracked(true);
    }
  }, [sectionType, providerLocationId]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.scrollTop = 0;
    }
  }, [results]);
  const [mobileMixedResult, setMobileMixedResult] = useState(false);
  useEffect(() => {
    if (mobileScreen && !openMapview) {
      setMobileMixedResult(true);
    }
    if (mobileScreen && openMapview) {
      setMobileMixedResult(false);
    }
    if (!mobileScreen) {
      setMobileRouteView(false);
      setMobileMixedResult(false);
    }
  }, [mobileScreen, openMapview]);

  const { t } = useTranslation();
  const pageHeader = `${t(`${sectionType}-results-for`)} ${search || ''}`;
  const toggleSidePanel = () => {
    const actionStr = closeSidePanel ? 'open' : 'close';
    adobeLinkTrackEvent({
      name: `sidepanel:${actionStr}`,
      location: `body:map`,
      type: 'internal',
    });
    setCloseSidePanel(!closeSidePanel);
  };

  const updatePin = (practitioner, flyToPin = false) => {
    mapViewupdatePin(
      practitioner,
      flyToPin,
      popupContent,
      setPopupContent,
      map
    );
  };

  const finalProviderResults = getFinalProviderResults(selectedItems, results);

  const shouldDisplayMap = map && latitude && longitude && results?.length;

  const handleClickLocation = async (locationId, locationLng, locationLat) => {
    if (selectedId === locationId && !mobileScreen) return;
    const routeDirections = await getRoute(
      map.current,
      longitude,
      latitude,
      +locationLng,
      +locationLat,
      false,
      'driving-traffic'
    );
    if (routeDirections) setDirections(routeDirections);
    setRouteEndCoords([locationLng, locationLat]);
    setSelectedId(locationId);
    adobeModalTrackEvent(Constants.MAP_VIEW_NAVIGATION_MODAL);
  };

  useEffect(() => {
    if (mobileScreen) setMobileRouteView(!!routeEndCoords[0]);
  }, [routeEndCoords, mobileScreen]);

  return (
    <React.Fragment>
      <MapViewHeader
        eapCodeFlag={eapCodeFlag}
        isBHCare={isBHCare}
        isPageTracked={isPageTracked}
        mobileScreen={mobileScreen}
        pageHeader={pageHeader}
        sectionType={sectionType}
      />
      {!mobileScreen ? (
        <MapViewTopPagination
          nextPageTop={nextPageTop}
          openCompare={openCompare}
          openShare={openShare}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          paginationProps={paginationProps}
          previousPageTop={previousPageTop}
          sectionType={sectionType}
          setOpenCompare={setOpenCompare}
          setOpenShare={setOpenShare}
          setPageSizeFunc={setPageSizeFunc}
          setSelectedCheckbox={setSelectedCheckbox}
          setSelectedItems={setSelectedItems}
          topPaginationRef={topPaginationRef}
        />
      ) : null}
      <MapviewWrapper
        cssProps={{
          isOpenCompareShare,
          openMapview,
          navigateToDirections,
        }}
      >
        <Layout.Stack>
          {!closeSidePanel ? (
            <React.Fragment>
              <ListWrapColumn cssProps={{ isOpenCompareShare }}>
                <ListWrap ref={listRef}>
                  {isLoading ? (
                    <DataCardListStyled>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                        <DataCardContainer isLoading={isLoading} key={item} />
                      ))}
                    </DataCardListStyled>
                  ) : (
                    !mobileScreen &&
                    !mobileRouteView &&
                    !mobileMixedResult && (
                      <DataCardList
                        DataCardStyle={{
                          width: '408px',
                          minHeight: dataCardMinHeight,
                        }}
                        addSelectedProvider={addSelectedProvider}
                        fromMobileListView={false}
                        headers={headers}
                        isLoading={!!isLoading}
                        isMobileMap={mobileScreen}
                        locationForAnalytics={`${sectionType} results for ${search}`}
                        map={map}
                        navigateToDirections={navigateToDirections}
                        pageNumber={pageNumber}
                        parentRef={listRef}
                        providerResults={results}
                        searchTerm={searchTerm}
                        sectionType={sectionType}
                        selectLocation={handleClickLocation}
                        selectedCheckbox={selectedCheckbox}
                        selectedFilters={selectedFilters}
                        setNavigateToDirections={setNavigateToDirections}
                        setRouteEndCoords={setRouteEndCoords}
                        setSelectedCheckbox={setSelectedCheckbox}
                        showCheckboxes={isOpenCompareShare}
                        updatePin={updatePin}
                      />
                    )
                  )}
                </ListWrap>
                {!mobileScreen ? (
                  <Flex>
                    <Flex
                      alignItems="center"
                      css={{ gap: '21px', marginTop: '16px', width: '408px' }}
                      direction="column"
                    >
                      <MapDataPagination
                        {...paginationProps}
                        data-auto-testid="pagination-component"
                        data-testid="pagination-component"
                        gotoPage={gotoPageFunc}
                        nextPage={nextPageBottom}
                        pageSize={pageSize}
                        previousPage={previousPageBottom}
                      />
                    </Flex>
                  </Flex>
                ) : null}
              </ListWrapColumn>
            </React.Fragment>
          ) : null}
        </Layout.Stack>
        <MapViewWrap>
          {showMapCollapse ? (
            <SidePaneCloseButton
              sidePanelArrowIcon={sidePanelArrowIcon}
              toggleSidePanel={toggleSidePanel}
            />
          ) : null}
          {shouldDisplayMap ? (
            <div style={{ position: 'relative' }}>
              {mobileScreen && !mobileRouteView ? (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '12px',
                    width: '100%',
                    zIndex: 999,
                  }}
                >
                  <ListWrap>
                    <DataCardList
                      DataCardStyle={{
                        width: '408px',
                        minHeight: dataCardMinHeight,
                      }}
                      addSelectedProvider={addSelectedProvider}
                      fromMobileListView={false}
                      headers={headers}
                      isLoading={!!isLoading}
                      isMobileMap={mobileScreen}
                      locationForAnalytics={`${sectionType} results for ${search}`}
                      map={map}
                      navigateToDirections={navigateToDirections}
                      pageNumber={pageNumber}
                      parentRef={listRef}
                      providerResults={results}
                      searchTerm={searchTerm}
                      sectionType={sectionType}
                      selectLocation={handleClickLocation}
                      selectedCheckbox={selectedCheckbox}
                      selectedFilters={selectedFilters}
                      setNavigateToDirections={setNavigateToDirections}
                      setRouteEndCoords={setRouteEndCoords}
                      setSelectedCheckbox={setSelectedCheckbox}
                      showCheckboxes={isOpenCompareShare}
                      updatePin={updatePin}
                    />
                  </ListWrap>
                </div>
              ) : null}
              <MapDisplay
                closeSidePanel={closeSidePanel}
                coords={{ latitude, longitude }}
                directions={directions}
                disableCost={disableCost}
                headers={headers}
                isOpenCompareShare={isOpenCompareShare}
                map={map}
                mobileMapControlChildren={
                  <MobileMapFilter
                    setDirections={setDirections}
                    setMobileRouteView={setMobileRouteView}
                    setOpenMapview={setOpenMapview}
                    setRouteEndCoords={setRouteEndCoords}
                    setSelectedId={setSelectedId}
                  />
                }
                mobileRouteView={mobileRouteView}
                providerResults={finalProviderResults}
                routeEndCoords={routeEndCoords}
                selectLocation={handleClickLocation}
                selectedItems={selectedItems}
                setPopupContent={setPopupContent}
                setRouteEndCoords={setRouteEndCoords}
                updatePin={updatePin}
              />
            </div>
          ) : null}
        </MapViewWrap>
      </MapviewWrapper>
      <Popup
        disableCost={disableCost}
        headers={headers}
        locationForAnalytics={`map:${sectionType} results for ${search}`}
        pageNumber={pageNumber}
        practitioner={popupContent}
        selectLocation={handleClickLocation}
        selectedFilters={selectedFilters}
        updatePin={updatePin}
      />
    </React.Fragment>
  );
};
