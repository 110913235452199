import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Card } from '@abyss/web/ui/Card';
import { CollapseProvider } from '@abyss/web/ui/CollapseProvider';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PhoneNumbers,
  ProviderDetails,
  ProviderType,
} from '../../../models/ProviderDetails';
import { getTokenData } from '../../../utils/common';
import {
  getAdditionalPhone,
  getFormattedPhoneNumber,
  getPrimaryPhone,
} from '../../../utils/phone.utils';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { ReverseCoverageTypesCodes } from '../../Constants';
import { mobileOnly } from '../../ConstantsStyles';
import {
  CollapseCardHeaderStyle,
  CollapseSection,
} from '../../FacilityDetailsTabs/FacilityDetailsAboutSection/FacilityDetailsAboutSection.styled';
import { IconAlignmentContainer } from './ProviderLocation.style';
import {
  IconMaterialContainer,
  PhoneAllContainer,
  PhoneContainer,
  PhoneFlexContainer,
  PhoneNumberContainer,
  infoSubTextContainer,
} from './ProviderLocationsDetails.style';

type Props = {
  isFromVirtualProviderGroup?: boolean;
  phoneNumbers?: PhoneNumbers | null;
  providerDetails?: ProviderDetails;
  tty?: string;
};

export const PhoneNumberList = ({
  isFromVirtualProviderGroup,
  phoneNumbers,
  providerDetails,
  tty,
}: Props) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(mobileOnly);

  const tokenData = getTokenData();
  const coverageType = tokenData?.coverageType;

  const showAdditionalPhones =
    coverageType !== ReverseCoverageTypesCodes.VISION &&
    coverageType !== ReverseCoverageTypesCodes.DENTAL;
  const showExamPhone =
    coverageType === ReverseCoverageTypesCodes.VISION &&
    providerDetails?.providerType === ProviderType.PRACTITIONER;

  const { phone, appointment, emergency } = phoneNumbers ?? {};
  const primaryPhones = getPrimaryPhone(phone, appointment);
  const additionalPhones = getAdditionalPhone(phone, appointment);
  const phoneListDataTestId = 'provider-locations-phones-list';
  const onClick = (event, name) => {
    adobeLinkTrackEvent({
      name,
      location: `body:${phoneListDataTestId}`,
      type: 'phone',
    });
  };
  return (
    <div
      data-auto-testid={phoneListDataTestId}
      data-testid={phoneListDataTestId}
    >
      <PhoneFlexContainer>
        <PhoneAllContainer
          style={{
            marginBottom: mobile && isFromVirtualProviderGroup ? '36px' : '',
          }}
        >
          <IconMaterialContainer color="$primary1" icon="call" size={18} />
          <PhoneContainer>
            {t('PROVIDER_LOCATIONS_DETAILS.PRIMARY_PHONE')}
            <PhoneNumberContainer>
              <React.Fragment>
                <a
                  data-auto-testid="phone-primary"
                  data-testid="phone-primary"
                  href={`tel:${primaryPhones}`}
                  onClick={(event) => onClick(event, 'phone primary')}
                  style={{ marginBottom: '36px' }}
                >
                  {getFormattedPhoneNumber(primaryPhones)}
                </a>
                <CollapseSection
                  style={{ width: '235px', marginBottom: '12px' }}
                >
                  {mobile && isFromVirtualProviderGroup && (
                    <CollapseProvider>
                      <Card
                        collapse
                        css={CollapseCardHeaderStyle}
                        header={t(
                          'VIRTUAL_GROUP_PROVIDER_ABOUT.SHOW_PHONE_NUMBERS'
                        )}
                        style={{
                          border: 0,
                          marginBottom: '12px',
                          padding: 0,
                          color: '#196ECF',
                          fontSize: '16px',
                        }}
                      />
                    </CollapseProvider>
                  )}
                </CollapseSection>
              </React.Fragment>
            </PhoneNumberContainer>
          </PhoneContainer>
        </PhoneAllContainer>
        {showAdditionalPhones && (
          <IconAlignmentContainer>
            <PhoneAllContainer>
              <IconMaterialContainer color="$primary1" icon="call" size={18} />
              <PhoneContainer>
                {t('PROVIDER_LOCATIONS_DETAILS.APPOINTMENT_PHONE')}
                <PhoneNumberContainer>
                  {additionalPhones ? (
                    <a
                      data-auto-testid={additionalPhones}
                      data-testid={additionalPhones}
                      href={`tel:${additionalPhones}`}
                    >
                      {getFormattedPhoneNumber(additionalPhones)}
                    </a>
                  ) : (
                    <Text css={infoSubTextContainer(false)}>
                      {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                    </Text>
                  )}
                </PhoneNumberContainer>
              </PhoneContainer>
            </PhoneAllContainer>
          </IconAlignmentContainer>
        )}
        {showAdditionalPhones && (
          <IconAlignmentContainer>
            <PhoneAllContainer>
              <IconMaterialContainer color="$primary1" icon="call" size={18} />
              <PhoneContainer>
                {t('PROVIDER_LOCATIONS_DETAILS.EMERGENCY_PHONE')}
                <PhoneNumberContainer>
                  {emergency ? (
                    <a
                      data-auto-testid={emergency}
                      data-testid={emergency}
                      href={`tel:${emergency[0]}`}
                    >
                      {getFormattedPhoneNumber(emergency[0])}
                    </a>
                  ) : (
                    <Text css={infoSubTextContainer(false)}>
                      {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                    </Text>
                  )}
                </PhoneNumberContainer>
              </PhoneContainer>
            </PhoneAllContainer>
          </IconAlignmentContainer>
        )}
        {showExamPhone && (
          <IconAlignmentContainer>
            <PhoneAllContainer>
              <IconMaterialContainer color="$primary1" icon="call" size={18} />
              <PhoneContainer>
                {t('OTHER_CARE_DETAILS.EXAM_PHONE')}
                <PhoneNumberContainer>
                  {emergency ? (
                    <a
                      data-auto-testid={emergency}
                      data-testid={emergency}
                      href={`tel:${emergency[0]}`}
                    >
                      {getFormattedPhoneNumber(emergency[0])}
                    </a>
                  ) : (
                    <Text css={infoSubTextContainer(false)}>
                      {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                    </Text>
                  )}
                </PhoneNumberContainer>
              </PhoneContainer>
            </PhoneAllContainer>
          </IconAlignmentContainer>
        )}
        {showAdditionalPhones && (
          <IconAlignmentContainer>
            <PhoneAllContainer>
              <IconMaterialContainer color="$primary1" icon="call" size={18} />
              <PhoneContainer>
                {t('PROVIDER_LOCATIONS_DETAILS.AFTER_HOURS_PHONE')}
                <PhoneNumberContainer>
                  {
                    <Text css={infoSubTextContainer(false)}>
                      {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                    </Text>
                  }
                </PhoneNumberContainer>
              </PhoneContainer>
            </PhoneAllContainer>
          </IconAlignmentContainer>
        )}
        {!isFromVirtualProviderGroup && (
          <IconAlignmentContainer>
            <PhoneAllContainer>
              <IconMaterialContainer color="$primary1" icon="call" size={18} />
              <PhoneContainer>
                {t('PROVIDER_LOCATIONS_DETAILS.TTY')}
                <PhoneNumberContainer>
                  {tty ? (
                    <a
                      data-auto-testid={tty}
                      data-testid={tty}
                      href={`tel:${tty}`}
                      onClick={(event) => onClick(event, 'TTY')}
                    >
                      {tty}
                    </a>
                  ) : (
                    <Text css={infoSubTextContainer(false)}>
                      {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                    </Text>
                  )}
                </PhoneNumberContainer>
              </PhoneContainer>
            </PhoneAllContainer>
          </IconAlignmentContainer>
        )}
      </PhoneFlexContainer>
    </div>
  );
};
