import cloneDeep from 'lodash/cloneDeep';

import { getNamesToCheck } from '../../utility/getNamesToCheck';

export const editCompareButtonRules = (
  items,
  setChangedTempItems,
  setOpenEdit,
  setNumChecked,
  nameOfButtonClickedLast,
  setNameOfButtonClickedLast,
  itemsToShowInCheckboxes,
  form,
  defaultAttributes
) => {
  if (nameOfButtonClickedLast === 'apply') {
    items.map((checkbox) => {
      const tempCheckbox = checkbox;
      const changedCheckBox = itemsToShowInCheckboxes.find(
        (e) => e.name === checkbox.name
      );
      if (changedCheckBox) tempCheckbox.isChecked = changedCheckBox.isChecked;
      return tempCheckbox;
    });
    setChangedTempItems(itemsToShowInCheckboxes);
    setOpenEdit(false);
  }

  if (nameOfButtonClickedLast === 'cancel') {
    itemsToShowInCheckboxes = cloneDeep(items);
    form.reset({
      'checkbox-form': getNamesToCheck(itemsToShowInCheckboxes),
    });
  }

  if (nameOfButtonClickedLast === 'deselect-all') {
    form.reset({
      'checkbox-form': [],
    });
    itemsToShowInCheckboxes.map((checkbox) => {
      const tempCheckbox = checkbox;
      tempCheckbox.isChecked = false;
      return tempCheckbox;
    });
    setChangedTempItems(itemsToShowInCheckboxes);
    setNumChecked(0);
  }

  if (nameOfButtonClickedLast === 'reset') {
    form.reset({ 'checkbox-form': defaultAttributes });
    setChangedTempItems(itemsToShowInCheckboxes);
    setNumChecked(defaultAttributes.length);
  }

  setNameOfButtonClickedLast('');
};
