import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Alert } from '@abyss/web/ui/Alert';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs } from '@abyss/web/ui/Tabs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { HealthGradesContainerStyled } from '../../frontends/ProviderSearch/routes/PSXHome/HealthGradesContainer.styled';
import { useAdobeBrowserBackButtonTrack } from '../../hooks/adobeHook/useAdobeBrowserBackButtonTrack';
import { useCarePathsSearch } from '../../hooks/useCarePath/useCarePath';
import { useCoverageType } from '../../hooks/useCoverageType';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useLagoon } from '../../hooks/useLagoon';
import { useSaveRecentSearchesResults } from '../../hooks/useRecentSearches';
import { CarePathsResponse } from '../../models/CarePaths';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../models/ProviderDetails';
import { getTokenData } from '../../utils/common';
import { returnSuppressFlag } from '../../utils/featureSuppress';
import {
  getFacilityDetailTabList,
  getProviderDetailTabList,
} from '../../utils/providerDetails.utils';
import {
  getNameAttrs,
  parseProviderName,
} from '../../utils/providerNames.utils';
import { getCurrentMember, getNetworkIdsForPES } from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  RecentSearchesSearchTypes,
  ReverseCoverageTypesCodes,
} from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { DisclaimersContainer } from '../DisclaimersContainer/DisclaimersContainer';
import { FacilityDetailsAboutSection } from '../FacilityDetailsTabs/FacilityDetailsAboutSection';
import { FacilityLocation } from '../FacilityDetailsTabs/FacilityLocation';
import { FacilityQuality } from '../FacilityDetailsTabs/FacilityQuality/FacilityQuality';
import { ProviderGroupAbout } from '../ProviderGroupDetailTabs/ProviderGroupAbout';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import { translatedTabTitle } from '../Utils/translatedTabTitle.util';
import { About } from './About/About';
import { Cost } from './Cost/Cost';
import { PhysicianDirectory } from './PhysicianDirectory';
import {
  Container,
  TabContainer,
  TabsContainer,
} from './ProviderDetailTabs.styled';
import { ProviderLocationsDetails } from './ProviderLocationsDetails/ProviderLocationsDetails';
import { Quality } from './Quality/Quality';

type Props = {
  providerDetails?: ProviderDetails;
  facilityDetails?: FacilityDetails;
  providerGroupDetails?: ProviderDetails;
  chipValue: string;
  navigateToDirections: boolean;
  setNavigateToDirections: (a: boolean) => void;
  isTiered?: boolean;
  isMapView?: boolean;
  coverageType: string;
  locationKey?: string;
  setIsMapView: (a: boolean) => void;
};

export const ProviderDetailTabs = ({
  providerDetails,
  facilityDetails,
  providerGroupDetails,
  navigateToDirections,
  chipValue,
  coverageType,
  isTiered,
  isMapView = false,
  locationKey,
  setNavigateToDirections,
  setIsMapView,
}: Props) => {
  const [enableCostTab] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.COST_TAB_ENABLED,
  ]);

  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const { adobeBrowserBackButtonTrack } = useAdobeBrowserBackButtonTrack();
  const suppressReviews = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );

  const locationTabIndex =
    facilityDetails?.providerType === ProviderType.ORGANIZATION ? 0 : 1;
  const [active, setActive] = useState(
    navigateToDirections ? locationTabIndex : 0
  );
  const [displayAlert, setDisplayAlert] = useState(false);

  const { longitude, latitude, name: location } = getGeoLocationFromStorage();
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const findSelectedLocationIndex = (
    locations: ProviderLocation[] | FacilityLocationsResponse[]
  ) => locations.findIndex((loc) => selectedId === loc.locationId);
  const locations =
    providerDetails?.providerLocations ||
    facilityDetails?.providerLocations ||
    [];
  const foundIndex = findSelectedLocationIndex(locations);
  const selectedIndex = foundIndex > -1 ? foundIndex : 0;
  const selectedLocation = locations[selectedIndex];

  const { BEHAVIORAL_HEALTH_TYPECODES } = Constants.PROVIDER_DETAILS;
  const isBehavioralHealthProvider =
    BEHAVIORAL_HEALTH_TYPECODES.includes(coverageType);

  const tokenData = getTokenData();
  const isVisionCare =
    tokenData?.coverageType === ReverseCoverageTypesCodes.VISION;

  const isDentalCare =
    tokenData?.coverageType === ReverseCoverageTypesCodes.DENTAL;

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const currentMember = getCurrentMember();
  const lob = currentMember?.lineOfBusiness;
  const locale = getLanguage().code;
  const costCareCategories = useLagoon('cost-care-categories')();
  const costCategoriesSortOrder = costCareCategories?.find((sortOrder) =>
    sortOrder?.locale?.toLowerCase().includes(locale.toLowerCase())
  )?.DNT_CostCategory;

  const locationId = locationKey ? locationKey : tokenData?.locationId;

  const [, setHasCarePathData] = useState<boolean>(false);
  const [carePathData, setCarePathData] = useState<CarePathsResponse>();
  const coverageTypeData = useCoverageType();
  const reciprocityId = getNetworkIdsForPES(
    currentMember,
    coverageType,
    featureFlags
  );
  const providerCoverageType = coverageType ? coverageType : coverageTypeData;

  const { data } = useCarePathsSearch({
    coverageType: providerCoverageType,
    carePathStepCategory: '',
    lob,
    providerLocationKey: locationId,
    providerType: ProviderType.PRACTITIONER,
    pageSize: 10,
    pageNumber: 1,
    reciprocityId,
    sortOrder: costCategoriesSortOrder,
    providerId: providerDetails?.providerId,
    locale,
  });

  useEffect(() => {
    if (data?.carePath) {
      const response = data?.carePath;
      if (response?.totalCount > 0) {
        setHasCarePathData(true);
        setCarePathData(response);
      } else {
        setCarePathData({});
      }
    }
  }, [data]);

  useEffect(() => {
    if (navigateToDirections) {
      setActive(locationTabIndex);
    }
  }, [navigateToDirections]);

  useEffect(() => {
    adobeBrowserBackButtonTrack();
  }, []);

  let recentSearchArgs = {
    psxSearchTerm: '',
    pesSearchTerm: '',
    searchType: '',
    category: chipValue,
    location,
    latitude,
    longitude,
    coverageType,
  };

  const boardCertifications = providerDetails?.boardCertifications;

  const providerTabs = getProviderDetailTabList(
    boardCertifications,
    providerDetails?.providerLocations,
    isVisionCare,
    isDentalCare
  );
  const facilityTabs = getFacilityDetailTabList(isVisionCare);

  if (providerDetails?.providerType === ProviderType.PRACTITIONER) {
    recentSearchArgs = {
      ...recentSearchArgs,
      psxSearchTerm: parseProviderName(
        providerDetails.providerName,
        providerDetails.providerType,
        providerDetails.primaryDegrees?.[0],
        getNameAttrs(providerDetails)
      ),
      pesSearchTerm: providerDetails.providerId,
      searchType: RecentSearchesSearchTypes.PRACTITIONER_DETAIL,
    };
  } else if (facilityDetails?.providerType === ProviderType.ORGANIZATION) {
    recentSearchArgs = {
      ...recentSearchArgs,
      psxSearchTerm: facilityDetails.providerName,
      pesSearchTerm: facilityDetails.providerId,
      searchType: RecentSearchesSearchTypes.ORGANIZATION_DETAIL,
    };
  } else if (providerGroupDetails) {
    recentSearchArgs = {
      ...recentSearchArgs,
      psxSearchTerm: providerGroupDetails.providerName,
      pesSearchTerm: providerGroupDetails.providerId,
      searchType: RecentSearchesSearchTypes.MEDICAL_GROUP_DETAIL,
    };
  }

  const activeTabs: string[] = [];
  const handleTabChange = (index) => {
    if (index !== 1) {
      setNavigateToDirections(false);
    }
    setActive(index);

    adobeLinkTrackEvent({
      name: activeTabs[index],
      location: `body:details tabs`,
      type: 'tab',
    });
  };

  useSaveRecentSearchesResults(recentSearchArgs);

  return (
    <Container data-auto-testid="provider-details-container">
      {displayAlert && !(isMapView && mobileScreen) && (
        <ContentWrapper>
          <Alert
            onClose={() => {
              setDisplayAlert(false);
            }}
            title={t('LOCATION_IS_UPDATED')}
            variant="success"
          />
        </ContentWrapper>
      )}
      <Layout.Stack
        css={{
          'abyss-layout-stack': {
            margin: '0px',
          },
        }}
        grow
        space={0}
      >
        <TabsContainer
          active={active}
          className="hide-scrollbar"
          cssProps={{ isMapView }}
          grow={false}
          key="provider-detail-tabs-list"
          onTabChange={handleTabChange}
          title={t('PROVIDER_DETAILS.TAB_TITLE')}
          variant="line"
        >
          {providerDetails?.providerType === ProviderType.PRACTITIONER
            ? providerTabs.map((tabTitle: string) => {
                if (
                  tabTitle === Constants.PROVIDER_DETAILS.COST &&
                  !enableCostTab
                )
                  return null;
                activeTabs.push(tabTitle);
                return (
                  <Tabs.Tab
                    data-auto-testid={tabTitle}
                    data-testid={tabTitle}
                    key={tabTitle}
                    label={t(translatedTabTitle('PROVIDER_DETAILS', tabTitle))}
                    tabbableContent={false}
                    value={t(translatedTabTitle('PROVIDER_DETAILS', tabTitle))}
                  >
                    <TabContainer>
                      {tabTitle === Constants.PROVIDER_DETAILS.ABOUT && (
                        <About
                          coverageType={coverageType}
                          providerDetails={providerDetails}
                          suppressReviews={suppressReviews}
                          tabTitle={tabTitle}
                        />
                      )}
                      {tabTitle === Constants.PROVIDER_DETAILS.LOCATIONS && (
                        <ProviderLocationsDetails
                          isMapView={isMapView}
                          isTiered={isTiered}
                          navigateToDirections={navigateToDirections}
                          providerDetails={providerDetails}
                          setIsMapView={setIsMapView}
                          setNavigateToDirections={setNavigateToDirections}
                          setUpdateLocation={setDisplayAlert}
                          tabTitle={tabTitle}
                        />
                      )}
                      {tabTitle === Constants.PROVIDER_DETAILS.COST && (
                        <Cost
                          carePathsData={carePathData}
                          coverageType={providerCoverageType}
                          providerDetails={providerDetails}
                          tabTitle={tabTitle}
                        />
                      )}
                      {tabTitle === Constants.PROVIDER_DETAILS.QUALITY && (
                        <Quality
                          boardCertifications={boardCertifications}
                          coverageType={coverageType}
                          providerName={providerDetails.providerName}
                          providerType={providerDetails.providerType}
                          selectedLocation={
                            selectedLocation as ProviderLocation
                          }
                          tabTitle={tabTitle}
                        />
                      )}
                      <HealthGradesContainerStyled>
                        <DisclaimersContainer
                          hideDivider
                          isVisionCare={isVisionCare}
                          showPremiumCareDisclaimer={
                            !isBehavioralHealthProvider
                          }
                        />
                      </HealthGradesContainerStyled>
                    </TabContainer>
                  </Tabs.Tab>
                );
              })
            : null}
          {facilityDetails?.providerType === ProviderType.ORGANIZATION
            ? facilityTabs.map((tabTitle: string) => {
                activeTabs.push(tabTitle);
                return (
                  <Tabs.Tab
                    data-auto-testid={tabTitle}
                    data-testid={tabTitle}
                    key={tabTitle}
                    label={t(translatedTabTitle('FACILITY_DETAILS', tabTitle))}
                    tabbableContent={false}
                    value={t(translatedTabTitle('FACILITY_DETAILS', tabTitle))}
                  >
                    <TabContainer>
                      {tabTitle === Constants.FACILITY_DETAILS.ABOUT && (
                        <FacilityDetailsAboutSection
                          coverageType={coverageType}
                          facilityDetails={facilityDetails}
                          tabTitle={tabTitle}
                        />
                      )}
                      {tabTitle === Constants.FACILITY_DETAILS.LOCATIONS && (
                        <FacilityLocation
                          coverageType={coverageType}
                          facilityDetails={facilityDetails}
                          isMapView={isMapView}
                          isTiered={isTiered}
                          navigateToDirections={navigateToDirections}
                          setIsMapView={setIsMapView}
                          setNavigateToDirections={setNavigateToDirections}
                          setUpdateLocation={setDisplayAlert}
                          tabTitle={tabTitle}
                        />
                      )}
                      {tabTitle ===
                        Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY && (
                        <PhysicianDirectory
                          locationTabIndex={locationTabIndex}
                          providerId={facilityDetails.providerId}
                          providerType={facilityDetails.providerType}
                          setActive={handleTabChange}
                        />
                      )}
                      {tabTitle === Constants.FACILITY_DETAILS.QUALITY && (
                        <FacilityQuality
                          providerName={facilityDetails.providerName}
                          providerType={facilityDetails.providerType}
                          selectedLocation={
                            selectedLocation as FacilityLocationsResponse
                          }
                          tabTitle={tabTitle}
                        />
                      )}
                      {isVisionCare && (
                        <HealthGradesContainerStyled>
                          <DisclaimersContainer
                            hideDivider
                            isVisionCare={isVisionCare}
                            showPremiumCareDisclaimer={
                              !isBehavioralHealthProvider
                            }
                          />
                        </HealthGradesContainerStyled>
                      )}
                    </TabContainer>
                  </Tabs.Tab>
                );
              })
            : null}
          {providerGroupDetails
            ? Constants.PROVIDER_GROUP_DETAILS.TABS_LIST.map(
                (tabTitle: string) => {
                  activeTabs.push(tabTitle);
                  return (
                    <Tabs.Tab
                      data-auto-testid={tabTitle}
                      data-testid={tabTitle}
                      key={tabTitle}
                      label={t(
                        translatedTabTitle('PROVIDER_GROUP_DETAILS', tabTitle)
                      )}
                      tabbableContent={false}
                      value={t(
                        translatedTabTitle('PROVIDER_GROUP_DETAILS', tabTitle)
                      )}
                    >
                      <TabContainer>
                        {tabTitle ===
                          Constants.PROVIDER_GROUP_DETAILS.ABOUT && (
                          <ProviderGroupAbout
                            coverageType={coverageType}
                            providerDetails={providerGroupDetails}
                            tabTitle={tabTitle}
                          />
                        )}

                        {tabTitle === Constants.PROVIDER_DETAILS.LOCATIONS && (
                          <ProviderLocationsDetails
                            isMapView={isMapView}
                            navigateToDirections={navigateToDirections}
                            providerDetails={providerGroupDetails}
                            setIsMapView={setIsMapView}
                            setNavigateToDirections={setNavigateToDirections}
                            setUpdateLocation={setDisplayAlert}
                            tabTitle={tabTitle}
                          />
                        )}
                        {tabTitle ===
                          Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY && (
                          <PhysicianDirectory
                            locationTabIndex={locationTabIndex}
                            providerId={providerGroupDetails?.providerId}
                            providerType={providerGroupDetails?.providerType}
                            setActive={handleTabChange}
                          />
                        )}
                        {isVisionCare && (
                          <HealthGradesContainerStyled>
                            <DisclaimersContainer
                              hideDivider
                              isVisionCare={isVisionCare}
                              showPremiumCareDisclaimer={
                                !isBehavioralHealthProvider
                              }
                            />
                          </HealthGradesContainerStyled>
                        )}
                      </TabContainer>
                    </Tabs.Tab>
                  );
                }
              )
            : null}
        </TabsContainer>
      </Layout.Stack>
    </Container>
  );
};
