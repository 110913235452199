import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../../../models/ProviderDetails';
import {
  getInNetworkStartDate,
  getOfferVirtualCareText,
  getPracticeLimitations,
  getYesNoNAText,
} from '../../../../utils/providerDetails.utils';
import { capitalizeEachWord } from '../../../../utils/providerNames.utils';
import { Constants, ReverseCoverageTypesCodes } from '../../../Constants';
import { ConstantsRoutes } from '../../../ConstantsRoutes';
import { mobileOnly, phoneOnly } from '../../../ConstantsStyles';
import { LinkWithTracking } from '../../../LinkWithTracking/LinkWithTracking';
import { CopyToClipBoard } from '../CopyToClipBoard';
import { InfoProviderContent } from '../InfoProviderContent';
import { IconAlignmentContainer } from '../ProviderLocation.style';
import {
  FlexContainer,
  infoContainer,
  infoProviderContentContainer,
  infoTitleContainer,
} from '../ProviderLocationsDetails.style';
import { EmailAndWebsiteInfo } from './EmailAndWebsiteInfo';
import { PreferredFacilityInfo } from './PreferredFacilityInfo';

type Props = {
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
  detailType: String;
  providerDetails?: ProviderDetails;
  facilityDetails?: FacilityDetails;
};

export const AllOtherInfosContainer = ({
  selectedLocation,
  detailType,
  providerDetails,
  facilityDetails,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;

  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const coverageType = useCoverageType();

  const allConfigs = useLagoon('config')();
  const hospitalsOrgCodes = allConfigs?.find(
    (row) => row.key === 'HOSPITAL_ORG_CODES'
  )?.value;
  const isHospital =
    facilityDetails?.organizationCode &&
    hospitalsOrgCodes?.includes(facilityDetails?.organizationCode.join());

  const practiceLimitation = getPracticeLimitations(
    selectedLocation?.practiceLimitations?.age,
    selectedLocation?.practiceLimitations?.gender
  );
  const inNetworkStartDate = getInNetworkStartDate(
    selectedLocation?.network?.start
  );

  const handleRedirectToProviderGroupDetails = (providerGroupClicked) => {
    const newToken = tokenizer.update(token, {
      providerId: providerGroupClicked.id,
      sectionType: Constants.RESULT_SECTION.PROVIDER_GROUPS,
    });

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      providerGroupClicked.id
    );

    navigate(`${ConstantsRoutes.PROVIDER_GROUP_DETAILS.path}${newToken}`);
  };

  const isBehavioralHealth =
    coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];

  return (
    <React.Fragment>
      <Flex
        css={infoProviderContentContainer(mobileScreen)}
        data-testid="all-other-infos-container"
      >
        <EmailAndWebsiteInfo selectedLocation={selectedLocation} />

        {detailType === ProviderType.ORGANIZATION && (
          <PreferredFacilityInfo
            detailType={detailType}
            selectedLocation={selectedLocation}
          />
        )}

        <InfoProviderContent
          icon="videocam"
          infoSubtext={getOfferVirtualCareText(
            selectedLocation.virtualCareOffered?.join(', ')
          )}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.OFFERS_VIRTUAL_CARE')}
        />

        {coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
        detailType === ProviderType.ORGANIZATION ? (
          <InfoProviderContent
            icon="playlist_add_check"
            infoSubtext={(
              selectedLocation as FacilityLocationsResponse
            )?.programs?.join(',')}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PROGRAMS')}
          />
        ) : null}
        <InfoProviderContent
          icon="date_range"
          infoSubtext={getYesNoNAText(
            t,
            selectedLocation.hasEveningAppointments
          )}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.OFFERS_EVENING_APPOINTMENT')}
        />
        <InfoProviderContent
          icon="accessibility"
          infoSubtext={selectedLocation.accessibility?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.ACCESSIBILITY')}
        />
        <InfoProviderContent
          icon="translate"
          infoSubtext={selectedLocation.languagesSpokenByOffice?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.LANGUAGES_SPOKEN_BY_OFFICE')}
        />
        {coverageType !== ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] ? (
          <InfoProviderContent
            icon="local_hospital"
            infoSubtext={getYesNoNAText(
              t,
              selectedLocation.hasPCPServiceAvailability
            )}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PCP_SERVICES_AVAILABLE')}
            variant="outlined"
          />
        ) : null}
        {providerDetails?.providerType === ProviderType.PRACTITIONER ? (
          <FlexContainer
            css={{
              marginBottom: '3px',
              '@screen < $sm': { marginBottom: '0' },
            }}
          >
            <IconAlignmentContainer>
              <IconMaterial
                color="$primary1"
                css={{ marginTop: '3px' }}
                icon="group"
                size={18}
              />
              <Text css={infoContainer(phoneScreen)} fontWeight="$semibold">
                {t('PROVIDER_LOCATIONS_DETAILS.PROVIDER_GROUP')}
                {selectedLocation?.providerGroupsAffiliations?.length > 0 ? (
                  selectedLocation?.providerGroupsAffiliations?.map((group) => (
                    <Text
                      css={infoContainer(phoneScreen)}
                      fontWeight="$normal"
                      key={`provider-group-${group.value}`}
                    >
                      {isWidget ? (
                        <React.Fragment>
                          {capitalizeEachWord(group.value)}
                        </React.Fragment>
                      ) : (
                        <LinkWithTracking
                          analyticsInfo={{
                            name: group?.value,
                            location: `body:${Constants.PROVIDER_DETAILS.LOCATIONS_TAB.GROUP_AFFILIATION}`,
                          }}
                          css={infoContainer(phoneScreen)}
                          data-auto-testid="redirect-to-provider-group-details"
                          data-testid="redirect-to-provider-group-details"
                          fontWeight="$bold"
                          isDisabled={group?.id === null}
                          isStandardAnchor
                          onClick={() =>
                            handleRedirectToProviderGroupDetails(group)
                          }
                        >
                          {capitalizeEachWord(group.value)}
                        </LinkWithTracking>
                      )}
                    </Text>
                  ))
                ) : (
                  <Text css={infoContainer(phoneScreen)} fontWeight="$normal">
                    {t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                  </Text>
                )}
              </Text>
            </IconAlignmentContainer>
          </FlexContainer>
        ) : null}

        <InfoProviderContent
          icon="pan_tool"
          infoSubtext={
            practiceLimitation?.trim()?.length > 0
              ? practiceLimitation
              : t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
          }
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PRACTICE_LIMITATIONS')}
        />
        {detailType === ProviderType.ORGANIZATION ? (
          <React.Fragment>
            <InfoProviderContent
              icon="accessible"
              infoSubtext={selectedLocation?.medicalEquipments?.join(', ')}
              infoTitle={t(
                'PROVIDER_LOCATIONS_DETAILS.MEDICAL_EQUIPMENT_OFFER'
              )}
            />
          </React.Fragment>
        ) : null}
        <InfoProviderContent
          icon="event"
          infoSubtext={inNetworkStartDate}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.IN_NETWORK')}
          variant="outlined"
        />
        {detailType === ProviderType.PRACTITIONER && !isBehavioralHealth ? (
          <InfoProviderContent
            icon="pin_drop"
            infoSubtext={selectedLocation.pcpId}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PROVIDER_ID')}
            variant="outlined"
          >
            <Flex
              css={{
                gap: '$xs',
                '@screen < $sm': { width: 'auto' },
              }}
            >
              <Text
                color="$info1"
                css={infoContainer(phoneScreen)}
                fontWeight="$bold"
              >
                {selectedLocation?.pcpId?.replace(' ', '') ?? ''}
              </Text>

              <CopyToClipBoard
                gap="0"
                label={t(
                  'PROVIDER_LOCATIONS_DETAILS.COPY_PROVIDER_ID_TO_CLIPBOARD'
                )}
                text={selectedLocation?.pcpId?.replace(' ', '') ?? ''}
                title="provider-id"
              />
            </Flex>
          </InfoProviderContent>
        ) : null}
        {detailType === ProviderType.ORGANIZATION && !!isHospital && (
          <InfoProviderContent
            icon="task"
            infoTitle={t('PROVIDER_DETAILS.LOCATIONS_TAB.SPECIALTY_INNETWORK')}
            variant="outlined"
          >
            <Flex css={{ gap: '4px' }}>
              <Text css={phoneScreen}>
                {t('PROVIDER_DETAILS.LOCATIONS_TAB.ANESTHESIOLOGY')} -{' '}
              </Text>
              <Text css={infoTitleContainer(phoneScreen)}>
                {getYesNoNAText(
                  t,
                  selectedLocation?.facilityProviderSpecialties
                    ?.hasAnesthesiology
                )}
              </Text>
            </Flex>
            <Flex css={{ gap: '4px' }}>
              <Text css={phoneScreen}>
                {t('PROVIDER_DETAILS.LOCATIONS_TAB.EMERGENCY_MEDICINE')} -{' '}
              </Text>
              <Text css={infoTitleContainer(phoneScreen)}>
                {getYesNoNAText(
                  t,
                  selectedLocation?.facilityProviderSpecialties
                    ?.hasEmergencyMedicine
                )}
              </Text>
            </Flex>
            <Flex css={{ gap: '4px' }}>
              <Text css={phoneScreen}>
                {t('PROVIDER_DETAILS.LOCATIONS_TAB.PATHOLOGY')} -{' '}
              </Text>
              <Text css={infoTitleContainer(phoneScreen)}>
                {getYesNoNAText(
                  t,
                  selectedLocation?.facilityProviderSpecialties?.hasPathology
                )}
              </Text>
            </Flex>
            <Flex css={{ gap: '4px' }}>
              <Text css={phoneScreen}>
                {t('PROVIDER_DETAILS.LOCATIONS_TAB.RADIOLOGY')} -{' '}
              </Text>
              <Text css={infoTitleContainer(phoneScreen)}>
                {getYesNoNAText(
                  t,
                  selectedLocation?.facilityProviderSpecialties?.hasRadiology
                )}
              </Text>
            </Flex>
          </InfoProviderContent>
        )}

        {coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] ? (
          <InfoProviderContent
            icon="speed"
            infoSubtext={getYesNoNAText(
              t,
              selectedLocation?.isExpressAccessProvider
            )}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.EXPRESS_ACCESS_PROVIDER')}
          />
        ) : null}

        {detailType === ProviderType.ORGANIZATION ? (
          <InfoProviderContent
            icon="location_city"
            infoSubtext={facilityDetails?.providerId}
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.FACILITY_ID')}
            variant="outlined"
          >
            <Flex
              css={{
                gap: '$xs',
                '@screen < $sm': { width: 'auto' },
              }}
            >
              <Text
                color="$info1"
                css={infoContainer(phoneScreen)}
                fontWeight="$bold"
              >
                {facilityDetails?.providerId ?? ''}
              </Text>
              <CopyToClipBoard
                gap="0"
                label={t(
                  'PROVIDER_LOCATIONS_DETAILS.COPY_FACILITY_ID_TO_CLIPBOARD'
                )}
                text={facilityDetails?.providerId ?? ''}
                title="facility-id"
              />
            </Flex>
          </InfoProviderContent>
        ) : null}
      </Flex>
    </React.Fragment>
  );
};
