/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChipCategoryContext } from '../../../../context/ChipCategoryContext';
import { useLagoon } from '../../../../hooks/useLagoon';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../Constants';
import { getFeatureFlag } from '../../../Utils';
import {
  CustomAttributesBlock,
  convertTypeaheadProviderIdAndType,
  getNameForLinkTrack,
  getTypeAheadCategory,
} from '../../../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  getReqCoverageType,
  getReqCoverageTypes,
  handleSearchSelected,
} from '../utils';
import { HighlightedSuggestion } from './HighlightedSuggestion';
import {
  Divider,
  DropdownContainer,
  SuggestionListContainer,
  isPrimaryActiveSuggestion,
  keywordSearchDropdownIconColor,
} from './KeywordSearchDropdown.styled';
import { Skeleton } from './Skeleton';
import { SuggessionList } from './SuggessionList';

type Props = {
  activeSuggestion: number;
  headers: ResponseHeaders;
  isKeywordSearchLoading: boolean;
  typeAheadSuggestions?: any;
  keywordSearchTerm: string;
  isLoading: boolean;
  setIsFocusedOnKeywordSearchInput: (val: boolean) => void;
  enter: boolean;
  dentalCoverage: string;
  medicalCoverage: string;
  visionCoverage: string;
  autoCompleteCallCompleted: boolean;
  getCoverageType: Array<string>;
  blurKeywordSearchInput: () => void;
};

export const KeywordSearchDropdown = ({
  activeSuggestion,
  headers,
  isKeywordSearchLoading,
  typeAheadSuggestions,
  keywordSearchTerm,
  isLoading,
  setIsFocusedOnKeywordSearchInput,
  enter,
  visionCoverage,
  dentalCoverage,
  medicalCoverage,
  getCoverageType,
  autoCompleteCallCompleted,
  blurKeywordSearchInput,
}: Props) => {
  const { t } = useTranslation();
  const commonSearchesLagoon: () => any = useLagoon('common-searches');
  const commonSearchesData = commonSearchesLagoon();

  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { onlineRetailers, dependentSeqNbr } = tokenData;
  const { setChipValue } = useContext(ChipCategoryContext);
  const noResultsFound = t('No Results Found');
  const specialCharSearch = t('Special char search');
  const shortsearch = t('Short search');
  const specialCharCheck = !/[A-Za-z0-9]/.test(keywordSearchTerm);

  const featureFlags = useLagoon('feature-flags')();
  const dentalSearchLagoonFlag = getFeatureFlag(
    featureFlags,
    'TYPEAHEAD_DENTAL_SEARCH_STARTS_HERE_ENABLED'
  );

  const visionSearchLagoonFlag = getFeatureFlag(
    featureFlags,
    'TYPEAHEAD_VISION_SEARCH_STARTS_HERE_ENABLED'
  );

  const displayDentalSearchStartsHere =
    dentalCoverage && dentalSearchLagoonFlag;
  const displayVisionSearchStartsHere =
    visionCoverage && visionSearchLagoonFlag;

  const [currentToken, setCurrentToken] = useState(tokenData);

  const sortItems = (a, b) => {
    if (
      !a?.coverageType?.some((coverageCode: string) =>
        getCoverageType.includes(coverageCode)
      )
    ) {
      return 1;
    }
    if (
      !b?.coverageType?.some((coverageCode: string) =>
        getCoverageType.includes(coverageCode)
      )
    ) {
      return -1;
    }

    return 0;
  };

  useEffect(() => {
    if (onlineRetailers) {
      const updatedToken = tokenizer.update(token, {
        onlineRetailers: false,
      });
      setCurrentToken(updatedToken);
    }
  }, []);

  if (isKeywordSearchLoading) {
    return <Skeleton text="Search is taking longer than expected" />;
  }

  const searchInputOptionLocation = 'search-input-option-list';

  function getLinkNameForAnalytics(value) {
    return getNameForLinkTrack(
      value?.suggestion ||
        value?.displayName ||
        `common search:${value?.psxKeyword}`,
      value?.providerType
    );
  }

  function getSearchMethodForAnalytics(value) {
    let searchMethod;
    if (value?.psxKeyword) {
      searchMethod = 'guided';
    } else {
      searchMethod = 'predictive';
    }
    return searchMethod;
  }

  function adobeLinkTrack(value, index, typeAheadProviderLinks = false) {
    const customAttributesBlock =
      value?.searchType === 'Speciality' || typeAheadProviderLinks
        ? undefined
        : ({
            correlationId: headers?.correlationId,
            ...convertTypeaheadProviderIdAndType(value),
          } as CustomAttributesBlock);

    adobeLinkTrackEvent({
      name: getLinkNameForAnalytics(value),
      location: `body:${searchInputOptionLocation}:${getTypeAheadCategory(
        value?.providerType
      )}`,
      type: 'internal',
      searchBlock: {
        linkPosition: index + 1 || '1',
      },
      customAttributesBlock,
    });
  }

  const onChipClicked = (value, coverageType) => {
    adobeLinkTrackEvent({
      name: value,
      location: `body:${searchInputOptionLocation}`,
    });
    const updatedToken = tokenizer.update(currentToken, {
      chipValue: value,
      coverageType,
    });
    setChipValue(value);
    navigate(`/${updatedToken}`);
  };

  const onSuggestionClick = (value, index) => {
    if (value) {
      setIsFocusedOnKeywordSearchInput(false);
      adobeLinkTrack(value, index);
      handleSearchSelected(
        {
          ...value,
          searchTerm: keywordSearchTerm,
          linkName: getLinkNameForAnalytics(value),
          searchMethod: getSearchMethodForAnalytics(value),
          fromViewAll: false,
          includeSpecialityRollupCodes:
            value?.vertical?.code || value?.vertical || value?.pesKeyword,
          searchType: Constants.SEARCH_TYPES.SPECIALTY,
          coverageType: getReqCoverageType(value.coverageType, getCoverageType),
          coverageTypes: getReqCoverageTypes(
            value.coverageType,
            getCoverageType
          ),
          dependentSeqNbr,
        },
        currentToken,
        navigate
      );
    }
  };

  const setMessage = () => {
    let message: string;
    if (specialCharCheck && keywordSearchTerm !== '') {
      message = specialCharSearch;
    } else if (keywordSearchTerm?.length <= 1) {
      message = shortsearch;
    } else {
      message = noResultsFound;
    }

    return message;
  };

  const onSuggestionClickWithStartIndex = (commonSearchesStartIndex) => {
    onSuggestionClick(
      commonSearchesData[activeSuggestion - commonSearchesStartIndex],
      activeSuggestion - commonSearchesStartIndex
    );
  };

  const OnChipClickedUsingCategory = (chipCategory) => {
    if (activeSuggestion === 0) {
      if (Constants.CHIPS_CATEGORIES.DENTAL === chipCategory)
        onChipClicked(Constants.CHIPS_CATEGORIES.DENTAL, 'D');
      else if (Constants.CHIPS_CATEGORIES.VISION === chipCategory)
        onChipClicked(Constants.CHIPS_CATEGORIES.VISION, 'V');
    } else if (activeSuggestion > 0) {
      onSuggestionClickWithStartIndex(1);
    }
  };

  if (enter && keywordSearchTerm?.trim().length < 2) {
    blurKeywordSearchInput();
    setIsFocusedOnKeywordSearchInput(false);
    if (displayDentalSearchStartsHere && displayVisionSearchStartsHere) {
      if (activeSuggestion === 0) {
        onChipClicked(Constants.CHIPS_CATEGORIES.DENTAL, 'D');
      } else if (activeSuggestion === 1) {
        onChipClicked(Constants.CHIPS_CATEGORIES.VISION, 'V');
      } else if (activeSuggestion > 1) {
        // When both dental and vision search starts here are displayed, common searches index starts from 2.
        onSuggestionClickWithStartIndex(2);
      }
    } else if (displayDentalSearchStartsHere) {
      // When both only dental search starts here is displayed, common searches index starts from 1.
      OnChipClickedUsingCategory(Constants.CHIPS_CATEGORIES.DENTAL);
    } else if (displayVisionSearchStartsHere) {
      // When both only vision search starts here is displayed, common searches index starts from 1.
      OnChipClickedUsingCategory(Constants.CHIPS_CATEGORIES.VISION);
    } else {
      // When both dental and vision are not displayed, common searches index starts from 0.
      onSuggestionClickWithStartIndex(0);
    }
  }

  const numberOfOtherCoverageOptionsForNoKeywordInput =
    (displayDentalSearchStartsHere ? 1 : 0) +
    (displayVisionSearchStartsHere ? 1 : 0);

  return (
    <React.Fragment>
      {(keywordSearchTerm?.trim().length === 0 ||
        keywordSearchTerm?.trim().length === 1) &&
      !enter ? (
        <React.Fragment>
          <Divider />
          {displayDentalSearchStartsHere || displayVisionSearchStartsHere ? (
            <Divider />
          ) : null}
          <DropdownContainer
            data-testid="primary-dropdown-container"
            role="listbox"
          >
            {displayVisionSearchStartsHere ? (
              <li
                aria-posinset={displayDentalSearchStartsHere ? 1 : 0}
                aria-selected={
                  activeSuggestion === (displayDentalSearchStartsHere ? 1 : 0)
                }
                id={
                  activeSuggestion === (displayDentalSearchStartsHere ? 1 : 0)
                    ? 'primary-search-bar-result-suggestion'
                    : ''
                }
                role="option"
              >
                <Link
                  after={<IconMaterial icon="chevron_right" />}
                  css={{
                    '&.abyss-link-root':
                      activeSuggestion ===
                      (displayDentalSearchStartsHere ? 1 : 0)
                        ? isPrimaryActiveSuggestion
                        : '',
                  }}
                  data-testid="search-input-box-vision-common-suggestion"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setIsFocusedOnKeywordSearchInput(false);
                    onChipClicked(Constants.CHIPS_CATEGORIES.VISION, 'V');
                  }}
                  style={{ whiteSpace: 'pre' }}
                  tabIndex={-1}
                >
                  {t('VISION_SEARCH')}
                </Link>
              </li>
            ) : null}
            {displayDentalSearchStartsHere ? (
              <li
                aria-posinset={0}
                aria-selected={activeSuggestion === 0}
                id={
                  activeSuggestion === 0
                    ? 'primary-search-bar-result-suggestion'
                    : ''
                }
                role="option"
              >
                <Link
                  after={<IconMaterial icon="chevron_right" />}
                  css={{
                    '&.abyss-link-root':
                      activeSuggestion === 0 ? isPrimaryActiveSuggestion : '',
                  }}
                  data-testid="search-input-box-dental-common-suggestion"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setIsFocusedOnKeywordSearchInput(false);
                    onChipClicked(Constants.CHIPS_CATEGORIES.DENTAL, 'D');
                  }}
                  style={{ whiteSpace: 'pre' }}
                  tabIndex={-1}
                >
                  {t('DENTAL_SEARCH')}
                </Link>
              </li>
            ) : null}
            {medicalCoverage ? (
              <React.Fragment>
                <Text
                  data-testid="heading-common-search"
                  fontWeight={700}
                  size="$sm"
                >
                  {t('COMMON_SEARCH')}
                </Text>
                <SuggestionListContainer
                  data-auto-testid="suggestion-list-container"
                  data-testid="suggestion-list-container"
                >
                  {commonSearchesData.map((value, index) => (
                    <li
                      aria-label="common-search-term"
                      aria-posinset={
                        index + numberOfOtherCoverageOptionsForNoKeywordInput
                      }
                      aria-selected={
                        activeSuggestion ===
                        index + numberOfOtherCoverageOptionsForNoKeywordInput
                      }
                      id={
                        activeSuggestion ===
                        index + numberOfOtherCoverageOptionsForNoKeywordInput
                          ? 'primary-search-bar-result-suggestion'
                          : ''
                      }
                      role="option"
                    >
                      <Link
                        before={
                          <IconMaterial
                            color={keywordSearchDropdownIconColor}
                            icon="search"
                          />
                        }
                        css={{
                          '&.abyss-link-root':
                            activeSuggestion ===
                            index +
                              numberOfOtherCoverageOptionsForNoKeywordInput
                              ? isPrimaryActiveSuggestion
                              : '',
                        }}
                        data-testid="search-input-box-common-suggestion-link"
                        key={value.id}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          ev.preventDefault();
                          onSuggestionClick(value, index);
                        }}
                        style={{ whiteSpace: 'pre' }}
                        tabIndex={-1}
                      >
                        <HighlightedSuggestion
                          keyword={value?.psxKeyword}
                          text={value?.psxKeyword}
                        />
                      </Link>
                    </li>
                  ))}
                </SuggestionListContainer>
              </React.Fragment>
            ) : null}
          </DropdownContainer>
        </React.Fragment>
      ) : typeAheadSuggestions?.length ? (
        <SuggessionList
          activeSuggestion={activeSuggestion}
          enter={enter}
          getCoverageType={getCoverageType}
          keywordSearchTerm={keywordSearchTerm}
          onSuggestionClick={onSuggestionClick}
          sortItems={sortItems}
          typeAheadSuggestions={typeAheadSuggestions}
        />
      ) : (
        (autoCompleteCallCompleted || specialCharCheck) &&
        !isLoading && (
          <DropdownContainer
            data-testid="primary-dropdown-container"
            role="listbox"
          >
            <SuggestionListContainer
              data-auto-testid="suggestion-list-container"
              data-testid="suggestion-list-containter"
            >
              <span
                className="no-results"
                data-testid="suggestion-list-no-results"
                role="alert"
              >
                {setMessage()}
              </span>
            </SuggestionListContainer>
          </DropdownContainer>
        )
      )}
    </React.Fragment>
  );
};
