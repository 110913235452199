import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';

import {
  Constants,
  ReverseCoverageTypesCodes,
  TypeaheadCoverageTypes,
} from '../../../Constants';
import { PSXIcon } from '../../../PSXIcon';
import {
  CoverageIcon,
  CoverageType,
  InactiveCoverage,
} from './KeywordSearchDropdown.styled';

const notInYourBenefits = `\u00A0(${Constants.NOT_IN_YOUR_BENEFITS})`;
const dataTestIdForInEligibileCoverageType = 'not-a-eligibile-coverage-type';

export const DonotDisturbIcon = () => (
  <IconMaterial
    color="$gray7"
    data-testid="disabled-coverage-type-icon"
    icon="do_not_disturb"
    size={16}
    style={{ marginBottom: '-4px', marginRight: '2px' }}
    variant="outlined"
  />
);
type Props = {
  item: any;
  getCoverageType: Array<string>;
};

export const DisplayCoverageTypes = ({ item, getCoverageType }: Props) => (
  <React.Fragment>
    {item?.coverageType.some((value: string) =>
      getCoverageType.includes(value)
    ) && (
      <React.Fragment>
        {item?.coverageType?.includes(ReverseCoverageTypesCodes.MEDICAL) &&
          getCoverageType?.includes(ReverseCoverageTypesCodes.MEDICAL) && (
            <Text style={CoverageType}>
              <div style={{ backgroundColor: '#F2F8E6' }}>
                <PSXIcon icon="Stethoscope" size={16} style={CoverageIcon} />
                {TypeaheadCoverageTypes.MEDICAL}
              </div>
            </Text>
          )}

        {item?.coverageType?.includes(
          ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
        ) &&
          getCoverageType?.includes(
            ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
          ) && (
            <Text style={CoverageType}>
              <div style={{ backgroundColor: '#F2F8E6' }}>
                <IconMaterial
                  color="$success1"
                  icon="psychology"
                  size={16}
                  style={CoverageIcon}
                  variant="outlined"
                />
                {TypeaheadCoverageTypes.BEHAVIORAL}
              </div>
            </Text>
          )}

        {item?.coverageType?.includes(ReverseCoverageTypesCodes.VISION) &&
          getCoverageType?.includes(ReverseCoverageTypesCodes.VISION) && (
            <Text style={CoverageType}>
              <div style={{ backgroundColor: '#F2F8E6' }}>
                <IconMaterial
                  color="$success1"
                  icon="remove_red_eye"
                  size={16}
                  style={CoverageIcon}
                  variant="outlined"
                />
                {TypeaheadCoverageTypes.VISION}
              </div>
            </Text>
          )}

        {item?.coverageType?.includes(ReverseCoverageTypesCodes.DENTAL) &&
          getCoverageType?.includes(ReverseCoverageTypesCodes.DENTAL) && (
            <Text style={CoverageType}>
              <div style={{ backgroundColor: '#F2F8E6' }}>
                <IconSymbol
                  color="$success1"
                  icon="Dentistry"
                  size="16px"
                  style={{
                    marginRight: '2px',
                    position: 'relative',
                    top: '4px',
                  }}
                  variant="outlined"
                />
                {TypeaheadCoverageTypes.DENTAL}
              </div>
            </Text>
          )}
      </React.Fragment>
    )}

    {!item?.coverageType.every((value: string) =>
      getCoverageType.includes(value)
    ) && (
      <React.Fragment>
        {item?.coverageType?.includes(ReverseCoverageTypesCodes.MEDICAL) &&
          !getCoverageType?.includes(ReverseCoverageTypesCodes.MEDICAL) && (
            <Text
              data-testid={dataTestIdForInEligibileCoverageType}
              style={InactiveCoverage}
            >
              <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: '#F3F3F3' }}>
                  <DonotDisturbIcon />
                  {TypeaheadCoverageTypes.MEDICAL}
                </div>
                {notInYourBenefits}
              </div>
            </Text>
          )}

        {item?.coverageType?.includes(
          ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
        ) &&
          !getCoverageType?.includes(
            ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
          ) && (
            <Text
              data-testid={dataTestIdForInEligibileCoverageType}
              style={InactiveCoverage}
            >
              <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: '#F3F3F3' }}>
                  <DonotDisturbIcon />
                  {TypeaheadCoverageTypes.BEHAVIORAL}
                </div>
                {notInYourBenefits}
              </div>
            </Text>
          )}

        {item?.coverageType?.includes(ReverseCoverageTypesCodes.VISION) &&
          !getCoverageType?.includes(ReverseCoverageTypesCodes.VISION) && (
            <Text
              data-testid={dataTestIdForInEligibileCoverageType}
              style={InactiveCoverage}
            >
              <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: '#F3F3F3' }}>
                  <DonotDisturbIcon />
                  {TypeaheadCoverageTypes.VISION}
                </div>
                {notInYourBenefits}
              </div>
            </Text>
          )}

        {item?.coverageType?.includes(ReverseCoverageTypesCodes.DENTAL) &&
          !getCoverageType?.includes(ReverseCoverageTypesCodes.DENTAL) && (
            <Text
              data-testid={dataTestIdForInEligibileCoverageType}
              style={InactiveCoverage}
            >
              <div style={{ display: 'flex' }}>
                <div style={{ backgroundColor: '#F3F3F3' }}>
                  <DonotDisturbIcon />
                  {TypeaheadCoverageTypes.DENTAL}
                </div>
                {notInYourBenefits}
              </div>
            </Text>
          )}
      </React.Fragment>
    )}
  </React.Fragment>
);
