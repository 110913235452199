import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { phoneOnly } from '../../ConstantsStyles';
import { StyledWhereToGetHelpIcon } from '../WhereToGetHelp.Style';

export const ContentCardsContainer = styled('div', {
  dynamic: ({ cssProps: { backgroundColor } }) => ({
    backgroundColor,
  }),
  height: '100%',
  padding: '$md 32px',

  borderRadius: '8px',
  '@screen < $sm': {
    padding: '$md 24px',
    minHeight: '174px',
  },
});

export const CardText = styled(Text, {
  '&.abyss-text-root': {
    color: '#4B4D4F',
    fontWeight: '$medium',
    '@screen < $sm': { fontSize: '14.22px' },
  },
});

export const CardButton = {
  height: '40px',
  '@screen < $sm': {
    height: '42px',
    fontWeight: '600',
  },
  'abyss-button-root': {
    '@screen < $sm': {
      fontWeight: '$semibold',
    },
  },
};

const srOnly = { display: 'none' };

type Props = {
  cardTitle?: string;
  cardContent: string;
  buttonLabel: string;
  url?: string;
  cardIcon: React.ReactNode;
  cardBGColor: string;
  testId?: string;
  accessibilityText?: string;
};

export const WhereToGetHelpContainer = ({
  cardTitle = '',
  cardContent,
  buttonLabel,
  url,
  cardIcon,
  cardBGColor,
  testId = '',
  accessibilityText = '',
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();

  const analyticsInfo = {
    name: buttonLabel || testId,
    location: 'body:where to get help',
    destinationUrl: url,
  };
  const handleLinkTrackEvent = () => {
    adobeLinkTrackEvent({
      ...analyticsInfo,
    });
  };

  return (
    <ContentCardsContainer cssProps={{ backgroundColor: cardBGColor }}>
      <Layout.Group
        alignItems="top"
        css={{
          '&, > div': {
            height: '100%',
          },
        }}
        data-auto-testid="where-to-get-help-card"
        data-testid="where-to-get-help-card"
        space={mobileScreen ? 24 : 32}
      >
        <Layout.Stack
          css={{
            marginRight: '20px',
            '@screen < $md': {
              size: '48px',
            },
          }}
        >
          {cardIcon ? (
            <StyledWhereToGetHelpIcon
              icon={cardIcon}
              size="60px"
              variant="twotone"
            />
          ) : null}
        </Layout.Stack>
        <Layout.Stack
          alignItems="left"
          css={{
            justifyContent: 'space-between',
          }}
          space={mobileScreen ? 16 : 24}
        >
          <Flex>
            <Heading
              css={{
                'abyss-heading-root': {
                  marginBottom: '4px',
                  '@screen < $sm': {
                    lineHeight: '20px !important',
                    fontSize: '$md !important',
                  },
                },
              }}
              data-auto-testid={`${testId}heading`}
              data-testid={`${testId}heading`}
              display="h6"
              offset={1}
            >
              {t(cardTitle)}
            </Heading>
            <CardText
              data-auto-testid={`${testId}content`}
              data-testid={`${testId}content`}
            >
              {cardContent}
            </CardText>
          </Flex>
          <Button
            aria-label={`${buttonLabel} ${accessibilityText}`.toLowerCase()}
            css={CardButton}
            data-auto-testid={`${testId}button`}
            data-testid={`${testId}button`}
            href={url}
            name="get_started"
            onClick={() => handleLinkTrackEvent()}
          >
            {buttonLabel}
            <span className="sr-only" style={srOnly}>
              {accessibilityText.toLowerCase()}
            </span>
            {!buttonLabel?.includes('Call') ? (
              <IconMaterial
                css={{ marginLeft: mobileScreen ? '4px' : '8px' }}
                icon="open_in_new"
              />
            ) : null}
          </Button>
        </Layout.Stack>
      </Layout.Group>
    </ContentCardsContainer>
  );
};
