import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { StoreKeys } from '../hooks/useStore/state';
import { useStore } from '../hooks/useStore/useStore';

export const returnSuppressFlag = (featureDnt) => {
  const { suppressionLagoonData, memberPolicy: storeMemberPolicy } = useStore(
    StoreKeys.UI_FEATURE_SUPPRESSION
  );

  const [featureSuppressionFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.FEATURE_SUPPRESSION,
  ]);

  const suppressFeature = suppressionLagoonData?.find(
    (item) =>
      item.DNT_feature === featureDnt &&
      item.policyIds.split(',').includes(storeMemberPolicy)
  )?.active;

  if (featureSuppressionFlag && suppressFeature) return true;
  return false;
};
