import { Pagination, ResultCount } from '@abyss/web/ui/Pagination';
import React from 'react';

import { CarePathsResponse } from '../../../../models/CarePaths';

type Props = {
  pagesCount: number;
  pageSize: number;
  setCarePathsData: (a: CarePathsResponse) => void;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (a: number) => void;
  paginationProps: any;
  resultsCount: number;
  canNextPage: boolean;
};

export const CarePathsPagination = ({
  pagesCount,
  pageSize,
  gotoPage,
  nextPage,
  previousPage,
  setCarePathsData,
  paginationProps,
  resultsCount,
  canNextPage,
}: Props) => (
  <React.Fragment>
    <Pagination
      {...paginationProps}
      canNextPage={canNextPage}
      data-auto-testid="pagination-component-desktop"
      data-testid="pagination-component-desktop"
      gotoPage={(page) => {
        setCarePathsData({});
        gotoPage(page);
      }}
      nextPage={() => {
        setCarePathsData({});
        nextPage();
      }}
      pageCount={pagesCount}
      pageSize={pageSize}
      previousPage={() => {
        setCarePathsData({});
        previousPage();
      }}
    />
    <ResultCount
      {...paginationProps}
      css={{
        'abyss-pagination-results-root': {
          height: '36px',
          padding: '10px',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '18px',
          color: '#4B4D4F',
        },
      }}
      data-testid="care-path-pagination-results"
      pageSize={pageSize}
      resultsTotalCount={resultsCount}
    />
  </React.Fragment>
);
