import { ReactNode } from 'react';

import { PSXHeaderWrapper, Wrapper } from './ContentWrapper.styled';

export type ContentWrapperProps = {
  children: ReactNode;
  // eslint-disable react/no-unused-prop-types
  showSearchInputBackButton?: boolean;
  isWidget?: boolean;
};

export const ContentWrapper = ({ children, isWidget }: ContentWrapperProps) => (
  <Wrapper isWidget={isWidget}>{children}</Wrapper>
);

export const PSXHeaderContentWrapper = ({
  children,
  showSearchInputBackButton,
}: ContentWrapperProps) => (
  <PSXHeaderWrapper showSearchInputBackButton={showSearchInputBackButton}>
    {children}
  </PSXHeaderWrapper>
);
