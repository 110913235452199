import { styled } from '@abyss/web/tools/styled';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Text } from '@abyss/web/ui/Text';

import { PSXIcon } from '../PSXIcon';

export const HealthGradesStyled = styled('div', {
  backgroundColor: '$white',
  dynamic: ({ cssProps: { isWidget } }) => ({
    '> div': {
      paddingBottom: isWidget ? '16px' : '10px',
    },
  }),
});

export const StyledIcon = styled(PSXIcon, {
  height: '35px !important',
  marginTop: 16,
});

export const StyledDisclaimerText = styled(Text, {
  color: '$gray7 !important',
  fontSize: '12.64px !important',
  fontWeight: 700,
  lineHeight: '16px',
});

export const disclaimerLinkStyles = {
  'abyss-link-root': {
    fontSize: '12.64px',
    lineHeight: '16px',
  },
};

export const StyledMarkdown = styled(Markdown, {
  fontSize: '12.64px',
  lineHeight: '16px',
  color: '$gray8',
  p: {
    color: '$gray8',
  },
});
