import { config } from '@abyss/web/tools/config';
import { t } from 'i18next';
/* istanbul ignore file */
/* eslint-disable no-param-reassign */
import mapboxgl from 'mapbox-gl';

import { Directions } from '../models/RouteDirections';
import { RESULT_LAYER, ROUTE_LAYER, USER_LAYER } from './generalMap.utils';

export const USER_LOCATION_PNG = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/user_location.png`;
export const RESULT_LOCATION_PNG = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/location_pin.png`;

let clickResultId = null;
let hoverResultId = null;
let curNavType = 'driving-traffic';
let mapboxKey = '';

const loadUserLocationPin = (map, userLng, userLat) => {
  map.loadImage(USER_LOCATION_PNG, (error, image) => {
    if (error) {
      throw error;
    }

    map.addImage('userLocation', image);
  });

  map.addSource(USER_LAYER, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [userLng, userLat],
          },
        },
      ],
    },
  });

  map.addLayer({
    id: 'curLocationLayer',
    type: 'symbol',
    source: USER_LAYER,
    layout: {
      'icon-image': 'userLocation',
      'icon-allow-overlap': true,
    },
  });
};

export const setZoomLevel = async (map, coordinates) => {
  if (coordinates?.length) {
    const bounds = new mapboxgl.LngLatBounds();
    coordinates?.forEach((f: [number, number]) => bounds.extend(f));
    await map?.fitBounds(bounds, { maxZoom: 20, padding: 100 });
  }
};

const clickMapPinPoint = (
  map,
  location,
  practitioner,
  highlightPinCallback,
  updatePin
) => {
  clickResultId = location.id;

  highlightPinCallback({
    providerId: practitioner.providerId,
    from: 'mapView',
  });
  updatePin?.(practitioner);

  setResultIcon(map, hoverResultId, false, highlightPinCallback, null);
  setResultIcon(
    map,
    clickResultId,
    true,
    highlightPinCallback,
    practitioner.providerId
  );
};

const hoverMapPinPoint = (
  el,
  map,
  location,
  practitioner,
  highlightPinCallback,
  updatePin
) => {
  if (clickResultId === null) {
    if (hoverResultId !== null) {
      setResultIcon(map, hoverResultId, false, highlightPinCallback, null);
    }
    updateIconSize(true, el);
    hoverResultId = location.id;
    setResultIcon(
      map,
      hoverResultId,
      true,
      highlightPinCallback,
      practitioner.providerId
    );
    updatePin?.(practitioner);
  }
};

export const closePopUp = (el, popUp, tooltip, updatePin) => {
  (popUp as HTMLElement).style.visibility = 'hidden';
  (tooltip as HTMLElement).style.visibility = 'hidden';
  el.focus();
  updatePin?.(null);
};

const loadResultLocationPins = async (
  map,
  features,
  coordinates,
  highlightPinCallback,
  selectPinCallback,
  disabledPinAction,
  updatePin
) => {
  await map.isStyleLoaded();

  features?.forEach((location, index) => {
    const { description } = location.properties;
    const practitioner = JSON.parse(description);
    const el = document.createElement('button');

    const elImage = document.createElement('img');
    elImage.src = RESULT_LOCATION_PNG;
    elImage.alt = '';

    el.className = 'custom-marker';
    el.id = `custom-marker-${practitioner.providerId}`;
    el.ariaLabel = `${t('ACCESSIBILITY_LABELS.MAP_PINPOINT')}, ${
      practitioner.providerName
    }`;
    el.appendChild(elImage);
    el.tabIndex = 0;

    let enterPin = false;
    if (!disabledPinAction) {
      el.addEventListener('keydown', (e) => {
        if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Escape') {
          if (e.key === 'Enter') {
            clickMapPinPoint(
              map,
              location,
              practitioner,
              highlightPinCallback,
              updatePin
            );
            enterPin = true;
          }
          const rootElem = document.querySelector('.datacard-popup');
          const tooltip = document.querySelector('.mapboxgl-popup-tip');

          if (e.key === 'Tab' && enterPin === true) {
            (rootElem as HTMLElement)?.focus();
            enterPin = false;
          }
          if (e.key === 'Escape') {
            closePopUp(el, rootElem, tooltip, updatePin);
            clickResultId = null;
          }
        }
      });

      el.addEventListener('mouseenter', () => {
        hoverMapPinPoint(
          el,
          map,
          location,
          practitioner,
          highlightPinCallback,
          updatePin
        );
      });

      el.addEventListener('click', (event) => {
        el.focus();
        enterPin = true;
        event?.stopPropagation();
        clickMapPinPoint(
          map,
          location,
          practitioner,
          highlightPinCallback,
          updatePin
        );
      });

      el.addEventListener('mouseleave', () => {
        updateIconSize(false, el);
        if (clickResultId === null) {
          updatePin?.(null);
          highlightPinCallback({ providerId: '', from: 'mapView' });
        }
      });

      el.addEventListener('focus', () => {
        clickResultId = null;
      });
    }

    new mapboxgl.Marker(el).setLngLat(coordinates[index])?.addTo(map);
  });
};

const updateIconSize = (isHighlighting, marker) => {
  const iconWidth = isHighlighting ? '32.67px' : '23.33px';
  const iconHeight = isHighlighting ? '46.67px' : '33.33px';
  marker.style.width = iconWidth;
  marker.style.height = iconHeight;
};

export const setResultIcon = (
  map,
  targetId,
  isHighlighting,
  highlightPinCallback,
  providerId
) => {
  const iconSize = isHighlighting ? 1.2 : 1;
  const loaded = map.isStyleLoaded();
  if (loaded)
    map?.setLayoutProperty(RESULT_LAYER, 'icon-size', [
      'match',
      ['id'],
      targetId,
      iconSize,
      1,
    ]);
  if (map) {
    highlightPinCallback({ providerId, from: 'mapView' });
  }
};
export const clearRoute = (
  map,
  selectPinCallback?,
  highlightPinId?,
  highlightPinCallback?
) => {
  if (map.getSource(ROUTE_LAYER)) {
    map.removeLayer(ROUTE_LAYER);
    map.removeSource(ROUTE_LAYER);
  }

  if (highlightPinCallback) {
    setResultIcon(map, highlightPinId, false, highlightPinCallback, null);
  }

  if (selectPinCallback) {
    selectPinCallback([null, null]);
  }
};

export const loadRoute = (
  map,
  routes,
  choosenRoute,
  userLng,
  userLat,
  endLng,
  endLat,
  mediumScreen = false
) => {
  clearRoute(map);

  const data = routes[choosenRoute];
  const route = data.geometry.coordinates;
  const geojson = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: route,
    },
  };

  if (map.getSource(ROUTE_LAYER)) {
    map.getSource(ROUTE_LAYER).setData(geojson);
  } else {
    map.addLayer({
      id: ROUTE_LAYER,
      type: 'line',
      source: {
        type: 'geojson',
        data: geojson,
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#3887be',
        'line-width': 5,
        'line-opacity': 0.75,
      },
    });
  }

  map.fitBounds(
    [
      [userLng, userLat],
      [endLng, endLat],
    ],
    { padding: mediumScreen ? 50 : 120 }
  );
};

export const getRoute = async (
  map,
  userLng,
  userLat,
  endLng,
  endLat,
  mediumScreen = false,
  navType?,
  openDirections?,
  selectedRoute = 0
): Promise<void | Directions> => {
  if (!map) {
    return;
  }

  if (navType) {
    curNavType = navType;
  }

  const routeQuery = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/${curNavType}/${userLng},${userLat};${endLng},${endLat}?steps=true&geometries=geojson&alternatives=true&access_token=${mapboxKey}`,
    { method: 'GET' }
  );
  const userPlaceQuery = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${userLng},${userLat}.json?types=address&&access_token=${mapboxKey}`,
    { method: 'GET' }
  );
  const endPlaceQuery = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${endLng},${endLat}.json?types=address&&access_token=${mapboxKey}`,
    { method: 'GET' }
  );
  const routeJson = await routeQuery.json();
  const userPlaceJson = await userPlaceQuery.json();
  const endPlaceJson = await endPlaceQuery.json();
  const { routes } = routeJson;
  const choosenRoute = selectedRoute < routes?.length ? selectedRoute : 0;
  const directions: Directions = {
    userPlaceName: userPlaceJson.features[0]?.place_name,
    endPlaceName: endPlaceJson.features[0]?.place_name,
    routes: routes?.map((route) => ({
      steps: route.legs[0].steps,
      duration: route.duration,
      distance: route.distance,
      geometry: route.geometry,
    })),
  };

  loadRoute(
    map,
    routes,
    choosenRoute,
    userLng,
    userLat,
    endLng,
    endLat,
    mediumScreen
  );

  // eslint-disable-next-line consistent-return
  return directions;
};

const mapClick = (map, highlightPinCallback, setPopupContent) => {
  map.on('click', () => {
    if (map.getSource(ROUTE_LAYER)) {
      return;
    }

    if (clickResultId !== null) {
      setResultIcon(map, clickResultId, false, highlightPinCallback, null);
      clickResultId = null;
      setPopupContent?.(null);
      highlightPinCallback({ providerId: '', from: 'mapView' });
    }
  });
};

export const loadMapPins = async (
  map,
  mapKey,
  userLng,
  userLat,
  features,
  coordinates,
  highlightPinCallback,
  selectPinCallback,
  disabledPinAction,
  setPopupContent,
  updatePin
) => {
  if (!map.getSource(USER_LAYER)) {
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    map.getCanvas()?.setAttribute('role', 'application');
    map.getCanvas()?.setAttribute('aria-label', t('ACCESSIBILITY_LABELS.MAP'));
    mapboxKey = mapKey;
    await setZoomLevel(map, coordinates);
    await loadResultLocationPins(
      map,
      features,
      coordinates,
      highlightPinCallback,
      selectPinCallback,
      disabledPinAction,
      updatePin
    );
    loadUserLocationPin(map, userLng, userLat);
    mapClick(map, highlightPinCallback, setPopupContent);
  }
};

export const setMapboxKey = (mapKey: string) => {
  mapboxKey = mapKey;
};
