export const getWhereToGetHelpContent = (whereToGetHelps, activeChip) =>
  whereToGetHelps.filter(
    (whereToGetHelp) =>
      whereToGetHelp.activeTabs
        ?.split(',')
        .find(
          (activeTab) =>
            activeTab?.trim().toLowerCase() === activeChip.toLowerCase()
        ) && whereToGetHelp.isActive
  );
