import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Flex } from '@abyss/web/ui/Flex';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Option,
  ProviderFiltersType,
  ProviderSearchFilters,
} from '../../../../models/ProviderSearch';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { CheckBoxGroup } from '../../../CheckBoxGroup';
import { RadioGroup } from '../../../RadioGroup';
import { AllFilterSkeleton } from './AllFilterSkeleton';
import {
  FilterAccordianContent,
  FilterAccordianItem,
  FilterAccordianTrigger,
} from './ProviderFilters.style';

type Props = {
  providerFilters: ProviderFiltersType[];
  selectedFilters: ProviderSearchFilters;
  onFilterSelectionChange: (
    data: ProviderSearchFilters,
    lastModifiedModel: string
  ) => void;
  value?: string[];
  sectionType: string;
  AccordionType?: string;
  isData: boolean;
};

export const ProviderFilters = ({
  providerFilters,
  selectedFilters,
  onFilterSelectionChange,
  value,
  sectionType,
  AccordionType = 'single',
  isData,
}: Props) => {
  const { t } = useTranslation();
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token);
  const { coverageType } = tokenData || {};
  const [openedAccordion, setOpenedAccordion] = useState<string>('');
  const [hideSKelton, setHideSkeleton] = useState(false);
  const allFiltersDrawer: string = 'all filters drawer';
  const getLocationForAnalytics = (label: string) =>
    `modal:${allFiltersDrawer}:${label} filter`;

  const onOptionChange = (e: { [key: string]: Option[] }) => {
    const currentSelectedFilters = {
      ...selectedFilters,
      ...e,
    };
    const lastModifiedModel =
      Object.values(e)[0].length > 0 ? Object.keys(e)[0] : '';
    onFilterSelectionChange(currentSelectedFilters, lastModifiedModel);
  };

  function onChangeAccordion(e) {
    let linkName;
    let linkType;
    const linkLocation: string = `modal:${allFiltersDrawer}`;

    if (e && openedAccordion !== e) {
      linkName = providerFilters?.find((option) => e === option.model)?.label;
      linkType = 'accordion open';
    } else {
      linkName = providerFilters?.find(
        (option) => openedAccordion === option.model
      )?.label;
      linkType = 'accordion close';
    }

    adobeLinkTrackEvent({
      name: linkName,
      location: linkLocation,
      type: linkType,
    });

    setOpenedAccordion(e);
  }

  useEffect(() => {
    setHideSkeleton(isData);
  }, []);

  return (
    <Accordion
      defaultValue={value}
      isCollapsible
      key={value?.join('-')}
      onValueChange={(e) => onChangeAccordion(e)}
      type={AccordionType}
    >
      {hideSKelton
        ? providerFilters
            ?.filter(({ allFilterDrawerFilters }) =>
              allFilterDrawerFilters[coverageType]?.includes(sectionType)
            )
            .sort(
              (a, b) =>
                a.allFilterDrawerOrder[coverageType] -
                b.allFilterDrawerOrder[coverageType]
            )
            .map(({ options, model, label, title, type }) => (
              <FilterAccordianItem
                data-testid={`${label.replace(/ /g, '-').toLowerCase()}-filter`}
                key={model}
                value={model}
              >
                <FilterAccordianTrigger
                  data-testid={`${label
                    .replace(/ /g, '-')
                    .toLowerCase()}-filter-heading`}
                >
                  <Flex css={{ gap: '8px' }}>{t(title || label)}</Flex>
                </FilterAccordianTrigger>
                <FilterAccordianContent>
                  {type === 'radio' ? (
                    <RadioGroup
                      defaultValue={
                        selectedFilters[model]?.length
                          ? selectedFilters[model][0].value
                          : ''
                      }
                      locationForAnalytics={getLocationForAnalytics(label)}
                      model={model}
                      onOptionChange={onOptionChange}
                      options={options}
                    />
                  ) : (
                    <CheckBoxGroup
                      defaultValue={
                        selectedFilters[model]
                          ? selectedFilters[model].map(
                              (item: Option) => item.value
                            )
                          : []
                      }
                      locationForAnalytics={getLocationForAnalytics(label)}
                      model={model}
                      onOptionChange={onOptionChange}
                      options={options}
                    />
                  )}
                </FilterAccordianContent>
              </FilterAccordianItem>
            ))
        : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
            <AllFilterSkeleton index={i} />
          ))}
    </Accordion>
  );
};
