import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../../../../common/Constants';
import { ModalWithTracking } from '../../../../../../../common/ModalWithTracking/ModalWithTracking';
import { PSXIcon } from '../../../../../../../common/PSXIcon';
import { useCoverageType } from '../../../../../../../hooks/useCoverageType';
import { StoreKeys } from '../../../../../../../hooks/useStore/state';
import { useStore } from '../../../../../../../hooks/useStore/useStore';
import { CompareProvider } from '../../../../../../../models/Provider';
import { getDefaultCompareAttributes } from '../../../../ViewAll/utils/getDefaultCompareAttributes';
import { compareModalButtonStyle } from '../../utility/compareDrawer.styled';
import { CompareCheckboxInfo } from '../../utility/compareDrawerConstants';

export type CustomAttributesBlock = {
  correlationId: string;
  providerId: string;
  providerType: string;
};
interface DataForAnalytics {
  linkName: string;
  linkLocation: string;
  modalName: string;
  linkType?: string;
  customAttributesBlock?: CustomAttributesBlock;
}
type Props = {
  setSelectedProviders?: (a: CompareProvider[]) => void;
  setSelectedCheckbox?: (a: { checked: {} }) => void;
  setOpenCompare?: (a: boolean) => void;
  setItems?: React.Dispatch<React.SetStateAction<CompareCheckboxInfo[]>>;
  handleClickCb?: () => void;
  closeButtonText?: string;
  isViewProvider?: boolean;
  locationForAnalytics?: string;
  dataForAnalytics?: DataForAnalytics;
  modal?: any;
  modalName?: string;
};

export const ConfirmationModal = ({
  setSelectedProviders,
  setSelectedCheckbox,
  setOpenCompare,
  setItems,
  closeButtonText,
  handleClickCb,
  isViewProvider,
  locationForAnalytics,
  dataForAnalytics,
  modal,
  modalName,
}: Props) => {
  const { t } = useTranslation();
  const coverageType = useCoverageType();

  const handleAdobeLinkTrack = (buttonName: string) => {
    adobeLinkTrackEvent({
      name: buttonName,
      location: locationForAnalytics ?? `modal:close confirmation`,
      type: 'internal',
    });
  };

  const handleClick = () => {
    handleAdobeLinkTrack('done');
    if (setOpenCompare) {
      setOpenCompare(false);
    }
    if (setSelectedProviders) {
      setSelectedProviders([]);
    }
    if (setSelectedCheckbox) {
      setSelectedCheckbox({ checked: {} });
    }
    const newItems = getDefaultCompareAttributes(coverageType).map((item) => ({
      ...item,
    }));
    if (setItems) {
      setItems(newItems);
    }
  };

  // begin: a11y keyboard navigation: move focus to last  selected provider index
  const setUIstate = useStore(StoreKeys.SET_UI_STATE);
  const uiState = useStore(StoreKeys.UI_STATE);
  const { compareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const { indexOflastProviderSelected } = compareProvidersFlow;

  const handleFocusLastIndex = () => {
    setUIstate({
      ...uiState,
      compareProvidersFlow: {
        indexOflastProviderSelected,
        shouldFocusLastIndex: true,
      },
    });
  };
  // end

  return (
    <ModalWithTracking
      analyticsInfo={{
        linkName: dataForAnalytics?.linkName,
        linkLocation: dataForAnalytics?.linkLocation,
        modalName: dataForAnalytics?.modalName,
        customAttributesBlock: dataForAnalytics?.customAttributesBlock,
      }}
      closeOnClickOutside={false}
      css={{
        '.abyss-modal-header-container': { paddingTop: '0px' },
        '.abyss-modal-content-container': {
          width: 'auto',
          height: 'auto',
          borderRadius: '8px',
        },
        '.abyss-modal-content-title': {
          marginTop: '36px',
          height: '84px',
        },
        '.abyss-modal-close-button': {
          marginRight: '16px',
          marginTop: '16px',
          width: 'auto',
          svg: {
            width: '18px',
            height: '20px',
            color: '#1F2937',
            fill: '#1F2937',
          },
        },
        '.abyss-modal-overlay': {
          background: 'rgba(0, 0, 0, 0.7)',
        },
      }}
      data-auto-testid="confirmation-modal-container"
      data-testid="confirmation-modal-container"
      model={modalName}
      onClose={() => {
        handleAdobeLinkTrack('close');
        modal.close();
      }}
      size="320px"
      title={<PSXIcon icon="real_time_clock" size={84} />}
      titleAlign="center"
      trackingType={Constants.ADOBE_TRACKING.MODAL_WITH_LINK_TRACKING}
    >
      <Modal.Section
        css={{
          'abyss-modal-section-root': {
            textAlign: 'center',
            padding: '16px 16px 32px 16px',
            lineHeight: '18px',
          },
        }}
      >
        <Text fontWeight="bold" size="14px">
          {isViewProvider
            ? t(
                'COMPARE_DRAWER_CONFIRMATION_MODAL.MODAL_SECTION_PART_ONE_VIEW_PROVIDER'
              )
            : t('COMPARE_DRAWER_CONFIRMATION_MODAL.MODAL_SECTION_PART_ONE')}
        </Text>
        <br />
        <Text size="14px">
          {isViewProvider
            ? t(
                'COMPARE_DRAWER_CONFIRMATION_MODAL.MODAL_SECTION_PART_TWO_VIEW_PROVIDER'
              )
            : t('COMPARE_DRAWER_CONFIRMATION_MODAL.MODAL_SECTION_PART_TWO')}
        </Text>
      </Modal.Section>
      <Modal.Section>
        <Layout.Group alignLayout="center" space={8}>
          <Button
            css={compareModalButtonStyle}
            data-auto-testid="confirmation-modal-close-drawer"
            data-testid="confirmation-modal-close-drawer"
            onClick={() => {
              modal.close();
              if (handleClickCb) {
                handleClickCb();
              } else {
                handleClick();
              }
            }}
            size="30px"
          >
            {closeButtonText ||
              t('COMPARE_DRAWER_CONFIRMATION_MODAL.CLOSE_BUTTON')}
          </Button>
          <Button
            css={compareModalButtonStyle}
            data-auto-testid="confirmation-modal-keep-comparing"
            data-testid="confirmation-modal-keep-comparing"
            onClick={() => {
              handleFocusLastIndex();
              handleAdobeLinkTrack('keep working');
              modal.close();
            }}
            size="30px"
            variant="outline"
          >
            {t('COMPARE_DRAWER_CONFIRMATION_MODAL.CONTINUE_WORKING_BUTTON')}
          </Button>
        </Layout.Group>
      </Modal.Section>
    </ModalWithTracking>
  );
};

ConfirmationModal.defaultProps = {
  isViewProvider: false,
};
