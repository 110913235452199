import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
/* eslint-disable no-nested-ternary */
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import { useContext } from 'react';

import { CountySearchContext } from '../../context/CountySearchContext';
import { useLagoon } from '../../hooks/useLagoon';
import { SnackCardProvider } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { OutsideCountyAlert } from '../DataCard/OutsideCountyAlert';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import { CardInfo } from '../SnackCard/CardInfo';
import { SnackCard } from '../SnackCard/SnackCard';
import { getFeatureFlag } from '../Utils';
import { isOutOfCounty } from './utils';

const flexStyles = {
  'abyss-flex-root': {
    gap: '$md',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    alignItems: 'end',

    '& > div': {
      flex: 'none',
    },
  },
};

type Props = {
  coverageType?: string;
  dataTestId?: string;
  snackCardProviders: SnackCardProvider[];
  isLoading: boolean;
  locationForAnalytics?: string;
  headers: ResponseHeaders;
};

export const SnackCardContainer = ({
  coverageType,
  snackCardProviders = [],
  dataTestId = '',
  isLoading,
  locationForAnalytics,
  headers,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);

  const featureFlags = useLagoon('feature-flags')();
  const countySearchAlertFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_COUNTY_SEARCH_ALERT
  );
  const { isCountySearch } = useContext(CountySearchContext);
  const { name, stateCode } = getGeoLocationFromStorage();

  const noProvidersFoundData = useLagoon('ui-messaging')();
  const noProvidersFoundDataContent = find(noProvidersFoundData, {
    key: ConstantsLagoon.NO_PROVIDERS_FOUND,
  });
  const getLinkPositionIndex = (linkPosition?: number) => {
    if (linkPosition !== undefined) {
      return linkPosition + 1;
    }
    return linkPosition;
  };

  const linkLocationName = `body:${locationForAnalytics}`;

  return (
    <Flex
      css={flexStyles}
      data-auto-testid={dataTestId}
      data-testid={dataTestId}
    >
      {isLoading ? (
        mobileScreen ? (
          [1, 2, 3].map((item) => (
            <SnackCard isLoading={isLoading} key={item} />
          ))
        ) : (
          [1, 2, 3, 4, 5].map((item) => (
            <SnackCard isLoading={isLoading} key={item} />
          ))
        )
      ) : snackCardProviders.length ? (
        snackCardProviders.map((snackCardProvider, index) => {
          const isOutOfCnty = isOutOfCounty(
            isCountySearch,
            name,
            stateCode,
            snackCardProvider
          );
          return (
            <div
              data-testid={`snack-card-${snackCardProvider?.providerType}`.replace(
                / /g,
                '-'
              )}
              key={snackCardProvider.locationId}
              style={{
                display: 'grid',
                flexDirection: 'column',
                gridTemplateRows: 'max-content',
              }}
            >
              <CardInfo
                cardType="snack-card-container"
                headers={headers}
                indexForAnalytics={getLinkPositionIndex(index)}
                landingPage
                locationForAnalytics={linkLocationName}
                practitioner={snackCardProvider}
              />
              <SnackCard
                coverageType={coverageType}
                distance={snackCardProvider.distance}
                hasPhysicalAddress={!!snackCardProvider.address}
                headers={headers}
                imgSrc={snackCardProvider.imageLocation}
                index={index}
                isLoading={isLoading}
                isTieredProvider={snackCardProvider?.isTieredProvider}
                isVirtualCareOffered={
                  !!snackCardProvider.virtualCareOffered?.length
                }
                key={snackCardProvider.locationId}
                locationForAnalytics={locationForAnalytics}
                locationId={snackCardProvider.locationId}
                organizationType={snackCardProvider.organizationType}
                professionalDesignation={
                  snackCardProvider.primaryDegrees?.length
                    ? snackCardProvider.primaryDegrees[0]
                    : ''
                }
                providerId={snackCardProvider.providerId}
                providerName={snackCardProvider.providerName}
                providerType={snackCardProvider.providerType}
                rating={
                  snackCardProvider.healthGradeRating
                    ? parseFloat(
                        parseFloat(snackCardProvider.healthGradeRating).toFixed(
                          1
                        )
                      )
                    : undefined
                }
                snackCardProvider={snackCardProvider}
                speciality={snackCardProvider?.speciality}
                website={snackCardProvider?.website || ''}
              />
              {countySearchAlertFlag && (
                <OutsideCountyAlert
                  county={name?.split(',')[0]}
                  isOutOfCounty={isOutOfCnty}
                />
              )}
            </div>
          );
        })
      ) : (
        <Text
          color="#6E7072"
          css={{
            'abyss-text-root': {
              fontWeight: 500,
            },
          }}
          data-testid="no-provider-results-message"
        >
          {noProvidersFoundDataContent?.message ?? ''}
        </Text>
      )}
    </Flex>
  );
};
