import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../hooks/useLagoon';
import { usePrimaryCare } from '../../../hooks/usePrimaryCare';
import { useSpecialtiesResult } from '../../../hooks/useSpecialties';
import { ProviderDetails } from '../../../models/ProviderDetails';
import { getTokenData } from '../../../utils/common';
import { getProviderPCPId } from '../../../utils/providerDetails.utils';
import {
  getCurrentMember,
  getCurrentMemberPCPInfo,
} from '../../../utils/user.utils';
import { Constants, ReverseCoverageTypesCodes } from '../../Constants';
import { FeatureFlags } from '../../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { ContentWrapper } from '../../ContentWrapper';
import { EAPCode } from '../../EAPCode';
import { getFeatureFlag } from '../../Utils';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  convertSpecialtiesList,
  getSearchBlock,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { dateFormatCurrent } from '../../Utils/datesUtils/formatDate';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs.styled';
import { StyledPCPAlert, StyledPCPWrapper } from './About.styled';
import { BiographySection } from './BiographySection';
import { CollapseDetailsSectionRoot } from './CollapseProviderDetailsSection/CollapseDetailsSectionRoot';
import { PatientReviews } from './PatientReviews/PatientReviews';
import { ReferralBannerBox } from './ReferralBannerBox';

type Props = {
  providerDetails: ProviderDetails;
  tabTitle?: string;
  coverageType?: string;
  suppressReviews: boolean;
};

const getFuturePCPDetails = (providerDetails, memberPCP) => {
  let isFuturePCP = false;
  let futurePCPStartDate;
  if (memberPCP?.futurePCP) {
    const { providerID: memberFuturePCPId, startDate } = memberPCP?.futurePCP;
    const providerPCPId = getProviderPCPId(
      providerDetails.providerLocations,
      providerDetails.providerId,
      memberFuturePCPId
    );

    if (memberFuturePCPId === providerPCPId) {
      isFuturePCP = true;
      futurePCPStartDate = dateFormatCurrent(startDate);
    }
  }

  return {
    isFuturePCP,
    futurePCPStartDate,
  };
};

export const About = ({
  providerDetails,
  tabTitle,
  coverageType,
  suppressReviews,
}: Props) => {
  const { t } = useTranslation();
  const providerLocations = providerDetails?.providerLocations;
  const [selectedLocation, setSelectedLocation] = useState(providerLocations);
  const [isPageTracked, setPageTracked] = useState<boolean>(false);
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const showReferralBanner = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.REFERRAL_REQUIRED_BANNER
  );

  const eapCodeFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.EAP_CODE
  );

  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );
  const currentMember = getCurrentMember();
  const { data: pcpData } = usePrimaryCare();

  const selectedLocationValue = selectedLocation
    ? selectedLocation[0]
    : undefined;
  const locationId =
    selectedId !== undefined ? selectedId : providerLocations[0].locationId;

  let currentLocation;
  providerLocations?.forEach((location) => {
    if (location.locationId === locationId) {
      currentLocation = location;
    }
  });

  const tier =
    (showTierProviderTag && currentLocation?.isTieredProvider) ||
    (showTierProviderTag && providerLocations[0]?.isTieredProvider)
      ? 'tier 1'
      : '';
  const { hasPCPServiceAvailability } = currentLocation || providerLocations[0];

  let providerRole = 'specialist';
  if (hasPCPServiceAvailability) {
    providerRole += '|pcp';
  }

  useEffect(() => {
    setSelectedLocation(providerLocations);
  }, []);

  const tokenData = getTokenData();
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const searchTokenData = tokenizer.parse(token) || {};
  const { specialtySearch, searchByProvider } = searchTokenData;
  const isBHCare = tokenData?.coverageType?.includes(
    ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerDetails.providerType),
      indicator: '',
    },
    providerBlock: {
      type: convertCoverageType(coverageType),
      specialty:
        convertSpecialtiesList(
          providerDetails?.defaultTranslatedValues?.specialties
        ) || '',
      tier,
      role: providerRole || '',
    },
    searchBlock: getSearchBlock(tokenData),
  });

  useEffect(() => {
    if (providerDetails.providerName) adobePageTrackEvent();
    setPageTracked(true);
  }, [providerDetails]);

  const { primaryCare } = pcpData;
  const memberPCP = getCurrentMemberPCPInfo(
    currentMember?.demographics?.dependentSeqNbr,
    primaryCare
  );

  const { isFuturePCP, futurePCPStartDate } = getFuturePCPDetails(
    providerDetails,
    memberPCP
  );

  const userState = selectedLocationValue?.address?.state;
  const { data: referralRequiredSpecialties = [] } = useSpecialtiesResult({
    isReferralRequired: true,
    userState,
  });

  const referralCheck = referralRequiredSpecialties?.find(
    (specialityWithReferral) =>
      specialityWithReferral?.name?.toLowerCase() ===
      specialtySearch?.toString()?.toLowerCase()
  );
  const providerSpecialty = providerDetails?.specialties;

  const result = referralRequiredSpecialties.filter((allSpecialties) =>
    providerSpecialty.find(
      (referralSpecialty) =>
        referralSpecialty?.toLocaleLowerCase() ===
        allSpecialties?.name?.toLocaleLowerCase()
    )
  );

  const isProviderSpeciality = (searchResults: string | undefined) =>
    searchResults && searchResults.length > 0;
  const searchBySpecialtyOrProvider = searchByProvider
    ? isProviderSpeciality(result)
    : referralCheck;
  return (
    <div style={{ overflow: 'auto hidden', marginTop: '8px' }}>
      {isFuturePCP ? (
        <StyledPCPWrapper>
          <ContentWrapper>
            <StyledPCPAlert
              title={`${t('PROVIDER_DETAILS.FUTURE_PCP')}${futurePCPStartDate}`}
              variant="info"
            />
          </ContentWrapper>
        </StyledPCPWrapper>
      ) : null}
      {!!currentMember?.isGatedUser &&
        searchBySpecialtyOrProvider &&
        showReferralBanner && (
          <ReferralBannerBox
            data-auto-testid="referral-banner-box"
            data-testid="referral-banner-box"
            pcpData={pcpData?.primaryCare}
            specialtyWithReferral={result}
          />
        )}
      {isBHCare && eapCodeFlag && isPageTracked && (
        <ContainerForAllSectionsStyled>
          <ContentWrapper>
            <EAPCode />
          </ContentWrapper>
        </ContainerForAllSectionsStyled>
      )}
      <BiographySection
        content={providerDetails?.biographicData}
        data-auto-testid="about-tab-biography-section"
        data-testid="about-tab-biography-section"
        ethnicity={providerDetails?.ethnicity}
        gender={providerDetails?.gender}
        heading="BIOGRAPHY_SECTION.HEADING"
        language={providerDetails?.languagesSpoken}
      />
      {!suppressReviews && (
        <PatientReviews
          providerDetails={providerDetails}
          selectedLocation={selectedLocationValue}
        />
      )}
      <CollapseDetailsSectionRoot
        areaOfExpertise={providerDetails?.areaOfExpertise}
        culturalCompetencies={providerDetails?.culturalCompetencies}
        educationInfo={providerDetails?.educationInfo}
        hospitalAffiliations={providerDetails?.hospitalAffiliations}
        licenseNumber={providerDetails?.licenseNumber}
        npi={providerDetails?.npi}
        specialties={providerDetails?.specialties}
        userState={userState}
      />
    </div>
  );
};
