import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { capitalizeEachWord } from '../../../../utils/providerNames.utils';

type Props = {
  culturalCompetencies?: string[];
  mobileScreen: boolean;
};
export const ListCulturalCompetencies = ({
  culturalCompetencies = [],
  mobileScreen,
}: Props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <b>
        {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.CULTURAL_COMPETENCE')}
      </b>
      <Layout.Stack alignItems="left" space={2}>
        {culturalCompetencies?.length > 0 ? (
          culturalCompetencies?.map(
            (competence) => `${capitalizeEachWord(competence)}`
          )
        ) : (
          <Text
            color="#4B4D4F"
            fontWeight="$medium"
            size={mobileScreen ? '$md' : '18px'}
          >
            {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE')}
          </Text>
        )}
      </Layout.Stack>
    </React.Fragment>
  );
};
