import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { NULL_ATTRIBUTE_STATE } from '../frontends/ProviderSearch/routes/MapView/CompareDrawer/utility/compareDrawerConstants';

export const getFormattedAndValidatedPhoneNumber = (
  number: string | null | undefined
): string => {
  if (
    typeof number !== 'string' ||
    isNil(number) ||
    isEmpty(number) ||
    number === ''
  ) {
    return NULL_ATTRIBUTE_STATE.PHONE_NUMBER;
  }

  try {
    const phoneRegex =
      /^\(?[-+ ]?[-1 ]?[-() ]?([0-9]{3})\)?\s*[-. ]?([0-9]{3})\s*[-. ]?([0-9]{4})$/;
    if (phoneRegex.test(number)) {
      return number.replace(phoneRegex, '($1) $2-$3');
    }
  } catch (error) {
    // TODO --> handle parse or format error
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return number;
};

export const getFormattedPhoneNumber = (phoneNumber: String) => {
  if (!phoneNumber || phoneNumber.length <= 3) {
    return phoneNumber;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(4)}`;
};

function removeDuplicatePhones(filteredAllPhones, appointmentPhone) {
  if (
    filteredAllPhones.length > 1 &&
    filteredAllPhones[0] !== appointmentPhone[0]
  ) {
    return filteredAllPhones[0];
  }

  return filteredAllPhones[1];
}

export const getAdditionalPhone = (additionalPhone, appointmentPhone) => {
  if (additionalPhone && appointmentPhone) {
    const allPhones = [...additionalPhone, ...appointmentPhone];
    const filteredAllPhones = allPhones.filter(
      (item, index) => allPhones.indexOf(item) === index
    );
    return removeDuplicatePhones(filteredAllPhones, appointmentPhone);
  }
  if (appointmentPhone?.length > 1 && !additionalPhone) {
    const filteredPhones = appointmentPhone.filter(
      (item, index) => appointmentPhone.indexOf(item) === index
    );
    return filteredPhones[1];
  }

  if (additionalPhone?.length > 1 && !appointmentPhone) {
    const filteredPhones = additionalPhone.filter(
      (item, index) => additionalPhone.indexOf(item) === index
    );
    return filteredPhones[1];
  }
  return null;
};

export const getPrimaryPhone = (phone, appointmentPhone) => {
  if (appointmentPhone) {
    return appointmentPhone[0];
  }
  if (phone) {
    return phone[0];
  }
  return null;
};
