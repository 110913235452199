import { Text } from '@abyss/web/ui/Text';
import React from 'react';

import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { Directions } from '../../models/RouteDirections';
import { Constants } from '../Constants';
import {
  ArrowContainer,
  DetailsContainer,
  DistanceContainer,
  DistanceContainerWidget,
  DurationDistanceWrapper,
  DuratonContainer,
  HorizontalDivider,
  RouteDetailsContainer,
  RouteInstructionsContainer,
  RoutesContainer,
  RoutesTab,
  RoutesTabContainer,
  SeeDirectionsContainer,
  StepContainer,
  StepDataContainer,
  StepIconContainer,
  StepsContainter,
  StepsWrapper,
  TripDurationContainer,
  TripLocationContainer,
  TripLocationDataContainer,
  VerticalDivider,
} from './MapDisplay.styled';
import { NavigationButtonsRow } from './Navigation/NavigationButtonsRow';

type Props = {
  currentRoute: number;
  getDistanceString(distance: number | undefined): string;
  getDurationString(
    duration: number | undefined,
    isSecondRequired?: boolean
  ): string;
  getNavTypeString(navType: string): string;
  getStepDistanceString(distance: number): string;
  getStepIcon: any;
  handleCloseNav(): void;
  latitude: string;
  loadRoute: any;
  longitude: string;
  map: any;
  mapContainerRef: any;
  mapContainerTestId: string;
  mapDirections: Directions;
  mobileScreen: boolean;
  navType: string;
  routeEndCoords: [number | null, number | null];
  routesContainerRef?: React.MutableRefObject<HTMLElement | undefined>;
  setCurrentRoute(index: number): void;
  setNavType(navType: string): void;
  setShowStepInstructions(show: boolean): void;
  showStepInstructions: boolean;
};

export const MapDirections = ({
  currentRoute,
  getDistanceString,
  getDurationString,
  getNavTypeString,
  getStepDistanceString,
  getStepIcon,
  handleCloseNav,
  latitude,
  loadRoute,
  longitude,
  map,
  mapContainerRef,
  mapContainerTestId,
  mapDirections,
  mobileScreen,
  navType,
  routeEndCoords,
  routesContainerRef,
  setCurrentRoute,
  setNavType,
  setShowStepInstructions,
  showStepInstructions,
}: Props) => {
  const {
    DISPLAY_DIRECTIONS: {
      SEE_DIRECTIONS,
      ROUTES,
      STEPS,
      START_LOCATION,
      DESTINATION,
      GET_DIRECTIONS,
    },
  } = Constants;
  const isWidget = useStore(StoreKeys.IS_WIDGET);

  return (
    <React.Fragment>
      <NavigationButtonsRow
        handleCloseNav={handleCloseNav}
        locationForAnalytics={mapContainerTestId}
        navType={navType}
        setNavType={setNavType}
      />
      {!isWidget ? (
        <React.Fragment>
          <TripLocationContainer>
            <TripLocationDataContainer>
              <Text
                css={{
                  color: '$gray8',
                  fontSize: '$smallText',
                  fontWeight: '$bold',
                  lineHeight: '$sm',
                  '@screen < $sm': {
                    fontWeight: '$semibold',
                  },
                }}
              >
                A. {START_LOCATION}
              </Text>
              <Text
                css={{
                  color: '$gray8',
                  fontSize: '$md',
                  fontWeight: '$medium',
                  lineHeight: '$md',
                  textWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {mapDirections.userPlaceName}
              </Text>
            </TripLocationDataContainer>
            <TripLocationDataContainer>
              <Text
                css={{
                  color: '$gray8',
                  fontSize: '$smallText',
                  fontWeight: '$bold',
                  lineHeight: '$sm',
                  '@screen < $sm': {
                    fontWeight: '$semibold',
                  },
                }}
              >
                B. {DESTINATION}
              </Text>
              <Text
                css={{
                  color: '$gray8',
                  fontSize: '$md',
                  fontWeight: '$medium',
                  lineHeight: '$md',
                  textWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {mapDirections.endPlaceName}
              </Text>
            </TripLocationDataContainer>
          </TripLocationContainer>
          <HorizontalDivider />
        </React.Fragment>
      ) : null}
      <TripDurationContainer>
        <DurationDistanceWrapper>
          <DuratonContainer>
            {getDurationString(mapDirections.routes[currentRoute]?.duration)}{' '}
            {getNavTypeString(navType)}
          </DuratonContainer>
          {!isWidget ? (
            <DistanceContainer>
              ({getDistanceString(mapDirections.routes[currentRoute]?.distance)}
              )
            </DistanceContainer>
          ) : (
            <DistanceContainerWidget>
              ({getDistanceString(mapDirections.routes[currentRoute]?.distance)}
              )
            </DistanceContainerWidget>
          )}
        </DurationDistanceWrapper>

        <SeeDirectionsContainer
          aria-expanded={showStepInstructions}
          data-auto-testid="see-directions"
          data-testid="see-directions"
          onClick={() => {
            setShowStepInstructions(!showStepInstructions);
          }}
          role="button"
          tabIndex="0"
        >
          <Text
            css={{
              fontWeight: showStepInstructions ? '$bold' : '$normal',
              fontSize: showStepInstructions ? '12.64px' : '$sm',
              lineHeight: showStepInstructions ? '16px' : '18px',
              textWrap: 'nowrap',
              color: '$interactive1',
            }}
          >
            {isWidget ? GET_DIRECTIONS : SEE_DIRECTIONS}
          </Text>
          <ArrowContainer>
            {showStepInstructions ? (
              <svg
                fill="none"
                height="18"
                viewBox="0 0 18 18"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M9 6L4.5 10.5L5.5575 11.5575L9 8.1225L12.4425 11.5575L13.5 10.5L9 6Z"
                  fill="#196ECF"
                  fillRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                fill="none"
                height="18"
                viewBox="0 0 18 18"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M12.4425 6.44238L9 9.87738L5.5575 6.44238L4.5 7.49988L9 11.9999L13.5 7.49988L12.4425 6.44238Z"
                  fill="#196ECF"
                  fillRule="evenodd"
                />
              </svg>
            )}
          </ArrowContainer>
        </SeeDirectionsContainer>
      </TripDurationContainer>
      <RouteInstructionsContainer
        css={{ display: showStepInstructions ? 'block' : 'none' }}
      >
        <HorizontalDivider />

        <RouteDetailsContainer>
          <DetailsContainer>
            <Text
              css={{
                display: 'block',
                fontSize: '$md',
                color: '$gray8',
                fontWeight: '$bold',
                lineHeight: '$md',
              }}
            >
              {getDurationString(mapDirections.routes[currentRoute]?.duration)}
            </Text>
            <Text
              css={{
                color: '$gray8',
                marginTop: '$xs',
                display: 'block',
                fontSize: '$md',
                fontWeight: '$medium',
                lineHeight: '$md',
              }}
            >
              {getDistanceString(mapDirections?.routes[currentRoute]?.distance)}
            </Text>
          </DetailsContainer>
        </RouteDetailsContainer>

        <RoutesContainer ref={routesContainerRef} tabIndex="-1">
          <Text
            css={{
              color: '$gray8',
              fontSize: '$md',
              fontWeight: '$bold',
              lineHeight: '$md',
            }}
          >
            {ROUTES}
          </Text>
          <RoutesTabContainer>
            {mapDirections?.routes?.map((_, index: number) => (
              <React.Fragment>
                <RoutesTab
                  aria-pressed={currentRoute === index}
                  css={{
                    background: index === currentRoute ? '$info1' : 'none',
                    color: index === currentRoute ? '$white' : '$gray6',
                  }}
                  data-auto-testid={`routes-tab-${index}`}
                  data-testid={`routes-tab-${index}`}
                  key={`routes-tab-${index}`}
                  onClick={() => {
                    setCurrentRoute(index);
                    loadRoute(
                      map.current,
                      mapDirections?.routes,
                      index,
                      longitude,
                      latitude,
                      routeEndCoords[0],
                      routeEndCoords[1]
                    );
                  }}
                >
                  {index + 1}
                </RoutesTab>
                {index !== mapDirections.routes.length - 1 && (
                  <VerticalDivider />
                )}
              </React.Fragment>
            ))}
          </RoutesTabContainer>
        </RoutesContainer>
        <StepsContainter
          cssProps={{
            mapContainerHeight: mapContainerRef?.current?.clientHeight,
          }}
          tabIndex="0"
        >
          <Text
            css={{
              display: 'block',
              padding: '$md 0',
              fontWeight: '$bold',
              lineHeight: '$md',
              color: '$gray8',
              fontSize: '$md',
            }}
          >
            {STEPS}
          </Text>
          <StepsWrapper>
            {!mobileScreen && (
              <StepContainer>
                <StepIconContainer>A</StepIconContainer>
                <StepDataContainer>
                  <Text
                    css={{
                      color: '$gray8',
                      display: 'block',
                      fontSize: '$smallText',
                      lineHeight: '$md',
                      fontWeight: '$bold',
                    }}
                  >
                    {START_LOCATION}
                  </Text>
                  <Text
                    css={{
                      color: '$neutralGray5',
                      display: 'block',
                      fontSize: '$smallText',
                      lineHeight: '$md',
                      fontWeight: '$medium',
                    }}
                  >
                    {mapDirections.userPlaceName}
                  </Text>
                </StepDataContainer>
              </StepContainer>
            )}

            {mapDirections.routes?.[currentRoute]?.steps?.map(
              (
                { distance, maneuver: { modifier, instruction, type } },
                index
              ) => (
                <StepContainer key={`step-container-${index}`}>
                  <StepIconContainer>
                    <img
                      alt=""
                      aria-hidden="true"
                      src={`data:image/svg+xml,${encodeURIComponent(
                        getStepIcon(modifier, type)
                      )}`}
                    />
                  </StepIconContainer>
                  <StepDataContainer>
                    <Text
                      css={{
                        color:
                          (mobileScreen && index === 0) ||
                          index ===
                            (mapDirections?.routes?.[currentRoute]?.steps
                              ?.length as number) -
                              1
                            ? '$gray8'
                            : '$gray7',
                        display: 'block',
                        fontSize: '$smallText',
                        lineHeight: '$md',
                        fontWeight:
                          (mobileScreen && index === 0) ||
                          index ===
                            (mapDirections?.routes?.[currentRoute]?.steps
                              ?.length as number) -
                              1
                            ? '$bold'
                            : '$semibold',
                      }}
                    >
                      {instruction}
                    </Text>
                    <Text
                      css={{
                        color: '$neutralGray5',
                        display: 'block',
                        fontSize: '$smallText',
                        lineHeight: '$md',
                        fontWeight: '$medium',
                      }}
                    >
                      {getStepDistanceString(distance)}
                    </Text>
                  </StepDataContainer>
                </StepContainer>
              )
            )}

            {!mobileScreen && (
              <StepContainer>
                <StepIconContainer>B</StepIconContainer>
                <StepDataContainer>
                  <Text
                    css={{
                      color: '$gray8',
                      display: 'block',
                      fontSize: '$smallText',
                      lineHeight: '$md',
                      fontWeight: '$bold',
                    }}
                  >
                    {DESTINATION}
                  </Text>
                  <Text
                    css={{
                      color: '$neutralGray5',
                      display: 'block',
                      fontSize: '$smallText',
                      lineHeight: '$md',
                      fontWeight: '$medium',
                    }}
                  >
                    {mapDirections.endPlaceName}
                  </Text>
                </StepDataContainer>
              </StepContainer>
            )}
          </StepsWrapper>
        </StepsContainter>
      </RouteInstructionsContainer>
    </React.Fragment>
  );
};
