import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Button } from '@abyss/web/ui/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../../../hooks/useLagoon';
import {
  getCurrentMemberPCPInfo,
  getLoggedInMember,
} from '../../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../Constants';
import { ConstantsRoutes } from '../../../ConstantsRoutes';
import { landingDrawerIcon, phoneOnly } from '../../../ConstantsStyles';
import { ContentWrapper } from '../../../ContentWrapper';
import { handleLinkAndModalTrack } from '../../../Utils/adobeTrackUtils';
import { ContainerForAllSectionsStyled } from '../../ProviderDetailTabs.styled';
import {
  ArrowIcon,
  ContactMyPCP,
  PopOverStyle,
  ReferralBanner,
  ReferralBannerBoxBanner,
  ReferralBannerContactPCP,
  ReferralBannerReferralRequired,
  ReferralBannerSpecialtyContainer,
  ReferralBannerTextContainer,
  ReferralRequiredIcon,
} from './ReferralBannerBox.style';

const POPOVER_CONTENT_KEY = 'REFERRAL';
const REFERRAL_LAGOON_PATH = 'referral-required';

type CardGroup = {
  description?: string;
  name?: string;
  dspecialtyCode: string;
  codes?: string;
};

type Props = {
  specialtyWithReferral?: CardGroup[];
  pcpData;
};

export const ReferralBannerBox = ({
  pcpData,
  specialtyWithReferral,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const referralInfoLagoon =
    useLagoon(REFERRAL_LAGOON_PATH)(POPOVER_CONTENT_KEY) ?? {};
  const referralInfoLagoonText = referralInfoLagoon['1'] ?? '';
  const { t } = useTranslation();
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const referralBannerContactPcpTestId = 'referral-banner-contact-pcp';
  const referralRequiredPopOverTestId = 'referral-required-popover';
  const referralRequiredDrawerTestId = 'referral-required-drawer';

  const loggedInMember = getLoggedInMember();
  const loggedInMemberDepSeqNbr = loggedInMember?.demographics?.dependentSeqNbr;

  const handleContactPcpOnClick = () => {
    const loggedInMemberPCPInfo = getCurrentMemberPCPInfo(
      loggedInMemberDepSeqNbr,
      pcpData
    );

    adobeLinkTrackEvent({
      name: referralBannerContactPcpTestId,
      location: `body:${Constants.PROVIDER_DETAILS.BANNER.REFERRAL_REQUIRED}`,
    });

    if (loggedInMemberPCPInfo) {
      const { providerID } = loggedInMemberPCPInfo;
      const updatedToken = tokenizer.update(token, {
        providerId: providerID,
        specialtySearch: 'myPCP',
        searchByProvider: false,
        coverageType: 'M',
      });
      navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${updatedToken}`);
    } else {
      const updatedToken = tokenizer.update(token, {
        choosePCP: true,
        dependentSeqNbr: loggedInMemberDepSeqNbr,
        pcpIndicator: true,
        providerType: '',
        searchType: Constants.SEARCH_TYPES.LOCATION,
        acceptingNewPatients: true,
        originLinkNameForAnalytics: referralBannerContactPcpTestId,
        searchByProvider: false,
        coverageType: 'M',
      });
      navigate(`/results/${updatedToken}`);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const linkName: string = referralRequiredPopOverTestId;
      const linkLocation: string = `body:${Constants.PROVIDER_DETAILS.BANNER.REFERRAL_REQUIRED}`;
      const modalName: string =
        Constants.PROVIDER_DETAILS.BANNER.REFERRAL_REQUIRED;
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpen]);

  const listOfSpecialtyWithReferral = specialtyWithReferral?.map(
    (specialty) => (
      <li
        data-testid="referral-banner-provider-specialities"
        key={`referral-banner-specialty-${specialty.name}`}
        style={{ marginRight: '28px' }}
      >
        {specialty.name}
      </li>
    )
  );

  return (
    <ContainerForAllSectionsStyled>
      <ContentWrapper>
        <ReferralBannerBoxBanner>
          <ReferralBanner
            data-auto-testid="referral-banner"
            data-testid="referral-banner"
          >
            <ReferralRequiredIcon>
              <svg
                aria-labelledby="title"
                fill="none"
                focusable="false"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title id="title">Be aware</title>
                <path
                  d="M13.2512 24C9.9912 24 7.0612 22.01 5.8512 18.98L2.8212 11.37C2.5112 10.58 3.2512 9.79 4.0612 10.05L4.8512 10.31C5.4112 10.49 5.8712 10.92 6.0912 11.47L7.5012 15H8.2512V3.25C8.2512 2.56 8.8112 2 9.5012 2C10.1912 2 10.7512 2.56 10.7512 3.25V12H11.7512V1.25C11.7512 0.56 12.3112 0 13.0012 0C13.6912 0 14.2512 0.56 14.2512 1.25V12H15.2512V2.75C15.2512 2.06 15.8112 1.5 16.5012 1.5C17.1912 1.5 17.7512 2.06 17.7512 2.75V12H18.7512V5.75C18.7512 5.06 19.3112 4.5 20.0012 4.5C20.6912 4.5 21.2512 5.06 21.2512 5.75V16C21.2512 20.42 17.6712 24 13.2512 24Z"
                  fill="#002677"
                />
              </svg>
            </ReferralRequiredIcon>
            <Flex>
              <ReferralBannerReferralRequired
                data-auto-testid="referral-required"
                data-testid="referral-required"
              >
                <ReferralBannerTextContainer id="referralText">
                  {t('REFERRAL_REQUIRED_BANNER')}
                </ReferralBannerTextContainer>
                <Drawer
                  css={landingDrawerIcon}
                  isOpen={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                  position="bottom"
                  size="200"
                  title={t('PROVIDER_DETAILS.BANNER.POPOVER_TITLE')}
                >
                  {referralInfoLagoonText}
                </Drawer>
                {mobileScreen ? (
                  <Button
                    aria-label={`${t('INFORMATION_ABOUT')} ${t(
                      'Referral required'
                    )}`}
                    data-auto-testid={referralRequiredDrawerTestId}
                    data-testid={referralRequiredDrawerTestId}
                    onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                    rounded
                    size="$xs"
                    variant="ghost"
                  >
                    <IconMaterial
                      css={{
                        'abyss-icon-material': {
                          '@screen < $md': {
                            margin: '2px 0 0 4px ',
                          },
                        },
                      }}
                      icon="info"
                      size={16}
                      variant="outlined"
                    />
                  </Button>
                ) : (
                  <Popover
                    aria-label={`${t('INFORMATION_ABOUT')} ${t(
                      'Referral required'
                    )}`}
                    content={referralInfoLagoonText}
                    css={PopOverStyle}
                    data-auto-testid={referralRequiredPopOverTestId}
                    data-testid={referralRequiredPopOverTestId}
                    onOpenChange={() => setIsOpen(!isOpen)}
                    open={isOpen}
                    title={t('PROVIDER_DETAILS.BANNER.POPOVER_TITLE')}
                  />
                )}
              </ReferralBannerReferralRequired>
              <ReferralBannerSpecialtyContainer id="specialtyList">
                <ul
                  data-testid="single-referral-banner"
                  style={{
                    listStyleType: 'disc',
                    display: 'flex',
                    flexWrap: 'wrap',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {listOfSpecialtyWithReferral}
                </ul>
              </ReferralBannerSpecialtyContainer>
            </Flex>
            {!mobileScreen ? (
              <React.Fragment>
                <Divider
                  color="#BCD4F2"
                  css={{ marginLeft: '16px' }}
                  margin={0}
                  orientation="vertical"
                  width={1}
                />
                <ReferralBannerContactPCP
                  aria-describedby="referralText specialtyList"
                  data-auto-testid={referralBannerContactPcpTestId}
                  data-testid={referralBannerContactPcpTestId}
                  onClick={handleContactPcpOnClick}
                >
                  <ContactMyPCP>
                    {' '}
                    {t('PROVIDER_DETAILS.BANNER.CONTACT_MY_PCP')}
                  </ContactMyPCP>
                  <ArrowIcon>
                    <IconMaterial icon="chevron_right" size={24} />
                  </ArrowIcon>
                </ReferralBannerContactPCP>
              </React.Fragment>
            ) : null}
          </ReferralBanner>
          {mobileScreen ? (
            <React.Fragment>
              <Divider color="#BCD4F2" height={1} orientation="horizontal" />
              <ReferralBannerContactPCP
                aria-describedby="referralText specialtyList"
                data-auto-testid={referralBannerContactPcpTestId}
                data-testid={referralBannerContactPcpTestId}
                onClick={handleContactPcpOnClick}
              >
                <ContactMyPCP>
                  {' '}
                  {t('PROVIDER_DETAILS.BANNER.CONTACT_MY_PCP')}
                </ContactMyPCP>
                <ArrowIcon>
                  <IconMaterial icon="chevron_right" size={18} />
                </ArrowIcon>
              </ReferralBannerContactPCP>
            </React.Fragment>
          ) : null}

          {/* Uncomment once chat is back in scope
          <ReferralBannerNeedHelp>
            <IconBrand css={{ marginRight: '4px' }} icon="chat" size="18px" />

            {t('PROVIDER_DETAILS.BANNER.NEED_HELP')}
          </ReferralBannerNeedHelp> */}
        </ReferralBannerBoxBanner>
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
