/* eslint-disable @typescript-eslint/naming-convention */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { styled } from '@abyss/web/tools/styled';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Flex } from '@abyss/web/ui/Flex';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { getOnlineRetailers } from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { CardHeader } from '../CardHeader/CardHeader';
import { Chip } from '../Chip';
import { Constants } from '../Constants';
import { FeatureFlags } from '../ConstantsFeatureFlags';
import { cardHeaderNullPageStyle } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import { mappingRollUpCodeToCategory } from '../RecentActivity/helper';
import { getFeatureFlag } from '../Utils';
import { replaceSpaceToUpperCase } from '../Utils/replaceString';
import { getTokenData } from '../Utils/tokenUtils/tokenUtils';
import { CommonlySearchedDental } from './CommonlySearchedDental';

export const ChipContainer = styled(Flex, {
  gap: '8px',
  '@screen < $md': {
    gap: '12px',
  },
});

export interface CommonSearch {
  id: string;
  category?: string;
  icon: string;
  key: string;
  score?: string;
  psxKeyword: string;
  searchType: string;
  dentalTerm: string;
  visionTerm: string;
  bhTerm: string;
  path?: string;
  embeddedUrl?: string;
  specialitySet?: string;
  DNT_dentalTerm?: string;
  DNT_visionTerm?: string;
}

export type Props = {
  selectedChipValue?: string;
  onCommonSearchChipClicked?: () => void;
};

const { ALL, BEHAVIORAL_HEALTH, DENTAL, VISION } = Constants.CHIPS_CATEGORIES;

const createVision = (
  visionTerm: string,
  path: string,
  lat: string,
  long: string,
  baseUrl: string,
  currentZipcode: string
): URL => {
  let visionUrl: URL;
  if (path.includes('rp_zipcode')) {
    visionUrl = new URL(
      baseUrl + path?.replace('rp_zipcode', currentZipcode || '')
    );
    visionUrl.searchParams.set('lat', lat);
    visionUrl.searchParams.set('long', long);
  } else {
    visionUrl = new URL(baseUrl + path);
  }

  return visionUrl;
};

export const CommonlySearchedCard = ({
  selectedChipValue = ALL,
  onCommonSearchChipClicked,
}: Props) => {
  const { t } = useTranslation();

  const { longitude, latitude } = getGeoLocationFromStorage();

  const { ZIPCODE } = Constants.STORAGE_KEYS.LOCAL;
  const currentZipcode = storage.local.get(ZIPCODE);

  const baseUrlLagoon = useLagoon(
    Constants.LAGOON_TABLE.VISION_AND_DENTAL_REDIRECTION_BASEURL
  )();
  const baseUrl = baseUrlLagoon?.[0]?.baseUrl;

  const lagoonTable = () => {
    switch (selectedChipValue) {
      case BEHAVIORAL_HEALTH:
        return Constants.LAGOON_TABLE.COMMONLY_SEARCHED.BH_NULL_PAGE;
      case VISION:
        return Constants.LAGOON_TABLE.COMMONLY_SEARCHED.VISION_NULL_PAGE;
      case DENTAL:
        return Constants.LAGOON_TABLE.COMMONLY_SEARCHED.DENTAL_NULL_PAGE;
      default:
        return Constants.LAGOON_TABLE.COMMONLY_SEARCHED[selectedChipValue];
    }
  };
  const commonSearchesData: CommonSearch[] =
    lagoonTable() && useLagoon(lagoonTable())();
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = getTokenData(token);
  const { keyword = '' } = tokenData;

  const { COMMONLY_SEARCHED = '' } =
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[selectedChipValue];
  const featureFlags = useLagoon('feature-flags')();
  const showCommonlySearched = getFeatureFlag(featureFlags, COMMONLY_SEARCHED);
  const getUpdatedToken = (item) => {
    adobeLinkTrackEvent({
      name: item?.psxKeyword,
      location: 'body:browse for care',
      type: 'internal',
    });

    if ([item?.psxKeyword, item?.searchTerm].includes(keyword)) {
      return;
    }

    const updatedToken = tokenizer.update(token, {
      search: item?.psxKeyword,
      category: item?.category,
      searchType: item?.searchType?.toLowerCase(),
      pesKeyword: item?.pesKeyword,
      choosePCP: false,
      chipValue: mappingRollUpCodeToCategory(item?.pesKeyword),
      originLinkNameForAnalytics: item?.psxKeyword,
      resultType: Constants.RESULT_SECTION.ALL,
      coverageType: item?.coverageType,
      keyword: item?.psxKeyword || item?.searchTerm,
      onlineRetailers: getOnlineRetailers(item?.isOnlineProviders),
      includeSpecialityRollupCodes: item?.pesKeyword,
    });
    onCommonSearchChipClicked?.();
    navigate(`/results/${updatedToken}`);
  };
  const getBrowseForCare = (chipValue) => {
    switch (chipValue) {
      case ALL:
        return (
          <ChipContainer>
            {commonSearchesData?.map((item) => {
              const { psxKeyword, id, icon } = item;
              return (
                <Chip
                  data-auto-testid={`commonly-searched-${replaceSpaceToUpperCase(
                    psxKeyword
                  )}`}
                  data-testid={`commonly-searched-${replaceSpaceToUpperCase(
                    psxKeyword
                  )}`}
                  icon={icon}
                  key={`common-search-${id}`}
                  onClick={() => getUpdatedToken(item)}
                  text={psxKeyword}
                />
              );
            })}
          </ChipContainer>
        );
      case DENTAL:
        return (
          <ChipContainer>
            {commonSearchesData?.map((item) => {
              let { path } = item;
              const { dentalTerm, id, icon, DNT_dentalTerm, psxKeyword } = item;
              path = path?.replace('rp_zipcode', currentZipcode || '');
              const url = new URL(`${baseUrl}${path}`);
              url.searchParams.set('long', longitude);
              url.searchParams.set('lat', latitude);
              return (
                <CommonlySearchedDental
                  DNT_dentalTerm={DNT_dentalTerm}
                  dentalTerm={dentalTerm}
                  icon={icon}
                  id={id}
                  item={item}
                  key={`common-dental-${id}`}
                  psxKeyword={psxKeyword}
                  updatedToken={() => getUpdatedToken(item)}
                  url={url.toString()}
                />
              );
            })}
          </ChipContainer>
        );
      case VISION:
        return (
          <ChipContainer>
            {commonSearchesData?.map((item) => {
              const { visionTerm, id, icon, DNT_visionTerm, psxKeyword } = item;
              const { path = '' } = item;
              const visionUrl: URL = createVision(
                visionTerm,
                path,
                latitude,
                longitude,
                baseUrl,
                currentZipcode
              );
              return visionTerm ? (
                <Chip
                  adobeChipName={DNT_visionTerm}
                  data-auto-testid={`vision-searched-${replaceSpaceToUpperCase(
                    visionTerm
                  )}`}
                  data-testid={`vision-searched-${replaceSpaceToUpperCase(
                    visionTerm
                  )}`}
                  icon={icon}
                  isStandardAnchor={false}
                  key={`vision-search-${id}`}
                  text={visionTerm}
                  url={visionUrl.toString()}
                />
              ) : (
                <Chip
                  adobeChipName={DNT_visionTerm}
                  data-auto-testid={`vision-searched-${replaceSpaceToUpperCase(
                    psxKeyword
                  )}`}
                  data-testid={`vision-searched-${replaceSpaceToUpperCase(
                    psxKeyword
                  )}`}
                  icon={icon}
                  key={`vision-search-${id}`}
                  onClick={() => getUpdatedToken(item)}
                  text={psxKeyword}
                />
              );
            })}
          </ChipContainer>
        );

      case BEHAVIORAL_HEALTH:
        return (
          <ChipContainer>
            {commonSearchesData?.map((item) => {
              const { psxKeyword, id, icon } = item;
              return (
                <Chip
                  data-auto-testid={`bh-searched-${replaceSpaceToUpperCase(
                    psxKeyword
                  )}`}
                  data-testid={`bh-searched-${replaceSpaceToUpperCase(
                    psxKeyword
                  )}`}
                  icon={icon}
                  key={`bh-search-${id}`}
                  onClick={() => getUpdatedToken(item)}
                  text={psxKeyword}
                />
              );
            })}
          </ChipContainer>
        );
      default:
        return null;
    }
  };

  return showCommonlySearched && commonSearchesData ? (
    <ContainerForAllSectionsStyled height="auto">
      <ContentWrapper>
        <CardHeader
          css={cardHeaderNullPageStyle}
          data-auto-testid="commonly-searched-card-heading"
          data-testid="commonly-searched-card-heading"
          offset={3}
        >
          {t('Browse for care')}
        </CardHeader>
        {getBrowseForCare(selectedChipValue)}
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  ) : null;
};
