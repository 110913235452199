import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Flex } from '@abyss/web/ui/Flex';
import { Popover } from '@abyss/web/ui/Popover';
import find from 'lodash/find';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useLagoon } from '../../hooks/useLagoon';
import { useSnackCardProviderResults } from '../../hooks/useSnackCardProviderSearch/useSnackCardProviderSearch';
import { getSnackCardDisplayValByLength } from '../../utils/snackcard.utils';
import { CardHeader } from '../CardHeader/CardHeader';
import { Constants, FilterCollectionModel, ProviderTypes } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import {
  cardHeaderStyle,
  cardHeaderWithNavigatorStyle,
} from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { LinkContainer } from '../LinkContainer/LinkContainer';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { SnackCardCarousel, SnackCardContainer } from '../SnackCardContainer';

export const SuggestedProviderDescription = styled('div', {
  color: '$gray7',
  fontWeight: '$bold',
  fontSize: '$lg',
  lineHeight: '24px',
  paddingBottom: '$md',
  '@screen < $md': {
    fontSize: '$md',
    lineHeight: '20px',
  },
});

export const SuggestedProviders = () => {
  const { t } = useTranslation();
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { searchType } = tokenData;
  const lagoonData = useLagoon('ui-messaging')();
  const suggestedProvidersContent = find(lagoonData, {
    key: ConstantsLagoon.SUGGESTED_PROVIDERS.SUGGESTED_PROVIDERS_MESSAGE,
  });
  const { setDefaultSearchFilters } = useContext(SearchFilterContext);

  const getResultType = (type: string) => {
    let resultType = { sectionType: '', providerType: '', heading: '' };
    if (type === Constants.SEARCH_TYPES.PROVIDER.toLowerCase()) {
      resultType = {
        sectionType: Constants.SEARCH_TYPES.PROVIDER,
        providerType: ProviderTypes.PRACTITIONER,
        heading: t('SUGGESTED_PROVIDERS_CARD.HEADING_SUGGESTED_PROVIDERS'),
      };
    } else if (type === Constants.SEARCH_TYPES.FACILITY.toLowerCase()) {
      resultType = {
        sectionType: Constants.SEARCH_TYPES.FACILITY,
        providerType: ProviderTypes.ORGANIZATION,
        heading: t('SUGGESTED_PROVIDERS_CARD.HEADING_SUGGESTED_FACILITIES'),
      };
    }
    return resultType;
  };

  const { heading, providerType, sectionType } = getResultType(searchType);

  const [shouldGetHGData] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_HEALTH_GRADES_INFO_FOR_PRACTIONER,
  ]);

  const { data, isLoading, headers } = useSnackCardProviderResults({
    providerType,
    pcpIndicator: false,
    shouldGetHGData,
  });
  const { snackCardList, searchRadius } = data;

  const numberOfCards = data?.snackCardList?.length;
  const displayCarousel = getSnackCardDisplayValByLength(numberOfCards);

  const handleViewAllOnClick = () => {
    const updatedToken = tokenizer.update(token, {
      search: '',
      sectionType,
      pcpIndicator: false,
      providerType,
      searchType: Constants.SEARCH_TYPES.LOCATION,
    });
    setDefaultSearchFilters([FilterCollectionModel.AUTO_SEARCH_RADIUS], {
      autoSearchRadius: searchRadius,
    });
    navigate(
      `${ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.path}/${updatedToken}`
    );
  };

  return (
    <ContainerForAllSectionsStyled>
      <ContentWrapper>
        <SuggestedProviderDescription
          data-auto-testid="suggested-provider-card-description"
          data-testid="suggested-provider-card-description"
        >
          {t('SUGGESTED_PROVIDERS_CARD.DESCRIPTION')}
        </SuggestedProviderDescription>
        <Flex alignItems="flex-end" css={cardHeaderStyle} justify="start">
          <CardHeader
            css={cardHeaderWithNavigatorStyle}
            data-auto-testid="suggested-providers"
            data-testid="suggested-providers"
          >
            {heading}
            <Popover
              content={suggestedProvidersContent?.message ?? ''}
              css={{
                'abyss-popover-trigger-icon': {
                  marginLeft: '4px',
                },
              }}
              title={heading}
            />
          </CardHeader>
          {isLoading ? null : (
            <LinkContainer
              aria-label={`${t('View all')} ${t(
                'SUGGESTED_PROVIDERS_CARD.DESCRIPTION'
              )}`}
              data-auto-testid="suggested-providers-cards-view-all"
              data-testid="suggested-providers-cards-view-all"
              onClick={handleViewAllOnClick}
              text={`${t('View all')}`}
            />
          )}
        </Flex>
        {displayCarousel ? (
          <SnackCardCarousel
            headers={headers}
            isFixes={false}
            isLoading={isLoading}
            snackCardProviders={snackCardList?.slice(0, 5)}
          />
        ) : (
          <SnackCardContainer
            headers={headers}
            isLoading={isLoading}
            snackCardProviders={snackCardList?.slice(0, 5)}
          />
        )}
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
