import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Print } from '@abyss/web/ui/Print';
import { format } from 'date-fns';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Constants,
  ProviderSummaryTypes,
} from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { RatingText } from '../../../../../common/DataCard/DataCard.style';
import { getFeatureFlag } from '../../../../../common/Utils';
import { useLagoon } from '../../../../../hooks/useLagoon';
import { returnSuppressFlag } from '../../../../../utils/featureSuppress';
import {
  UserDDPCode,
  getSuppressFacilityFlags,
  isShowPreferredFacilityBadge,
  isUserDDPEligible,
} from '../../../../../utils/providerDetails.utils';
import {
  getNameAttrs,
  parseProviderName,
} from '../../../../../utils/providerNames.utils';
import { getLoggedInMember } from '../../../../../utils/user.utils';
import { PreferredProviderLabels } from './PreferredProviderLabels';
import {
  CostText,
  PrintTierInfoBox,
  PrintViewDivider,
  PrintViewProviderBoldText,
  PrintViewProviderDetailsNameText,
  PrintViewProviderDetailsXSText,
  PrintViewProviderMediumText,
  PrintViewProviderNormalText,
  PrintViewProviderTierOneText,
} from './PrintView.style';
import { PrintPreviewTierOneIcon } from './TierOneIcon';

type Props = {
  selectedProviderList: {
    numberOfReviews?: string;
    acceptingNewPatients: boolean;
    providerName: string;
    providerId: string;
    primaryDegrees: string[];
    speciality: string;
    organizationType: string;
    providerType?: string;
    networkStatus: string;
    distance: string;
    isPreferredLab?: boolean;
    isFreeStandingFacility?: boolean;
    ddpCode?: string;
    healthGradeRating?: string;
    isTieredProvider: boolean;
    virtualCareOffered?: boolean;
    languagesSpoken: string[];
    website: string;
    address: {
      postalCode: string;
      district: string;
      line: string[];
      state: string;
      city: string;
    };
    firstName?: string;
    middleName?: string;
    lastName?: string;
    costIndicator?: string;
  }[];
};

export const getStarsRating = (providerHealthGradeRating: number) => {
  let stars = '';
  const count = Array.from([1, 2, 3, 4, 5]);
  for (let i = 0; i < count.length; i++) {
    if (count[i] <= providerHealthGradeRating) {
      stars += '★';
    } else {
      stars += '☆';
    }
  }
  return stars;
};

export const PrintView = ({ selectedProviderList }: Props) => {
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { chipValue, sectionType, search } = tokenData;

  const { t } = useTranslation();
  const pageHeader = `${t(`${sectionType}-results-for`)} ${search || ''}`;
  const theDate = () => {
    const rawDate = format(new Date(), 'MMMM dd, yyyy');
    const dateSplit = rawDate.split(' ');
    const parsedTranslatedMonth = t(dateSplit[0]);
    return ` ${parsedTranslatedMonth} ${dateSplit[1]} ${dateSplit[2]}`;
  };
  const getCost = (cost: string | undefined) => {
    switch (cost) {
      case '$$$':
        return `$$$ - ${t('COST.ABOVE_AVERAGE')}`;
      case '$$':
        return `$$ - ${t('COST.AVERAGE')}`;
      case '$':
        return `$ - ${t('COST.BELOW_AVERAGE')}`;
      default:
        return '';
    }
  };

  const uiMessaging = useLagoon(Constants.LAGOON_TABLE.UI_MESSAGING)();
  const disclaimerInPage = find(uiMessaging, {
    key: ConstantsLagoon.HEALTHGRADES.DISCLAIMER_IN_PAGE,
  });
  const healthGradesDisclaimer = find(uiMessaging, {
    key: ConstantsLagoon.HEALTHGRADES.PRINT_VIEW_CONTENT,
  });

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const costEnableFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED
  );

  const suppressRatings: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const suppressFacilityFlags = getSuppressFacilityFlags();
  const {
    suppressPreferredFacilityPLN,
    suppressPreferredFacilityDDP,
    suppressPreferredFacilityFSF,
  } = suppressFacilityFlags;
  const preferredFacilityFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PREFERRED_FACILITY_IDENTIFICATION
  );

  const ddpCodeUser = getLoggedInMember()?.ddpCode;

  const getPreferredFacilities = (provider) => {
    const { ddpCode, isFreeStandingFacility, isPreferredLab } = provider || {};
    const showPreferredContent = isShowPreferredFacilityBadge(
      suppressFacilityFlags,
      preferredFacilityFlag,
      ddpCode,
      ddpCodeUser,
      isPreferredLab,
      isFreeStandingFacility
    );

    const showPLN = !suppressPreferredFacilityPLN && !!isPreferredLab;
    const showDDP =
      !suppressPreferredFacilityDDP &&
      !!isUserDDPEligible(ddpCodeUser as UserDDPCode) &&
      !!ddpCode;
    const showFSF = !suppressPreferredFacilityFSF && !!isFreeStandingFacility;

    return { showPreferredContent, showPLN, showDDP, showFSF };
  };

  return (
    <Print.Section
      css={{
        border: 'none',
        padding: '30px 30px 30px 30px',
        fontFamily: '$primary',
      }}
    >
      <Flex alignContent="flex-start" direction="column">
        <Flex justify="space-between">
          <Brandmark
            affiliate="uhc"
            brand="uhc"
            color="blue"
            css={{
              'abyss-brandmark': {
                width: '139px',
                height: '43px',
              },
            }}
            size="$lg"
            variant="lockup"
          />
        </Flex>

        <PrintViewDivider orientation="horizontal" />
        <Flex justify="space-between">
          <Flex>
            <PrintViewProviderMediumText color="#000000">
              {pageHeader}
            </PrintViewProviderMediumText>
          </Flex>
          <Flex>
            <PrintViewProviderBoldText color="#000000">
              {t('SHARE_PDF.Date')}:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="#000000">
              {theDate()}
            </PrintViewProviderNormalText>
          </Flex>
        </Flex>
        <PrintViewDivider orientation="horizontal" />
        {selectedProviderList?.map((provider, index) => {
          const { healthGradeRating = '' } = provider;
          const { showPreferredContent, showPLN, showDDP, showFSF } =
            getPreferredFacilities(provider);

          const nameAttrs = getNameAttrs(provider);
          const parsedHealthGradeRating = parseFloat(
            parseFloat(healthGradeRating).toFixed(1)
          );

          return (
            <React.Fragment key={provider.providerId}>
              {/** ***** First detail row (Number label, Provider Name, Network Status) ****** */}
              <Flex
                css={{
                  'abyss-flex-root': {
                    gap: '4px',
                    width: '100%',
                  },
                }}
              >
                <span
                  style={{
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    background: 'black',
                    fontSize: '14px',
                    lineHeight: '16.71px',
                    border: '2px solid #666',
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: '500',
                  }}
                >
                  {index + 1}
                </span>
                <Flex
                  css={{
                    'abyss-flex-root': {
                      gap: '2px',
                      padding: 0,
                      flexWrap: 'nowrap',
                      width: '93%',
                    },
                  }}
                  direction="column"
                >
                  <Flex justify="space-between">
                    <PrintViewProviderDetailsNameText color="#333333">
                      {parseProviderName(
                        provider.providerName,
                        provider.providerType,
                        provider.primaryDegrees?.[0],
                        nameAttrs
                      )}
                    </PrintViewProviderDetailsNameText>
                    <PrintViewProviderDetailsXSText color="#333333">
                      {provider.networkStatus === 'INN'
                        ? t('SHARE_PDF.In-network')
                        : t('SHARE_PDF.Out-of-network')}
                    </PrintViewProviderDetailsXSText>
                  </Flex>

                  {/** ***** Second detail row (Provider Specialty, Accepting New Patients Status) ****** */}
                  <Flex justify="space-between">
                    <PrintViewProviderDetailsXSText color="#333333">
                      {provider?.speciality || provider?.organizationType}
                    </PrintViewProviderDetailsXSText>
                    {provider?.providerType !==
                      ProviderSummaryTypes.ORGANIZATION && (
                      <PrintViewProviderDetailsXSText color="#333333">
                        {provider?.acceptingNewPatients
                          ? t('SHARE_PDF.Accepting new patients')
                          : t('SHARE_PDF.Not accepting new patients')}
                      </PrintViewProviderDetailsXSText>
                    )}
                    <PreferredProviderLabels
                      providerType={
                        provider?.providerType as ProviderSummaryTypes
                      }
                      showDDP={showDDP}
                      showFSF={showFSF}
                      showPLN={showPLN}
                      showPreferredContent={showPreferredContent}
                    />
                  </Flex>

                  {/** ***** Third detail row (Provider ratings, Offers Virtual Visit Status) ****** */}
                  <Flex justify="space-between">
                    {!suppressRatings &&
                      provider?.providerType ===
                        ProviderSummaryTypes.PRACTITIONER && (
                        <Flex>
                          <Layout.Group space="">
                            {getStarsRating(parsedHealthGradeRating)}
                            {parsedHealthGradeRating ? (
                              <RatingText className="text" size="11px">
                                {provider?.numberOfReviews
                                  ? `${provider?.numberOfReviews} Reviews`
                                  : parsedHealthGradeRating}
                              </RatingText>
                            ) : null}
                          </Layout.Group>
                        </Flex>
                      )}
                    <Flex>
                      <PrintViewProviderDetailsXSText color="#333333">
                        {provider?.virtualCareOffered
                          ? t('SHARE_PDF.Offers virtual visits')
                          : null}
                      </PrintViewProviderDetailsXSText>
                    </Flex>
                  </Flex>

                  {/** ***** Fourth detail row (Tier 1 Provider Status only visible for Dec release) ****** */}
                  <Flex justify="space-between">
                    {provider.isTieredProvider ? (
                      <Flex>
                        <PrintTierInfoBox
                          data-auto-testId="print-preview-tier-one-indicator"
                          data-testId="print-preview-tier-one-indicator"
                        >
                          <PrintPreviewTierOneIcon />
                          <PrintViewProviderTierOneText color="#333333">
                            {t('SHARE_PDF.Tier 1 Provider')}
                          </PrintViewProviderTierOneText>
                        </PrintTierInfoBox>
                      </Flex>
                    ) : null}
                  </Flex>

                  {/** ***** Fifht detail row (Language spoken by Provider) ****** */}
                  <Flex justify="space-between">
                    <Flex>
                      <PrintViewProviderDetailsXSText color="#333333">
                        {provider.languagesSpoken?.join(', ')}
                      </PrintViewProviderDetailsXSText>
                    </Flex>
                  </Flex>

                  {/** ***** Sixth detail row (Provider Address) ****** */}
                  <Flex justify="space-between">
                    <Flex>
                      {provider?.address?.city && provider?.address?.state ? (
                        <PrintViewProviderDetailsXSText color="#333333">
                          {`${provider?.address?.line?.[0]}, ${
                            provider?.address?.city
                          }, ${provider?.address?.state} • ${parseFloat(
                            provider?.distance
                          ).toFixed(1)} ${t('SHARE_PDF.Miles away')}`}
                        </PrintViewProviderDetailsXSText>
                      ) : (
                        <PrintViewProviderDetailsXSText color="#333333">
                          {provider?.website}
                        </PrintViewProviderDetailsXSText>
                      )}
                    </Flex>
                    {costEnableFlag ? (
                      <CostText color="#333333">
                        {getCost(provider.costIndicator)}
                      </CostText>
                    ) : null}
                  </Flex>

                  {/** ***** To be continue... add more detail rows using this template ****** */}
                  {/* <Flex justify="space-between">
                    <Flex>
                      <PrintViewProviderDetailsXSText color="#333333">

                      </PrintViewProviderDetailsXSText>
                    </Flex>
                    <Flex>
                      <PrintViewProviderDetailsXSText color="#333333">

                      </PrintViewProviderDetailsXSText>
                    </Flex>
                  </Flex> */}

                  <PrintViewDivider orientation="horizontal" />
                </Flex>
              </Flex>
            </React.Fragment>
          );
        })}
      </Flex>
      <Flex
        alignContent="center"
        css={{ marginLeft: '25px', display: 'flex', width: '95%' }}
      >
        <Flex
          alignContent="flex-start"
          css={{
            display: 'flex',
            color: '#333',
            fontFamily: '$primary',
            fontSize: '9px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
          }}
          direction="column"
        >
          {/* Lagoon data disclaimer */}
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                chipValue === Constants.CHIPS_CATEGORIES.VISION
                  ? `${disclaimerInPage?.message.replace(/\n/g, '<br/>')}
                  <br><br/>
                  ${t('VISION_DICTIONARY_DISCLAIMER.TEXT')}
                  <br><br/>
                  ${t('VISION_DICTIONARY_DISCLAIMER.ADDL_TEXT')}`
                  : healthGradesDisclaimer?.message,
            }}
          />
        </Flex>
      </Flex>
    </Print.Section>
  );
};
