import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Constants } from '../common/Constants';

export const sleep = (delay) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export const searchResultBreadcrumbTitle = (providerTypeLocal) => {
  let titleAssigned;
  switch (providerTypeLocal) {
    case Constants.RESULT_SECTION.FACILITY:
      titleAssigned = 'Facility results';
      break;
    case Constants.RESULT_SECTION.PROVIDER_GROUPS:
      titleAssigned = 'Provider group results';
      break;
    default:
      titleAssigned = 'Provider results';
      break;
  }
  return titleAssigned;
};
  export const getTokenData = () => {
    const { getRouteParams } = useRouter();
    const { token } = getRouteParams();
    const data = tokenizer.parse(token) || {};
    return data;
  };
