import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import { CommonlySearchedCard } from '../../../../common/CommonlySearched';
import { Constants } from '../../../../common/Constants';
import { PSXHeader } from '../../../../common/PSXHeader';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { StillNeedHelp } from '../../../../common/StillNeedHelp';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { NoSpecialtyResult } from './NoSpecialtyResult';
import { NullPageContainerStyled } from './NullSpecialtyPage.styles';

export type NullResultsPageProps = {
  searchTitle: string;
};

export const NullResultPageForSpecialty = () => {
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'null specialty results',
    sitesectionLevel1: `${Constants.ADOBE_TRACKING.VIEWALL_SITESECTION1}`,
    sitesectionLevel2: `${Constants.ADOBE_TRACKING.VIEWALL_SITESECTION2}`,
  });

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  const { t } = useTranslation();

  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { search, linkName } = tokenData;
  const { name: locationName, zipCode } = getGeoLocationFromStorage();

  const breadcrumbs = [
    {
      title: t('BC Results'),
      href: ``,
    },
  ];

  useEffect(() => {
    adobeStandardSearchEvent({
      linkName,
      numberOfResults: 0,
      method: 'guided',
      customAttributesBlock: {
        searchLocation: zipCode || locationName,
      },
    });
  }, []);
  return (
    <React.Fragment>
      <PSXHeader
        breadcrumbs={breadcrumbs}
        dataTestId="no-specialty-page"
        showChips={false}
        showChoosePCPHeader={false}
        showMemberSelection={false}
      />
      <NullPageContainerStyled>
        <NoSpecialtyResult
          data-auto-testid="null-specialty-page"
          data-testid="null-specialty-page"
          searchName={search}
        />
        <CommonlySearchedCard />
        <StillNeedHelp />
      </NullPageContainerStyled>
    </React.Fragment>
  );
};
