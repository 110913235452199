import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { useContext, useEffect, useMemo, useState } from 'react';

import { Constants } from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { getProviderType } from '../../utils/providerDetails.utils';
import {
  getCurrentMemberData,
  getLocale,
  getOrgTypeCodes,
  getSpecialityRollupCodes,
  getTokenData,
  parseProviderSearchFilters,
} from '../../utils/providerSearch.utils';
import {
  getCoverageTypes,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getOnlineRetailers,
  getPlanVariationCode,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import GET_PROVIDER_FILTER_COUNT from './ProviderFilterCount.graphql';

export const useProviderFilterCount = (options) => {
  const [filterOptions, setFilterOptions] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GET_PROVIDER_FILTER_COUNT, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'providerSearch',
    /* istanbul ignore next */
    onCompleted: (result) => {
      const data = result?.data?.providerFilterCount;
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
      setFilterOptions(data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: filterOptions,
  };

  return [parsedResult, queryAction];
};

export const useParsedProviderFilters = (params) => {
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = getTokenData(token);
  const {
    search = '',
    providerType = '',
    category,
    searchType,
    pcpIndicator = false,
    includeOrgTypeCodes,
    requestType = '',
    coverageType,
    dependentSeqNbr,
    sectionType = '',
    specialtyCode,
    includeSpecialityRollupCodes,
    onlineRetailers,
  } = tokenData;

  const { selectedFilters = {} } = params;
  const { longitude, latitude, stateCode } = useGeoLocationStorage();
  const currentMember = getCurrentMemberData(dependentSeqNbr);

  const featureFlags: [{ key: string; active: boolean }] = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_FLAGS
  )();

  const rollupCodes = useMemo(() => {
    const codes = getSpecialityRollupCodes(
      specialtyCode,
      includeSpecialityRollupCodes,
      category
    );
    return codes.split(',');
  }, [specialtyCode, includeSpecialityRollupCodes, category]);

  const { searchFilters } = useContext(SearchFilterContext);
  const { AutoSearchRadius: autoSearchRadius } = searchFilters;
  const filterParams = parseProviderSearchFilters(selectedFilters);

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );
  const llmFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM
  );

  const rollupCodeMapLagoonData = useLagoon(
    Constants.LAGOON_TABLE.ROLLUPCODE_MAPPING
  )();

  const rollupCodeMap = rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (item.source && item.destination) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});

  const variables = {
    lob: currentMember?.lineOfBusiness,
    coverages: getCoverageTypes(currentMember),
    planYear: getCurrentPlanYear(),
    planVariationCode: getPlanVariationCode(currentMember, coverageType),
    policyID: currentMember?.policyNumber,
    reciprocityId: getNetworkIdsForPES(
      currentMember,
      coverageType,
      featureFlags
    ),
    locale: getLocale(),
    latitude,
    longitude,
    stateCode,
    memberDemographics: {
      gender: currentMember.demographics.gender,
      dateOfBirth: currentMember.demographics.dateOfBirth,
    },
    coverageType,
    providerType: getProviderType(providerType, sectionType),
    search,
    includeSpecialityRollupCodes: rollupCodes,
    requestType,
    rollupCodeMap,
    searchType,
    pcpIndicator,
    onlineRetailers: getOnlineRetailers(onlineRetailers),
    includeOrgTypeCodes: getOrgTypeCodes(includeOrgTypeCodes),
    // remove distance limit for Virtual Care filter for BH providers (US6465032),
    // backend logic will handle -1 for no distance limit search
    searchRadius:
      coverageType === 'B' && filterParams?.virtualCare === true
        ? -1
        : autoSearchRadius,
    ...filterParams,
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    llmFlag,
    ddpCode: currentMember?.ddpCode,
  };

  const [providerFilters, getProviderFilters] = useProviderFilterCount({});

  useEffect(() => {
    getProviderFilters({ variables });
  }, [JSON.stringify(variables)]);

  return {
    error: providerFilters?.error,
    isLoading: providerFilters?.isLoading,
    totalCount: providerFilters?.data?.totalCount || 0,
    providerFilterList: providerFilters?.data?.providerFilterList || {},
  };
};
