import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';
import React from 'react';

import { ChipFilters } from '../../../../common/ChipFiltersGroup';
import { ResultFilter } from '../../../../common/ContentFilters/ResultFilter';
import { ResultSort } from '../../../../common/ContentFilters/ResultSort';

const Filters = styled('div', {
  marginTop: '$md',
  marginBottom: '28px',
});

const filterLabelStyle = {
  '&.abyss-text-root': {
    fontWeight: '$semibold',
    fontSize: '$smallText',
    lineHeight: '$sm',
  },
  marginBottom: '-4px',
};

const Filterlabel = styled('div', {
  marginBottom: '-4px',
});
const FilterContainer = styled('div', {});

const filterFlexStyle = {
  'abyss-flex-root': {
    margin: '0 0 $md 0',
    gap: '40px',
  },
};

type FiltersContainerDesktopProps = {
  sectionType: string;
};

export const FiltersContainerDesktop = ({
  sectionType = '',
}: FiltersContainerDesktopProps) => (
  <Filters>
    <Flex alignItems="flex-end" css={filterFlexStyle} justify="start">
      <Layout.Stack alignItems="left">
        <Filterlabel>
          <Text
            css={{ ...filterLabelStyle }}
            data-testid="filter-by-heading"
            id={t('ACCESSIBILITY_LABELS.FILTER_BY')}
          >
            {t('FILTER_LABELS.FILTER_BY')}
          </Text>
        </Filterlabel>
        <Flex
          aria-labelledby={t('ACCESSIBILITY_LABELS.FILTER_BY')}
          css={{ gap: '$sm', flexWrap: 'wrap' }}
          role="group"
        >
          <ResultFilter
            color="$descriptionBlack"
            icon="filter_list"
            name={t('CONTENT_FILTERS.ALL_FILTERS')}
            size="$md"
            variant="ghost"
          />

          <ChipFilters sectionType={sectionType} />
        </Flex>
      </Layout.Stack>

      <Layout.Stack alignItems="left">
        <Filterlabel>
          <Text
            css={{ ...filterLabelStyle }}
            data-testid="sort-by-heading"
            id="sort-by"
          >
            {t('FILTER_LABELS.SORT_BY')}
          </Text>
        </Filterlabel>

        <FilterContainer aria-labelledby="sort-by" role="group">
          <ResultSort sectionType={sectionType} />
        </FilterContainer>
      </Layout.Stack>
    </Flex>
  </Filters>
);
