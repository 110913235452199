import React, { RefObject } from 'react';

type RefType = RefObject<HTMLElement>;

export const useIsOverflow = (ref: RefType) => {
  const [isOverflow, setIsOverflow] = React.useState<Boolean>();

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow: boolean = current
        ? current.scrollHeight > current.clientHeight
        : false;
      setIsOverflow(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [ref]);

  return isOverflow;
};
