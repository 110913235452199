import { useEffect } from 'react';

import { getGeoLocationFromStorage } from '../../common/PSXHeader/SearchBar/utils';

export const useGeoLocationStorage = () => {
  const { name, latitude, longitude, stateCode, zipCode } =
    getGeoLocationFromStorage();
  useEffect(() => {
    const getLatLng = () => getGeoLocationFromStorage();
    window.addEventListener('storage', getLatLng);
    return () => {
      window.removeEventListener('storage', getLatLng);
    };
  }, []);
  return { name, latitude, longitude, stateCode, zipCode };
};
