import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';

import { RecentSearchesSearchTypes } from '../../Constants';
import {
  phoneOnly,
  recentActivityDrawerDesktop,
  recentActivityDrawerMobile,
} from '../../ConstantsStyles';
import { formatDate } from '../../Utils/datesUtils/formatDate';
import { handleRecentSearchesChipClick } from '../helper';

// TODO All mocked data will be replaced with lagoon
enum SearchTypes {
  Viewed = 'Viewed',
  Visited = 'Visited',
  Searched = 'Searched',
}

const ListContainer = styled('ul', {
  margin: '15px 0 auto auto',
  listStyleType: 'none',
  counterReset: 'steps',
});

const ItemContainer = styled('li', {
  padding: '0 0 16px 20px',
  position: 'relative',
  margin: '0',

  '&:after': {
    position: 'absolute',
    top: '3px',
    left: 0,
    content: '',
    border: '1px solid $gray6',
    borderRadius: '50%',
    display: 'inline-block',
    height: '12px',
    width: '12px',
    textAlign: 'center',
    lineHeight: '24px',
    background: '#fff',
  },
  '&:before': {
    position: 'absolute',
    left: '5px',
    top: '3px',
    content: '',
    height: '100%',
    width: 0,
    borderLeft: '1px dashed #B3B3B3',
  },
  '&:last-of-type:before': {
    border: 'none',
  },
});

interface RecentSearch {
  category: string;
  pesSearchTerm: string;
  psxSearchTerm: string;
  searchType: string;
  createdDate: string;
}

type Props = {
  recentSearchesResponse: RecentSearch[];
  isOpen: boolean;
  setIsOpen: Function;
  drawerHeading: string;
};
const sources: { [key: string]: string } = {
  [RecentSearchesSearchTypes.SPECIALITY]: SearchTypes.Searched,
  [RecentSearchesSearchTypes.NAME]: SearchTypes.Searched,
  [RecentSearchesSearchTypes.ORGANIZATION_DETAIL]: SearchTypes.Viewed,
  [RecentSearchesSearchTypes.MEDICAL_GROUP_DETAIL]: SearchTypes.Viewed,
  [RecentSearchesSearchTypes.PRACTITIONER_DETAIL]: SearchTypes.Viewed,
};

export const RecentActivityDrawer = ({
  recentSearchesResponse,
  isOpen,
  setIsOpen,
  drawerHeading,
}: Props) => {
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const mobileScreen = useMediaQuery(phoneOnly);

  return (
    <Drawer
      css={
        mobileScreen ? recentActivityDrawerMobile : recentActivityDrawerDesktop
      }
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      position={mobileScreen ? 'bottom' : 'right'}
      size={mobileScreen ? window.innerHeight - 104 : 408}
      title={
        <Heading
          color="$black"
          data-auto-testid="heading-recent-activity"
          data-testid="heading-recent-activity"
          fontStyle="normal"
          offset={3}
        >
          {drawerHeading}
        </Heading>
      }
    >
      <Box color="$white">
        <React.Fragment>
          <ListContainer>
            {recentSearchesResponse.slice(0, 10).map((item) => {
              const formatedDate = formatDate(new Date(item.createdDate));
              const result = sources[item.searchType] || SearchTypes.Viewed;
              return (
                <ItemContainer key={`recent-activity-item-${item.createdDate}`}>
                  <Flex alignContent="flex-start" direction="row">
                    <Text
                      color="$gray6"
                      css={{
                        'abyss-text-root': {
                          fontSize: '14.22px',
                          fontWeight: '$medium',
                          fontStyle: 'normal',
                          lineHeight: '20px',
                        },
                      }}
                    >
                      {result}
                    </Text>
                    <Link
                      data-auto-testid="recent-activity-keyword"
                      data-testid="recent-activity-keyword"
                      isStandardAnchor
                      onClick={() => {
                        handleRecentSearchesChipClick(
                          item,
                          navigate,
                          '',
                          token
                        );
                      }}
                      size="sm"
                    >
                      <Text
                        color="$interactive1"
                        css={{
                          'abyss-text-root': {
                            fontSize: '14.22px',
                            fontWeight: '$bold',
                            fontStyle: 'normal',
                          },
                        }}
                      >
                        &nbsp;"
                        {item.psxSearchTerm}"
                      </Text>
                    </Link>
                  </Flex>
                  <Text
                    color="#636363"
                    css={{
                      'abyss-text-root': {
                        textOverflow: 'ellipsis',
                        fontStyle: 'normal',
                        lineHeight: '16px',
                        fontWeight: '$medium',
                        fontSize: '12.64px',
                      },
                    }}
                  >
                    {`${formatedDate.monthName} ${formatedDate.day}`}
                  </Text>
                </ItemContainer>
              );
            })}
          </ListContainer>
        </React.Fragment>
      </Box>
    </Drawer>
  );
};
