import { usePrint } from '@abyss/web/hooks/usePrint';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { LinkWithTracking } from '../../../../../common/LinkWithTracking/LinkWithTracking';
import { handleLinkAndModalTrack } from '../../../../../common/Utils/adobeTrackUtils';
import { StoreKeys } from '../../../../../hooks/useStore/state';
import { useStore } from '../../../../../hooks/useStore/useStore';
import { CompareProvider } from '../../../../../models/Provider';
import { PrintView } from '../Print/PrintView';
import { ConfirmationModalShare } from './ConfirmationModal';
import { ShareContainer } from './ShareDrawer.styled';
import { adobeShareDrawerLinkLocation, hideFeedbackContainer } from './utils';

type Props = {
  selected: number;
  setOpenShare: (a: boolean) => void;
  openBoxContents: boolean;
  setOpenBoxContents: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  total: number;
  selectedProviderList: {
    providerName: string;
    providerId: string;
    primaryDegrees: string[];
    speciality: string;
    organizationType: string;
    address: {
      postalCode: string;
      district: string;
    };
    phones: {
      phone: string[];
    };
    firstName?: string;
    middleName?: string;
    lastName?: string;
  }[];
};

export const ShareContainerDesktop = ({
  setOpenShare,
  selected,
  openBoxContents,
  total,
  setSelectedItems,
  setSelectedCheckbox,
  setOpenBoxContents,
  selectedProviderList,
}: Props) => {
  const { t } = useTranslation();

  const caretRef = React.useRef<HTMLInputElement>(null);
  const print = usePrint();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selected > 0) {
      caretRef?.current?.focus();
    }
  }, [selected]);
  const { shareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const { indexOflastProviderSelected } = shareProvidersFlow;
  const setUIstate = useStore(StoreKeys.SET_UI_STATE);
  const uiState = useStore(StoreKeys.UI_STATE);

  const handleTabOutOfSelection = (e) => {
    if (e.shiftKey && e.key === 'Tab') {
      setUIstate({
        ...uiState,
        shareProvidersFlow: {
          indexOflastProviderSelected,
          shouldFocusLastIndex: true,
        },
      });
    }
  };

  const handleFocusIn = () => {
    setUIstate({
      ...uiState,
      shareProvidersFlow: {
        indexOflastProviderSelected,
        shouldFocusLastIndex: false,
      },
    });
  };

  const handleOnClickCarret = () => {
    adobeLinkTrackEvent({
      name: 'share details',
      location: `modal:${adobeShareDrawerLinkLocation}`,
      type: `${openBoxContents ? 'accordion close' : 'accordion open'}`,
    });
    setOpenBoxContents(!openBoxContents);
  };
  return (
    <ShareContainer>
      <Box
        css={{
          'abyss-box-root': {
            backgroundColor: 'transparent',
            padding: '0px',
          },
        }}
      >
        <Flex
          alignItems="left"
          css={{
            'abyss-flex-root': {
              marginTop: '26px',
            },
          }}
          justify="space-between"
        >
          <Layout.Stack>
            <Layout.Group
              data-auto-testid="selection-count-providers"
              data-testid="selection-count-providers"
              onClick={handleOnClickCarret}
              onFocus={() => handleFocusIn()}
              onKeyDown={(e) => handleTabOutOfSelection(e)}
              space={1}
            >
              <Button
                aria-expanded={Boolean(openBoxContents)}
                ref={caretRef}
                variant="tertiary"
              >
                <Heading
                  color="$gray8"
                  css={{
                    paddingLeft: '10px',
                    paddingRight: '5px',
                    fontSize: '20.25px !important',
                    lineHeight: '24px !important',
                    fontWeight: '700 !important',
                    fontFamily: 'AbyssUHCSans !important',
                  }}
                  offset={2}
                >
                  {selected} {t('of')} {total} {t('SELECTED')}
                </Heading>
                <IconMaterial
                  data-auto-testid="icon-button-to-collapse-providers"
                  data-testid="icon-button-to-collapse-providers"
                  icon={
                    openBoxContents
                      ? 'keyboard_arrow_down'
                      : 'keyboard_arrow_up'
                  }
                  size="$md"
                />
              </Button>
            </Layout.Group>
          </Layout.Stack>
          <Layout.Group>
            <LinkWithTracking
              analyticsInfo={{
                location: `modal:${adobeShareDrawerLinkLocation}`,
                name: 'print',
              }}
              before={<IconMaterial icon="print" />}
              css={{
                'abyss-link-root': {
                  minWidth: '20px',
                  height: '18px',

                  fontWeight: '$bold',
                  lineHeight: '20px',
                },
              }}
              data-auto-testid="print-link-share-float"
              data-testid="print-link-share-float"
              onClick={() => {
                hideFeedbackContainer(true);
                print.printPage();
                hideFeedbackContainer(false);
              }}
            >
              {t('SHARE_DRAWER_TEXT.PRINT')}
            </LinkWithTracking>

            <PrintView selectedProviderList={selectedProviderList} />
            <Button
              aria-label="Close"
              css={{
                'abyss-button-content-container': {
                  justifyContent: 'center',
                },
              }}
              data-auto-testid="icon-button-to-close"
              data-testid="icon-button-to-close"
              onClick={() => {
                handleLinkAndModalTrack(
                  'close',
                  `modal:${adobeShareDrawerLinkLocation}`,
                  'close confirmation'
                );
                setIsOpen(true);
              }}
              rounded
              variant="ghost"
            >
              <IconMaterial
                css={{
                  'abyss-icon': {
                    position: 'relative',

                    minWidth: '20px',
                    height: '20px',
                  },
                }}
                icon="close"
                size="$md"
              />
            </Button>
          </Layout.Group>
        </Flex>
      </Box>
      <ConfirmationModalShare
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setOpenBoxContents={setOpenBoxContents}
        setOpenShare={setOpenShare}
        setSelectedCheckbox={setSelectedCheckbox}
        setSelectedItems={setSelectedItems}
      />
    </ShareContainer>
  );
};
