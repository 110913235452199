export type CompareCheckboxInfo = {
  name?: string;
  label: string;
  isChecked?: boolean;
};

export const defaultItems: CompareCheckboxInfo[] = [
  {
    name: 'reviews',
    label: 'Ratings',
    isChecked: true,
  },
  {
    name: 'tierOneProvider',
    label: 'Tier 1 provider',
    isChecked: true,
  },
  {
    name: 'education',
    label: 'Education',
    isChecked: false,
  },
  {
    name: 'languagesSpoken',
    label: 'Language(s) spoken',
    isChecked: false,
  },
  {
    name: 'cost',
    label: 'Cost',
    isChecked: true,
  },
  {
    name: 'specialties',
    label: 'Specialty',
    isChecked: true,
  },
  {
    name: 'distance',
    label: 'Distance from me',
    isChecked: true,
  },
  {
    name: 'acceptingNewPatients',
    label: 'Accepting new patients',
    isChecked: false,
  },
  {
    name: 'gender',
    label: 'Gender',
    isChecked: false,
  },
  {
    name: 'virtualCareOffered',
    label: 'Virtual care offered',
    isChecked: false,
  },
  {
    name: 'hoursOfOperation',
    label: 'Hours of operation',
    isChecked: false,
  },
  {
    name: 'premiumCareProvider',
    label: 'Premium Care Physician',
    isChecked: true,
  },
  {
    name: 'hasPCPServiceAvailability',
    label: 'PCP services available',
    isChecked: false,
  },
];

export const bhSpecificDefaultItems: CompareCheckboxInfo[] = [
  {
    name: 'reviews',
    label: 'Ratings',
    isChecked: true,
  },
  {
    name: 'education',
    label: 'Education',
    isChecked: true,
  },
  {
    name: 'languagesSpoken',
    label: 'Language(s) spoken',
    isChecked: false,
  },
  {
    name: 'cost',
    label: 'Cost',
    isChecked: true,
  },
  {
    name: 'specialties',
    label: 'Specialty',
    isChecked: true,
  },
  {
    name: 'distance',
    label: 'Distance from me',
    isChecked: true,
  },
  {
    name: 'acceptingNewPatients',
    label: 'Accepting new patients',
    isChecked: false,
  },
  {
    name: 'gender',
    label: 'Gender',
    isChecked: false,
  },
  {
    name: 'virtualCareOffered',
    label: 'Virtual care offered',
    isChecked: false,
  },
  {
    name: 'hoursOfOperation',
    label: 'Hours of operation',
    isChecked: false,
  },
  {
    name: 'providerEthnicityExpress',
    label: 'Provider ethnicity',
    isChecked: false,
  },
  {
    name: 'expressAccessProvider',
    label: 'Express Access Provider',
    isChecked: false,
  },
  {
    name: 'hasEveningAppointments',
    label: 'Offers evening appointments',
    isChecked: false,
  },
  {
    name: 'hasWeekendAppointment',
    label: 'Offers weekend appointments',
    isChecked: false,
  },
  {
    name: 'practiceLimitations',
    label: 'Practice Limitations',
    isChecked: false,
  },
];

export const visionSpecificDefaultItems: CompareCheckboxInfo[] = [
  {
    name: 'reviews',
    label: 'Ratings',
    isChecked: true,
  },
  {
    name: 'languagesSpoken',
    label: 'Language(s) spoken',
    isChecked: false,
  },
  {
    name: 'cost',
    label: 'Cost',
    isChecked: true,
  },
  {
    name: 'specialties',
    label: 'Specialty',
    isChecked: true,
  },
  {
    name: 'distance',
    label: 'Distance from me',
    isChecked: true,
  },
  {
    name: 'acceptingNewPatients',
    label: 'Accepting new patients',
    isChecked: false,
  },
  {
    name: 'gender',
    label: 'Gender',
    isChecked: false,
  },
  {
    name: 'hoursOfOperation',
    label: 'Hours of operation',
    isChecked: false,
  },
  {
    name: 'servicesOffered',
    label: 'Services provided',
    isChecked: false,
  },
];

export const dentalSpecificDefaultItems: CompareCheckboxInfo[] = [
  {
    name: 'reviews',
    label: 'Ratings',
    isChecked: true,
  },
  {
    name: 'education',
    label: 'Education',
    isChecked: false,
  },
  {
    name: 'languagesSpoken',
    label: 'Language(s) spoken',
    isChecked: false,
  },
  {
    name: 'cost',
    label: 'Cost',
    isChecked: true,
  },
  {
    name: 'specialties',
    label: 'Specialty',
    isChecked: true,
  },
  {
    name: 'distance',
    label: 'Distance from me',
    isChecked: true,
  },
  {
    name: 'acceptingNewPatients',
    label: 'Accepting new patients',
    isChecked: true,
  },
  {
    name: 'gender',
    label: 'Gender',
    isChecked: false,
  },
  {
    name: 'hoursOfOperation',
    label: 'Hours of operation',
    isChecked: false,
  },
];
export const NULL_ATTRIBUTE_STATE = {
  SPECIALTY: 'Specialty information not available',
  LANGUAGES_SPOKEN: 'Languages spoken information not available',
  ACCEPTING_NEW_PATIENTS: 'Accepting new patients information not available',
  GENDER: 'Gender information not available',
  HEALTH_GRADE_RATINGS: 'Ratings information not available',
  VIRTUAL_CARE_OFFERED: 'Virtual care information not available',
  DISTANCE: 'Distance information not available',
  ADDRESS: 'Address information not available',
  PHONE_NUMBER: 'Phone number not available',
  HOURS_OF_OPERATION: 'Hours of operation not available',
  TIER_ONE_PROVIDER: 'Tier 1 information not available',
  PREMIUM_CARE_PROVIDER: 'Premium Care Physician information not available',
  PCP_SERVICES_AVAILABILE: 'PCP Services information not available',
  EDUCATION_SCHOOLS: 'University information not available',
  EDUCATION_DEGREES: 'Degree information not available',
  PROVIDER_ETHNICITY: 'Provider Ethnicity information not available',
  EXPRESS_ACCESS_PROVIDER: 'Express Access Provider information not available',
  OFFERS_EVENING_APPOINTMENTS: 'Evening appointments information not available',
  OFFERS_WEEKEND_APPOINTMENTS: 'Weekend appointments information not available',
  PRACTICE_LIMITATIONS: 'Practice Limitations information not available',
  SERVICES_OFFERED: 'Services provided information not available',
};

export const ATTRIBUTE_STATUS_TEXT = {
  ACCEPTING_NEW_PATIENTS: {
    TRUE: 'Accepting new patients',
    FALSE: 'Not accepting new patients',
  },
  VIRTUAL_CARE_OFFERED: {
    TRUE: 'Offers virtual care',
    FALSE: 'Does not offer virtual care',
  },
  TIER_ONE_PROVIDER: {
    TRUE: 'Tier 1 Provider',
    FALSE: 'Not a Tier 1 Provider',
  },
  PREMIUM_CARE_PROVIDER: {
    TRUE: 'Premium Care Physician',
    FALSE: 'Not a Premium Care Physician',
  },
  PCP_SERVICES_AVAILABILE: {
    TRUE: 'PCP services available',
    FALSE: 'PCP services not available',
  },
  OFFERS_EVENING_APPOINTMENTS: {
    TRUE: 'Offers evening appointments',
    FALSE: 'Does not offer evening appointments',
  },
  OFFERS_WEEKEND_APPOINTMENTS: {
    TRUE: 'Offers weekend appointments',
    FALSE: 'Does not offer weekend appointments',
  },
  PRACTICE_LIMITATIONS: {
    TRUE: 'Practice limitations',
    FALSE: 'No Practice limitations',
  },
  EXPRESS_ACCESS_PROVIDER: {
    TRUE: 'Express Access Provider',
    FALSE: 'Not an Express Access Provider',
  },
};

export const MAX_NUM_OF_ATTRIBUTES = 7; // in-Network/Not-In-Network is the 8th attribute on Edit Compare Floating section

export const adobeCompareDrawerLinkLocation = 'compare drawer';
