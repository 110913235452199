import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';

import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { FacilityLocationsResponse } from '../../../../models/FacilityDetails';
import { ProviderLocation } from '../../../../models/ProviderDetails';
import { mobileOnly, phoneOnly } from '../../../ConstantsStyles';
import { LinkWithTracking } from '../../../LinkWithTracking/LinkWithTracking';
import { IconAlignmentContainer } from '../ProviderLocation.style';
import {
  InfoWebAlignmentContainer,
  infoContainer,
} from '../ProviderLocationsDetails.style';

type Props = {
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
  isFromVirtualProviderGroup?: boolean;
};

export const EmailAndWebsiteInfo = ({
  selectedLocation,
  isFromVirtualProviderGroup = false,
}: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;
  const isMobile = useMediaQuery(mobileOnly) || isWidget;
  let contentWidth = '';
  if (isFromVirtualProviderGroup) {
    if (isMobile) {
      contentWidth = '100%';
    } else {
      contentWidth = '50%';
    }
  }

  if (!selectedLocation?.website && !selectedLocation?.emailAddress?.length) {
    return null;
  }

  return (
    <React.Fragment>
      <Flex
        css={{
          flexBasis: '100%',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'normal' : 'space-between',
          gap: '12px',
        }}
        data-testid="email-and-website-info"
      >
        {selectedLocation?.website ? (
          <IconAlignmentContainer
            style={{
              width: contentWidth,
              alignItems: 'flex-start',
            }}
          >
            <IconMaterial
              color="$primary1"
              css={{
                marginTop: '4px',
                'abyss-icon-material': {
                  cursor: 'pointer',
                  minWidth: '20px',
                  marginTop: '4px',
                },
              }}
              icon="web"
              isScreenReadable
              size={20}
              title="Website"
            />
            <Text css={infoContainer(phoneScreen)} fontWeight="$bold">
              <LinkWithTracking
                analyticsInfo={{
                  name: 'website',
                  location: 'body:provider-website',
                }}
                css={infoContainer(phoneScreen)}
                data-auto-testid={`provider-website${selectedLocation.website}`}
                data-testid={`provider-website${selectedLocation.website}`}
                fontWeight="$bold"
                href={selectedLocation.website}
              >
                {selectedLocation.website}
              </LinkWithTracking>
            </Text>
          </IconAlignmentContainer>
        ) : null}

        {selectedLocation.emailAddress?.length ? (
          <InfoWebAlignmentContainer>
            <IconMaterial
              color="$primary1"
              css={{
                marginTop: '5px',
                ...(isMobile && { marginTop: '3px' }),
              }}
              icon="mail"
              size={16.67}
            />
            <Text css={infoContainer(phoneScreen)} fontWeight="$bold">
              {selectedLocation.emailAddress.map((email, index) => (
                <div key={`email-address-${index}`}>
                  <a
                    data-auto-testid={`${email}-email-address`}
                    data-testid={`${email}-email-address`}
                    href={`mailto:${email}`}
                  >
                    {email}
                  </a>
                </div>
              ))}
            </Text>
          </InfoWebAlignmentContainer>
        ) : null}
      </Flex>
    </React.Fragment>
  );
};
