/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Card } from '@abyss/web/ui/Card';
import { CollapseProvider } from '@abyss/web/ui/CollapseProvider';
import { Heading } from '@abyss/web/ui/Heading';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { FacilityDetails } from '../../../models/FacilityDetails';
import { getTokenData } from '../../../utils/common';
import { Constants } from '../../Constants';
import { mobileOnly } from '../../ConstantsStyles';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  convertSpecialtiesList,
  getSearchBlock,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { extractRollupCodesParts } from '../Utils';
import {
  CollapseCardHeaderStyle,
  CollapseSection,
  SectionContainer,
  StyledContentText,
  StyledHeadingText,
  TextContainer,
} from './FacilityDetailsAboutSection.styled';

type Props = {
  facilityDetails: FacilityDetails;
  tabTitle?: string;
  coverageType: string;
};

export const FacilityDetailsAboutSection = ({
  facilityDetails,
  tabTitle,
  coverageType,
}: Props) => {
  const mobile = useMediaQuery(mobileOnly);
  const { t } = useTranslation();

  const organizationTypes = extractRollupCodesParts(
    facilityDetails?.organizationType
  );

  const defaultTranslatedOrgTypes = extractRollupCodesParts(
    facilityDetails?.defaultTranslatedValues?.organizationType
  );

  const tokenData = getTokenData();

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      facilityDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(facilityDetails.providerType),
      indicator: '',
    },
    providerBlock: {
      type: convertCoverageType(coverageType),
      specialty: convertSpecialtiesList(defaultTranslatedOrgTypes),
      role: 'facility',
    },
    searchBlock: getSearchBlock(tokenData),
  });

  useEffect(() => {
    if (facilityDetails.providerName) adobePageTrackEvent();
  }, [facilityDetails]);

  return (
    <React.Fragment>
      <CollapseSection>
        {mobile ? (
          <CollapseProvider>
            <Card
              collapse
              css={CollapseCardHeaderStyle}
              header={
                <Heading
                  css={{
                    '&.abyss-heading-root': {
                      fontSize: '20.25px !important',
                      lineHeight: '24px !important',
                    },
                  }}
                  display="h4"
                  offset={1}
                >
                  {t(Constants.FACILITY_DETAILS_ABOUT_SECTION.SPECIALTY)}
                </Heading>
              }
            >
              <Card.Section
                css={{
                  'abyss-card-section': {
                    paddingLeft: '0px',
                    paddingTop: '0px',
                    paddingBottom: '$md',
                  },
                }}
              >
                <TextContainer>
                  {organizationTypes?.map((organizationType) => (
                    <StyledHeadingText key={`organization_${organizationType}`}>
                      {organizationType}
                    </StyledHeadingText>
                  ))}
                </TextContainer>
              </Card.Section>
            </Card>
          </CollapseProvider>
        ) : (
          <SectionContainer>
            <Heading
              css={{ marginBottom: '12px' }}
              data-auto-testid="specialty-heading"
              data-testid="specialty-heading"
              display="h4"
              offset={1}
            >
              {t(Constants.FACILITY_DETAILS_ABOUT_SECTION.SPECIALTY)}
            </Heading>
            {organizationTypes?.map((organizationType) => (
              <StyledContentText
                data-auto-testid="specialty-type"
                data-testid="specialty-type"
                key={`organization_${organizationType}`}
              >
                {organizationType}
              </StyledContentText>
            ))}
          </SectionContainer>
        )}
      </CollapseSection>
    </React.Fragment>
  );
};
