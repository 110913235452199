import { config } from '@abyss/web/tools/config';
import { useState } from 'react';

import { Constants } from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import {
  getCurrentMember,
  getCurrentPlanYear,
  getPlanVariationCode,
} from '../../utils/user.utils';
import { useCoverageType } from '../useCoverageType';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import CATEGORY_COUNT from './CategoriesCount.graphql';

export const useCategoryCountSearch = (options) => {
  const [categoryCount, setCategoryCount] = useState({});
  const [queryResult, queryAction] = useCustomQuery(CATEGORY_COUNT, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'getChipCategoriesCount',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setCategoryCount(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: categoryCount,
  };

  return [parsedResult, queryAction];
};

export const useCategoriesCount = () => {
  const locale = getLanguage()?.code || 'en';
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const currentMember = getCurrentMember();
  const coverageType = useCoverageType();

  const [categoryCount, getCategoryCountDetail] = useCategoryCountSearch({});

  const rollupCodeMapLagoonData = useLagoon(
    Constants.LAGOON_TABLE.ROLLUPCODE_MAPPING
  )();
  const rollupCodeMap = rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (item.source && item.destination) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );

  const getCategoryCountResults = (passedParams) => {
    getCategoryCountDetail({
      variables: {
        locale,
        providerType: '',
        policyID: currentMember?.policyNumber,
        planYear: getCurrentPlanYear(),
        lob: currentMember?.lineOfBusiness,
        latitude,
        longitude,
        stateCode,
        planVariationCode: getPlanVariationCode(currentMember, coverageType),
        searchRadius: passedParams?.searchRadius,
        autoIncrementRadius: passedParams?.autoIncrementRadius,
        categoryRecords: passedParams.payloadwithName.map((eachCategory) => ({
          ...eachCategory,
          uesType: undefined,
          includeSpecialityRollupCodes: [
            ...new Set(
              eachCategory.includeSpecialityRollupCodes.map(
                (code) => rollupCodeMap[code?.trim()] || code?.trim()
              )
            ),
          ],
          useMedicalNewRollUpCodes,
          useBHNewRollUpCodes,
          useVisionNewRollUpCodes,
          useDentalNewRollUpCodes,
        })),
      },
    });
  };

  return [categoryCount, getCategoryCountResults];
};
