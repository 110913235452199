import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import isEmpty from 'lodash/isEmpty';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Miles } from '../../../../../../../../../../common/DataCard/Miles';
import { getDistance } from '../../../../../../../../../../common/Utils/dataCardUtils';
import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import { CompareProvider } from '../../../../../../../../../../models/Provider';
import { ResponseHeaders } from '../../../../../../../../../../models/ResponseHeaders';
import {
  StyledDistanceDiv,
  distanceNullStyle,
  popoverStyle,
} from '../../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../../utility/compareDrawerConstants';

type Props = {
  distance: string;
  indexForAnalytics;
  providerId: string;
  providerType: string;
  mobile: boolean;
  selectedProvider: CompareProvider;
  headers: ResponseHeaders;
};

export const isDistanceAttributeEmpty = (numMiles) => {
  if (isEmpty(numMiles)) {
    return true;
  }
  return false;
};

export const Distance = ({
  distance,
  headers,
  indexForAnalytics,
  providerId,
  providerType,
  selectedProvider,
  mobile,
}: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);
  const isAttributeEmpty = isDistanceAttributeEmpty(distance);

  const displayedDistance = isDistanceAttributeEmpty(distance)
    ? NULL_ATTRIBUTE_STATE.DISTANCE
    : `${getDistance(distance)}`;
  const indexAdobe =
    indexForAnalytics && indexForAnalytics >= 0 ? indexForAnalytics + 1 : 1;
  const distanceAttribute = (
    <StyledDistanceDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      ref={containerRef}
    >
      {!isAttributeEmpty ? (
        <Layout.Group>
          <Miles
            calledFrom="comparecard"
            distance={distance}
            headers={headers}
            id={providerId}
            indexForAnalytics={indexAdobe}
            sectionType={providerType}
            selectedProvider={selectedProvider}
          />
        </Layout.Group>
      ) : (
        <Text css={distanceNullStyle}>{t(NULL_ATTRIBUTE_STATE.DISTANCE)}</Text>
      )}
    </StyledDistanceDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isAttributeEmpty ? (
          <Popover
            content={displayedDistance}
            css={popoverStyle}
            position="bottom"
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {distanceAttribute}
          </Popover>
        ) : (
          distanceAttribute
        )}
      </React.Fragment>
    );
  }

  return <React.Fragment>{distanceAttribute}</React.Fragment>;
};
