import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import { useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { LinkWithTracking } from '../../../../common/LinkWithTracking/LinkWithTracking';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { widgetModalEvent } from '../../../../utils/widgetEvents.utils';
import { phoneOnly } from '../../../ConstantsStyles';
import { ListItemDrawer } from './ListItemPopover.styled';

type UrlInfo = {
  url: string;
  title: string;
};

type Props = {
  content: string;
  icon: string;
  title: string;
  urlInfo?: UrlInfo;
};

export const ListItemPopover = ({ content, icon, title, urlInfo }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const isPhoneScreen = useMediaQuery(phoneOnly) || isWidget;
  const mobileScreen = useMediaQuery(phoneOnly);
  const fontSize = isPhoneScreen ? 'md' : 'lg';
  const testId = `${title}`.replace(/ /g, '-');

  const handleItemClick = () => {
    if (isWidget) {
      widgetModalEvent({
        modalName: title,
      });
    }
  };

  const handleDrawerOpen = () => {
    handleItemClick();
    setOpen(!isOpen);
  };

  const renderContent = () => (
    <>
      <Markdown>{content}</Markdown>
      {urlInfo?.url && (
        <LinkWithTracking
          analyticsInfo={{ name: 'website', location: 'body:facility-website' }}
          css={{ lineHeight: '20px', paddingTop: '16px' }}
          data-auto-testid="ddp-tracking-link"
          data-testid="ddp-tracking-link"
          fontWeight="$bold"
          href={urlInfo?.url}
        >
          {urlInfo?.title}
        </LinkWithTracking>
      )}
    </>
  );

  const mobileDrawer = () => (
    <>
      <IconMaterial
        data-testid="preferred-facility-drawer"
        icon="info"
        onClick={handleDrawerOpen}
        size={16}
        variant="outlined"
      />
      <ListItemDrawer
        isOpen={isOpen}
        onClose={() => setOpen(!isOpen)}
        position="bottom"
        title={title}
        titleAlign="left"
      >
        <Drawer.Section>{renderContent()}</Drawer.Section>
      </ListItemDrawer>
    </>
  );

  return (
    <Flex
      css={{
        gap: '8px',
        flexWrap: 'nowrap',
        alignItems: 'center',
      }}
    >
      <img
        alt={`${icon} icon`}
        src={icon}
        style={{
          width: '16px',
          height: '16px',
        }}
      />
      <Text data-testid={`${title}-subtext`.replace(/ /g, '-')} size={fontSize}>
        {title}
      </Text>
      {mobileScreen ? (
        mobileDrawer()
      ) : (
        <Popover
          align="center"
          content={renderContent()}
          css={{
            'abyss-popover-trigger': {
              display: 'flex',
              alignItem: 'center',
            },
          }}
          data-testid={testId}
          onClick={handleItemClick}
          title={title}
          width={250}
        />
      )}
    </Flex>
  );
};
