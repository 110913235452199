import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import mapboxgl from 'mapbox-gl';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import translationsEnJson from '../../../../../public/locale/en/translation.json';
import { adobeModalTrackEvent } from '../../../../common/AdobeTagging/adobeModalTrackEvent';
import { adobeSearchModifiedEvent } from '../../../../common/AdobeTagging/adobeSearchModifiedEvent';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import {
  AdobeEventMethods,
  Constants,
  INITIAL_PAGE,
  PAGE_SIZE,
  ReverseCoverageTypesCodes,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../../common/ContentWrapper';
import { PSXHeader } from '../../../../common/PSXHeader';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import {
  convertProviderTypeToAdobeType,
  getFormattedFilters,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { ErrorBoundary } from '../../../../errors/ErrorBoundary';
import { useCompareProviders } from '../../../../hooks/useCompareProviders';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useFilterProviderResults } from '../../../../hooks/useProviderSearch';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { ProviderSearchFilters } from '../../../../models/ProviderSearch';
import { Directions } from '../../../../models/RouteDirections';
import { getRoute } from '../../../../utils/map.utils';
import {
  getCurrentMember,
  getCurrentPlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
} from '../../../../utils/user.utils';
import { getLanguage } from '../../context/Internationalization/helpers';
import { MapView } from '../MapView';
import { AllPaginationProps } from '../MapView/AllPaginationProps';
import { CustomAttributesBlock } from '../MapView/CompareDrawer/Components/ConfirmationModal/ConfirmationModal';
import { CompareCheckboxInfo } from '../MapView/CompareDrawer/utility/compareDrawerConstants';
import { openTabOrNavigateOnClick } from '../MapView/CompareDrawer/utility/handleDetailsOnClick';
import { CompareDrawerProps } from '../MapView/CompareDrawerProps';
import { MobileListView } from './MobileListView';
import { ShowCompareFloatingSection } from './ShowCompareFloatingSection';
import { ShowShareFloatingSection } from './ShowShareFloatingSection';
import { successToast } from './Toast';
import { getDefaultCompareAttributes } from './utils/getDefaultCompareAttributes';
import { parseBreadcrumbs } from './utils/parseBreadcrumbs';
import { MapViewWrapper, PageContainer } from './ViewAll.style';
import { ViewAllDisclaimers } from './ViewAllDisclaimers';

export const ViewAll = () => {
  const { t } = useTranslation();

  const { searchFilters: selectedFilters } = useContext(SearchFilterContext);
  const [filters, setFilters] =
    useState<ProviderSearchFilters>(selectedFilters);

  const [isFiltersChanged, setIsFiltersChanged] = useState(false);
  const [navigateToDirections, setNavigateToDirections] = useState(false);
  const [lastPageSize, setLastPageSize] = useState(0);
  const map = React.useRef<mapboxgl.Map>(null);
  const mobileScreen = useMediaQuery(phoneOnly);
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const coverageType = useCoverageType();
  storage.session.set(
    Constants.STORAGE_KEYS.SESSION.COVERAGE_TYPE,
    coverageType
  );
  const [items, setItems] = useState<CompareCheckboxInfo[]>(
    getDefaultCompareAttributes(coverageType)
  );

  const setIsTier1Plan = useStore(StoreKeys.SET_TIER1_PLAN_FLAG);

  const [costEnableFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED,
  ]);

  const [shouldGetHGData] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_HEALTH_GRADES_INFO_FOR_PRACTIONER,
  ]);

  if (!costEnableFlag) {
    items.forEach((item) => {
      if (item.name === 'cost') {
        // eslint-disable-next-line no-param-reassign
        item.isChecked = false;
      }
    });
  }

  const {
    sectionType = '',
    search = '',
    searchTerm = '',
    searchMethod,
    providerType = '',
    choosePCP = false,
    linkName,
    virtualCare = false,
  } = tokenData;

  const isVisionCare = coverageType === ReverseCoverageTypesCodes.VISION;

  const [openMapview, setOpenMapview] = useState<boolean>(!mobileScreen);
  const [mobileRouteView, setMobileRouteView] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(
    selectedFilters.PageNumber || INITIAL_PAGE
  );
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const pageSizeOptions = [10, 15, 20];
  const containerRef = useRef<HTMLDivElement>(null);

  const currentMember = getCurrentMember();
  const { name: locationName, zipCode } = getGeoLocationFromStorage();
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  if (virtualCare) {
    selectedFilters.VirtualCare = {
      label: t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.VIRTUAL_CARE'),
      value: virtualCare,
      hide: !virtualCare,
      dnt_label:
        translationsEnJson.CONTENT_FILTERS.ALL_FILTERS_DRAWER.VIRTUAL_CARE,
    };
  }

  const {
    results = [],
    totalResultsCount,
    isLoading = false,
    isTieredPlan,
    headers,
  } = useFilterProviderResults({
    pageNumber,
    pageSize,
    selectedFilters,
    coverageType,
    shouldGetHGData,
  });

  if (isTieredPlan !== undefined) {
    setIsTier1Plan(isTieredPlan);
  }

  const totalPages = Math.ceil(totalResultsCount / pageSize);
  const { state, gotoPage, nextPage, previousPage, ...paginationProps } =
    usePagination({
      pages: totalPages,
      start: pageNumber,
    });

  const { currentPage } = state;
  const { firstPage } = paginationProps;

  useEffect(() => {
    if (pageNumber > totalPages) {
      const totalPageCount = Math.ceil(totalResultsCount / lastPageSize);
      const prevPercentage = Math.ceil((pageNumber / totalPageCount) * 100);
      const updatedPageNumber = Math.ceil((prevPercentage / 100) * totalPages);
      // abbys pagination starts with 0 that's why -1 is required
      gotoPage(updatedPageNumber - 1);
    }
  }, [pageSize]);

  useEffect(() => {
    if (pageNumber !== currentPage) {
      setPageNumber(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    setOpenMapview(!mobileScreen);
  }, [mobileScreen]);

  const serializedSelectedFilters = getFormattedFilters(selectedFilters);
  const [isCountySearch, setIsCountySearch] = useState(false);
  useEffect(() => {
    if (totalResultsCount === 0) {
      navigate(`${ConstantsRoutes.NULL_SPECIALTY_RESULTS.path}/${token}`, {
        replace: true,
      });
    } else if (totalResultsCount !== undefined) {
      if (isFiltersChanged) {
        adobeSearchModifiedEvent({
          linkName,
          filters: serializedSelectedFilters,
          numberOfResults: totalResultsCount,
          method: searchMethod ?? 'guided',
          customAttributesBlock: {
            providerType: convertProviderTypeToAdobeType(providerType),
            searchLocation: zipCode || locationName,
          },
          term: searchTerm,
        });
      } else {
        (!searchMethod || searchMethod === AdobeEventMethods.Guided) &&
          adobeStandardSearchEvent({
            linkName,
            filters: serializedSelectedFilters,
            numberOfResults: totalResultsCount,
            method: searchMethod ?? 'guided',
            customAttributesBlock: {
              providerType: convertProviderTypeToAdobeType(providerType),
              searchLocation: zipCode || locationName,
            },
            term: searchTerm,
          });
      }
    }
  }, [results]);

  useEffect(() => {
    if (JSON.stringify(selectedFilters) !== JSON.stringify(filters)) {
      setIsFiltersChanged(true);
      setFilters(selectedFilters);
      firstPage();
    }
  }, [JSON.stringify(selectedFilters)]);

  const [breadcrumbUrls, setBreadcrumbUrls] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const breadcrumbs = parseBreadcrumbs(breadcrumbUrls, sectionType, t);

  useEffect(() => {
    breadcrumbUrls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key] =
      window.location.pathname.replace('/findcare', '');
    setBreadcrumbUrls(breadcrumbUrls);
  }, []);

  useEffect(() => {
    document.title = `${t(sectionType)} ${t('results for')} ${search || ''} | ${
      Constants.SITE_NAME
    }`;

    storage.session.set(Constants.STORAGE_KEYS.SESSION.IS_COUNTY_SEARCH, false);
  }, []);

  const [selectedCheckbox, setSelectedCheckbox] = useState({ checked: {} });
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [openCompare, setOpenCompare] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [openBoxContents, setOpenBoxContents] = useState<boolean>(
    !!selectedItems.length
  );
  const [isShareAllResults, setIsShareAllResults] = useState<boolean>(false);
  const toastMessage = t('Your results have been shared');
  const [selectedId, setSelectedId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );
  const [routeEndCoords, setRouteEndCoords] = useState<
    [number | null, number | null]
  >([null, null]);

  const [directions, setDirections] = useState<Directions>({
    userPlaceName: '',
    endPlaceName: '',
    routes: [],
  });

  const { longitude, latitude } = getGeoLocationFromStorage();

  const closeAllDrawersAndOpenSuccessAlert = () => {
    setOpenCompare(false);
    setOpenShare(false);
    // after integration, check for success here
    successToast(toastMessage);
  };

  // begin: a11y keyboard navigation
  const { compareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const { indexOflastProviderSelected, shouldFocusLastIndex } =
    compareProvidersFlow;

  useEffect(() => {
    const cards = document.querySelectorAll("input[type='checkbox']");
    if (cards && shouldFocusLastIndex) {
      const lastSelectedCard = cards[
        indexOflastProviderSelected
      ] as HTMLInputElement;
      if (lastSelectedCard) lastSelectedCard?.focus();
    }
  }, [openCompare, shouldFocusLastIndex]);

  const { shareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const {
    indexOflastProviderSelected: indexOflastShareProviderSelected,
    shouldFocusLastIndex: shouldFocusLastShareProviderIndex,
  } = shareProvidersFlow;

  useEffect(() => {
    const cards = document.querySelectorAll("input[type='checkbox']");
    if (cards && shouldFocusLastShareProviderIndex) {
      const lastSelectedCard = cards[
        indexOflastShareProviderSelected
      ] as HTMLInputElement;
      if (lastSelectedCard) lastSelectedCard?.focus();
    }
  }, [openShare, shouldFocusLastShareProviderIndex]);
  // end: a11y keyboard navigation

  const [, getCompareDetails] = useCompareProviders({
    onCompleted: (result) => result,
    onError: () => {},
  });

  const { stateCode } = useGeoLocationStorage();

  const addSelectedProvider = async (providerData) => {
    const index = selectedItems.findIndex(
      (provider) => provider.locationId === providerData.locationId
    );
    if (index === -1) {
      const locale = getLanguage()?.code || 'en';
      const result = await getCompareDetails({
        variables: {
          locale,
          providerId: providerData.providerId,
          providerType: providerData.providerType,
          latitude: providerData.latitude || '',
          longitude: providerData.longitude || '',
          stateCode,
          locationId: providerData.locationId || '',
          reciprocityId: getNetworkIdsForPES(
            currentMember,
            coverageType,
            featureFlags
          ),
          planVariationCode: getPlanVariationCode(currentMember, coverageType),
          planYear: getCurrentPlanYear(),
          policyId: currentMember?.policyNumber,
          coverageType,
        },
      });

      const compareProviderData = result.data.compare.provider;
      let finalData = providerData;
      if (compareProviderData) {
        finalData = { ...providerData, ...compareProviderData };
      }
      setSelectedItems((prevState) => [...prevState, finalData]);
    } else {
      const selected = selectedItems;
      selected.splice(index, 1);
      setSelectedItems([...selected]);
    }
  };

  const removeItem = (locationId: string) => {
    setSelectedCheckbox((prevState) => ({
      checked: {
        ...prevState.checked,
        [locationId]: !prevState.checked[locationId],
      },
    }));
    const selectedProvider = selectedItems.find(
      (provider) => provider.locationId === locationId
    );
    addSelectedProvider(selectedProvider); // To remove selected provider from compare drawer
  };

  const handleDetailsOnClickCb = (
    providerId,
    options,
    customAttributesBlock: CustomAttributesBlock
  ) => {
    openTabOrNavigateOnClick(
      providerId,
      token,
      navigate,
      options,
      customAttributesBlock
    );
  };

  const compareDrawerProps: CompareDrawerProps = {
    map,
    openCompare,
    setOpenCompare,
    selectedItems,
  };

  const allPaginationProps: AllPaginationProps = {
    pageSize,
    pageSizeOptions,
    paginationProps,
    setPageSize,
    setLastPageSize,
    gotoPage,
    nextPage,
    previousPage,
    pageNumber,
  };

  const handleClickLocation = async (locationId, locationLng, locationLat) => {
    if (selectedId === locationId && !mobileScreen) return;
    const routeDirections = await getRoute(
      map.current,
      longitude,
      latitude,
      +locationLng,
      +locationLat,
      false,
      'driving-traffic'
    );
    if (routeDirections) setDirections(routeDirections);
    setRouteEndCoords([locationLng, locationLat]);
    setSelectedId(locationId);
    adobeModalTrackEvent(Constants.MAP_VIEW_NAVIGATION_MODAL);
  };

  return (
    <div ref={containerRef} style={{ overflow: 'hidden' }}>
      <ErrorBoundary>
        <CountySearchContext.Provider
          value={{ isCountySearch, setIsCountySearch }}
        >
          {!mobileRouteView && (
            <PSXHeader
              breadcrumbs={breadcrumbs}
              dataTestId="view-all-on-submit"
              showChips={false}
              showChoosePCPHeader={choosePCP}
              showMemberSelection={choosePCP ? false : !mobileScreen}
              showSearchInputBackButton={choosePCP ? false : mobileScreen}
            />
          )}
          <PageContainer data-auto-testid="viewall-container">
            <React.Fragment>
              <MapViewWrapper>
                <MapView
                  addSelectedProvider={addSelectedProvider}
                  allPaginationProps={allPaginationProps}
                  compareDrawerProps={compareDrawerProps}
                  coverageType={coverageType}
                  directions={directions}
                  headers={headers}
                  isLoading={isLoading}
                  mobileRouteView={mobileRouteView}
                  navigateToDirections={navigateToDirections}
                  openMapview={openMapview}
                  openShare={openShare}
                  providerType={providerType}
                  results={results}
                  routeEndCoords={routeEndCoords}
                  search={search}
                  searchMethod={searchMethod}
                  searchTerm={searchTerm}
                  sectionType={sectionType}
                  selectedCheckbox={selectedCheckbox}
                  selectedFilters={serializedSelectedFilters}
                  setDirections={setDirections}
                  setMobileRouteView={setMobileRouteView}
                  setNavigateToDirections={setNavigateToDirections}
                  setOpenMapview={setOpenMapview}
                  setOpenShare={setOpenShare}
                  setRouteEndCoords={setRouteEndCoords}
                  setSelectedCheckbox={setSelectedCheckbox}
                  setSelectedItems={setSelectedItems}
                  totalResultsCount={totalResultsCount}
                />
                <ShowShareFloatingSection
                  closeAllDrawersAndOpenSuccessAlert={
                    closeAllDrawersAndOpenSuccessAlert
                  }
                  handleDetailsOnClickCb={handleDetailsOnClickCb}
                  isShareAllResults={isShareAllResults}
                  items={items}
                  map={map}
                  mobileScreen={mobileScreen}
                  openBoxContents={openBoxContents}
                  openShare={openShare}
                  removeItem={removeItem}
                  selectedItems={selectedItems}
                  setIsShareAllResults={setIsShareAllResults}
                  setOpenBoxContents={setOpenBoxContents}
                  setOpenShare={setOpenShare}
                  setSelectedCheckbox={setSelectedCheckbox}
                  setSelectedItems={setSelectedItems}
                />
                <ShowCompareFloatingSection
                  containerRef={containerRef}
                  headers={headers}
                  items={items}
                  map={map}
                  mobileScreen={mobileScreen}
                  openCompare={openCompare}
                  removeItem={removeItem}
                  selectedCheckbox={selectedCheckbox}
                  selectedItems={selectedItems}
                  setItems={setItems}
                  setOpenCompare={setOpenCompare}
                  setSelectedCheckbox={setSelectedCheckbox}
                  setSelectedItems={setSelectedItems}
                />
              </MapViewWrapper>
              {mobileScreen ? (
                <ContentWrapper>
                  <MobileListView
                    addSelectedProvider={addSelectedProvider}
                    allPaginationProps={allPaginationProps}
                    gotoPage={gotoPage}
                    headers={headers}
                    isLoading={isLoading}
                    map={map}
                    mobileRouteView={mobileRouteView}
                    navigateToDirections={navigateToDirections}
                    nextPage={nextPage}
                    openCompare={openCompare}
                    openMapview={openMapview}
                    openShare={openShare}
                    pageSize={pageSize}
                    paginationProps={paginationProps}
                    previousPage={previousPage}
                    results={results}
                    search={search}
                    searchTerm={searchTerm}
                    sectionType={sectionType}
                    selectLocation={handleClickLocation}
                    selectedCheckbox={selectedCheckbox}
                    selectedFilters={serializedSelectedFilters}
                    setNavigateToDirections={setNavigateToDirections}
                    setOpenCompare={setOpenCompare}
                    setOpenMapview={setOpenMapview}
                    setOpenShare={setOpenShare}
                    setSelectedCheckbox={setSelectedCheckbox}
                    setSelectedItems={setSelectedItems}
                    totalResultsCount={totalResultsCount}
                  />
                </ContentWrapper>
              ) : null}
            </React.Fragment>
          </PageContainer>
          <ViewAllDisclaimers
            isVisionCare={isVisionCare}
            sectionType={sectionType}
          />
        </CountySearchContext.Provider>
      </ErrorBoundary>
    </div>
  );
};
