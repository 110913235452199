import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { Print } from '@abyss/web/ui/Print';
import { Text } from '@abyss/web/ui/Text';
import { format } from 'date-fns';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../../common/ConstantsLagoon';
import { useLagoon } from '../../../../../../hooks/useLagoon';
import { CompareProvider } from '../../../../../../models/Provider';
import { CompareCheckboxInfo } from '../../CompareDrawer/utility/compareDrawerConstants';
import { PrintExpandedCard } from './PrintExpandedCard';

export const PrintViewAddress = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '9px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '11px',
  },
});

export const PrintViewDivider = styled(Divider, {
  '&.abyss-divider-root': {
    border: '0.5px solid #CCCCCC',
    height: 0,
    marginTop: '$sm !important',
    marginBottom: '$md !important',
    transform: 'matrix(1, 0, 0, 1, 0, 0)',
  },
});

export const PrintViewProviderMediumText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '14px',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '17px',
    marginBottom: '5px',
  },
});

export const PrintViewProviderBoldText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '10px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '11.87px',
  },
});

export const PrintViewProviderNormalText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '10px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '11.87px',
  },
});

export const PrintViewProviderDetailsXSText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '14px',
  },
});

type Props = {
  selectedProviders: CompareProvider[];
  compareCheckboxes: CompareCheckboxInfo[];
};

export const PrintCompareDrawer = ({
  selectedProviders,
  compareCheckboxes,
}: Props) => {
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { chipValue } = tokenData;

  const lagoonData = useLagoon('ui-messaging')();
  const printViewContent = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.DISCLAIMER_IN_PAGE,
  });
  const { t } = useTranslation();

  return (
    <Print.Section css={{ border: 'none', padding: '10px' }}>
      <Flex alignContent="flex-start" direction="column">
        <Flex justify="space-between">
          <Brandmark
            affiliate="uhc"
            brand="uhc"
            color="blue"
            css={{
              'abyss-brandmark': {
                width: '114.24px',
                height: '35.36px',
              },
            }}
            size="$lg"
            variant="lockup"
          />
        </Flex>
        <PrintViewDivider orientation="horizontal" />
        <Flex justify="space-between">
          <PrintViewProviderMediumText color="$black">
            Selected providers
          </PrintViewProviderMediumText>
          <Flex>
            <PrintViewProviderBoldText color="$black">
              Date:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="$black">
              {format(new Date(), 'MMMM dd, yyyy')}
            </PrintViewProviderNormalText>
          </Flex>
        </Flex>
        <Flex justify="space-between">
          {/* <Flex>
            <PrintViewProviderBoldText color="$black">
              Provider Status:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="$black">
              Network
            </PrintViewProviderNormalText>
          </Flex> */}
          {/* <Flex>
            <PrintViewProviderBoldText color="$black">
              Patient Account Number:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="$black">
              FG3355699098
            </PrintViewProviderNormalText>
          </Flex> */}
        </Flex>
        <PrintViewDivider orientation="horizontal" />
        {selectedProviders?.length ? (
          <Flex
            data-auto-testid="print-expanded-card"
            data-testid="print-expanded-card"
            direction="row"
            gutters
          >
            {selectedProviders.map((item) => (
              <React.Fragment key={item.locationId}>
                <PrintExpandedCard
                  compareCheckboxes={compareCheckboxes}
                  item={item}
                />
              </React.Fragment>
            ))}
          </Flex>
        ) : (
          <Print.Value>No Comparisions to display.</Print.Value>
        )}
      </Flex>
      <Flex
        alignContent="center"
        css={{ marginLeft: '25px', display: 'flex', width: '95%' }}
      >
        <Flex
          alignContent="flex-start"
          css={{
            display: 'flex',
            color: '#333',
            fontFamily: '$primary',
            fontSize: '9px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
          }}
          direction="column"
        >
          {/* Lagoon data disclaimer */}
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: printViewContent?.message.replace(/\n/g, '<br/>'),
            }}
          />
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                chipValue === Constants.CHIPS_CATEGORIES.VISION
                  ? `
                  ${t('VISION_DICTIONARY_DISCLAIMER.TEXT')}
                  <br><br/>
                  ${t('VISION_DICTIONARY_DISCLAIMER.ADDL_TEXT')}`
                  : t('PREMIUM_CARE_DISCLAIMER.CONTENT'),
            }}
            style={{ marginTop: '10px' }}
          />
        </Flex>
      </Flex>
    </Print.Section>
  );
};
