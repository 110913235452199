import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../../models/ProviderDetails';
import { getTokenData } from '../../../utils/common';
import {
  filterNotEmptyHoursOfOperations,
  getUncommonElements,
} from '../../../utils/providerDetails.utils';
import { Constants, ReverseCoverageTypesCodes } from '../../Constants';
import { phoneOnly } from '../../ConstantsStyles';
import { AllOtherInfos } from './AllOtherInfos/AllOtherInfos';
import { CollapsePhones } from './CollapsePhones';
import { InfoProviderContent } from './InfoProviderContent';
import { OpsHours } from './OpsHours';
import { PhoneNumberList } from './PhoneNumberList';

type Props = {
  detailType: String;
  facilityDetails?: FacilityDetails;
  providerDetails?: ProviderDetails;
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
};

export const InfoProvider = ({
  detailType,
  facilityDetails,
  providerDetails,
  selectedLocation,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;
  const tokenData = getTokenData();
  const isVisionCare =
    tokenData?.coverageType === ReverseCoverageTypesCodes.VISION;
  const { phones, tty } = selectedLocation || {};

  let icon: string;
  let acceptingPatientInfo: string;

  if (selectedLocation?.acceptingNewPatients == null) {
    icon = 'do_disturb_alt';
    acceptingPatientInfo = 'PROVIDER_LOCATIONS_DETAILS.NO_INFO_AVAILABLE';
  } else if (selectedLocation?.acceptingNewPatients) {
    icon = 'done';
    acceptingPatientInfo = 'PROVIDER_LOCATIONS_DETAILS.ACCEPTING_PATIENTS';
  } else {
    icon = 'close';
    acceptingPatientInfo = 'PROVIDER_LOCATIONS_DETAILS.NOT_ACCEPTING_PATIENTS';
  }

  // utils calls
  const notEmptyHoursOfOperation =
    filterNotEmptyHoursOfOperations(selectedLocation);

  const availableDays = notEmptyHoursOfOperation?.map((days) =>
    days.day.toLowerCase()
  );

  const notAvailableDays = getUncommonElements(
    Constants.ALL_DAYS,
    availableDays
  );

  return (
    <React.Fragment>
      {detailType !== ProviderType.ORGANIZATION || isVisionCare ? (
        <InfoProviderContent
          icon={icon}
          infoTitle={t(acceptingPatientInfo)}
          showSubTitle={false}
        />
      ) : null}
      {!(notAvailableDays.length >= 7) && (
        <OpsHours selectedLocation={selectedLocation} />
      )}
      {phoneScreen ? (
        <CollapsePhones
          collapseHeader={t('PROVIDER_LOCATIONS_DETAILS.SHOW_MORE_PHONE')}
          phoneNumbers={phones}
          providerDetails={providerDetails}
          tty={tty}
        />
      ) : (
        <PhoneNumberList
          phoneNumbers={phones}
          providerDetails={providerDetails}
          tty={tty}
        />
      )}
      <AllOtherInfos
        detailType={detailType}
        facilityDetails={facilityDetails}
        providerDetails={providerDetails}
        selectedLocation={selectedLocation}
      />
    </React.Fragment>
  );
};
