import { useQuery } from '@abyss/web/hooks/useQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';

import { useTranslationLog } from '../useTranslationLog/useTranslationLog';

const errorCodeRouteMap = {
  '4xx': '/4xx',
  404: '/404',
  408: '/408',
  500: '/500',
};

export const useCustomQuery = (query, queryOptions) => {
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const translationLogger = useTranslationLog({});

  const [queryResult, queryAction] = useQuery(query, {
    ...queryOptions,
    onCompleted: (result) => {
      if (queryOptions.onCompleted) {
        queryOptions.onCompleted(result);
      }

      if (result?.data?.missingPSXCodeTranslations?.length) {
        translationLogger({
          variables: {
            missedTranslations: result.data.missingPSXCodeTranslations,
          },
        });
      }
    },
    onError: (error) => {
      const gQLError = error?.response?.data?.errors?.[0]?.extensions;
      const regEx = /4\d{2}|5\d{2}/;
      if (
        regEx.test(gQLError?.status) &&
        gQLError?.pageType !== 'Custom' &&
        gQLError?.pageType !== 'Ignore'
      ) {
        const tokenData = tokenizer.parse(token) || {};
        const newToken = tokenizer.create({
          message: gQLError?.message,
          code: gQLError?.status,
          source: error?.operationName,
          uiSection:
            tokenData?.linkName || tokenData?.originLinkNameForAnalytics,
        });

        navigate(
          `${
            errorCodeRouteMap[gQLError?.status] || errorCodeRouteMap['4xx']
          }/${newToken}`
        );
      }
      if (queryOptions.onError && typeof queryOptions.onError === 'function') {
        queryOptions.onError(gQLError);
      }
    },
  });
  return [queryResult, queryAction];
};
