import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import { CardWrapperDetailsPage } from '../../../../common/CardWrapper';
import {
  AdobeEventMethods,
  Constants,
  ProviderTypes,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DetailsPageHeader } from '../../../../common/DetailsPageHeader/DetailsPageHeader';
import { LoadingSpinnerComp } from '../../../../common/Loader';
import { ProviderDetailTabs } from '../../../../common/ProviderDetailTabs/ProviderDetailTabs';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { internalConvertProviderTypeToAdobeType } from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useProviderDetails } from '../../../../hooks/useProviderDetails';
import { ProviderType } from '../../../../models/ProviderDetails';
import {
  getNetworkStatus,
  getProviderId,
  getSelectedLocationIndex,
} from '../../../../utils/providerDetails.utils';
import { getNameAttrs } from '../../../../utils/providerNames.utils';

export const ProviderDetailsView = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { avatar = 'heart' } = tokenData;
  const providerId = getProviderId(tokenData);
  const {
    showDirection,
    chipValue,
    isTieredSnackCard,
    isTieredDataCard,
    coverageType,
    isOHBS,
    locationId,
    dependentSeqNbr,
    searchTerm,
    linkName,
    searchFromTypeAhead,
  } = tokenData;

  const [navigateToDirections, setNavigateToDirections] = useState(
    Boolean(showDirection)
  );

  const { name: locationName, zipCode } = getGeoLocationFromStorage();

  const [isMapView, setIsMapView] = useState<boolean>(false);

  const [shouldGetHGData] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_HEALTH_GRADES_INFO_FOR_PRACTIONER,
  ]);

  const { data, isLoading } = useProviderDetails({
    providerType: ProviderType.PRACTITIONER,
    providerId,
    isOHBS,
    locationId,
    shouldGetHGData,
  });

  const providerDetails = data?.providerDetails?.providerDetails || {};
  const providerLocations = providerDetails?.providerLocations || [];
  const nameAttrs = getNameAttrs(providerDetails);
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const [, setDirectionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  useEffect(() => {
    if (providerDetails?.providerName && searchFromTypeAhead) {
      adobeStandardSearchEvent({
        linkName,
        term: searchTerm,
        numberOfResults: 1,
        method: AdobeEventMethods.Predictive,
        customAttributesBlock: {
          searchLocation: zipCode || locationName,
          providerType: internalConvertProviderTypeToAdobeType(
            ProviderTypes.PRACTITIONER
          ),
        },
      });
    }
  }, [providerDetails?.providerName, searchFromTypeAhead]);

  useEffect(() => {
    if (showDirection) {
      setDirectionsTriggeredFrom('');
      setNavigateToDirections(true);
    }
  }, [showDirection]);

  const selectedLocationIndex = getSelectedLocationIndex(
    providerLocations,
    selectedId
  );
  const selectedLocation = providerLocations?.[selectedLocationIndex];
  const networkStatus = getNetworkStatus(selectedLocation?.network?.status);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingSpinnerComp isLoading={isLoading} />
      ) : (
        <React.Fragment>
          <CardWrapperDetailsPage backgroundColor="#FBFCFE">
            {!(isMapView && mobileScreen) ? (
              <DetailsPageHeader
                avatar={avatar}
                coverageType={coverageType}
                dependentSeqNbr={dependentSeqNbr}
                imageLocation={providerDetails?.imageLocation}
                inNetwork={networkStatus}
                nameAttrs={nameAttrs}
                npi={providerDetails?.npi}
                practiceId={selectedLocation?.docasapPracticeId}
                primarySpeciality={providerDetails?.specialties}
                professionalDesignation={providerDetails?.primaryDegrees?.[0]}
                providerId={providerId}
                providerLocations={providerLocations}
                providerName={providerDetails?.providerName}
                providerType={ProviderType.PRACTITIONER}
                rating={providerDetails?.healthGradeRating}
                setNavigateToDirections={setNavigateToDirections}
                surveyCount={providerDetails?.surveyCount}
                virtualCareOffered={selectedLocation?.virtualCareOffered}
              />
            ) : null}
          </CardWrapperDetailsPage>
          <CardWrapperDetailsPage>
            <ProviderDetailTabs
              chipValue={chipValue}
              coverageType={coverageType}
              isMapView={isMapView}
              isTiered={isTieredDataCard || isTieredSnackCard}
              locationKey={selectedLocation?.locationKey}
              navigateToDirections={navigateToDirections}
              providerDetails={providerDetails}
              setIsMapView={setIsMapView}
              setNavigateToDirections={setNavigateToDirections}
            />
          </CardWrapperDetailsPage>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
