import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Fragment, useEffect } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { Constants } from '../../../../common/Constants';
import { usePlanSelector } from '../../../../hooks/usePlanSelector';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { loginNotRequired } from '../../../../utils/session.utils';
import { IChildrenProp } from '../../types';
import { getPlanSelectorVariables } from './helpers';

export const PlanSelector = ({ children }: IChildrenProp) => {
  const [planSelector, setPlanSelector] = usePlanSelector({});
  const [, setMemberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  const { planToken, accountId } = getPlanSelectorVariables();
  const setPlanSelectorState = useStore(StoreKeys.SET_PLAN_SELECTOR);
  const { isLoading } = planSelector;
  const isLocal = loginNotRequired();

  useEffect(() => {
    if (!isLocal) {
      setPlanSelector({
        variables: {
          planToken,
          accountId,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (planSelector.data) {
      setPlanSelectorState(planSelector.data.planSelector);
      const memberId = parseInt(
        planSelector.data.planSelector.bigN.MemberNumber,
        10
      );
      setMemberId(memberId);
    }
  }, [planSelector]);

  return (
    <Fragment>
      {isLoading ? (
        <LoadingSpinner
          ariaLoadingLabel="Loading page"
          isLoading={isLoading}
          size="$sm"
        />
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </Fragment>
  );
};
