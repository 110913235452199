/* eslint-disable react/no-unused-prop-types */
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Badge } from '@abyss/web/ui/Badge';
import { Card } from '@abyss/web/ui/Card';
import { CollapseProvider } from '@abyss/web/ui/CollapseProvider';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  Degrees,
  Education,
  Identifiers,
} from '../../../../models/ProviderDetails';
import { capitalizeEachWord } from '../../../../utils/providerNames.utils';
import { getCurrentMember } from '../../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { ConstantsRoutes } from '../../../ConstantsRoutes';
import {
  h2SmallerFontForResponsive,
  mobileOnly,
  phoneOnly,
  tabletOnly,
} from '../../../ConstantsStyles';
import { getFeatureFlag } from '../../../Utils';
import {
  CollapseCardHeaderStyle,
  CollapseCardSectionProviderSpecialtyStyle,
  CollapseCardSectionStyle,
  HospitalAffiliationsContainerStyle,
  collapseSection,
} from '../About.styled';
import { ListCulturalCompetencies } from './ListCulturalCompetencies';
import { ListOtherProviderDetails } from './ListOtherProviderDetails';

function listDegrees(school: string, degrees: Degrees[]) {
  const { t } = useTranslation();
  const getDegree = (degree) =>
    `${degree?.description ? capitalizeEachWord(degree?.description) : ''} (${
      degree?.degreeCode || ''
    })`;

  return school === t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
    degrees?.map((degree) => getDegree(degree))
  ) : (
    <React.Fragment>
      <b> {school ? capitalizeEachWord(school) : ''}</b>
      <Layout.Stack alignItems="left" space={2}>
        {degrees?.map((degree) => getDegree(degree))}
      </Layout.Stack>
    </React.Fragment>
  );
}

interface referralRequiredSpecialties {
  name: string;
}

type Props = {
  collapseConstantKey: string;
  collapseContent?: string[];
  collapseHeader: string;
  culturalCompetencies?: string[];
  education?: Education[] | null;
  hospitalAffiliations?: Identifiers[] | null;
  licenseNumber?: string[];
  npi?: string;
  referralRequiredSpecialties?: referralRequiredSpecialties[] | null;
  showCulturalCompetencies?: Boolean;
};

export const CollapseProviderDetailsSection = ({
  collapseConstantKey,
  collapseContent,
  collapseHeader,
  culturalCompetencies,
  education,
  hospitalAffiliations,
  licenseNumber,
  npi,
  referralRequiredSpecialties = [],
  showCulturalCompetencies = true,
}: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly);
  const mobileScreen = useMediaQuery(mobileOnly);
  const tabletScreen = useMediaQuery(tabletOnly) || isWidget;
  const widgetPhoneScreen = useMediaQuery(phoneOnly) && isWidget;
  const WidgetDesktopScreen = !useMediaQuery(phoneOnly) && isWidget;
  const WidgetPages = widgetPhoneScreen || WidgetDesktopScreen;

  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const currentMember = getCurrentMember();
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const referralRequiredEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.REFERRAL_REQUIRED_BANNER
  );

  const doShowReferralBanner =
    currentMember.isGatedUser && referralRequiredEnabled;

  const { t } = useTranslation();
  const handleHospitalAffiliationOnClick = (providerId, name) => {
    adobeLinkTrackEvent({
      name,
      location: `body:${Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS}`,
      type: 'internal',
      destinationUrl: '',
    });
    const updatedToken = tokenizer.update(token, {
      providerId,
      sectionType: Constants.RESULT_SECTION.FACILITY,
    });
    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      providerId
    );
    navigate(`${ConstantsRoutes.FACILITY_DETAILS.path}${updatedToken}`);
  };

  function listHospitalAffiliationsResponsive(
    name: string,
    providerId: string
  ) {
    return (
      <Layout.Stack alignItems="left" space={2}>
        {isWidget ? (
          <Text
            color="#4B4D4F"
            data-testid="hospital-affiliations-widget"
            fontWeight="$medium"
            size={mobileScreen ? '$md' : '18px'}
          >
            {capitalizeEachWord(name)}
          </Text>
        ) : (
          <Link
            data-auto-testid="hospital-affiliations-content-desktop"
            data-testid="hospital-affiliations-content-desktop"
            fontWeight="$bold"
            isStandardAnchor
            onClick={() => {
              handleHospitalAffiliationOnClick(providerId, name);
            }}
          >
            {capitalizeEachWord(name)}
          </Link>
        )}
      </Layout.Stack>
    );
  }

  function hospitalAffiliationLink(name, hospitalAffiliationProviderId) {
    return (
      <Link
        css={{
          'abyss-link-root': {
            maxWidth: '155px',
          },
        }}
        data-auto-testid="hospital-affiliations-content-desktop"
        data-testid="hospital-affiliations-content-desktop"
        fontWeight="$bold"
        isStandardAnchor
        onClick={() =>
          handleHospitalAffiliationOnClick(hospitalAffiliationProviderId, name)
        }
      >
        <Text
          color="$info1"
          css={{
            'abyss-text-root': {
              maxWidth: '127px',
              fontWeight: '$semibold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          {capitalizeEachWord(name)}
        </Text>
        <IconMaterial css={{ marginLeft: '4px' }} icon="chevron_right" />
      </Link>
    );
  }

  function hospitalAffiliationsChips(
    name: string,
    hospitalAffiliationProviderId: string,
    tooltip: boolean
  ) {
    return (
      <Flex css={HospitalAffiliationsContainerStyle}>
        {tooltip ? (
          <Tooltip
            align="center"
            content={capitalizeEachWord(name)}
            css={{
              'abyss-text-root': {
                fontSize: '14.22px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '16px',
              },
            }}
            position="top"
            positionOffset={8}
          >
            {hospitalAffiliationLink(name, hospitalAffiliationProviderId)}
          </Tooltip>
        ) : (
          hospitalAffiliationLink(name, hospitalAffiliationProviderId)
        )}
      </Flex>
    );
  }

  const getAboutDetailResponsive = (detailCollapseHeaderResponsive) => {
    switch (detailCollapseHeaderResponsive) {
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION:
        return (
          <React.Fragment>
            {education?.map((item) => (
              <Card.Section
                css={CollapseCardSectionStyle}
                key={`collapse-section-${item?.schoolName}`}
              >
                <Layout.Stack alignItems="left" space={2}>
                  {listDegrees(item.schoolName, item.degrees)}
                </Layout.Stack>
              </Card.Section>
            ))}
            {showCulturalCompetencies && (
              <Card.Section
                css={CollapseCardSectionStyle}
                key="collapse-section-cultural-competence"
              >
                <Layout.Stack
                  alignItems="left"
                  space={2}
                  style={{ marginTop: '12px' }}
                >
                  <ListCulturalCompetencies
                    culturalCompetencies={culturalCompetencies}
                    mobileScreen={mobileScreen}
                  />
                </Layout.Stack>
              </Card.Section>
            )}
          </React.Fragment>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .HOSPITAL_AFFILIATIONS:
        return hospitalAffiliations?.map((item) =>
          item.value === t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
            <Text
              color="#4B4D4F"
              fontWeight="$medium"
              key={`hospital-affiliations-na`}
              size={mobileScreen ? '$md' : '18px'}
            >
              {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA')}
            </Text>
          ) : (
            listHospitalAffiliationsResponsive(item.value, item.id)
          )
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .OTHER_PROVIDER_DETAILS:
        return (
          <Layout.Stack alignItems="left" space={12}>
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NATIONAL_PROVIDER_IDENTIFIER'
              )}
              idNumber={npi}
            />
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.LICENSE_NUMBER'
              )}
              idNumber={licenseNumber?.join(', ')}
            />
          </Layout.Stack>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .PROVIDER_SPECIALTIES:
        return collapseContent?.map((item) => {
          const referralRequired =
            doShowReferralBanner &&
            referralRequiredSpecialties?.find(
              (rrs) => rrs?.name?.toLowerCase() === item?.toLowerCase()
            );
          return (
            <Layout.Group
              css={{ '&.abyss-card-section': { display: 'flex' } }}
              key={`collapse-section-${item}`}
            >
              <Card.Section
                css={CollapseCardSectionProviderSpecialtyStyle(isWidget)}
              >
                {capitalizeEachWord(item)}
              </Card.Section>
              {referralRequired ? (
                <Badge data-testid={`${item}-ref-req-mobile`} variant="info">
                  {t('Referral required')}
                </Badge>
              ) : null}
            </Layout.Group>
          );
        });
      default:
        return collapseContent?.map((item) => (
          <Card.Section
            css={CollapseCardSectionStyle}
            key={`collapse-section-${item}`}
          >
            {capitalizeEachWord(item)}
          </Card.Section>
        ));
    }
  };

  const getAboutDetail = (detailCollapseHeader) => {
    switch (detailCollapseHeader) {
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION:
        return (
          <span data-testid="education-label">
            {education?.map((item) => (
              <Text
                color="#4B4D4F"
                fontWeight="$medium"
                key={`expand-section-${item?.schoolName}`}
                size={18}
              >
                <Layout.Stack alignItems="left" space={2}>
                  {listDegrees(item.schoolName, item.degrees)}
                </Layout.Stack>
              </Text>
            ))}
            {showCulturalCompetencies && (
              <Text
                color="#4B4D4F"
                fontWeight="$medium"
                key="expand-section-cultural-competence"
                size={18}
              >
                <Layout.Stack
                  alignItems="left"
                  space={2}
                  style={{ marginTop: '12px' }}
                >
                  <ListCulturalCompetencies
                    culturalCompetencies={culturalCompetencies}
                    mobileScreen={mobileScreen}
                  />
                </Layout.Stack>
              </Text>
            )}
          </span>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .HOSPITAL_AFFILIATIONS:
        return (
          <Layout.Group>
            {hospitalAffiliations?.slice(0, 6).map((item) =>
              item.value ===
              t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
                <Text
                  color="#4B4D4F"
                  fontWeight="$medium"
                  key={`hospital-affiliations-na`}
                  size={mobileScreen ? '$md' : '18px'}
                >
                  {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA')}
                </Text>
              ) : (
                hospitalAffiliationsChips(item.value, item.id, !tabletScreen)
              )
            )}
          </Layout.Group>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .OTHER_PROVIDER_DETAILS:
        return (
          <Layout.Stack alignItems="left" space={24}>
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NATIONAL_PROVIDER_IDENTIFIER'
              )}
              idNumber={npi}
            />
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.LICENSE_NUMBER'
              )}
              idNumber={licenseNumber?.join(', ')}
            />
          </Layout.Stack>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .PROVIDER_SPECIALTIES:
        return collapseContent?.map((item) => {
          const referralRequired =
            doShowReferralBanner &&
            referralRequiredSpecialties?.find(
              (rrs) => rrs?.name?.toLowerCase() === item?.toLowerCase()
            );
          return (
            <Layout.Group
              data-testid="provider-with-specialities"
              key={`expand-section-${item}`}
            >
              <Text
                color="#4B4D4F"
                data-testid="provider-with-sub-specialities"
                fontWeight="$medium"
                size={18}
              >
                {capitalizeEachWord(item)}
              </Text>
              {referralRequired ? (
                <Badge data-testid="referral-required" outline variant="info">
                  {t('Referral required')}
                </Badge>
              ) : null}
            </Layout.Group>
          );
        });
      default:
        return collapseContent?.map((item) => (
          <Text
            color="#4B4D4F"
            fontWeight="$medium"
            key={`expand-section-${item}`}
            size={18}
          >
            {capitalizeEachWord(item)}
          </Text>
        ));
    }
  };

  return (
    <React.Fragment>
      {phoneScreen || isWidget ? (
        <Layout.Group
          css={collapseSection(
            true,
            mobileScreen,
            phoneScreen,
            widgetPhoneScreen,
            WidgetDesktopScreen,
            WidgetPages
          )}
        >
          <CollapseProvider defaultIsOpen={false}>
            <Card
              collapse
              css={CollapseCardHeaderStyle}
              header={t(collapseHeader)}
            >
              {getAboutDetailResponsive(collapseConstantKey)}
            </Card>
          </CollapseProvider>
        </Layout.Group>
      ) : (
        <Layout.Group
          css={collapseSection(false, mobileScreen, phoneScreen)}
          isFullWidth={
            collapseHeader ===
            t(
              'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS'
            )
          }
        >
          <Layout.Stack alignItems="left">
            <Heading
              css={h2SmallerFontForResponsive}
              data-testid={`${t(collapseHeader)}`
                .replace(/ /g, '-')
                .toLocaleLowerCase()}
              display="h4"
              offset={1}
            >
              {t(collapseHeader)}
            </Heading>
            {getAboutDetail(collapseConstantKey)}
          </Layout.Stack>
        </Layout.Group>
      )}
    </React.Fragment>
  );
};
