import { useRouter } from '@abyss/web/hooks/useRouter';
import React from 'react';

import { ProviderSearch } from '../../parcels/src/frontends/ProviderSearch';
import { DetailsDrawer } from './DetailsDrawer';

export const browser = () => {
  const { getLocation } = useRouter();
  const { pathname = '' } = getLocation() || {};

  if (pathname === '/details-widget') {
    return <DetailsDrawer />;
  }

  return <ProviderSearch />;
};
