import { createContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import translationsEnJson from '../../public/locale/en/translation.json';
import {
  Constants,
  DEFAULT_SEARCH_RADIUS,
  FilterCollectionModel,
  FilterOptionValues,
  SortOptionValues,
} from '../common/Constants';
import {
  FiltersDefaultValues,
  ProviderSearchFilters,
} from '../models/ProviderSearch';

interface SearchFiltersContextType {
  searchFilters: ProviderSearchFilters;
  defaultSearchFilters: ProviderSearchFilters;
  setSearchFilters: (selectedFilters: ProviderSearchFilters) => void;
  setDefaultSearchFilters: (
    defaultFilters: FilterCollectionModel[],
    defaultValues?: FiltersDefaultValues
  ) => void;
  clearSearchFilters: () => void;
}

type ProviderDefaultValue = {
  autoSearchRadius?: number;
};

export const SearchFilterContext = createContext<SearchFiltersContextType>({
  searchFilters: {},
  defaultSearchFilters: {},
  setSearchFilters: () => {},
  setDefaultSearchFilters: () => {},
  clearSearchFilters: () => {},
});

const getFilterDefaultValues = (
  translate,
  requiredFilters: FilterCollectionModel[] = [],
  defaultValues: FiltersDefaultValues = {}
): ProviderSearchFilters => {
  const { autoSearchRadius = DEFAULT_SEARCH_RADIUS } = defaultValues;

  const filtersDefaultValues: ProviderSearchFilters = {
    [FilterCollectionModel.SORT_BY]: {
      label: translate('SORT_OPTIONS.BEST_MATCH'),
      value: SortOptionValues.BEST_MATCH,
      hide: true,
      dnt_label: translationsEnJson.SORT_OPTIONS.BEST_MATCH,
    },
    [FilterCollectionModel.ACCEPTING_NEW_PATIENTS]: [
      {
        label: translate('FILTER_OPTIONS.AcceptingNewPatients.Y'),
        value: FilterOptionValues.AcceptingNewPatients.Yes,
        dnt_label: translationsEnJson.FILTER_OPTIONS.AcceptingNewPatients.Y,
      },
    ],
    [FilterCollectionModel.AUTO_SEARCH_RADIUS]: autoSearchRadius,
  };

  const filterValues = {};
  requiredFilters.forEach((key) => {
    if (filtersDefaultValues[key])
      filterValues[key] = filtersDefaultValues[key];
  });

  return filterValues;
};

export const SearchFilterContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t: translate } = useTranslation();

  const [defaultValue, setDefaultvalue] =
    useSessionStorage<ProviderDefaultValue>(
      Constants.STORAGE_KEYS.SESSION.DEFAULT_SEARCH_FILTERS,
      {}
    );

  const defaultFiltersState: ProviderSearchFilters = useMemo(
    () =>
      getFilterDefaultValues(
        translate,
        [
          FilterCollectionModel.SORT_BY,
          FilterCollectionModel.AUTO_SEARCH_RADIUS,
        ],
        defaultValue
      ),
    [JSON.stringify(defaultValue)]
  );

  const [storedSearchFilters, setStoredSearchFilters] =
    useSessionStorage<ProviderSearchFilters>(
      Constants.STORAGE_KEYS.SESSION.SELECTED_SEARCH_FILTERS,
      defaultFiltersState
    );

  const [searchFilters, setSearchFilters] =
    useState<ProviderSearchFilters>(storedSearchFilters);

  useEffect(() => {
    setStoredSearchFilters(searchFilters);
  }, [JSON.stringify(searchFilters)]);

  const onSearchFiltersChange = (selectedFilters: ProviderSearchFilters) => {
    setSearchFilters(selectedFilters);
  };

  const onDefaultFiltersChange = (
    defaultFilters: FilterCollectionModel[] = [],
    defaultValues: FiltersDefaultValues = {}
  ) => {
    const filterValues = getFilterDefaultValues(
      translate,
      defaultFilters,
      defaultValues
    );

    setDefaultvalue(defaultValues);
    setSearchFilters({ ...defaultFiltersState, ...filterValues });
  };

  const onClearSearchFilters = () => {
    setSearchFilters(defaultFiltersState);
  };

  return (
    <SearchFilterContext.Provider
      value={{
        searchFilters,
        defaultSearchFilters: defaultFiltersState,
        setSearchFilters: onSearchFiltersChange,
        setDefaultSearchFilters: onDefaultFiltersChange,
        clearSearchFilters: onClearSearchFilters,
      }}
    >
      {children}
    </SearchFilterContext.Provider>
  );
};
