import { useTranslation } from 'react-i18next';

import {
  Constants,
  FilterCollectionModel,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { suppressFiltersByFeatureAndPolicy } from '../../common/Utils/suppressFiltersByFeatureAndPolicy';
import { useCoverageType } from '../useCoverageType';
import { useLagoon } from '../useLagoon';

const { PROVIDER, PROVIDER_GROUPS, FACILITY } = Constants.RESULT_SECTION;
const BEHAVIORAL_HEALTH = ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];
const { MEDICAL, VISION, DENTAL } = ReverseCoverageTypesCodes;

export const useProviderFilters = () => {
  const { t } = useTranslation();
  const coverageType = useCoverageType();
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const isACOFilterDisabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_ACO_FILTER
  );
  const isVirtualCareFilterEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_VIRTUAL_CARE_BH_FILTER
  );

  const filters = [
    {
      label: 'ACO',
      title: t('CONTENT_FILTERS.ACO'),
      model: FilterCollectionModel.ACONAME,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 14,
        [BEHAVIORAL_HEALTH]: 14,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      chipFiltersOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 0,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
      isDisabled: !!isACOFilterDisabled,
    },
    {
      label: t('CONTENT_FILTERS.DISTANCE'),
      model: FilterCollectionModel.DISTANCE,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 4,
        [BEHAVIORAL_HEALTH]: 4,
        [VISION]: 1,
        [DENTAL]: 1,
      },
      chipFiltersOrder: {
        [MEDICAL]: 1,
        [BEHAVIORAL_HEALTH]: 1,
        [VISION]: 1,
        [DENTAL]: 1,
      },
      options: [1, 5, 10, 25, 50, 100].map((value) => ({
        label: `${value} ${t('CONTENT_FILTERS.MILE')}${value > 1 ? 's' : ''}`,
        value,
        dnt_label: `${value} miles`,
      })),
    },
    {
      label: t('CONTENT_FILTERS.VIRTUAL_CARE'),
      title: t('CONTENT_FILTERS.VIRTUAL_CARE'),
      model: FilterCollectionModel.VIRTUAL_CARE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 0,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      chipFiltersOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 2,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [
        {
          label: t('Offers virtual care'),
          value: true,
        },
      ],
      isDisabled: !isVirtualCareFilterEnabled,
    },
    {
      label: t('CONTENT_FILTERS.PATIENT_REVIEWS'),
      model: FilterCollectionModel.PATIENT_REVIEWS,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [PROVIDER],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [PROVIDER],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 5,
        [BEHAVIORAL_HEALTH]: 3,
        [VISION]: 3,
        [DENTAL]: 2,
      },
      chipFiltersOrder: {
        [MEDICAL]: 2,
        [BEHAVIORAL_HEALTH]: 2,
        [VISION]: 2,
        [DENTAL]: 2,
      },
      options: [
        {
          label: t('CONTENT_FILTERS.5_STARS'),
          value: '5',
          dnt_label: '5 Stars',
        },
        ...['4', '3', '2'].map((value) => ({
          label: `${value} ${t('CONTENT_FILTERS.STARS_AND_UP')}`,
          value,
          dnt_label: `${value} Stars and up`,
        })),
        {
          label: t('CONTENT_FILTERS.1_STAR'),
          value: '1',
          dnt_label: '1 Star and up',
        },
      ],
    },
    {
      label: t('CONTENT_FILTERS.SPECIALTY'),
      model: FilterCollectionModel.SPECIALTY_CODE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 6,
        [BEHAVIORAL_HEALTH]: 6,
        [VISION]: 2,
        [DENTAL]: 3,
      },
      chipFiltersOrder: {
        [MEDICAL]: 6,
        [BEHAVIORAL_HEALTH]: 6,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.AREAS_OF_EXPERTISE'),
      model: FilterCollectionModel.AREA_OF_EXPERTISE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 8,
        [BEHAVIORAL_HEALTH]: 8,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      chipFiltersOrder: {
        [MEDICAL]: 10,
        [BEHAVIORAL_HEALTH]: 10,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.FACILITY_TYPE'),
      model: FilterCollectionModel.FACILITY_TYPE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [FACILITY],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [FACILITY],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 6,
        [BEHAVIORAL_HEALTH]: 6,
        [VISION]: 2,
      },
      chipFiltersOrder: { [MEDICAL]: 5, [BEHAVIORAL_HEALTH]: 5, [VISION]: 2 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.EVENING_WEEKENDS'),
      model: FilterCollectionModel.EVENING_AND_WEEKEND,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 11,
        [BEHAVIORAL_HEALTH]: 11,
        [VISION]: 5,
        [DENTAL]: 5,
      },
      chipFiltersOrder: {
        [MEDICAL]: 10,
        [BEHAVIORAL_HEALTH]: 10,
        [VISION]: 0,
        [DENTAL]: 0,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.HEALTH_SUPPLIES_OFFERED'),
      model: FilterCollectionModel.HEALTH_SUPPLIES_OFFERED,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [FACILITY],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [FACILITY],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 12,
        [BEHAVIORAL_HEALTH]: 12,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 7, [BEHAVIORAL_HEALTH]: 7, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.ACCESSIBILITY'),
      model: FilterCollectionModel.ACCESSIBILITY_CODE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 13,
        [BEHAVIORAL_HEALTH]: 13,
        [VISION]: 6,
        [DENTAL]: 6,
      },
      chipFiltersOrder: { [MEDICAL]: 10, [BEHAVIORAL_HEALTH]: 10, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.LANGUAGE'),
      model: FilterCollectionModel.LANGUAGE_CODE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS, FACILITY],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 15,
        [BEHAVIORAL_HEALTH]: 15,
        [VISION]: 8,
        [DENTAL]: 8,
      },
      chipFiltersOrder: {
        [MEDICAL]: 11,
        [BEHAVIORAL_HEALTH]: 11,
        [VISION]: 4,
        [DENTAL]: 4,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.GENDER'),
      model: FilterCollectionModel.GENDER_CODE,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [PROVIDER],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 16,
        [BEHAVIORAL_HEALTH]: 16,
        [VISION]: 7,
        [DENTAL]: 7,
      },
      chipFiltersOrder: { [MEDICAL]: 10, [BEHAVIORAL_HEALTH]: 10, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.ETHNICITY'),
      model: FilterCollectionModel.ETHNICITY_CODE,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 17,
        [BEHAVIORAL_HEALTH]: 17,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 10, [BEHAVIORAL_HEALTH]: 10, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.PROVIDER_GROUP'),
      model: FilterCollectionModel.MEDICAL,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 18,
        [BEHAVIORAL_HEALTH]: 18,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 3, [BEHAVIORAL_HEALTH]: 3, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.HOSPITAL_AFFILIATIONS'),
      model: FilterCollectionModel.HOSPITAL_AFFILIATIONS,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 14,
        [BEHAVIORAL_HEALTH]: 10,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 10, [BEHAVIORAL_HEALTH]: 10, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.ACCEPTING_NEW_PATIENTS'),
      model: FilterCollectionModel.ACCEPTING_NEW_PATIENTS,
      type: 'radio',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [],
        [DENTAL]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER, PROVIDER_GROUPS],
        [BEHAVIORAL_HEALTH]: [PROVIDER, PROVIDER_GROUPS],
        [VISION]: [PROVIDER],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 20,
        [BEHAVIORAL_HEALTH]: 20,
        [VISION]: 9,
        [DENTAL]: 9,
      },
      chipFiltersOrder: {
        [MEDICAL]: 10,
        [BEHAVIORAL_HEALTH]: 10,
        [VISION]: 0,
        DENTAL: 0,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.PROGRAMS'),
      model: FilterCollectionModel.BEHAVIORAL_PROGRAM_INDICATOR,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [FACILITY],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 7,
        [BEHAVIORAL_HEALTH]: 7,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 8, [BEHAVIORAL_HEALTH]: 8, [VISION]: 0 },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.SERVICES_PROVIDED'),
      model: FilterCollectionModel.SERVICES_OFFERED,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [],
        [BEHAVIORAL_HEALTH]: [],
        [VISION]: [PROVIDER, FACILITY],
        [DENTAL]: [PROVIDER],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 0,
        [VISION]: 4,
        [DENTAL]: 4,
      },
      chipFiltersOrder: {
        [MEDICAL]: 0,
        [BEHAVIORAL_HEALTH]: 0,
        [VISION]: 3,
        [DENTAL]: 3,
      },
      options: [],
    },
    {
      label: t('CONTENT_FILTERS.UPCOMING_AVAILABILITY'),
      model: FilterCollectionModel.UPCOMING_AVAILABILITY,
      type: 'checkbox',
      chipFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerFilters: {
        [MEDICAL]: [PROVIDER],
        [BEHAVIORAL_HEALTH]: [PROVIDER],
        [VISION]: [],
      },
      allFilterDrawerOrder: {
        [MEDICAL]: 9,
        [BEHAVIORAL_HEALTH]: 8,
        [VISION]: 0,
      },
      chipFiltersOrder: { [MEDICAL]: 4, [BEHAVIORAL_HEALTH]: 3, [VISION]: 0 },
      options: [2, 7, 14, 30].map((value) => ({
        label: `Next ${value} days`,
        value,
        dnt_label: `Next ${value} days`,
      })),
      isDisabled: coverageType !== BEHAVIORAL_HEALTH,
    },
  ];

  suppressFiltersByFeatureAndPolicy(filters);

  return filters;
};
