/* eslint-disable no-nested-ternary */
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { ProviderType } from '../../models/ProviderDetails';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import {
  ProviderRatingStar,
  ProviderRatingText,
  VoiceOnlyText,
} from './DetailsPageHeader.styled';

type Props = {
  rating?: string;
  providerType?: ProviderType;
  surveyCount?: number;
};

export const ProviderRatings = ({
  rating,
  providerType,
  surveyCount,
}: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery<boolean>(phoneOnly) || isWidget;
  const { t } = useTranslation();
  const [shouldGetHGData] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_HEALTH_GRADES_INFO_FOR_PRACTIONER,
  ]);

  const VoiceA11yScreenReader = (
    <VoiceOnlyText>
      {rating
        ? `${rating} ${t('stars')}`
        : t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.RATING_NOT_AVAILABLE')}
    </VoiceOnlyText>
  );
  const surveyRating = shouldGetHGData ? ` (${surveyCount})` : '';
  const ratingTextContent = rating
    ? `${rating}${surveyRating}`
    : t(Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA);
  const RatingsText = () => (
    <React.Fragment>
      {providerType == ProviderType.PRACTITIONER ? ratingTextContent : null}
    </React.Fragment>
  );

  const ratingSize = mobileScreen ? '$sm' : '$md';

  const StartIcons = parseInt(rating || '0') ? 'star' : 'star_border';

  return mobileScreen ? (
    providerType === ProviderType.PRACTITIONER ? (
      <Flex>
        {VoiceA11yScreenReader}
        <ProviderRatingStar
          aria-hidden="true"
          className="star-icon"
          color="$secondary2"
          icon={StartIcons}
          size={ratingSize}
        />
        <ProviderRatingText
          aria-hidden="true"
          className="text"
          color="$primary1"
          data-auto-testid="provider-details-rating"
          data-testid="provider-details-rating"
          size={ratingSize}
        >
          <RatingsText />
        </ProviderRatingText>
      </Flex>
    ) : null
  ) : (
    <Flex
      css={{
        alignItems: 'end',
        flexWrap: 'nowrap',
      }}
      data-testid="provider-deatials-header-rating-container"
      direction="row"
      justify="flex-end"
    >
      {VoiceA11yScreenReader}
      <ProviderRatingStar
        aria-hidden="true"
        className="star-icon"
        color="$secondary2"
        icon={StartIcons}
        size={ratingSize}
      />
      <ProviderRatingText
        aria-hidden="true"
        className="text"
        color="$primary1"
        data-auto-testid="provider-details-rating"
        data-testid="provider-details-rating"
      >
        <RatingsText />
      </ProviderRatingText>
    </Flex>
  );
};
