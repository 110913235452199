import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import React, { Suspense, lazy } from 'react';

import { Constants } from '../Constants';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';

const IconBrand = lazy(async () => ({
  default: (await import('@abyss/web/ui/IconBrand')).IconBrand,
}));

interface Props {
  onClick?: () => void;
  icon: string;
  text: string;
  url?: string;
  'data-testid'?: string;
  isStandardAnchor?: boolean;
  adobeChipName?: string;
}

const containerStyle = {
  'abyss-flex-root': {
    alignItems: 'center',
    background: '$primary2',
    border: '1px solid $gray3',
    boxShadow: '0px 1px 4px 0px rgba(25, 25, 26, 0.16)',
    borderRadius: '8px',
    marginRight: '8px',
    '@screen >= $md': {
      paddingRight: '24px',
      paddingLeft: '16px',
      height: '42px',
    },
    '@screen < $md': {
      paddingRight: '12px',
      paddingLeft: '4px',
      height: '40px',
      marginRight: '0px',
    },
  },
};

export const Chip = ({
  onClick,
  icon,
  'data-testid': testId = '',
  text,
  url = '',
  isStandardAnchor = true,
  adobeChipName = '',
}: Props) => (
  <Flex
    css={containerStyle}
    data-auto-testid="chips-link-commonly-searched-container"
    data-testid="chips-link-commonly-searched-container"
  >
    <LinkWithTracking
      analyticsInfo={{
        location: `body:${Constants.BROWSE_FOR_CARE.HEADING}`,
        ...(adobeChipName && { name: adobeChipName }),
      }}
      css={{
        'abyss-link-root': {
          width: 'max-content',
        },
      }}
      data-auto-testid={`chips-link-${testId}`}
      data-testid={`chips-link-${testId}`}
      fontWeight="$bold"
      href={url}
      isStandardAnchor={isStandardAnchor}
      onClick={onClick}
    >
      {icon ? (
        <Suspense>
          {' '}
          <IconBrand icon={icon} size="$md" variant="twotone" />
        </Suspense>
      ) : null}
      {text ? (
        <Text
          color="$info1"
          css={{
            'abyss-text-root': {
              paddingLeft: '$xs',
              fontWeight: '$semibold',
            },
          }}
        >
          {text}
        </Text>
      ) : null}
    </LinkWithTracking>
  </Flex>
);
