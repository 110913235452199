import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { returnSuppressFlag } from '../../../utils/featureSuppress';
import {
  NoRatingText,
  ProviderCardContent,
  RatingText,
  RatingTextWrapper,
  VoiceOnlyText,
} from '../DataCard.style';
import { StarRating } from './StarRating';

type Props = {
  healthGradeRating: string | undefined;
  providerId: string;
  sectionTypeDerived: string;
  surveyCount?: number;
};
export const ProviderRating = ({
  healthGradeRating,
  providerId,
  sectionTypeDerived,
  surveyCount,
}: Props) => {
  const suppressRatings = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const [shouldGetHGData] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_HEALTH_GRADES_INFO_FOR_PRACTIONER,
  ]);
  const surveyRating = shouldGetHGData ? ` (${surveyCount})` : '';
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ProviderCardContent data-testid="provider-ratings">
        {healthGradeRating && !suppressRatings && (
          <RatingTextWrapper
            data-auto-testid="rating-wrap"
            data-testid="rating-wrap"
          >
            <VoiceOnlyText>{`${healthGradeRating} stars`}</VoiceOnlyText>
            <StarRating
              healthGradeRating={healthGradeRating}
              key={`healthgrade-rating-${healthGradeRating}`}
              providerId={providerId}
              sectionTypeDerived={sectionTypeDerived}
            />

            <RatingText
              aria-hidden="true"
              className="text"
              color="$primary1"
              data-auto-testid={`data-card-rating-${sectionTypeDerived}`}
              data-testid={`data-card-rating-${sectionTypeDerived}`}
            >
              {`${healthGradeRating}${surveyRating}`}
            </RatingText>
          </RatingTextWrapper>
        )}
        {!healthGradeRating && (
          <NoRatingText data-testid="provider-no-rating">
            {t('Ratings not available')}
          </NoRatingText>
        )}
      </ProviderCardContent>
    </React.Fragment>
  );
};
