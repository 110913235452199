import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';

export const ListItemDrawer = styled(Drawer, {
  '.abyss-modal-body': {
    borderTop: '1px solid $gray4',
    color: '$neutralGray',
    '.abyss-modal-section-root': {
      lineHeight: '20px',
      paddingBottom: '32px',
    },
  },
  '.abyss-modal-close-button': {
    margin: '6px 8px 0 0',
    '.abyss-modal-close-icon': {
      fill: '$gray7',
    },
  },
  '.abyss-modal-header-container': {
    padding: '0 16px 16px',
    '.abyss-modal-content-title': {
      fontSize: '$mediumText',
      color: '$primary1',
      fontFamily: '$primary',
      lineHeight: '24px',
      fontWeight: '$bold',
    },
  },
  '.abyss-modal-content-container': {
    height: 'auto',
    borderRadius: '20px 20px 0 0',
  },
});
