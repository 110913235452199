import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';

import { adobeLinkTrackEvent } from '../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  NEW_TAB_CONFIRMATION,
} from '../../../../../../common/Constants';
import { ConstantsRoutes } from '../../../../../../common/ConstantsRoutes';
import { CustomAttributesBlock } from '../../../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';

export const openTabOrNavigateOnClick = (
  providerId,
  token,
  navigate,
  options,
  customAttributesBlock?: CustomAttributesBlock
) => {
  const detailsRoute = ConstantsRoutes.PROVIDER_DETAILS.path;

  const updatedToken = tokenizer.update(token, { providerId });

  storage.session.set(
    Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
    providerId
  );
  adobeLinkTrackEvent({
    name: 'view provider',
    location: `modal:${NEW_TAB_CONFIRMATION}`,
    type: 'internal',
    destinationUrl: `${detailsRoute}${updatedToken}`,
    customAttributesBlock,
  });
  if (options && options?.openInNewTab) {
    window.open(`/findcare${detailsRoute}${updatedToken}`, '_blank');
  } else {
    navigate(`${detailsRoute}${updatedToken}`);
  }
};
