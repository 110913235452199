import { config } from '@abyss/web/tools/config';
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProviderSummaryTypes } from '../../../../../common/Constants';
import { PrintViewProviderDetailsXSText } from './PrintView.style';

const Preferred_Lab = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/preferred_lab_network.svg`;
const Freestanding_Facility = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/freestanding_facility.svg`;
const Designated_Diagnostic = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/check-circle.svg`;

type Props = {
  providerType: ProviderSummaryTypes | undefined;
  showDDP: boolean;
  showFSF: boolean;
  showPreferredContent: boolean;
  showPLN: boolean;
};

export const PreferredProviderLabels = ({
  providerType,
  showDDP,
  showFSF,
  showPLN,
  showPreferredContent,
}: Props) => {
  const { t } = useTranslation();

  if (
    !showPreferredContent ||
    providerType !== ProviderSummaryTypes.ORGANIZATION
  ) {
    return null;
  }

  return (
    <Flex
      css={{
        'abyss-flex-root': {
          gap: '2px',
          padding: 0,
          alignItems: 'flex-end',
          display: 'flex',
          flex: '1',
          flexWrap: 'nowrap',
        },
      }}
      direction="column"
    >
      {showDDP ? (
        <PrintViewProviderDetailsXSText color="#333333">
          <img
            src={Designated_Diagnostic}
            style={{
              width: '12px',
              height: '12px',
              marginRight: '5px',
              filter: 'grayscale(100%)',
            }}
          />
          {t('SHARE_PDF.Designated Diagnostic')}
        </PrintViewProviderDetailsXSText>
      ) : null}
      {showPLN ? (
        <PrintViewProviderDetailsXSText color="#333333">
          <img
            src={Preferred_Lab}
            style={{
              width: '12px',
              height: '12px',
              marginRight: '5px',
              filter: 'grayscale(100%)',
            }}
          />
          {t('SHARE_PDF.Preferred Lab Network')}
        </PrintViewProviderDetailsXSText>
      ) : null}
      {showFSF ? (
        <PrintViewProviderDetailsXSText color="#333333">
          <img
            src={Freestanding_Facility}
            style={{
              width: '12px',
              height: '12px',
              marginRight: '5px',
              filter: 'grayscale(100%)',
            }}
          />
          {t('SHARE_PDF.Freestanding Facility')}
        </PrintViewProviderDetailsXSText>
      ) : null}
    </Flex>
  );
};
