import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { getModuleOrDefault } from '@optum-fpc-psx-mobile-apps/mapping-files-translation';
import { pick } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { adobeImpressionTrackEvent } from '../../../../common/AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeSearchModifiedEvent } from '../../../../common/AdobeTagging/adobeSearchModifiedEvent';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import {
  CareCategories,
  Constants,
  RECOMMENDED_PROVIDER,
  ReverseCoverageTypesCodes,
  SUGGESTED_PROVIDER,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { SectionSkeleton } from '../../../../common/LazyLoaderSkeleton/SectionSkeleton';
import { NullResultsPage } from '../../../../common/NullResultsPage';
import { PSXHeader } from '../../../../common/PSXHeader';
import { getCategoryFromChipCategoriesCount } from '../../../../common/RecentActivity/helper';
import { StillNeedHelp } from '../../../../common/StillNeedHelp';
import {
  convertCoverageType,
  getIndicatorsForImpressions,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { getFeatureFlag } from '../../../../common/Utils/getFeatureFlag';
import { ChipCategoryContext } from '../../../../context/ChipCategoryContext';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCategoriesCount } from '../../../../hooks/useCategoriesCount';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useProviderResults } from '../../../../hooks/useProviderSearch';
import { useSaveRecentSearches } from '../../../../hooks/useRecentSearches/useRecentSearches';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import {
  getAutoIncrementRadius,
  getLagoonConfigValue,
  getProviderSearchParams,
  getSearchRadius,
} from '../../../../utils/providerSearch.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getDependentInfo,
  getNetworkIdsForPES,
} from '../../../../utils/user.utils';
import { NullPageContainerStyled } from '../NullSpecialtyPage/NullSpecialtyPage.styles';
import { SearchedResultSectionData } from './SearchedResultSectionData';
import {
  formatData,
  getNoOfResultsForAnalytics,
  getPayloadWithRecriprocityId,
  getProviderType,
  getProviderTypesFromDisplayedResults,
  isEmptyResult,
} from './searchUtils';
import {
  IsSearchResultsLoading,
  getModifiedPayloadForCategoriesCount,
  getModifiedPayloadForProviderSearch,
} from './utils';

const specialtyCodes = require('../../../../common/PSXHeader/specialtyCodes.json');
const compareAndReturn = (a: any, b: any) => a || b;

const combineProvidersAcrossSections = (
  data: any,
  selectOnlyFacility: boolean
) => {
  let providers: any[] = [];

  if (data?.providers?.length > 0 && !selectOnlyFacility) {
    providers = [
      ...providers,
      ...data.providers.slice(
        0,
        Constants.RESULT_SECTION
          .MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN
      ),
    ];
  }
  if (data?.medicalGroup?.length > 0 && !selectOnlyFacility) {
    providers = [
      ...providers,
      ...data.medicalGroup.slice(
        0,
        Constants.RESULT_SECTION
          .MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN
      ),
    ];
  }
  if (data?.facilities?.length > 0) {
    providers = [
      ...providers,
      ...data.facilities.slice(
        0,
        Constants.RESULT_SECTION
          .MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN
      ),
    ];
  }

  return providers;
};

export const SearchResults = () => {
  const { t } = useTranslation();
  const [specialtySearch, setSpecialtySearch] = useState(false);
  const [uesTranslation, setUEStranslation] = useState({});
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const [currentChip, setCurrentChip] = useState(CareCategories.ALL);
  const [noCategoryDataFound, setNoCategoryDataFound] = useState(false);
  const [emptyResults, setEmptyResults] = useState(false);
  const [isSearchModified, setIsSearchModified] = useState(false);
  const [isPageTracked, setPageTracked] = useState<boolean>(false);
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) ?? {};
  const {
    searchType,
    providerType = '',
    userZip,
    category: searchCategory,
    pesKeyword,
    choosePCP,
    search = '',
    chipValue,
    includeSpecialityRollupCodes: includeSpecialityRollupCodesProps,
    originLinkNameForAnalytics = '',
    searchMethod,
    searchTerm,
    dependentSeqNbr,
    coverageType,
    keyword = '',
    medicalSpeciality,
    onlineRetailers,
    isMixedSuggestions,
    previous,
    coverageTypes = '',
  } = tokenData;

  const { value: contextValue, setChipValue } = useContext(ChipCategoryContext);
  const { clearSearchFilters } = useContext(SearchFilterContext);
  const [isCountySearch, setIsCountySearch] = useState(false);
  const isPcpIndicator =
    choosePCP || contextValue === CareCategories.PRIMARY_CARE;
  let acceptingNewPatients;
  if (isPcpIndicator) {
    acceptingNewPatients = true;
  }
  const {
    longitude,
    latitude,
    name: location,
    zipCode,
  } = useGeoLocationStorage();

  useEffect(() => {
    const UESData = getModuleOrDefault('en', 'uesSpecialityTranslations');
    setUEStranslation(UESData);
    storage.session.set(Constants.STORAGE_KEYS.SESSION.IS_COUNTY_SEARCH, false);
  }, []);

  const [breadcrumbUrls, setBreadcrumbUrls] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const dependentInfo = getDependentInfo(dependentSeqNbr);

  const specialtyCode =
    pesKeyword ?? specialtyCodes[searchCategory?.toUpperCase()];

  const includeSpecialityRollupCodes =
    specialtyCode ?? includeSpecialityRollupCodesProps;

  const allConfigs = useLagoon('config');
  const searchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const autoIncrementRadius = getLagoonConfigValue(
    allConfigs,
    'AUTO_INCREMENT_SEARCH_RADIUS'
  );

  const [shouldGetHGData] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_HEALTH_GRADES_INFO_FOR_PRACTIONER,
  ]);

  const getSearchTitle = (defaultSearchTitle) => {
    if (!isEmpty(tokenData)) {
      return searchType === Constants.SEARCH_TYPES.LOCATION &&
        search !== Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS
        ? userZip
        : defaultSearchTitle;
    }

    return defaultSearchTitle;
  };

  const searchTitle = getSearchTitle(search);

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();

  const customParams = {
    search: searchTitle,
    providerType,
    includeSpecialityRollupCodes,
    searchType,
    pcpIndicator: isPcpIndicator,
    searchRadius: getSearchRadius(searchRadius),
    autoIncrementRadius: getAutoIncrementRadius(autoIncrementRadius),
    acceptingNewPatients,
    ...(dependentSeqNbr && {
      memberDemographics: {
        gender: dependentInfo?.demographics.gender,
        dateOfBirth: dependentInfo?.demographics.dateOfBirth,
      },
    }),
    coverageType,
    keyword,
    onlineRetailers,
    isExtendedSearchEnabled: true,
    shouldGetHGData,
    ...(choosePCP && {
      reciprocityId: getNetworkIdsForPES(
        currentMember,
        coverageType,
        featureFlags
      ),
    }),
    ...(isEmpty(providerType) && { typeaheadSearch: searchTitle }),
  };

  const [categoryCount, getCategoryCountResults] = useCategoriesCount();
  const [providersResult, getProvidersResult] =
    useProviderResults(customParams);
  const [organizationResult, getOrgResult] = useProviderResults(customParams);
  const [medGrpResult, getMedGroupResult] = useProviderResults(customParams);
  const { data: practitionerData, isLoading: providerIsLoading } =
    providersResult;
  const { data: orgdata, isLoading: orgIsLoading } = organizationResult;
  const { data: medGrpData, isLoading: medGrpIsLoading } = medGrpResult;
  const data: any = formatData(practitionerData, orgdata, medGrpData);
  const allProviders = combineProvidersAcrossSections(
    data,
    contextValue === CareCategories.FACILITIES ||
      contextValue === CareCategories.HEALTH_SUPPLIES
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'overview',
    sitesectionLevel1: 'search results',
    sitesectionLevel2: 'category',
    providerBlock: {
      type: convertCoverageType(coverageType),
    },
    impressionBlock: {
      type: 'provider',
      indicator:
        allProviders?.length > 0
          ? getIndicatorsForImpressions(allProviders, false)
          : '',
    },
  });

  if (
    contextValue === CareCategories.FACILITIES ||
    contextValue === CareCategories.HEALTH_SUPPLIES
  ) {
    data.totalCountPractitioners = 0;
    data.totalCountMedicalGroup = 0;
  }

  const groupSpecialtyRollupCodes = () => {
    if (
      includeSpecialityRollupCodes &&
      (searchType === Constants.SEARCH_TYPES.SPECIALTY || specialtySearch)
    ) {
      return includeSpecialityRollupCodes.split(',');
    }
    return undefined;
  };

  const memberCoverageTypes = getCoverageTypes(currentMember);

  const { data: chipCategoryData, isLoading: isCategoryCountLoading } =
    categoryCount;

  const selectedCategory = Constants.CARE_CATEGORIES_TYPEAHEAD.find(
    (category) => category.label === contextValue
  );

  const getDataForProviders = () => {
    setCurrentChip(contextValue);
    const codeByGroup = groupSpecialtyRollupCodes();
    const payloadWithRecriprocityId = JSON.parse(
      JSON.stringify(Constants.CARE_CATEGORIES_NAME_PAYLOAD)
    )
      .filter((category) =>
        memberCoverageTypes.includes(category?.coverages?.[0])
      )
      .map((category) => {
        if (
          category.chipCategory === 'health-supplies' ||
          category.chipCategory === 'labs-and-services' ||
          category.chipCategory === 'behavioral-health'
        ) {
          return category;
        }
        return {
          ...category,
          reciprocityId: getNetworkIdsForPES(
            currentMember,
            choosePCP ? coverageType : category?.coverages?.[0],
            featureFlags
          ),
        };
      });

    const payload = getModifiedPayloadForProviderSearch(
      payloadWithRecriprocityId,
      codeByGroup,
      uesTranslation,
      search,
      keyword,
      coverageTypes.split(',')
    );

    const selectedCategoryPayload = payload.find(
      (item) => item.chipCategory === selectedCategory?.id
    );
    if (selectedCategory || choosePCP) {
      const { practitionerParams, orgParams, medGrpParams } =
        getProviderSearchParams(
          customParams,
          selectedCategoryPayload,
          specialtySearch ? Constants.SEARCH_TYPES.SPECIALTY : searchType,
          choosePCP
        );

      if (
        selectedCategory?.label === CareCategories.FACILITIES ||
        selectedCategory?.label === CareCategories.HEALTH_SUPPLIES
      ) {
        getOrgResult(orgParams);
      } else {
        getProvidersResult(practitionerParams);
        getOrgResult(orgParams);
        getMedGroupResult(medGrpParams);
      }
    }
  };

  useEffect(() => {
    setPageTracked(false);
  }, [contextValue]);

  useEffect(() => {
    const isCategoryDataFound = chipCategoryData?.getChipCategoriesCount?.some(
      (category) => category.chipEnabled
    );
    if (
      (isCategoryDataFound &&
        Object.keys(uesTranslation).length &&
        !isCategoryCountLoading) ||
      choosePCP
    ) {
      setNoCategoryDataFound(false);
      getDataForProviders();
    }
  }, [
    chipCategoryData?.getChipCategoriesCount?.length,
    contextValue,
    latitude,
    longitude,
    search,
    searchTerm,
    JSON.stringify(uesTranslation),
  ]);

  useEffect(() => {
    if (Object.keys(uesTranslation).length && !choosePCP) {
      const payloadWithRecriprocityId = getPayloadWithRecriprocityId({
        currentMember,
        onlineRetailers,
        featureFlags,
      });
      const codeByGroup = groupSpecialtyRollupCodes();
      const payload = getModifiedPayloadForCategoriesCount(
        payloadWithRecriprocityId,
        codeByGroup,
        uesTranslation,
        search,
        keyword,
        coverageTypes.split(',')
      );
      getCategoryCountResults({
        ...customParams,
        payloadwithName: payload,
        searchTerm: compareAndReturn(searchTerm, search),
      });
    }
  }, [
    longitude,
    latitude,
    specialtySearch,
    JSON.stringify(uesTranslation),
    search,
    searchTerm,
  ]);

  useEffect(() => {
    const updatedToken = tokenizer.update(token, {
      searchType:
        isMixedSuggestions && searchType === Constants.SEARCH_TYPES.SPECIALTY
          ? Constants.SEARCH_TYPES.NAME
          : searchType,
    });
    setSpecialtySearch(false);
    navigate(`/results/${updatedToken}`);
    setChipValue('');
    clearSearchFilters();
  }, []);

  useEffect(() => {
    if (previous) {
      const updatedToken = tokenizer.update(token, {
        searchType:
          isMixedSuggestions && searchType === Constants.SEARCH_TYPES.SPECIALTY
            ? Constants.SEARCH_TYPES.NAME
            : searchType,
        previous: false,
      });
      setSpecialtySearch(false);
      navigate(`/results/${updatedToken}`);
    }
  }, [previous]);
  const recentSearchArgs = {
    psxSearchTerm: search,
    location,
    latitude,
    longitude,
    searchType,
    pesSearchTerm: compareAndReturn(includeSpecialityRollupCodes, search),
    isMixedSearchSuggestion: compareAndReturn(isMixedSuggestions, false),
  };

  const isSuperUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.SUPER_USER
  );
  const [, getSaveRecentSearches] = useSaveRecentSearches({});

  useEffect(() => {
    setSpecialtySearch(false);
  }, [search, searchTerm, location, longitude]);

  useEffect(() => {
    if (chipCategoryData?.getChipCategoriesCount?.length) {
      const { getChipCategoriesCount } = chipCategoryData;
      const firstChipId = getChipCategoriesCount?.find(
        (item: { chipEnabled: boolean; chipCategory: string }) =>
          item.chipEnabled
      )?.chipCategory;
      const matchedId = firstChipId;
      const cValue = Constants.CARE_CATEGORIES_TYPEAHEAD.find(
        (item) => item.id === matchedId
      )?.value;
      if (!cValue) setNoCategoryDataFound(true);
      setChipValue(compareAndReturn(cValue, ''));
    }
  }, [JSON.stringify(chipCategoryData), keyword]);

  useEffect(() => {
    const { getChipCategoriesCount } = chipCategoryData;
    if (
      !isSuperUser &&
      getChipCategoriesCount?.length &&
      !specialtySearch &&
      !isCategoryCountLoading
    ) {
      getSaveRecentSearches({
        variables: {
          ...recentSearchArgs,
          category: getCategoryFromChipCategoriesCount(getChipCategoriesCount),
          coverageType: compareAndReturn(coverageType, 'M'),
        },
      });
    }
  }, [isCategoryCountLoading]);

  const resultType = contextValue;

  useEffect(() => {
    setIsSearchModified(false);
  }, [keyword]);

  useEffect(() => {
    setEmptyResults(
      isEmptyResult(
        providerIsLoading || orgIsLoading || medGrpIsLoading,
        resultType,
        data.totalCountOrganizations,
        data.totalCountMedicalGroup,
        data.totalCountPractitioners
      ) || noCategoryDataFound
    );
  }, [
    providerIsLoading,
    medGrpData,
    orgIsLoading,
    resultType,
    data.totalCountOrganizations,
    data.totalCountMedicalGroup,
    data.totalCountPractitioners,
    noCategoryDataFound,
  ]);

  useEffect(() => {
    const totalNumberOfResults = getNoOfResultsForAnalytics(data);

    if (
      !isLoading &&
      contextValue &&
      currentChip === contextValue &&
      totalNumberOfResults > 0
    ) {
      const adobeSearchMethod = compareAndReturn(searchMethod, 'guided');
      const adobeSearchTerm =
        adobeSearchMethod === 'guided'
          ? ''
          : compareAndReturn(searchTerm, search);
      const adobeEventBody = {
        term: adobeSearchTerm,
        type: getProviderType(providerType),
        linkName: originLinkNameForAnalytics,
        method: adobeSearchMethod,
        filters: contextValue?.toLowerCase(),
        numberOfResults: getNoOfResultsForAnalytics(data),
        customAttributesBlock: {
          providerType: getProviderTypesFromDisplayedResults(data),
          searchLocation: zipCode || location,
        },
      };

      adobePageTrackEvent();
      setPageTracked(true);

      if (!isSearchModified) {
        adobeStandardSearchEvent(adobeEventBody);
        setIsSearchModified(true);
      } else {
        adobeSearchModifiedEvent(adobeEventBody);
      }

      if (isMixedSuggestions && specialtySearch) {
        adobeImpressionTrackEvent({
          type: 'search modify',
          message:
            'search instead for providers that include typed term in their name',
          searchTerm: adobeSearchTerm,
          method: adobeSearchMethod,
        });
      }
      if (isMixedSuggestions && !specialtySearch) {
        adobeImpressionTrackEvent({
          type: 'search modify',
          message: 'search instead for providers that can treat typed term',
          searchTerm: adobeSearchTerm,
          method: adobeSearchMethod,
        });
      }

      setCurrentChip('');
    }
  }, [
    providerIsLoading,
    medGrpIsLoading,
    orgIsLoading,
    currentChip,
    specialtySearch,
    contextValue,
  ]);

  useEffect(() => {
    breadcrumbUrls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key] =
      window.location.pathname.replace('/findcare', '');
    const updatedBreadCrumbUrls = pick(
      breadcrumbUrls,
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
    );
    setBreadcrumbUrls(updatedBreadCrumbUrls);
    document.title = `${searchTitle} | ${Constants.SITE_NAME}`;
  }, [searchTitle]);
  useEffect(() => {
    document.title = resultType
      ? `${t(`${resultType}-results-for`)} ${searchTitle} | ${
          Constants.SITE_NAME
        }`
      : Constants.SITE_NAME;
  }, [resultType, searchTitle]);

  const breadcrumbs = [
    {
      title: t('BC Results'),
      href: '',
    },
  ];

  const mobileScreen = useMediaQuery(phoneOnly);

  const eapCodeFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.EAP_CODE
  );

  const isBHCare: boolean = coverageType?.includes(
    ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
  );

  const isLoading = IsSearchResultsLoading(
    orgIsLoading,
    providerIsLoading,
    medGrpIsLoading,
    noCategoryDataFound,
    isCategoryCountLoading,
    selectedCategory?.label
  );

  const onSpecialtyResultClicked = (isSpecialtySearch, title = '') => {
    const updatedToken = tokenizer.update(token, {
      searchType: isSpecialtySearch
        ? Constants.SEARCH_TYPES.SPECIALTY
        : Constants.SEARCH_TYPES.NAME,
    });
    setSpecialtySearch(isSpecialtySearch);

    adobeLinkTrackEvent({
      name: title,
      location: 'body:result heading',
    });

    setChipValue('');
    navigate(`/results/${updatedToken}`);
  };
  const loadSearchedResultDataFn = () =>
    specialtyCode === RECOMMENDED_PROVIDER ||
    specialtyCode === SUGGESTED_PROVIDER ||
    emptyResults ? (
      (medicalSpeciality &&
        chipValue === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS &&
        navigate(`${ConstantsRoutes.NULL_SPECIALTY_RESULTS.path}/${token}`, {
          replace: true,
        })) || (
        <NullResultsPage
          emptyResults={emptyResults}
          onCommonSearchChipClicked={() => {
            setEmptyResults(false);
            setNoCategoryDataFound(false);
          }}
          searchTitle={searchTitle}
          setSpecialtySearch={setSpecialtySearch}
          specialtyCode={specialtyCode}
          specialtySearch={specialtySearch}
        />
      )
    ) : (
      <SearchedResultSectionData
        acceptingNewPatients={acceptingNewPatients}
        choosePCP={choosePCP}
        contextValue={contextValue}
        data={data}
        dependentSeqNbr={dependentSeqNbr}
        eapCodeFlag={eapCodeFlag}
        facilitiesHeaders={organizationResult.headers}
        isBHCare={isBHCare}
        isMixedSuggestions={isMixedSuggestions}
        isPageTracked={isPageTracked}
        medGrpIsLoading={medGrpIsLoading}
        medicalGroupHeaders={medGrpResult.headers}
        onSpecialtyResultClicked={onSpecialtyResultClicked}
        orgIsLoading={orgIsLoading}
        providerHeaders={providersResult.headers}
        providerIsLoading={providerIsLoading}
        resultType={resultType}
        searchMethod={searchMethod}
        searchTerm={searchTerm}
        searchTitle={searchTitle}
        searchType={searchType}
        specialtyCode={specialtyCode}
        specialtySearch={specialtySearch}
        userZip={userZip}
      />
    );

  return (
    <React.Fragment>
      <CountySearchContext.Provider
        value={{ isCountySearch, setIsCountySearch }}
      >
        {!choosePCP ? (
          <PSXHeader
            breadcrumbs={breadcrumbs}
            categoryCount={categoryCount?.data?.getChipCategoriesCount || []}
            dataTestId="search-results-search-form"
            isMixedResultsPage
            showChips={!emptyResults}
            showChoosePCPHeader={false}
            showMemberSelection={!mobileScreen}
            showSearchInputBackButton={mobileScreen}
          />
        ) : (
          <PSXHeader
            breadcrumbs={breadcrumbs}
            dataTestId="search-results-search-form"
            showChips={false}
            showChoosePCPHeader
            showMemberSelection={false}
          />
        )}
        {isLoading ? <SectionSkeleton /> : loadSearchedResultDataFn()}
        <NullPageContainerStyled hidden={!emptyResults}>
          {' '}
          <StillNeedHelp />
        </NullPageContainerStyled>
      </CountySearchContext.Provider>
    </React.Fragment>
  );
};
