import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../hooks/useLagoon';
import { CarePathsResponse } from '../../../models/CarePaths';
import { ProviderDetails } from '../../../models/ProviderDetails';
import {
  getNetworkStatus,
  getSelectedLocationIndex,
} from '../../../utils/providerDetails.utils';
import { Constants } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { convertProviderTypeToAdobeType } from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import { CarePaths } from './CarePaths/CarePaths';
import {
  CommonCostProceduresHeader,
  CommonCostProceduresTextSection,
  CommonProceduresAndCostContainer,
} from './Cost.styled';
import { InNetworkSection } from './InNetworkSection';

type Props = {
  carePathsData: CarePathsResponse;
  providerDetails: ProviderDetails;
  coverageType?: string;
  tabTitle: string;
};

export const Cost = ({
  carePathsData,
  providerDetails,
  coverageType,
  tabTitle,
}: Props) => {
  const [carePathDataAvailable, setCarePathDataAvailable] = useState(false);
  useEffect(() => {
    if (carePathsData && carePathsData?.carePathSteps) {
      setCarePathDataAvailable(carePathsData?.carePathSteps?.length > 0);
    } else {
      setCarePathDataAvailable(false);
    }
  }, [carePathsData]);
  const data = useLagoon('ui-messaging')();

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
  });

  useEffect(() => {
    if (providerDetails.providerName) adobePageTrackEvent();
  }, [providerDetails.providerName]);

  const costEstimateText = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.COST_TAB_COST_ESTIMATES_TEXT,
  });
  const costEstimateTextNotAvailable = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS
      .COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_TEXT,
  });
  const costEstimateHeading = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.COST_TAB_COST_ESTIMATES_HEADING,
  });
  const costEstimateNotAvailableHeading = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS
      .COST_TAB_COST_ESTIMATES_NOT_AVAILABLE_HEADING,
  });
  const locationsDetails = providerDetails?.providerLocations;
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );
  const index = getSelectedLocationIndex(locationsDetails, selectedId);
  const selectedLocation = locationsDetails?.[index];
  const networkStatus = getNetworkStatus(selectedLocation?.network?.status);

  return (
    <React.Fragment>
      <InNetworkSection inNetwork={networkStatus} />
      <CommonProceduresAndCostContainer>
        <Heading
          css={CommonCostProceduresHeader}
          data-auto-testid="cost-tab-cost-estimate-heading"
          data-testid="cost-tab-cost-estimate-heading"
          display="h4"
          offset={1}
        >
          {carePathDataAvailable
            ? costEstimateHeading?.message
            : costEstimateNotAvailableHeading?.message}
        </Heading>
        <CommonCostProceduresTextSection>
          <Flex
            css={{
              '.&abyss-flex-root': {
                color: '$gray7',
                fontSize: '18px',
                '@screen < $sm': {
                  fontSize: '16px',
                  paddingTop: '12px',
                },
              },
              paddingBottom: '8px',
            }}
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: carePathDataAvailable
                  ? costEstimateText?.message
                  : costEstimateTextNotAvailable?.message,
              }}
              style={{ color: '#4B4D4F' }}
            />
          </Flex>
        </CommonCostProceduresTextSection>

        <CarePaths
          carePaths={carePathsData}
          coverageType={coverageType}
          providerId={providerDetails?.providerId}
          selectedLocation={selectedLocation}
          setCarePathDataAvailable={setCarePathDataAvailable}
        />
      </CommonProceduresAndCostContainer>
    </React.Fragment>
  );
};
