import { styled } from '@abyss/web/tools/styled';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';

export const letterBadgeStyles = {
  'abyss-badge-root': {
    width: '44px',
    height: '44px',
    marginBottom: '$md',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    gap: '$md',
    '@screen < $sm': {
      width: '32px',
      height: '32px',
    },
  },
  'abyss-badge-content': {
    fontWeight: '$bold',
    lineHeight: '24px',
    fontSize: '14px',
    color: '#$gray7',
    '@screen < $sm': {
      color: '$gray8',
    },
  },
};

export const SectionContainer = styled(Flex, {
  alignItems: 'start',
  borderWidth: '1px 0px',
  borderStyle: 'solid',
  borderColor: '$cardBorder',
  marginTop: '$sm',

  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
});

export const SearchContainer = styled('div', {
  width: '100%',
});

export const radioGroupStyles = {
  'abyss-radio-group-label': {
    color: '$grey8',
    fontWeight: 700,
    fontSize: '25.63px',
    lineHeight: '32px',
    marginBottom: '24px',
  },
  '&.abyss-radio-group-container': {
    marginBottom: '$lg',
  },
  '&.abyss-radio-root': {
    marginBottom: '$lg',
    padding: '10px 0',
  },
};

export const containerStyles = {
  'abyss-flex-root': {
    padding: '$md $md',
    '@screen >= $md': {
      padding: '$md 84px',
    },
    '@screen < $md': {
      paddingLeft: '16px',
    },
  },
};

export const StyledResultsContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$xs',
  marginBottom: '$md',
  '@screen < $sm': {
    justifyContent: 'space-between',
    width: '100%',
  },
});

export const ResultsContainerStyle = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$xs',
  '@screen < $sm': {
    gap: '0px',
  },
});

export const styledIcon = {
  'abyss-icon-material': {
    marginRight: '4px',
    '@screen < $sm': {
      width: '16px',
      height: '16px',
      marginRight: '0px',
    },
  },
};

export const textContentStyles = {
  'abyss-text-root': {
    marginRight: '$md',
    '@screen < $md': {
      fontSize: '16px',
    },
    '@screen < $sm': {
      color: '#4B4D4F',
    },
  },
};

export const layoutGroupStyles = {
  'abyss-layout-group': {
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
};

export const physicianLinkStyles = {
  display: 'flex',
  '&.abyss-link-root': {
    fontWeight: '$semibold',
    fontSize: '$md',
    lineHeight: '20px',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '12px',
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: '230px',
    maxWidth: '335px',
    '@screen >= $md': {
      maxWidth: '380px',
      marginBottom: '$md',
    },
  },
  marginLeft: '2px',
};

export const StyledPopover = styled(Popover, {
  '&.abyss-popover-content': {
    padding: '24px 24px 24px 16px',
    borderRadius: '12px',
    zIndex: '999 !important',
  },
  '&.abyss-popover-close-button': {
    marginTop: '12.5px',
    right: '$md',
  },
});

export const MobileText = styled(Text, {
  'abyss-text-root': {
    display: 'block',
    '@screen >= $md': {
      display: 'none',
    },
  },
});

export const DesktopSortText = styled(Text, {
  'abyss-text-root': {
    display: 'none',
    '@screen >= $md': {
      display: 'block',
    },
  },
});

export const swapIconStyles = {
  '&div': {
    marginLeft: 0,
  },
};

export const StyledDivider = styled(Divider, {
  '&.abyss-divider-root': {
    height: '1px !important',
    '@screen < $sm': {
      display: 'none',
    },
  },
});

export const ProviderSpeciality = styled('div', {
  fontWeight: 500,
  color: '#4B4D4F',
  sixe: '16px',
  lineHeight: '20px',
  textTransform: 'capitalize',
  textWrap: 'pretty',
});

export const ContentTextContainer = styled(Text, {
  '&.abyss-text-root': {
    color: '#323334',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '500',
    '@screen < $sm': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  '.abyss-link-root': {
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    '@screen < $sm': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
});

export const DirectoryTextContainer = styled(Text, {
  '&.abyss-text-root': {
    color: '#323334',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    '@screen < $sm': {
      fontSize: '14.22px !important',
    },
  },
});
