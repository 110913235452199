import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { phoneOnly } from '../../ConstantsStyles';
import {
  FlexContainer,
  InfoAlignmentContainer,
  infoSubTextContainer,
  infoTitleContainer,
} from './ProviderLocationsDetails.style';

type Props = {
  icon: string;
  infoTitle: string;
  infoSubtext?: null | string;
  children?: ReactNode;
  variant?: String;
  showSubTitle?: Boolean;
  isFromVirtualProviderGroup?: boolean;
};

export const InfoProviderContent = ({
  icon,
  infoTitle,
  infoSubtext,
  children,
  variant,
  showSubTitle = true,
  isFromVirtualProviderGroup,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;
  const infoTitleLowerCase = infoTitle.toLowerCase().split(' ').join('-');
  return (
    <FlexContainer
      data-auto-testid={`provider-info-container-${
        infoTitleLowerCase.includes('accepting')
          ? 'accepting-patients-status'
          : infoTitleLowerCase
      }`}
      data-testid={`provider-info-container-${
        infoTitleLowerCase.includes('accepting')
          ? 'accepting-patients-status'
          : infoTitleLowerCase
      }`}
    >
      <IconMaterial color="$primary1" icon={icon} size={20} variant={variant} />
      <InfoAlignmentContainer>
        <React.Fragment>
          <Text
            css={infoTitleContainer(phoneScreen)}
            data-testid={`${infoTitle}-Heading`.replace(/ /g, '-')}
          >
            {infoTitle}
          </Text>
          {showSubTitle && !isFromVirtualProviderGroup
            ? children || (
                <Text
                  css={infoSubTextContainer(phoneScreen)}
                  data-testid={`${infoTitle}-subtext`.replace(/ /g, '-')}
                >
                  {infoSubtext || t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')}
                </Text>
              )
            : null}
        </React.Fragment>
      </InfoAlignmentContainer>
    </FlexContainer>
  );
};
