/* eslint-disable react/no-array-index-key */
import React from 'react';

type Props = {
  text?: string;
  keyword: string;
};
export const HighlightedSuggestion = ({ text, keyword }: Props) => {
  const lowerText = text?.toLowerCase();
  const lowerKeyword = keyword?.toLowerCase();
  const parts = lowerText
    ?.split(new RegExp(`(${lowerKeyword})`, 'gi'))
    .filter((part) => part);
  let currentPosition = 0;

  return (
    <React.Fragment>
      {keyword.length < 2
        ? text
        : parts?.map((part, index) => {
            currentPosition += part.length;

            return part === lowerKeyword ? (
              <React.Fragment key={index}>
                {text?.substring(currentPosition - part.length, part.length)}
              </React.Fragment>
            ) : (
              <strong
                data-auto-testid="highlighted-suggestion-non-matching-part"
                data-testid="highlighted-suggestion-non-matching-part"
                key={index}
              >
                {text?.substring(currentPosition - part.length, part.length)}
              </strong>
            );
          })}
    </React.Fragment>
  );
};
