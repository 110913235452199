import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { getWhereToGetHelpContent } from '../../utils/whereToGetHelp.utils';
import {
  cardHeaderContainerStyle,
  cardHeaderMyBenefits,
} from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { WhereToGetHelpCard } from './WhereToGetHelpCard/WhereToGetHelpCard';

export type WhereToGetHelpProps = {
  'data-testid'?: string;
  selectedChipValue: string;
};

export const WhereToGetHelp = ({
  'data-testid': testId = 'heading-where-to-get-help',
  selectedChipValue,
}: WhereToGetHelpProps) => {
  const { t } = useTranslation();
  const whereToGetHelp = useLagoon('where-to-get-help')();

  if (!whereToGetHelp) {
    return null;
  }

  const WhereToGetHelpContent = getWhereToGetHelpContent(
    whereToGetHelp,
    selectedChipValue
  );

  if (WhereToGetHelpContent.length === 0) {
    return null;
  }

  return (
    <ContentWrapper>
      <Flex
        alignItems="flex-end"
        css={cardHeaderContainerStyle}
        justify="start"
      >
        <Heading
          css={cardHeaderMyBenefits}
          data-auto-testid={testId}
          data-testid={testId}
          display="h4"
          offset={1}
        >
          {t('WHERE_TO_GET_HELP')}
        </Heading>
      </Flex>
      <WhereToGetHelpCard
        data-auto-testid="where-to-get-help-card-sindhu"
        data-testid="where-to-get-help-card"
        whereToGetHelpContent={WhereToGetHelpContent}
      />
    </ContentWrapper>
  );
};
