import React from 'react';

import { Constants } from '../../../../common/Constants';
import { DisclaimersContainer } from '../../../../common/DisclaimersContainer/DisclaimersContainer';
import { HealthGradesContainerStyled } from '../PSXHome/HealthGradesContainer.styled';

type Props = {
  sectionType: string;
  isVisionCare: boolean;
};

export const ViewAllDisclaimers = ({ sectionType, isVisionCare }: Props) => (
  <React.Fragment>
    {sectionType !== Constants.RESULT_SECTION.FACILITY &&
      sectionType !== Constants.RESULT_SECTION.PROVIDER_GROUPS && (
        <HealthGradesContainerStyled css={{ marginTop: '0px' }}>
          <DisclaimersContainer hideDivider isVisionCare={isVisionCare} />
        </HealthGradesContainerStyled>
      )}
    {isVisionCare &&
    (sectionType === Constants.RESULT_SECTION.FACILITY ||
      sectionType === Constants.RESULT_SECTION.PROVIDER_GROUPS) ? (
      <HealthGradesContainerStyled css={{ marginTop: '0px' }}>
        <DisclaimersContainer hideDivider isVisionCare={isVisionCare} />
      </HealthGradesContainerStyled>
    ) : null}
  </React.Fragment>
);
