import { styled } from '@abyss/web/tools/styled';
import { Heading } from '@abyss/web/ui/Heading';
import { Tabs } from '@abyss/web/ui/Tabs';

export const Container = styled('div', {
  gap: '32px',
  backgroundColor: '$primary2',
});

export const TabContainer = styled('div', {
  color: '$primary1',
  padding: '0px',
});

export const TabsContainer = styled(Tabs, {
  dynamic: ({ cssProps: { isMapView } }) => ({
    '.abyss-tabs-list': {
      '@screen < $sm': {
        display: isMapView ? 'none' : 'flex',
      },
    },
  }),

  '.abyss-c-esCbFz': {
    borderStyle: 'solid',
    borderColor: '$cardBorder',
    borderWidth: '1px 0px 1px 0px ',
  },
  '.abyss-tabs-content-container': {
    borderWidth: '0px 0px 0px 0px',
    padding: '0px',
  },
  '.abyss-tabs-tab-content': {
    fontSize: '$lg',
    fontWeight: '$bold',
    lineHeight: '$lg',
    textAlign: 'center',
  },
  '.abyss-tabs-tab-active-line': {
    borderColor: '$gray7',
    borderRadius: '6px 6px 0px 0px',
  },
  '.abyss-tabs-list': {
    gap: '20px',
    overflowX: 'auto',
    button: {
      padding: '32px $md $sm 12px',
    },

    paddingLeft: 'calc((100% - 1200px) / 2)',
    paddingRight: 'calc((100% - 1200px) / 2)',

    '@media (max-width: 1248px)': {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    '@screen < $md': {
      paddingLeft: '16px',
      paddingRight: '16px',
      scrollBehavior: 'smooth',
      scrollbarColor: 'transparent transparent',
      '&.hide-scrollbar': {
        overflow: 'auto',
        scrollBehavior: 'smooth',
        '&.hide-scrollbar::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    '.abyss-c-jekBZS-kGZVKw-display-row': {
      minWidth: 'fit-content',
    },
  },
});

export const PlaceholderContainer = styled('div', {
  paddingLeft: '84px',
  '@screen < $md': {
    padding: '0px 16px 0px 16px',
  },
});

export const PlaceholderHeader = styled(Heading, {
  color: '$primary1',
  display: 'block',
  marginBottom: '$sm',
  fontWeight: '$bold',
});

export const ContainerForAllSectionsStyled = styled('div', {
  dynamic: ({ cssProps: { display = 'block' } }) => ({
    display,
  }),
  border: '1px solid $cardBorder',
  borderLeft: 'none',
  borderRight: 'none',
  marginBottom: '$sm',
});

export const browseServiceHeader = {
  'abyss-heading-root': {
    marginBottom: '$sm',
    '@screen < $md': {
      fontSize: '$mediumText !important',
      lineHeight: '$lg !important',
    },
  },
};

export const ContainerTalkToNurse = styled('div', {
  border: '1px solid $cardBorder',
  borderLeft: 'none',
  borderRight: 'none',
  marginTop: '8px',
  '@screen < $sm': {
    paddingRight: '8px',
  },
});

export const LocationDetailsContainer = styled('div', {
  padding: '$lg 84px',
  gap: '24px',
  borderTop: '1px solid $cardBorder',
  borderLeft: 'none',
  borderRight: 'none',
  '@screen < $md': {
    padding: '$md',
  },
});

export const VisionCareHeader = {
  'abyss-heading-root': {
    '@screen < $md': {
      fontSize: '$mediumText !important',
      lineHeight: '$lg !important',
    },
  },
};
