import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Divider } from '@abyss/web/ui/Divider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCoverageType } from '../../hooks/useCoverageType';
import { useLagoon } from '../../hooks/useLagoon';
import { getIsCurrentMemberSubscriber } from '../../utils/user.utils';
import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import { Constants, ReverseCoverageTypesCodes } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { ScheduleModal } from '../ScheduleModal';
import { getFeatureFlag } from '../Utils';
import { ResponsiveText } from './DataCard.style';

export const ScheduleButton = styled('button', {
  gap: '4px',
  display: 'flex',
  alignItems: 'center',
});

export const scheduleTextStyles = {
  fontSize: '14.22px !important',
  lineHeight: '16px',
  fontWeight: '600',
  '@screen < $sm': {
    fontSize: '$extraSmallText !important',
    fontWeight: '700',
  },
};

type Props = {
  id?: string;
  npi: string;
  handleSearchResultClick(clickSection: string): void;
  phone: string;
  practiceId: string;
  sectionType?: string;
};

export const Schedule = ({
  id,
  npi,
  handleSearchResultClick,
  phone,
  practiceId,
  sectionType,
}: Props) => {
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const coverageType = useCoverageType();
  const featureFlags = useLagoon('feature-flags')();
  const schedulingEnabled = getFeatureFlag(featureFlags, 'ONLINE_SCHEDULING');
  const showScheduleButton: boolean = getFeatureFlag(
    featureFlags,
    'ENABLE_SCHEDULE_OPTION'
  );
  const isCurrentMemberSubscriber = getIsCurrentMemberSubscriber();
  const docAsapEnabled = schedulingEnabled && practiceId;

  const handleScheduleButtonClick = () => {
    handleSearchResultClick('Schedule');
    adobeModalTrackEvent(
      `schedule a visit:${docAsapEnabled ? 'schedule' : 'call'}`
    );
    setIsOpenScheduleModal(true);
  };

  if (
    !showScheduleButton ||
    !isCurrentMemberSubscriber ||
    [
      ReverseCoverageTypesCodes.DENTAL,
      ReverseCoverageTypesCodes.VISION,
    ].includes(coverageType) ||
    sectionType !== Constants.RESULT_SECTION.PROVIDER
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <div style={{ height: 'auto' }}>
        <Divider margin={0} orientation="vertical" width={1} />
      </div>
      <ScheduleButton
        data-testid={`data-card-calendar-schedule-${sectionType}`}
        onClick={handleScheduleButtonClick}
      >
        <IconMaterial
          aria-hidden="true"
          icon="date_range"
          size={mobileScreen ? 18 : 24}
        />
        <ResponsiveText
          color="$interactive1"
          css={scheduleTextStyles}
          data-auto-testid={`data-card-calendar-schedule-${sectionType}-${id}`}
          data-testid={`data-card-calendar-schedule-${sectionType}-${id}`}
          fontWeight="$bold"
        >
          {t('DATA_CARD.SCHEDULE')}
        </ResponsiveText>
      </ScheduleButton>
      <ScheduleModal
        isOpen={isOpenScheduleModal}
        npi={npi}
        phone={phone}
        practiceId={practiceId}
        setIsOpen={setIsOpenScheduleModal}
      />
    </React.Fragment>
  );
};
