import { getDefaultCompareAttributes } from '../../../ViewAll/utils/getDefaultCompareAttributes';

export function getDefaultCheckedCompareAttributes(
  coverageType: string
): string[] {
  const defaultItems = getDefaultCompareAttributes(coverageType);

  const attributeList: string[] = [];
  defaultItems.map((index) => {
    if (index.isChecked === true) {
      attributeList.push(index.name);
    }
  });

  return attributeList;
}
