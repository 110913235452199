export const distancePopover = {
  'abyss-popover-trigger': {
    display: 'flex',
    alignItem: 'center',
  },
};

export const distanceInfoIcon = {
  'abyss-icon-material': { padding: '0px 6px 0px 0px' },
};

export const distanceInfoIconText = {
  'abyss-text-root': {
    padding: '5px 6px 0px 0px',
    fontSize: '$extraSmallText',
    fontWeight: '$bold',
    lineHeight: '$sm',
    height: '16px',
    width: 'fit-content',
    color: '#196ECF',
  },
};

export const drawerStyle = {
  'abyss-modal-body': {
    color: '$neutralGray',
    '.abyss-modal-section-root': {
      padding: '16px 24px',
    },
  },
  'abyss-modal-close-button': { margin: '8px 10px 0 0' },
  'abyss-modal-header-container': {
    padding: '16px 24px 0px 24px',
    '.abyss-modal-content-title': {
      paddingTop: '2px',
      fontSize: '$mediumText',
      color: '$neutralGray7',
      lineHeight: '24px',
      fontWeight: '$bold',
    },
  },
  'abyss-modal-content-container': {
    height: 'auto',
    borderRadius: '20px 20px 0 0',
  },
};
