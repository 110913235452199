import flatten from 'lodash/flatten';

import {
  CareCategories,
  Constants,
  FACILITIES_B,
  FACILITIES_M,
  ReverseCoverageTypesCodes,
  TypeaheadProviderTypes,
} from '../../../../common/Constants';

const getModifiedPayload = (
  modifiedPayload,
  uesTranslation,
  codeByGroup,
  keyword
) => {
  let payload = modifiedPayload.map((category) => {
    // rollup code for Eye Care Location is currently mapped with medical and hence below overridding is requred.
    // eslint-disable-next-line no-param-reassign
    category.includeSpecialityRollupCodes =
      keyword !== Constants.EYE_CARE_LOCATIONS
        ? []
        : category.includeSpecialityRollupCodes.filter((item: string) =>
            codeByGroup.includes(item)
          );
    return category;
  });
  codeByGroup.forEach((value) => {
    const foundSpecialty = uesTranslation?.[value?.trim()];
    const isBHFacilityFound = foundSpecialty?.find(
      (specialty) =>
        specialty.type === TypeaheadProviderTypes.FACILITY &&
        specialty.coverageType ===
          ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
    );
    if (isBHFacilityFound) {
      payload.forEach((category) => {
        if (category.uesType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH) {
          // eslint-disable-next-line no-param-reassign
          category.includeSpecialityRollupCodes.push(value);
        }
      });
    }
    payload.forEach((category) => {
      foundSpecialty?.forEach((specialty) => {
        if (category.uesType === specialty?.type) {
          // eslint-disable-next-line no-param-reassign
          category.includeSpecialityRollupCodes.push(value);
        }
      });
    });
  });

  payload = payload.filter(
    (eachCategory) => eachCategory.includeSpecialityRollupCodes.length !== 0
  );
  return payload;
};

export const getModifiedPayloadForProviderSearch = (
  payloadWithRecriprocityId,
  codeByGroup,
  uesTranslation,
  search,
  keyword,
  coverageTypes
) => {
  let modifiedPayload = JSON.parse(JSON.stringify(payloadWithRecriprocityId));
  if (!codeByGroup) {
    let rollCodes:string[] = [];
    modifiedPayload = modifiedPayload.map((category) => ({
      ...category,
      name: search,
      ...(category.label === CareCategories.FACILITIES && {
        includeSpecialityRollupCodes: flatten(
          coverageTypes?.map((coverage) => {
            switch (coverage) {
              case 'M':
                rollCodes = [...rollCodes, ...FACILITIES_M];
                break;
              case 'B':
                rollCodes = [...rollCodes, ...FACILITIES_B];
            }
            return rollCodes;
          })
        ),
      }),
    }));
  } else {
    modifiedPayload = getModifiedPayload(
      modifiedPayload,
      uesTranslation,
      codeByGroup,
      keyword
    );
  }

  return modifiedPayload;
};

export const getModifiedPayloadForCategoriesCount = (
  payloadWithRecriprocityId,
  codeByGroup,
  uesTranslation,
  search,
  keyword,
  coverageTypes
) => {
  let modifiedPayload = JSON.parse(JSON.stringify(payloadWithRecriprocityId));
  if (!codeByGroup) {
    let rollCodes:string[] = [];
    modifiedPayload = modifiedPayload.map((category) => ({
      ...category,
      // below modification is required to perform speciality search for Online Providers
      name:
        category.chipCategory === Constants.VISION &&
        search === Constants.ONLINE_PROVIDERS
          ? ''
          : search,
      includeSpecialityRollupCodes:
        category.chipCategory === Constants.VISION &&
        search === Constants.ONLINE_PROVIDERS
          ? ['']
          : category.includeSpecialityRollupCodes,
      ...(category.label === CareCategories.FACILITIES && {
        includeSpecialityRollupCodes: flatten(
          coverageTypes?.map((coverage) => {
            switch (coverage) {
              case 'M':
                rollCodes = [...rollCodes, ...FACILITIES_M];
                break;
              case 'B':
                rollCodes = [...rollCodes, ...FACILITIES_B];
            }
            return rollCodes;
          })
        ),
      }),
    }));
  } else {
    modifiedPayload = getModifiedPayload(
      modifiedPayload,
      uesTranslation,
      codeByGroup,
      keyword
    );
    modifiedPayload = modifiedPayload.map((eachCategory) => ({
      ...eachCategory,
      uesType: undefined,
      includeSpecialityRollupCodes: [
        ...new Set(eachCategory.includeSpecialityRollupCodes),
      ],
    }));
  }
  return modifiedPayload;
};

export const IsSearchResultsLoading = (
  orgIsLoading: boolean,
  providerIsLoading: boolean,
  medGrpIsLoading: boolean,
  noCategoryDataFound: boolean,
  isCategoryCountLoading: boolean,
  selectedCategoryLabel?: string
) => {
  const isLoading =
    (orgIsLoading ||
      (selectedCategoryLabel !== CareCategories.FACILITIES &&
        (providerIsLoading || medGrpIsLoading))) &&
    (!noCategoryDataFound || isCategoryCountLoading);
  return isLoading;
};
