import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import mapboxgl from 'mapbox-gl';
import React, { useState } from 'react';

import { Constants } from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { compareCarouselScreen } from '../../../../../common/ConstantsStyles';
import { getFeatureFlag } from '../../../../../common/Utils';
import { useLagoon } from '../../../../../hooks/useLagoon';
import { CompareProvider } from '../../../../../models/Provider';
import { CustomAttributesBlock } from '../CompareDrawer/Components/ConfirmationModal/ConfirmationModal';
import { CompareCheckboxInfo } from '../CompareDrawer/utility/compareDrawerConstants';
import { openTabOrNavigateOnClick } from '../CompareDrawer/utility/handleDetailsOnClick';
import { ShareButtonContainer } from './ShareButtonContainer';
import { ShareContainerDesktop } from './ShareContainerDesktop';
import { ShareDrawerCarousel } from './ShareDrawerCarousel';
import { ShareContentsInnerDrawer } from './ShareDrawerContents/ShareContentsInnerDrawer';
import { providerShareContentDrawerDesktop } from './ShareDrawerContents/ShareDrawerContents.styled';
import { ShareDrawerDesktop } from './ShareDrawerDesktop';
import { sectionTypeToProviderType } from './utils';

export const displayFloatingSection = (
  compareCheckboxes: CompareCheckboxInfo[],
  openShareContents: boolean,
  setOpenShareContents: (a: boolean) => void,
  showSuccessAlertCallback: () => void,
  isShareAllResults: boolean,
  providerType: string,
  searchKeyword: string,
  specialtyCode: string,
  selectedProviderList:
    | {
        providerName: string;
        providerId: string;
        speciality: string;
        networkStatus: string;
        languagesSpoken: string[];
        acceptingNewPatients: boolean;
        virtualCareOffered: boolean;
        address: {
          line: string[];
          city: string;
          district: string;
          state: string;
          postalCode: string;
        };
        phones: { phone: string[] };
        firstName?: string;
        middleName?: string;
        lastName?: string;
      }[]
    | {
        providerName: string;
        providerId: string;
        primaryDegrees: string[];
        speciality: string;
        address: { line: string[] };
        phones: { phone: string[] };
        locationId: string;
      }[],
  isOriginCompareProviders: boolean
) =>
  openShareContents ? (
    <FloatingSection
      css={providerShareContentDrawerDesktop}
      data-auto-testid="share-contents-drawer"
      data-testid="share-contents-drawer"
      position="bottom"
    >
      <ShareContentsInnerDrawer
        compareCheckboxes={compareCheckboxes}
        isOriginCompareProviders={isOriginCompareProviders}
        isShareAllResults={isShareAllResults}
        openShareContents={openShareContents}
        providerType={providerType}
        searchKeyword={searchKeyword}
        selectedProviderList={selectedProviderList}
        setOpenShareContents={setOpenShareContents}
        showSuccessAlertCallback={showSuccessAlertCallback}
        specialtyCode={specialtyCode}
      />
    </FloatingSection>
  ) : null;

type Props = {
  compareCheckboxes: CompareCheckboxInfo[];
  selected: number;
  openShare: boolean;
  openBoxContents: boolean;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  removeItem: (item: string) => void;
  setOpenShare: (a: boolean) => void;
  setOpenBoxContents: (a: boolean) => void;
  selectedProviderList: {
    providerName: string;
    providerId: string;
    primaryDegrees: string[];
    speciality: string;
    networkStatus: string;
    languagesSpoken: string[];
    acceptingNewPatients: boolean;
    gender: string;
    virtualCareOffered: boolean;
    address: {
      line: string[];
    };
    phones: {
      phone: string[];
    };
    locationId: string;
    imageLocation: string;
    organizationType: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
  }[];
  showSuccessAlertCallback: () => void;
  isShareAllResults: boolean;
  setIsShareAllResults: (a: boolean) => void;
  map: React.MutableRefObject<mapboxgl.Map>;
};

export const ShareDrawer = ({
  compareCheckboxes,
  selected,
  openShare,
  openBoxContents,
  setOpenBoxContents,
  setSelectedItems,
  removeItem,
  setOpenShare,
  setSelectedCheckbox,
  selectedProviderList,
  showSuccessAlertCallback,
  isShareAllResults,
  setIsShareAllResults,
  map,
}: Props) => {
  const [openShareContents, setOpenShareContents] = useState<boolean>(false);
  const { navigate, getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { search, sectionType, specialtyCode } = tokenData;
  const providerType = sectionTypeToProviderType(sectionType);
  const isOriginCompareProviders = false;
  const carouselDisplay = useMediaQuery(compareCarouselScreen);
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const shareButtonFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.SHARE_RECIPIENT_DISABLE
  );

  const handleDetailsOnClickCb = (
    providerId,
    options,
    customAttributesBlock: CustomAttributesBlock
  ) => {
    openTabOrNavigateOnClick(
      providerId,
      token,
      navigate,
      options,
      customAttributesBlock
    );
  };

  return (
    <React.Fragment>
      <React.Fragment>
        {displayFloatingSection(
          compareCheckboxes,
          openShareContents,
          setOpenShareContents,
          showSuccessAlertCallback,
          isShareAllResults,
          providerType,
          search,
          specialtyCode,
          selectedProviderList,
          isOriginCompareProviders
        )}
        <ShareContainerDesktop
          openBoxContents={openBoxContents}
          openShare={openShare}
          selected={selected}
          selectedProviderList={selectedProviderList}
          setOpenBoxContents={setOpenBoxContents}
          setOpenShare={setOpenShare}
          setSelectedCheckbox={setSelectedCheckbox}
          setSelectedItems={setSelectedItems}
          total={5}
        />
        {openBoxContents ? (
          <React.Fragment>
            {carouselDisplay ? (
              <ShareDrawerCarousel
                handleDetailsOnClick={handleDetailsOnClickCb}
                removeItem={removeItem}
                selected={selected}
                selectedProviderList={selectedProviderList}
              />
            ) : (
              <ShareDrawerDesktop
                handleDetailsOnClick={handleDetailsOnClickCb}
                map={map}
                removeItem={removeItem}
                selected={selected}
                selectedProviderList={selectedProviderList}
              />
            )}
          </React.Fragment>
        ) : null}
        {!shareButtonFlag ? (
          <ShareButtonContainer
            openShareContents={openShareContents}
            selected={selected}
            setIsShareAllResults={setIsShareAllResults}
            setOpenBoxContents={setOpenBoxContents}
            setOpenShareContents={setOpenShareContents}
          />
        ) : null}
      </React.Fragment>
    </React.Fragment>
  );
};
